import { useMemo } from 'react'
import { Text, TextProps } from '@chakra-ui/react'
import { Editor } from '@tiptap/react'

import { getCharacterCountFromEditor } from './utils'

export const CharacterCountView = ({
  editor,
  characterLimit,
  ...props
}: {
  editor: Editor
  characterLimit: number
} & TextProps) => {
  const numChars = getCharacterCountFromEditor(editor)

  const remainingChars = useMemo(
    () => characterLimit - numChars,
    [characterLimit, numChars],
  )

  return (
    <Text
      textStyle="body-2"
      color={
        remainingChars === 0
          ? 'interaction.critical.default'
          : 'base.content.medium'
      }
      alignSelf="flex-start"
      {...props}
    >
      {`${remainingChars} characters left`}
    </Text>
  )
}
