import { useEffect } from 'react'
import { create } from 'zustand'

type DirtyFieldStore = {
  isDirty: boolean
  setIsDirty: (isDirty: boolean) => void
}

export const useDirtyFieldStore = create<DirtyFieldStore>((set) => ({
  isDirty: false,
  setIsDirty: (isDirty: boolean) => set({ isDirty }),
}))

export const isDirtySelector = (
  state: DirtyFieldStore,
): DirtyFieldStore['isDirty'] => state.isDirty

export const setIsDirtySelector = (
  state: DirtyFieldStore,
): DirtyFieldStore['setIsDirty'] => state.setIsDirty

export const useSyncToDirtyFieldStore = (isDirty: boolean) => {
  const setIsDirty = useDirtyFieldStore(setIsDirtySelector)
  useEffect(() => {
    setIsDirty(isDirty)

    return () => {
      setIsDirty(false)
    }
  }, [isDirty, setIsDirty])
}
