import { Fragment, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, useDisclosure, VStack } from '@chakra-ui/react'
import { useToast } from '@opengovsg/design-system-react'

import { GetEventRes } from '~shared/dto'

import { useAdminAuth } from '~features/auth'
import { useModifyEventPermission } from '~features/events/hooks/useEventPermission'

import { Collaborator } from './Collaborator'
import { RemoveCollaboratorModal } from './RemoveCollaboratorModal'

export const CurrentCollaborators = ({ event }: { event: GetEventRes }) => {
  const { adminUser } = useAdminAuth()
  const removeCollaboratorModalDisclosure = useDisclosure()
  const toast = useToast()
  const navigate = useNavigate()
  const [selectedEmail, setSelectedEmail] = useState<string>('')
  const isSelf = useMemo(
    () => selectedEmail === adminUser?.email,
    [adminUser?.email, selectedEmail],
  )

  const { mutate, isLoading } = useModifyEventPermission(event.id)

  const onDelete = useCallback(() => {
    mutate(
      {
        userEmail: selectedEmail,
        accessLevel: null,
      },
      {
        onSuccess: (_res, { userEmail }) => {
          if (isSelf) {
            toast({
              description: `Successfully removed yourself.`,
              status: 'success',
            })
            navigate('/admin/dashboard')
          } else {
            toast({
              description: `Successfully removed ${userEmail}`,
              status: 'success',
            })
            removeCollaboratorModalDisclosure.onClose()
          }
        },
      },
    )
  }, [
    isSelf,
    mutate,
    navigate,
    removeCollaboratorModalDisclosure,
    selectedEmail,
    toast,
  ])

  return (
    <>
      <RemoveCollaboratorModal
        email={selectedEmail}
        disclosure={removeCollaboratorModalDisclosure}
        onDelete={onDelete}
        isSelf={isSelf}
        isLoading={isLoading}
      />
      <VStack alignItems="stretch" w="full">
        <Divider />
        <Collaborator email={event.admin.email} accessLevel="owner" />
        <Divider />
        {event.permissions.map((permission) => (
          <Fragment key={permission.userEmail}>
            <Collaborator
              email={permission.userEmail}
              accessLevel={permission.accessLevel}
              onDelete={() => {
                setSelectedEmail(permission.userEmail)
                removeCollaboratorModalDisclosure.onOpen()
              }}
            />
            <Divider />
          </Fragment>
        ))}
      </VStack>
    </>
  )
}
