import { Editor } from '@tiptap/core'
import CharacterCount from '@tiptap/extension-character-count'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import { Extensions, generateHTML, JSONContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

export type RteExtensionOptions = {
  characterLimit: number
}

export const serializeToHTML = ({
  content,
  characterLimit,
}: {
  content: JSONContent
  characterLimit: number
}) => {
  return generateHTML(content, getRteExtensions({ characterLimit }))
}

const baseExtensions: Extensions = [
  StarterKit.configure({ code: false, horizontalRule: false }),
  Link,
  Underline,
  Image,
]

// returns all the extensions
export const getRteExtensions = (options: RteExtensionOptions) => {
  return [
    ...baseExtensions,
    // CharacterCount.configure needs to be called at point of useEditor
    // See https://github.com/ueberdosis/tiptap/issues/2589
    CharacterCount.configure({
      limit: options.characterLimit,
    }),
  ]
}

export const getCharacterCountFromEditor = (editor: Editor) => {
  const storage = editor.storage as {
    characterCount: { characters: () => number }
  }
  return storage.characterCount.characters()
}
