import { ComponentProps, useMemo, useState } from 'react'
import { IconType } from 'react-icons'
import {
  Box,
  Flex,
  ListItem,
  StackProps,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { Hideable } from '~components/Hideable'

import { FieldTypeBadge } from '../FieldTypeBadge'

import { FieldRowActions } from './FieldRowActions'

export interface FieldRowProps extends StackProps {
  fieldDisplayData: {
    label: string
    icon?: IconType
  }
  title: string
  description: string
  options?: string[]
  actions?: ComponentProps<typeof FieldRowActions>
}

const INITIAL_MAX_NUM_OPTIONS_SHOWN = 4

export const FieldRow = ({
  fieldDisplayData,
  title,
  description,
  options,
  actions,
  ...rest
}: FieldRowProps) => {
  const [areExtraOptionsShown, setAreExtraOptionsShown] = useState(false)
  /**
   * Number of options hidden assuming areExtraOptionsShown === false.
   */
  const numOptionsToHide = useMemo(() => {
    if (!options) return null
    if (options.length <= INITIAL_MAX_NUM_OPTIONS_SHOWN) return 0
    return options.length - INITIAL_MAX_NUM_OPTIONS_SHOWN + 1
  }, [options])

  const optionsToShow = useMemo(() => {
    // Case 1: This field has no options, e.g. short text
    if (!options) return null

    // Case 2: User has opted to show all options
    if (areExtraOptionsShown) return options

    // Case 3: There are few enough options to show all of them
    if (numOptionsToHide === 0) return options

    // Case 4: Show a subset of options
    // Why -1? e.g. if there are 5 options but initial max num options is 4,
    // we show 3 options then a button to show the additional 2 options
    return options.slice(0, INITIAL_MAX_NUM_OPTIONS_SHOWN - 1)
  }, [options, areExtraOptionsShown, numOptionsToHide])

  return (
    <VStack alignItems={'stretch'} {...rest}>
      {/* Extra Box prevents Badge from stretching */}
      <Box>
        <FieldTypeBadge {...fieldDisplayData} />
      </Box>
      <Flex
        justifyContent={actions ? 'space-between' : 'start'}
        alignItems="start"
      >
        <Text textStyle={'subhead-2'}>{title}</Text>
        {actions && (
          // Force buttons and title to align
          <FieldRowActions {...actions} />
        )}
      </Flex>
      {description && (
        <Hideable maxHeightPx={36}>
          <Text textStyle={'body-2'}>{description}</Text>
        </Hideable>
      )}
      {options && optionsToShow && (
        <Box>
          <UnorderedList>
            {optionsToShow.map((option, idx) => (
              <ListItem key={idx} textStyle={'body-2'} wordBreak="break-word">
                {option}
              </ListItem>
            ))}
          </UnorderedList>
          {numOptionsToHide !== null && numOptionsToHide > 0 && (
            <Button
              variant="link"
              textDecoration="underline"
              onClick={() => setAreExtraOptionsShown((val) => !val)}
            >
              <Text textStyle={'body-2'}>
                {areExtraOptionsShown
                  ? `Hide ${numOptionsToHide} options`
                  : `Show ${numOptionsToHide} more options`}
              </Text>
            </Button>
          )}
        </Box>
      )}
    </VStack>
  )
}
