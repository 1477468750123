import { Box, Image, Stack, Text } from '@chakra-ui/react'

import EmptyEventSvg from '~/assets/img/empty-event.svg'

import { LoadingState } from '~components/LoadingState'

import { useAdminAuth } from '~features/auth'
import { CreateEventButton } from '~features/events'

export const EmptyDashboard = (): JSX.Element => {
  const { adminUser } = useAdminAuth()
  if (!adminUser)
    return <LoadingState flex={1} py={{ base: '1rem', md: '3rem' }} />

  return (
    <Box py={{ base: '1rem', md: '3rem' }} flex={1}>
      <Stack
        maxW="100ch"
        spacing="1.5rem"
        alignItems="center"
        flexDirection="column"
        ml="auto"
        mr="auto"
        px={3}
      >
        <Box>
          <Text textStyle="h6" color="primary.500" mb="1rem" textAlign="center">
            {`You don't have any events yet`}
          </Text>
        </Box>
        <CreateEventButton />
        <Image src={EmptyEventSvg} w="227px" maxW="100%" aria-hidden />
      </Stack>
    </Box>
  )
}
