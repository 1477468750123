import { IconType } from 'react-icons'
import { Badge, BadgeLeftIcon } from '@opengovsg/design-system-react'

export interface FieldTypeBadgeProps {
  label: string
  icon?: IconType
}

export const FieldTypeBadge = ({ label, icon }: FieldTypeBadgeProps) => {
  return (
    <Badge bgColor={'interaction.sub-subtle.hover'} color="base.content.strong">
      {icon && <BadgeLeftIcon as={icon} />}
      {label}
    </Badge>
  )
}
