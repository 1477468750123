import ReactMarkdown from 'react-markdown'
import { Banner } from '@opengovsg/design-system-react'

import { BannerLevel } from '~shared/types'

import { generateBannerMarkdownComponents } from './banner-util'

export type AppBannerProps = {
  text: string | null
  level: BannerLevel | null
}

export const AppBanner = ({ text, level }: AppBannerProps) => {
  if (!text) {
    return null
  }

  const bannerLevel = level || 'info'
  return (
    <Banner isDismissable={true} variant={bannerLevel}>
      <ReactMarkdown components={generateBannerMarkdownComponents(bannerLevel)}>
        {text}
      </ReactMarkdown>
    </Banner>
  )
}
