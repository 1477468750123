import { BiListPlus } from 'react-icons/bi'
import { useDisclosure } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { CreateEventListModal } from './CreateEventListModal'

export const CreateEventListButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        leftIcon={<BiListPlus />}
        variant="outline"
        w={{ base: 'full', md: 'unset' }}
        onClick={onOpen}
      >
        {`Create event list`}
      </Button>
      <CreateEventListModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}
