import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { anatomy } from '@chakra-ui/theme-tools'

const parts = anatomy('sidebar').parts(
  'item',
  'list',
  'header',
  'label',
  'nest',
  'section',
  'parent',
  'child',
  'icon',
)

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  parent: {
    _hover: {
      bg: 'interaction.muted.main.hover',
    },
    _active: {
      bg: 'interaction.muted.main.active',
      color: 'interaction.main.default',
    },
    _expanded: {
      bg: 'unset',
      color: 'base.content.default',
    },
    borderRadius: 'md',
  },
  child: {
    borderRadius: 0,
    borderLeftWidth: '1px',
    borderColor: 'base.divider.medium',
    _active: {
      ml: '-1px',
      borderLeftWidth: '2px',
      borderColor: 'base.divider.brand',
      bg: 'interaction.muted.main.active',
      color: 'interaction.main.default',
    },
    _hover: {
      ml: '-1px',
      borderLeftWidth: '2px',
      bg: 'interaction.muted.main.hover',
      color: 'interaction.main.default',
      borderColor: 'base.divider.brand',
    },
  },
})

export const Sidebar = defineMultiStyleConfig({
  baseStyle,
})
