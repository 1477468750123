import wretch from 'wretch'
import QueryStringAddon from 'wretch/addons/queryString'
import { WretchError } from 'wretch/resolver'

import { hasProp } from '~shared/utils'

import { BrowserEvent } from '~constants/events'

/**
 * Default API client pointing to backend.
 * Automatically catches 403 errors and invalidates authentication state.
 */
export const api = wretch('/api/v1')
  .catcher(403, (err) => {
    window.dispatchEvent(new Event(BrowserEvent.UNAUTHORIZED))
    throw err
  })
  .catcher(429, (err) => {
    window.dispatchEvent(new Event(BrowserEvent.TOO_MANY_REQUESTS))
    throw err
  })
  .errorType('json')
  .addon(QueryStringAddon)

export type NestError = Omit<WretchError, 'json'> & {
  json: {
    statusCode: number
    message: string
  }
}

export const FALLBACK_NETWORK_ERROR_MESSAGE =
  'Sorry, something went wrong on our end. Please try again later.'

export const isNestError = (error: unknown): error is NestError => {
  if (!(error instanceof WretchError)) return false
  const json = error.json as unknown
  return (
    hasProp(json, 'statusCode') &&
    typeof json.statusCode === 'number' &&
    hasProp(json, 'message') &&
    typeof json.message === 'string'
  )
}

export const getNetworkErrorMessage = (error: unknown): string => {
  return isNestError(error)
    ? error.json.message
    : FALLBACK_NETWORK_ERROR_MESSAGE
}
