import { useCallback, useEffect, useMemo, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useDisclosure } from '@chakra-ui/hooks'
import { Flex, Text, VStack } from '@chakra-ui/react'
import { Button, SingleSelect, useToast } from '@opengovsg/design-system-react'
import isEqual from 'lodash/isEqual'

import {
  CreateDropdownFieldReq,
  CreateFieldReq,
  CreateMobileFieldReq,
  CreateNricFieldReq,
  CreateRadioFieldReq,
  CreateShortTextFieldReq,
  GetFormFieldRes,
} from '~shared/dto'
import { FormFieldType } from '~shared/types'
import { nanoid } from '~shared/utils'

import { useIsClientMobile } from '~hooks/useIsClientMobile'
import { SaveChangesButton } from '~components/SaveChangesButton'

import { useUpdateFormFields } from '~features/events/hooks/useAdminFormFields'
import { useSyncToDirtyFieldStore } from '~features/events/hooks/useDirtyFieldStore'
import { useEventIdParam } from '~features/events/hooks/useEventIdParam'
import { AdminEventOutletContext } from '~features/events/types'

import { createFieldRow } from './components/createFieldRow'
import { DefaultEmailFieldRow } from './components/FieldRow'
import { EditFieldModal } from './components'
import { ADD_FORM_FIELD_OPTIONS } from './fieldDisplayData'

const getDefaultField = (fieldType: FormFieldType) => {
  const baseFieldReq = {
    id: nanoid(),
    fieldType,
    title: 'Type your question here',
    description: '',
  }

  const defaultFieldOptions = ['Option 1', 'Option 2']

  switch (fieldType) {
    case FormFieldType.Radio:
      return {
        ...baseFieldReq,
        fieldOptions: defaultFieldOptions,
      } as CreateRadioFieldReq
    case FormFieldType.Dropdown:
      return {
        ...baseFieldReq,
        fieldOptions: defaultFieldOptions,
      } as CreateDropdownFieldReq
    case FormFieldType.Mobile:
      return baseFieldReq as CreateMobileFieldReq
    case FormFieldType.Nric:
      return baseFieldReq as CreateNricFieldReq
    default:
      return baseFieldReq as CreateShortTextFieldReq
  }
}

export interface InviteeQuestionsProps {
  initialFields: GetFormFieldRes[]
}

export const EditFormFields = (): JSX.Element => {
  const isMobile = useIsClientMobile()
  const { event } = useOutletContext<AdminEventOutletContext>()

  // track the current state of fields before save
  const [currFields, setCurrFields] = useState<CreateFieldReq[]>(
    event.formFields,
  )

  // user selection for which field type to add next
  const [fieldTypeToCreate, setFieldTypeToCreate] = useState<FormFieldType>(
    ADD_FORM_FIELD_OPTIONS[0].type,
  )

  // field currently being edited
  const [editingField, setEditingField] = useState<CreateFieldReq | null>(null)
  const { eventId } = useEventIdParam()
  const { isLoading, mutate: updateFormFields } = useUpdateFormFields(eventId)
  const toast = useToast()
  const {
    onOpen: onModalOpen,
    onClose: onModalClose,
    isOpen: isModalOpen,
  } = useDisclosure()

  const handleSave = useCallback(() => {
    updateFormFields(currFields, {
      onSuccess: () => {
        toast({
          description: 'Form fields updated successfully.',
          status: 'success',
        })
      },
    })
  }, [toast, currFields, updateFormFields])

  const handleModalClose = useCallback(() => {
    setEditingField(null)
    onModalClose()
  }, [onModalClose])

  const handleStartCreatingField = useCallback(
    (fieldType: FormFieldType) => {
      setEditingField(getDefaultField(fieldType))
      onModalOpen()
    },
    [onModalOpen],
  )

  const handleModalSave = useCallback(
    (field: CreateFieldReq) => {
      const fieldIndex = currFields.findIndex(
        (currField) => currField.id === field.id,
      )
      if (fieldIndex === -1) {
        setCurrFields((prevFields) => [...prevFields, field])
      } else {
        setCurrFields((prevFields) => [
          ...prevFields.slice(0, fieldIndex),
          field,
          ...prevFields.slice(fieldIndex + 1),
        ])
      }
      setEditingField(null)
    },
    [currFields],
  )

  const handleStartEditingField = useCallback(
    (idx: number) => {
      setEditingField(currFields[idx])
      onModalOpen()
    },
    [currFields, onModalOpen],
  )

  const handleDeleteField = useCallback((idx: number) => {
    setCurrFields((prevFields) => [
      ...prevFields.slice(0, idx),
      ...prevFields.slice(idx + 1),
    ])
  }, [])

  const handleMoveFieldUp = useCallback((idx: number) => {
    setCurrFields((prevFields) => {
      if (idx === 0) return prevFields
      return [
        ...prevFields.slice(0, idx - 1),
        prevFields[idx],
        prevFields[idx - 1],
        ...prevFields.slice(idx + 1),
      ]
    })
  }, [])

  const handleMoveFieldDown = useCallback((idx: number) => {
    setCurrFields((prevFields) => {
      if (idx === prevFields.length - 1) return prevFields
      return [
        ...prevFields.slice(0, idx),
        prevFields[idx + 1],
        prevFields[idx],
        ...prevFields.slice(idx + 2),
      ]
    })
  }, [])

  // This useEffect synchronizes the initialFields attribute with the internal
  // currFields state, which is required as the event object is invalidated in
  // the EditEventPage parent. When the event is mutated, the changes need to be
  // propagated into the internally maintained state for changes to be reflected.
  useEffect(() => {
    setCurrFields(event.formFields)
  }, [event])

  const isDirty = useMemo(
    () => !isEqual(currFields, event.formFields),
    [currFields, event.formFields],
  )
  useSyncToDirtyFieldStore(isDirty)

  return (
    <VStack spacing={10} alignItems="start">
      <VStack w="full" alignItems="start">
        <Text textStyle="h4">Form questions</Text>
        <Text textStyle="body-2">
          Collect any required information from attendees. CalSG can collect up
          to Restricted and Sensitive (Normal) data.
        </Text>
      </VStack>
      <VStack alignItems={'stretch'} spacing={8} w="full">
        <DefaultEmailFieldRow />
        {currFields.map((field, idx) =>
          createFieldRow(field, {
            onClickEdit: () => handleStartEditingField(idx),
            onClickDelete: () => handleDeleteField(idx),
            onMoveFieldUp: () => handleMoveFieldUp(idx),
            onMoveFieldDown: () => handleMoveFieldDown(idx),
          }),
        )}
      </VStack>

      <Flex
        gap="8px"
        flexDir={isMobile ? 'column' : 'row'}
        width={isMobile ? '100%' : 'initial'}
      >
        <SingleSelect
          items={ADD_FORM_FIELD_OPTIONS.map((option) => ({
            ...option,
            value: option.type,
          }))}
          onChange={(type) => setFieldTypeToCreate(type as FormFieldType)}
          value={fieldTypeToCreate}
          name="CALSG_CREATE_QUESTION_FIELD_TYPE"
          size="sm"
          isClearable={false}
          isSearchable={false}
          fixedItemHeight={45}
        />
        <Button
          onClick={() => handleStartCreatingField(fieldTypeToCreate)}
          size="sm"
          variant="outline"
        >
          Add question
        </Button>
      </Flex>

      {editingField !== null && (
        <EditFieldModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onSubmit={handleModalSave}
          initialField={editingField}
        />
      )}

      <SaveChangesButton
        isDirty={isDirty}
        isLoading={isLoading}
        onClick={handleSave}
        w="full"
      />
    </VStack>
  )
}
