import { ChangeEvent } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { Box, Flex, FormControl, HStack, Icon, Text } from '@chakra-ui/react'
import {
  BxsHelpCircle,
  Checkbox,
  FormErrorMessage,
  NumberInput,
  SingleSelect,
  TouchableTooltip,
} from '@opengovsg/design-system-react'

import { DISPLAY_TIME_UNIT_OPTIONS } from '~shared/utils'

import { BookingRulesFields } from '../types/field-info'
import { validateMinLeadTimeMinutes } from '../utils/form-utils'

export const MinLeadTimeCheckbox = () => {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    register,
  } = useFormContext<BookingRulesFields>()
  // useWatch ensures that this component rerenders when isMinLeadTimeEnabled
  // changes, else disabled and error states go out of sync
  const isMinLeadTimeEnabled = useWatch<BookingRulesFields>({
    control,
    name: 'isMinLeadTimeEnabled',
  }) as boolean
  return (
    <Flex flexDir={'column'} alignItems={'flex-start'}>
      <HStack>
        <Checkbox
          size="sm"
          {...register('isMinLeadTimeEnabled', {
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              if (!event.target.checked) {
                setValue('minLeadTimeQuantity', null)
                clearErrors('minLeadTimeQuantity')
              }
            },
          })}
        >
          <Text textStyle={'subhead-2'}>Require an advance notice period</Text>
        </Checkbox>
        {/* Wrap tooltip in a Box with padding to force alignment with label */}
        <Box>
          <TouchableTooltip
            label="e.g. if this is 2 days, then on 1st Jan, slots are only shown from 3rd Jan onwards"
            placement={'top'}
          >
            <Icon
              onClick={(evt) => evt.preventDefault()}
              as={BxsHelpCircle}
              fontSize="1rem"
              display="block"
              aria-hidden
            />
          </TouchableTooltip>
        </Box>
      </HStack>
      <Text
        textStyle={'body-2'}
        as="span"
        ml="36px"
        mt={1.5}
        pb={2}
        color={
          isMinLeadTimeEnabled
            ? undefined
            : 'interaction.support.disabled-content'
        }
      >
        Invitees can&apos;t schedule within...
      </Text>
      <FormControl isInvalid={!!errors.minLeadTimeQuantity}>
        <Flex flexWrap={'wrap'} alignItems="center" gap="4px" ml="36px">
          <Controller
            name="minLeadTimeQuantity"
            rules={{
              validate: validateMinLeadTimeMinutes,
            }}
            render={({ field: { onChange, ref, value } }) => (
              <NumberInput
                ref={ref}
                value={(value ?? '') as string}
                onChange={(_valueAsString, valueAsNumber) =>
                  onChange(isNaN(valueAsNumber) ? null : valueAsNumber)
                }
                showSteppers={false}
                width="20%"
                minW={{ base: '100%', md: '80px' }}
                maxW={{ md: '10ch' }}
                display="inline-block"
                fontSize={'14px'}
                isDisabled={!isMinLeadTimeEnabled}
                size="sm"
              />
            )}
          />
          <Controller
            name="minLeadTimeUnit"
            render={({ field: { onChange, ref, value } }) => (
              // Force select box to be smaller, but big enough to accommodate all characters
              <Box
                maxW={{ md: '15ch' }}
                minW={{ base: '100%', md: 'unset' }}
                display="inline-block"
              >
                <SingleSelect
                  ref={ref}
                  name="minLeadTimeUnit"
                  isClearable={false}
                  value={(value ?? '') as string}
                  onChange={onChange}
                  size="sm"
                  items={DISPLAY_TIME_UNIT_OPTIONS}
                  isDisabled={!isMinLeadTimeEnabled}
                />
              </Box>
            )}
          />
          <Text
            textStyle={'body-2'}
            as="span"
            color={
              isMinLeadTimeEnabled
                ? undefined
                : 'interaction.support.disabled-content'
            }
          >
            of booking start time
          </Text>
        </Flex>
        <FormErrorMessage ml="36px">
          {errors.minLeadTimeQuantity?.message}
        </FormErrorMessage>
      </FormControl>
    </Flex>
  )
}
