import { VStack } from '@chakra-ui/react'

import { FormFieldType } from '~shared/types'

import { FORM_FIELD_DISPLAY_DATA } from '../fieldDisplayData'

import { EditFieldTitleAndDescription } from './EditFieldTitleAndDescription'
import { FieldTypeBadge } from './FieldTypeBadge'

export interface EditTextFieldProps {
  fieldType: FormFieldType
}

export const EditTextField = ({ fieldType }: EditTextFieldProps) => {
  const fieldDisplayData = FORM_FIELD_DISPLAY_DATA[fieldType]
  return (
    <VStack spacing="1.5rem" alignItems="start">
      <FieldTypeBadge {...fieldDisplayData} />
      <EditFieldTitleAndDescription />
    </VStack>
  )
}
