import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  formResponsesSelector,
  leaveFormPageSelector,
  setIsSlotGoneSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'

export interface FormPageWrapperProps {
  children: React.ReactNode
}

export const FormPageWrapper = ({ children }: FormPageWrapperProps) => {
  const formResponses = useFormStore(formResponsesSelector)
  const leaveFormPage = useFormStore(leaveFormPageSelector)
  const setIsSlotGone = useFormStore(setIsSlotGoneSelector)

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: formResponses ?? undefined,
  })

  // Return a cleanup function to save the responses on form unmount
  useEffect(
    () => () => {
      leaveFormPage(methods.getValues())
    },
    [methods, setIsSlotGone, leaveFormPage],
  )

  return <FormProvider {...methods}>{children}</FormProvider>
}
