import { BiCheck } from 'react-icons/bi'
import { Button, ButtonProps } from '@opengovsg/design-system-react'

export type SaveChangesButtonProps = {
  isDirty: boolean
} & ButtonProps

/**
 * The isDisabled behaviour defaults to !isDirty || isLoading.
 * Passing isDisabled to this component overrides this behaviour.
 */
export const SaveChangesButton = ({
  isDirty,
  isLoading,
  isDisabled,
  ...props
}: SaveChangesButtonProps) => (
  <Button
    rightIcon={!isDirty ? <BiCheck fontSize="1.25rem" /> : undefined}
    isDisabled={isDisabled ?? (isLoading || !isDirty)}
    {...props}
  >
    {isDirty ? 'Save changes' : 'Changes saved'}
  </Button>
)
