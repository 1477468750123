import { FieldRow } from './FieldRow'

export const DefaultEmailFieldRow = () => {
  return (
    <FieldRow
      fieldDisplayData={{ label: 'Email (collected by default)' }}
      title="Email address"
      description=""
    />
  )
}
