import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { Button, ModalCloseButton } from '@opengovsg/design-system-react'

import { ButtonStack } from '~components/ButtonStack'

export type EventListRemoveSelfModalProps = {
  onDelete?: () => void
  isLoading?: boolean
  disclosure: UseDisclosureReturn
}

export const EventListRemoveSelfModal = ({
  onDelete,
  isLoading = false,
  disclosure,
}: EventListRemoveSelfModalProps) => {
  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Remove myself as a collaborator</ModalHeader>
        <ModalBody>
          <Text>
            You are removing yourself as a collaborator and will lose access to
            this event list and all the events under it. This action cannot be
            undone.
          </Text>
        </ModalBody>
        <ModalFooter>
          <ButtonStack isReverseOrderOnMobile>
            <Button onClick={disclosure.onClose} variant="clear">
              Cancel
            </Button>
            <Button
              onClick={onDelete}
              colorScheme="critical"
              isLoading={isLoading}
            >
              Yes, remove myself
            </Button>
          </ButtonStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
