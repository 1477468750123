import { colours } from './colors'
import { shadows } from './shadows'
import { sizes } from './sizes'
import { spacing } from './spacing'
import { textStyles } from './textStyles'
import { typography } from './typography'

export const foundations = {
  colors: colours,
  space: spacing,
  shadows,
  fontSizes: typography.fontSize,
  fontWeights: typography.fontWeights,
  lineHeights: typography.lineHeights,
  letterSpacings: typography.letterSpacing,
  sizes,
  textStyles,
}
