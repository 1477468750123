import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import {
  CreateEventReq,
  DuplicateEventReq,
  GetEventRes,
  UpdateEventReq,
} from '~shared/dto'

import { api } from '~lib/api'
import { adminQueryKeys } from '~constants/queryKeys'

type UseGetEvent = {
  eventId: string
} & UseQueryOptions<
  GetEventRes,
  unknown,
  GetEventRes,
  ReturnType<typeof adminQueryKeys.events>
>

export const useGetEvent = ({ eventId, ...useQueryOptions }: UseGetEvent) => {
  return useQuery(
    adminQueryKeys.events({ eventId }),
    () => api.get(`/admin/events/${eventId}`).json<GetEventRes>(),
    useQueryOptions,
  )
}

export const useCreateEvent = ({
  eventListId,
}: { eventListId?: string } = {}) => {
  const queryCache = useQueryClient()
  const urlPrefix = eventListId ? `/admin/event-lists/${eventListId}` : '/admin'
  return useMutation(
    async (req: CreateEventReq) =>
      api.url(`${urlPrefix}/events`).post(req).json<GetEventRes>(),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(adminQueryKeys.events())
        await queryCache.invalidateQueries(adminQueryKeys.dashboard())
        await queryCache.invalidateQueries(adminQueryKeys.eventLists())
      },
    },
  )
}

export const useUpdateEvent = (eventId: string) => {
  const queryCache = useQueryClient()
  return useMutation(
    async (req: UpdateEventReq) =>
      api.url(`/admin/events/${eventId}`).put(req).json<GetEventRes>(),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(adminQueryKeys.events({ eventId }))
      },
    },
  )
}

export const useDeleteEvent = ({
  eventListId,
}: { eventListId?: string } = {}) => {
  const queryCache = useQueryClient()
  const urlPrefix = eventListId ? `/admin/event-lists/${eventListId}` : '/admin'
  return useMutation(
    async (eventId: string) =>
      api.url(`${urlPrefix}/events/${eventId}`).delete().res(),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(adminQueryKeys.events())
        await queryCache.invalidateQueries(adminQueryKeys.dashboard())
        await queryCache.invalidateQueries(adminQueryKeys.eventLists())
      },
    },
  )
}

export const useDuplicateEvent = ({
  eventListId,
}: {
  eventListId?: string
} = {}) => {
  const queryCache = useQueryClient()
  const urlPrefix = eventListId ? `/admin/event-lists/${eventListId}` : '/admin'
  return useMutation(
    async ({ eventId, req }: { eventId: string; req: DuplicateEventReq }) =>
      api
        .url(`${urlPrefix}/events/${eventId}/duplicate`)
        .post(req)
        .json<GetEventRes>(),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(adminQueryKeys.events())
        await queryCache.invalidateQueries(adminQueryKeys.dashboard())
        await queryCache.invalidateQueries(adminQueryKeys.eventLists())
      },
    },
  )
}
