import { BiCalendarX } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button, BxsCheckCircle } from '@opengovsg/design-system-react'

import { GetPublicEventRes } from '~shared/dto'
import { formatEmailConfirmationBookingTime } from '~shared/format-email-confirmation'

import SuccessfulSubmissionSvgDesktop from '~/assets/img/confirmed-booking-large.svg'
import SuccessfulSubmissionSvgMobile from '~/assets/img/confirmed-booking-small.svg'

import { useIsClientMobile } from '~hooks/useIsClientMobile'
import { ButtonStack } from '~components/ButtonStack'
import { CalendarTimeIcon } from '~components/Icons'

import { EventSummary } from '~features/form/components/EventSummary'
import { SgidLogoutButton } from '~features/form/components/SgidButtons'
import {
  createdBookingSelector,
  identitySelector,
  previousBookingSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { FormState } from '~features/form/types/formState'
import { RichTextEditor } from '~features/richtext'

export interface ConfirmationPageProps {
  event: GetPublicEventRes
}

export const ConfirmationPage = ({
  event,
}: ConfirmationPageProps): JSX.Element => {
  const previousBooking = useFormStore(previousBookingSelector)
  const createdBooking = useFormStore(createdBookingSelector)!
  const isMobile = useIsClientMobile()
  const identity = useFormStore(identitySelector)

  return (
    <VStack w="full" spacing={6}>
      <HStack w="full" alignItems="start">
        <VStack w="full" alignItems="stretch" spacing={6} flex={1}>
          <HStack>
            <Icon
              as={BxsCheckCircle}
              fontSize="1.25rem"
              color="utility.feedback.success"
            />
            <Text textStyle="subhead-2">
              {previousBooking
                ? `Your booking has been updated`
                : `We've received your booking`}
            </Text>
          </HStack>
          <Box w="full">
            <Text
              textStyle={{ base: 'h6', md: 'h4' }}
              w="full"
              mb="0.5rem"
              overflowWrap="anywhere"
            >
              {event.title}
            </Text>
            <EventSummary event={event} booking={createdBooking} />
          </Box>
          {isMobile && (
            <Center w="full">
              <Image
                src={SuccessfulSubmissionSvgMobile}
                w="full"
                h="full"
                maxW="200px"
                maxH="100%"
                aria-hidden
              />
            </Center>
          )}
          <Box
            textStyle={'body-2'}
            mt="1.5rem"
            whiteSpace={'pre-wrap'}
            overflowWrap="anywhere"
          >
            <RichTextEditor
              isReadOnly={true}
              content={formatEmailConfirmationBookingTime({
                startAt: createdBooking.startAt,
                endAt: createdBooking.endAt,
                emailBody: event.emailConfirmationBody,
              })}
            />
          </Box>
        </VStack>
        {!isMobile && (
          <Box>
            <Image
              src={SuccessfulSubmissionSvgDesktop}
              w="full"
              h="full"
              maxW="316px"
              maxH="100%"
              aria-hidden
            />
          </Box>
        )}
      </HStack>
      <Divider />
      <ButtonStack w="full" alignItems="stretch">
        {/* reloadDocument prevents old state from messing with reschedule/cancel */}
        <Link to={`/${event.id}?bookingId=${createdBooking.id}`} reloadDocument>
          <Button
            w="full"
            variant="outline"
            leftIcon={<CalendarTimeIcon fontSize="1.5rem" />}
            h={{ base: '56px', md: 'auto' }}
          >
            Change booking
          </Button>
        </Link>
        <Link
          to={`/${event.id}?bookingId=${createdBooking.id}&action=cancel`}
          reloadDocument
        >
          <Button
            variant="clear"
            w="full"
            colorScheme={'red'}
            leftIcon={<BiCalendarX fontSize="1.5rem" />}
            h={{ base: '56px', md: 'auto' }}
          >
            Cancel booking
          </Button>
        </Link>
        {identity !== null && (
          <>
            <Flex
              alignItems="center"
              direction={{ base: 'column', md: 'row' }}
              flex={1}
              pt={{ base: 2, md: 0 }}
            >
              <Spacer />
              <SgidLogoutButton
                redirectToState={FormState.Submitted}
                identity={identity}
              />
            </Flex>
          </>
        )}
      </ButtonStack>
    </VStack>
  )
}
