import {
  Center,
  Flex,
  HStack,
  Image,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'

import UncleOnPhoneSvg from '~/assets/img/uncle-on-phone.svg'

export type DashboardHeaderProps = {
  children?: React.ReactNode
  titleChildren?: React.ReactNode
  title: string
}

export const DashboardHeader = ({
  children,
  title,
  titleChildren,
}: DashboardHeaderProps) => {
  return (
    <Center alignItems="center" bgColor="standard.white" w="full">
      <Flex
        alignItems="stretch"
        w="full"
        maxW="51rem"
        px={3}
        flexDir={{ base: 'column', md: 'row' }}
      >
        <HStack alignItems="center" spacing={0} mt={{ base: 4, md: 0 }}>
          <Image
            src={UncleOnPhoneSvg}
            flexGrow={0}
            flexShrink={1}
            minW={0}
            w={{ base: 0, md: 'auto' }}
          />
          <Text textStyle="h5" flexGrow={1} flexShrink={0}>
            {title}
          </Text>
          {titleChildren}
        </HStack>
        <Spacer />
        <VStack justifyContent="center">{children}</VStack>
      </Flex>
    </Center>
  )
}
