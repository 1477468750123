import { Text, VStack } from '@chakra-ui/react'

import { BookingFlowErrorLayout } from './BookingFlowErrorLayout'

export const EventNotFound = () => {
  return (
    <BookingFlowErrorLayout minH="100vh">
      <VStack paddingX={8}>
        <Text textStyle="h5">{'Oops we can’t find that...'}</Text>

        <Text>
          {'Please contact the agency that gave you this booking link.'}
        </Text>
      </VStack>
    </BookingFlowErrorLayout>
  )
}
