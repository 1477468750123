import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GetBannerRes } from '~shared/types/health.dto'

import { api } from '~lib/api'

import { bannerQueryKeys } from '../query-keys'

type usePublicBanner = UseQueryOptions<
  GetBannerRes,
  unknown,
  GetBannerRes,
  ReturnType<typeof bannerQueryKeys.publicBannerKey>
>

export const usePublicBanner = (useQueryOptions: usePublicBanner = {}) => {
  return useQuery(
    bannerQueryKeys.publicBannerKey(),
    async () => api.get(`/metadata/public-banner`).json<GetBannerRes>(),
    {
      refetchOnWindowFocus: true,
      refetchInterval: 1000 * 60 * 5, // 5 minutes,
      ...useQueryOptions,
    },
  )
}
