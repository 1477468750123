import { createContext, FC, useCallback, useContext, useEffect } from 'react'
import {
  FieldValues,
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
  useWatch,
} from 'react-hook-form'

import { nextAvailabilityTimeRange } from './manage-schedule-time-range-utils'

type ScheduleTimeRangeListContextProps = {
  fieldName: string
  slotDurationMins: number
  onEmptyTimeRangeList: () => void
  isDisabled?: boolean
}

type ScheduleTimeRangeListContextReturn = {
  onAdd: () => void
  onDelete: (index: number) => void
  timeRangeFields: UseFieldArrayReturn<FieldValues, string, 'id'>
  timeRanges: {
    start: number
    end: number
  }[]
} & ScheduleTimeRangeListContextProps

const ScheduleTimeRangeListContext = createContext<
  ScheduleTimeRangeListContextReturn | undefined
>(undefined)

/**
 * This provider manages the state for inputting a list of time ranges in
 * a form. A fieldArray is used internally to manage the list.
 * This provider must be wrapped by a FormProvider context either as the direct
 * parent or as an ancestor as useFormContext and useFieldArray are called in this
 * provider.
 */
export const ScheduleTimeRangeListProvider: FC<
  React.PropsWithChildren & ScheduleTimeRangeListContextProps
> = ({
  children,
  fieldName,
  slotDurationMins,
  onEmptyTimeRangeList,
  isDisabled,
}): JSX.Element => {
  const timeRanges = useWatch({ name: fieldName }) as {
    start: number
    end: number
  }[]

  const { trigger } = useFormContext()
  const timeRangeFields = useFieldArray({
    shouldUnregister: false,
    name: fieldName,
  })

  const onAdd = useCallback(() => {
    // Our default always concats a 9-5 disabled slot, so modified should
    // never be 0 but we still check just in case.
    const next = nextAvailabilityTimeRange(
      timeRanges.length === 0 ? 0 : timeRanges[timeRanges.length - 1].end,
      slotDurationMins,
    )

    timeRangeFields?.append({
      start: next.start,
      end: next.end,
    })
  }, [timeRanges, slotDurationMins, timeRangeFields])

  // To ignore disabled days by revalidating them
  useEffect(() => {
    void trigger(fieldName)
  }, [trigger, fieldName])

  const onDelete = useCallback(
    (index: number) => {
      timeRangeFields.fields.length > 1
        ? timeRangeFields?.remove(index)
        : onEmptyTimeRangeList()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeRangeFields.fields.length, onEmptyTimeRangeList],
  )

  return (
    <ScheduleTimeRangeListContext.Provider
      value={{
        onAdd,
        onDelete,
        fieldName,
        slotDurationMins,
        onEmptyTimeRangeList,
        isDisabled,
        timeRangeFields,
        timeRanges,
      }}
    >
      {children}
    </ScheduleTimeRangeListContext.Provider>
  )
}

export const useScheduleTimeRangeList =
  (): ScheduleTimeRangeListContextReturn => {
    const context = useContext(ScheduleTimeRangeListContext)
    if (!context) {
      throw new Error(
        `useScheduleTimeRangeList must be used within a ScheduleTimeRangeListProvider component`,
      )
    }
    return context
  }
