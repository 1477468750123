import { useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import { FormErrorMessage, SingleSelect } from '@opengovsg/design-system-react'

import { UpdateDropdownFieldReq } from '~shared/dto'

import { FieldLabel } from './FieldLabel'

export const DropdownFieldView = ({
  id,
  title,
  description,
  fieldOptions,
}: UpdateDropdownFieldReq) => {
  const {
    formState: { errors },
    getValues,
    control,
    resetField,
  } = useFormContext<Record<string, string>>()
  const options = useMemo(
    () =>
      fieldOptions.map((option) => ({
        value: option,
        label: option,
      })),
    [fieldOptions],
  )

  // If fetching latest state of event results in current option
  // being made invalid, reset this field
  useEffect(() => {
    const currentValue = getValues(id)
    if (!fieldOptions.includes(currentValue)) {
      resetField(id)
    }
  }, [getValues, fieldOptions, id, resetField])

  return (
    <FormControl isRequired isInvalid={!!errors[id]?.message}>
      <FieldLabel title={title} description={description} />
      <Controller
        name={id}
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field: { onChange, value } }) => (
          <SingleSelect
            value={value}
            onChange={onChange}
            name={id}
            items={options}
            isClearable={false}
          />
        )}
      />
      <FormErrorMessage>{errors[id]?.message}</FormErrorMessage>
    </FormControl>
  )
}
