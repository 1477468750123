import simplur from 'simplur'

import {
  MIN_CHANGE_LEAD_TIME_MINS_MAXIMUM,
  MIN_CHANGE_LEAD_TIME_MINS_MINIMUM,
} from '~shared/constants'
import {
  GetEventRes,
  GetScheduleRes,
  UpdateEventReq,
  UpdateScheduleReq,
} from '~shared/dto'
import { EventStatus } from '~shared/types'
import {
  displayTimeUnitsToMinutes,
  minutesToDisplayTimeUnits,
} from '~shared/utils'

import { minutesToDays } from '~utils/date'

import { GeneralSettingsFormFields } from '../types'

export const validateMinChangeLeadTimeMinutes = (
  value: number | null,
  formValues: GeneralSettingsFormFields,
): true | string => {
  const { minChangeLeadTimeUnit, isMinChangeLeadTimeEnabled } = formValues
  const minChangeLeadTimeMinutes = displayTimeUnitsToMinutes({
    quantity: value,
    unit: minChangeLeadTimeUnit,
  })
  if (minChangeLeadTimeMinutes === null) {
    if (isMinChangeLeadTimeEnabled) {
      return `Please enter a number, or disable this option`
    }
    return true
  }
  if (minChangeLeadTimeMinutes < MIN_CHANGE_LEAD_TIME_MINS_MINIMUM) {
    return simplur`Booking change notice period must be least ${MIN_CHANGE_LEAD_TIME_MINS_MINIMUM} minute[|s]`
  }
  if (minChangeLeadTimeMinutes > MIN_CHANGE_LEAD_TIME_MINS_MAXIMUM) {
    return simplur`Booking change notice period can be at most ${minutesToDays(
      MIN_CHANGE_LEAD_TIME_MINS_MAXIMUM,
    )} day[|s]`
  }
  return true
}

export const getGeneralSettingsFields = ({
  event,
  schedule,
}: {
  event: GetEventRes
  schedule: GetScheduleRes
}): GeneralSettingsFormFields => {
  const { minChangeLeadTimeMinutes, showRemainingCapacity } = schedule
  const { quantity: minChangeLeadTimeQuantity, unit: minChangeLeadTimeUnit } =
    minutesToDisplayTimeUnits(minChangeLeadTimeMinutes)
  return {
    isMinChangeLeadTimeEnabled: minChangeLeadTimeMinutes !== null,
    minChangeLeadTimeQuantity,
    minChangeLeadTimeUnit,
    showRemainingCapacity,
    isEventOpen: event.eventStatus === EventStatus.Open,
    closedEventMessage: event.closedEventMessage,
  }
}

export const generalSettingsFieldsToScheduleReqBody = (
  fields: GeneralSettingsFormFields,
): UpdateScheduleReq => {
  const minChangeLeadTimeMinutes = displayTimeUnitsToMinutes({
    quantity: fields.minChangeLeadTimeQuantity,
    unit: fields.minChangeLeadTimeUnit,
  })

  return {
    minChangeLeadTimeMinutes: fields.isMinChangeLeadTimeEnabled
      ? minChangeLeadTimeMinutes
      : null,
    showRemainingCapacity: fields.showRemainingCapacity,
  }
}

export const generalSettingsFieldsToEventReqBody = (
  fields: GeneralSettingsFormFields,
): UpdateEventReq => {
  return {
    eventStatus: fields.isEventOpen ? EventStatus.Open : EventStatus.Closed,
    closedEventMessage: fields.closedEventMessage,
  }
}
