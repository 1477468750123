import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import { GetBookingRes } from '~shared/dto'

import { api } from '~lib/api'
import { adminQueryKeys } from '~constants/queryKeys'

type UseGetEventBookings = {
  eventId: string
} & UseQueryOptions<
  GetBookingRes[],
  unknown,
  GetBookingRes[],
  ReturnType<typeof adminQueryKeys.bookings>
>

export const useGetEventBookings = ({
  eventId,
  ...useQueryOptions
}: UseGetEventBookings) => {
  return useQuery(
    adminQueryKeys.bookings({ eventId }),
    () => api.get(`/admin/events/${eventId}/bookings`).json<GetBookingRes[]>(),
    {
      ...useQueryOptions,
    },
  )
}

export const useDeleteEventBooking = () => {
  const queryCache = useQueryClient()
  return useMutation(
    async ({
      bookingId,
      eventId,
      noteFromOrganiser,
    }: {
      bookingId: string
      eventId: string
      noteFromOrganiser: string
    }) =>
      api
        .url(`/admin/events/${eventId}/bookings/${bookingId}`)
        .patch({ noteFromOrganiser, action: 'cancel' })
        .res(),
    {
      onSuccess: async (_data, { eventId }) => {
        await queryCache.invalidateQueries(adminQueryKeys.bookings({ eventId }))
      },
    },
  )
}
