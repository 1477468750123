import { FC, PropsWithChildren } from 'react'
import { Flex, Grid, GridItem, GridProps } from '@chakra-ui/react'

export const AppGrid = (props: GridProps) => (
  <Grid
    px={{ base: '1.5rem', md: '1.75rem', lg: '2rem' }}
    columnGap={{ base: '0.5rem', lg: '1rem' }}
    templateColumns={{ base: 'repeat(4, 1fr)', md: 'repeat(12, 1fr)' }}
    {...props}
  />
)

// Component for the split blue/white background.
export const BackgroundBox: FC<PropsWithChildren> = ({ children }) => (
  <Flex
    flex="1"
    flexDir="column"
    h="inherit"
    bgGradient={{
      md: 'linear(to-b, brand.primary.200 20.5rem, white 0)',
      lg: 'linear(to-r, black calc(41.6667% - 4px), white 0)',
    }}
  >
    {children}
  </Flex>
)

// Component that controls the various grid areas according to responsive breakpoints.
export const BaseGridLayout = (props: GridProps) => (
  <AppGrid templateRows={{ md: 'auto 1fr auto', lg: '1fr auto' }} {...props} />
)

// Grid area styling for the login form.
export const LoginGridArea: FC<PropsWithChildren> = ({ children }) => (
  <GridItem
    gridColumn={{ base: '1 / 5', md: '2 / 12', lg: '7 / 12' }}
    py="4rem"
    display="flex"
    alignItems={{ base: 'initial', lg: 'center' }}
    justifyContent="center"
  >
    {children}
  </GridItem>
)

// Grid area styling for the footer.
export const FooterGridArea: FC<PropsWithChildren> = ({ children }) => (
  <GridItem
    alignSelf="end"
    gridColumn={{ base: '1 / 5', md: '2 / 12' }}
    pb={{ base: 0, lg: '2.5rem' }}
    bg={{ base: 'base.canvas.brandLight', lg: 'transparent' }}
  >
    {children}
  </GridItem>
)

// Grid area styling for the left sidebar that only displays on tablet and desktop breakpoints.
export const NonMobileSidebarGridArea: FC<PropsWithChildren> = ({
  children,
}) => (
  <GridItem
    display={{ base: 'none', md: 'flex' }}
    gridColumn={{ md: '1 / 13', lg: '1 / 6' }}
    h={{ md: '20.5rem', lg: 'auto' }}
    pt={{ base: '1.5rem', md: '2.5rem', lg: '3rem' }}
    pb={{ lg: '3rem' }}
    px={{ lg: '2rem' }}
    flexDir="column"
    alignItems="stretch"
    justifyContent="center"
    overflow="hidden"
  >
    {children}
  </GridItem>
)
