import { BiListUl } from 'react-icons/bi'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Box,
  HStack,
  Stack,
  StackProps,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react'

import { AdminDashboardRes } from '~shared/dto'

import { EventListActionsButtonGroup } from '~features/event-lists/components/EventListActionsButtonGroup'

type DashboardEventListRowProps = {
  eventList: AdminDashboardRes['eventLists'][number]
} & StackProps

export const DashboardEventListRow = ({
  eventList,
  ...rest
}: DashboardEventListRowProps): JSX.Element => {
  return (
    <HStack alignItems="stretch" spacing={0} pr={4} {...rest}>
      <HStack
        spacing={4}
        borderStartRadius={4}
        flex={1}
        alignItems="center"
        p={6}
        transition="background-color 0.2s ease"
        _hover={{
          backgroundColor: 'brand.primary.100',
        }}
        as={ReactRouterLink}
        to={`/admin/event-list/${eventList.id}`}
      >
        <Box>
          <BiListUl fontSize="1.25rem" />
        </Box>
        <Stack
          flex={1}
          spacing={{ base: '0.25rem', md: '3rem' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Wrap
            shouldWrapChildren
            flex={1}
            alignItems={{ base: 'center', md: 'flex-start' }}
            direction={{ base: 'row', md: 'column' }}
          >
            <Text
              textStyle="subhead-1"
              color="secondary.700"
              wordBreak="break-word"
            >
              {eventList.title}
            </Text>
          </Wrap>
        </Stack>
      </HStack>

      <VStack alignItems="center">
        <EventListActionsButtonGroup
          eventList={eventList}
          isEventListOwner={eventList.accessLevel === 'owner'}
        />
      </VStack>
    </HStack>
  )
}
