import { Controller, useFormState } from 'react-hook-form'
import { Flex, FormControl, Text } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  NumberInput,
  NumberInputProps,
} from '@opengovsg/design-system-react'
import simplur from 'simplur'

import {
  SLOT_DURATION_MINS_MAXIMUM,
  SLOT_DURATION_MINS_MINIMUM,
} from '~shared/constants'
import { numberWithCommas } from '~shared/utils'

import { minutesToDays } from '~utils/date'

export type SlotDurationInputProps = NumberInputProps

export const SlotDurationInput = (props: SlotDurationInputProps) => {
  const { errors } = useFormState<{
    slotDurationMins: number
  }>()
  return (
    <FormControl isInvalid={!!errors.slotDurationMins}>
      <FormLabel isRequired>
        <Text textStyle={'subhead-2'}>How long is each slot?</Text>
      </FormLabel>
      <Flex>
        <Controller
          name="slotDurationMins"
          rules={{
            required: 'This field is required',
            min: {
              value: SLOT_DURATION_MINS_MINIMUM,
              message: simplur`Slot duration must be at least ${SLOT_DURATION_MINS_MINIMUM} minute[|s].`,
            },
            max: {
              value: SLOT_DURATION_MINS_MAXIMUM,
              message: simplur`Slot duration can be at most ${numberWithCommas(
                SLOT_DURATION_MINS_MAXIMUM,
                // no pluralisation here as numberWithCommas returns string
              )} minutes (${minutesToDays(
                SLOT_DURATION_MINS_MAXIMUM,
              )} day[|s])`,
            },
          }}
          render={({ field: { onChange, ref, value } }) => (
            <NumberInput
              ref={ref}
              value={(value ?? '') as string}
              onChange={(_valueAsString, valueAsNumber) =>
                onChange(isNaN(valueAsNumber) ? null : valueAsNumber)
              }
              showSteppers={false}
              width="20%"
              minW="80px"
              size="sm"
              {...props}
            />
          )}
        />
        <Text textStyle={'body-2'} alignSelf="center" ml="1rem">
          minutes
        </Text>
      </Flex>

      <FormErrorMessage>{errors.slotDurationMins?.message}</FormErrorMessage>
    </FormControl>
  )
}
