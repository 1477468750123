import { Box, Image, Text, VStack } from '@chakra-ui/react'

import EmptyEventSvg from '~/assets/img/empty-event.svg'

import { LoadingState } from '~components/LoadingState'

import { useAdminAuth } from '~features/auth'

export const EmptyEventList = (): JSX.Element => {
  const { adminUser } = useAdminAuth()
  if (!adminUser)
    return <LoadingState flex={1} py={{ base: '1rem', md: '3rem' }} />

  return (
    <Box py={2} flex={1}>
      <VStack maxW="100ch" alignItems="center" ml="auto" mr="auto" px={3}>
        <Image src={EmptyEventSvg} w="265px" maxW="100%" aria-hidden />
        <VStack spacing={1}>
          <Text textStyle="subhead-1" textAlign="center">
            {`Events within this event list will appear here`}
          </Text>
          <Text textStyle="body-2" textAlign="center">
            {`Create one to get started`}
          </Text>
        </VStack>
      </VStack>
    </Box>
  )
}
