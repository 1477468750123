import { BiLeftArrowAlt } from 'react-icons/bi'
import { Icon } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import {
  formStateSelector,
  setFormStateSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { FormState } from '~features/form/types/formState'

export const EventFormBackButton = () => {
  const formState = useFormStore(formStateSelector)
  const setFormState = useFormStore(setFormStateSelector)
  let handleBack: () => void

  // Get previous page
  switch (formState) {
    case FormState.PickDate:
    case FormState.PickTime:
      handleBack = () => {
        setFormState(FormState.ViewSummary)
      }
      break
    case FormState.FormFields:
      handleBack = () => setFormState(FormState.PickTime)
      break
    default:
      return null
  }

  return (
    <Button
      size="xs"
      variant="link"
      aria-label="Back"
      leftIcon={<Icon as={BiLeftArrowAlt} fontSize="1.25rem" />}
      p="0"
      color="interaction.links.neutral.default"
      onClick={handleBack}
    >
      Back
    </Button>
  )
}
