import { Text } from '@chakra-ui/react'

import { useClientBreakpointValue } from '~hooks/useClientBreakpointValue'
import { formatDateDisplay, formatTimeDisplay } from '~utils/date'

import {
  dateSelector,
  formStateSelector,
  slotSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { FormState } from '~features/form/types/formState'

export const EventFormSubtitle = () => {
  const formState = useFormStore(formStateSelector)
  const date = useFormStore(dateSelector)
  const slot = useFormStore(slotSelector)
  const textStyle = useClientBreakpointValue({ base: 'subhead-2', md: 'h6' })

  const dateString = date ? formatDateDisplay({ date }) : ''
  const timeString = slot ? formatTimeDisplay(new Date(slot.startAt)) : '-'

  switch (formState) {
    case FormState.PickDate:
      return (
        <Text textStyle={textStyle} w="full">
          Select a date and time
        </Text>
      )
    case FormState.PickTime:
      return slot ? (
        <Text textStyle={textStyle} color="utility.feedback.info" w="full">
          {`${dateString}, ${timeString}`}
        </Text>
      ) : (
        <Text textStyle={textStyle} w="full">
          <Text color="utility.feedback.info" as="span">{`${dateString}`}</Text>
          <Text as="span">{', -'}</Text>
        </Text>
      )
    case FormState.FormFields:
      return (
        <Text
          textStyle={textStyle}
          w="full"
        >{`${dateString}, ${timeString}`}</Text>
      )
    default:
      return null
  }
}
