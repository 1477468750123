import { CreateFieldReq } from '~shared/dto'
import { FormFieldType } from '~shared/types'

import { EditFieldWithOptions } from './EditFieldWithOptions'
import { EditTextField } from './EditTextField'

export interface EditFieldModalContentProps {
  field: CreateFieldReq
}

export const EditFieldModalContent = ({
  field,
}: EditFieldModalContentProps): JSX.Element => {
  switch (field.fieldType) {
    case FormFieldType.Radio:
    case FormFieldType.Dropdown:
      return <EditFieldWithOptions fieldType={field.fieldType} />
    default:
      return <EditTextField fieldType={field.fieldType} />
  }
}
