import { Outlet, RouteObject } from 'react-router-dom'

import { AppLayout } from '~/layouts/AppLayout'
import { PublicLayout } from '~/layouts/PublicLayout'

import { FourOhFour } from '~components/FourOhFour'

import { AdminAuthProvider } from '~features/auth'
import { LandingPage } from '~features/common/landing/LandingPage'

import { adminRoutes } from './admin.routes'
import { userRoutes } from './user.routes'

export const appRoutes: RouteObject[] = [
  {
    element: <AppLayout />,
    children: [
      {
        path: 'admin',
        element: (
          <AdminAuthProvider>
            <Outlet />
          </AdminAuthProvider>
        ),
        children: adminRoutes,
      },
      {
        element: <PublicLayout />,
        children: [
          /** <UserAuthProvider> can be wrapped here, if we create one */
          ...userRoutes,
          // Redirect to admin page by default
          {
            index: true,
            element: <LandingPage />,
          },
          {
            path: 'fourohfour',
            element: <FourOhFour />,
          },
          {
            path: '*',
            element: <FourOhFour />,
          },
        ],
      },
    ],
  },
]
