import { useMemo } from 'react'
import {
  Center,
  Grid,
  GridItem,
  Spacer,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react'

import { isValidNanoId } from '~shared/utils'

import { isNestError } from '~lib/api'
import { EventListEmpty, EventListNotFound } from '~components/ErrorPages'
import { Linkify } from '~components/Linkify'
import { LoadingState } from '~components/LoadingState'

import { useEventListIdParam } from '~features/event-lists/hooks/useEventListIdParam'

import { EventListCard } from '../components/EventListCard'
import { useGetPublicEventList } from '../hooks/usePublicEventList'

export const PublicEventListPage = () => {
  const { eventListId } = useEventListIdParam()
  const isEventListIdValid = useMemo(
    () => isValidNanoId(eventListId),
    [eventListId],
  )
  const {
    data: eventList,
    isLoading: isEventListLoading,
    isError: isEventListError,
  } = useGetPublicEventList({
    eventListId,
    // Security measure to ensure user cannot make arbitrary GET requests
    // by editing the URL, e.g. by going to /..%252fauth%252fwhoami
    enabled: isEventListIdValid,
    retry: (failureCount, error) => {
      // Prevent retries if failureCount >= 3 OR status code is known to be 404
      return failureCount < 3 && (!isNestError(error) || error.status !== 404)
    },
  })

  // When query to fetch event list is disabled because event list ID is invalid,
  // query will initialise with `isLoading === true`. So this case has
  // to come before the isLoading case.
  if (isEventListError || !isEventListIdValid) {
    return <EventListNotFound />
  }
  // We wait for the event list to load
  if (isEventListLoading) {
    return <LoadingState height="100vh" />
  }

  return (
    <Center py={{ base: 0, md: '3rem' }} w="full" h="full">
      <VStack
        mt={{ base: 0, md: 9 }}
        py={8}
        px={{ base: 6, md: 10 }}
        spacing={8}
        bgColor="white"
        minH={{ base: 'calc(100vh - 24px)', md: '680px' }}
        w="full"
        maxW="47.5rem"
        mx="auto"
        alignItems="stretch"
      >
        <VStack alignItems="stretch">
          <Text textStyle="h4">{eventList.title}</Text>
          <Text
            textStyle="body-2"
            whiteSpace="pre-wrap"
            overflowWrap="anywhere"
          >
            <Linkify>{eventList.description}</Linkify>
          </Text>
        </VStack>
        {eventList.events.length === 0 ? (
          <>
            <EventListEmpty />
            <Spacer />
          </>
        ) : (
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
            gap={6}
          >
            {eventList.events.map((event) => (
              <GridItem key={event.id}>
                <EventListCard title={event.title} eventId={event.id} />
              </GridItem>
            ))}
          </Grid>
        )}
      </VStack>
    </Center>
  )
}
