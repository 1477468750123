import { dayJsToTimeOfDay, timeOfDayToDayJs } from '~utils/day'

export type TimeRange = { start: number; end: number }

/**
 * Calculates the next time interval starting from the given time
 * with duration of the given interval in minutes.
 * Returns undefined if the interval crosses into the next day.
 */
export const nextAvailabilityTimeRange = (
  fromTime: number,
  intervalInMinutes: number,
): TimeRange => {
  const start = timeOfDayToDayJs(fromTime)

  // We only allow up to 23:59
  const endOfDay = start.hour(23).minute(59)
  const intervalEnd = start.add(intervalInMinutes, 'minute')

  // If start + intervalInMins is a different dayOfMonth from start, it
  // passed the day boundary. In that case, we don't want the time to
  // increment beyond endOfDay.
  const end = intervalEnd.date() !== start.date() ? endOfDay : intervalEnd
  return { start: dayJsToTimeOfDay(start), end: dayJsToTimeOfDay(end) }
}
