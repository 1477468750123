import { memo } from 'react'
import { Text, VStack } from '@chakra-ui/react'

export const ManageScheduleHeading = memo(() => {
  return (
    <VStack alignItems={'start'} spacing={1}>
      <Text textStyle="h6">When are you available?</Text>
      <Text textStyle="body-2">
        Attendees can make bookings within these dates and times
      </Text>
    </VStack>
  )
})

ManageScheduleHeading.displayName = 'ManageScheduleHeading'
