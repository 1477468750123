import { useFormState } from 'react-hook-form'
import { BiInfoCircle } from 'react-icons/bi'
import { Box, HStack, Text } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { SaveChangesButton } from '~components/SaveChangesButton'

type ManageScheduleActionBarProps = {
  isFormLoading: boolean
}

export const ManageScheduleActionBar = ({
  isFormLoading,
}: ManageScheduleActionBarProps) => {
  const { isDirty } = useFormState()

  return (
    <>
      {isDirty && (
        <HStack
          borderRadius="md"
          bgColor="blue.100"
          p={4}
          spacing={4}
          alignItems="center"
        >
          <Box>
            <BiInfoCircle fontSize="1.25rem" />
          </Box>
          <Text textStyle="subhead-2">
            {`Bookings affected by schedule changes will not be cancelled. Please
            contact your users to reschedule.`}
          </Text>
        </HStack>
      )}

      <SaveChangesButton
        type="submit"
        isDirty={isDirty}
        isLoading={isFormLoading}
      />
    </>
  )
}
