import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import {
  Button,
  ModalCloseButton,
  useToast,
} from '@opengovsg/design-system-react'

import { ButtonStack } from '~components/ButtonStack'

import { useDeleteEventList } from '~features/event-lists/hooks/useAdminEventLists'

export interface DeleteEventListModalProps {
  eventListTitle: string
  eventListId: string
  isOpen: boolean
  onClose: () => void
}

export const DeleteEventListModal = ({
  eventListTitle,
  eventListId,
  isOpen,
  onClose,
}: DeleteEventListModalProps) => {
  const { mutate: deleteEventList, isLoading } = useDeleteEventList()
  const toast = useToast()
  const navigate = useNavigate()

  const handleDeleteEventList = useCallback(() => {
    deleteEventList(eventListId, {
      onSuccess: () => {
        navigate('/admin/dashboard')
        toast({
          description: 'Event deleted successfully.',
          status: 'success',
        })
        onClose()
      },
    })
  }, [deleteEventList, eventListId, navigate, toast, onClose])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Delete event list</ModalHeader>
        <ModalBody>
          <Text>
            Your event list and all the events in the list, including all their
            booking records and pending appointments, will be permanently lost.
            CalSG will not notify any attendees that the event is deleted.
          </Text>
          <Text mt={2}>Are you sure you want to delete this event list?</Text>
          <Text fontWeight="medium" mt={4}>
            {eventListTitle}
          </Text>
        </ModalBody>
        <ModalFooter>
          <ButtonStack isReverseOrderOnMobile>
            <Button variant="clear" colorScheme="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="button"
              colorScheme="critical"
              onClick={handleDeleteEventList}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Yes, delete event list
            </Button>
          </ButtonStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
