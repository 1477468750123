import { useCallback, useState } from 'react'
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form'
import { Box, FormControl, Text, VStack } from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  SingleSelect,
} from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import {
  AVAILABILITY_COL_1_GRID_TEMPLATE_AREA,
  AVAILABILITY_COL_2_GRID_TEMPLATE_AREA,
  AvailabilityRowWrapper,
} from '../AvailabilityRowWrapper'
import { ManageScheduleFormState } from '../ManageScheduleForm/recurring-schedule-form-utils'

import {
  ADD_OVERRIDE_OPTIONS,
  AllDayOverride,
  DateAndTimeOverride,
  OVERRIDES_FORM_VALUES_KEY,
  OverrideType,
} from './common/constants'
import { doDatesOverlap } from './common/helpers'
import { OverrideRow } from './components/OverrideRow'

export const ManageOverrides = () => {
  const formMethods = useFormContext<ManageScheduleFormState>()
  const {
    fields,
    remove: removeRow,
    append,
  } = useFieldArray({
    name: OVERRIDES_FORM_VALUES_KEY,
    control: formMethods.control,
    rules: {
      validate: {
        isNotOverlapping: (values) => {
          if (doDatesOverlap(values)) {
            return 'Please remove duplicate dates. You can add multiple time slots for the same day.'
          }
          return true
        },
      },
    },
  })
  const formState = useFormState<ManageScheduleFormState>()

  const [overrideTypeToCreate, setOverrideTypeToCreate] = useState(
    OverrideType.AllDay,
  )

  const getLastDateInFields = useCallback(() => {
    const lastField = fields[fields.length - 1]
    if (!lastField) return null
    return lastField.type === OverrideType.AllDay
      ? (lastField as AllDayOverride).date
      : (lastField as DateAndTimeOverride).dateStart
  }, [fields])

  const handleAddRow = useCallback(() => {
    const lastDate = getLastDateInFields() ?? Date.now()
    const startOfLastDate = dayjs(lastDate)
      .add(1, 'day')
      .startOf('day')
      .valueOf()
    switch (overrideTypeToCreate) {
      case OverrideType.AllDay:
        append({
          type: OverrideType.AllDay,
          date: startOfLastDate,
        })
        return
      case OverrideType.DateAndTimes:
        append({
          type: OverrideType.DateAndTimes,
          dateStart: startOfLastDate,
          times: [{ start: 9 * 60 * 60 * 1000, end: 17 * 60 * 60 * 1000 }],
        })
        return
    }
  }, [append, getLastDateInFields, overrideTypeToCreate])

  const errorMessage =
    formState.errors[OVERRIDES_FORM_VALUES_KEY]?.root?.message

  return (
    <>
      <VStack spacing={6} alignItems="stretch">
        <VStack w="full" alignItems="start" spacing={1}>
          <Text textStyle="h6">When are you unavailable?</Text>
          <Text textStyle="body-2">
            Specify dates when bookings cannot be made
          </Text>
        </VStack>

        <VStack spacing="24px" alignItems={'stretch'}>
          {fields.map((overridesFormValue, index) => (
            <OverrideRow
              overridesFormValue={overridesFormValue}
              index={index}
              onDelete={() => removeRow(index)}
              key={overridesFormValue.id}
            />
          ))}
          {errorMessage && (
            <FormControl isInvalid={!!errorMessage}>
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </FormControl>
          )}
        </VStack>
        <AvailabilityRowWrapper>
          <Box
            gridArea={AVAILABILITY_COL_1_GRID_TEMPLATE_AREA}
            mr={{ base: 0, lg: '8px' }}
          >
            <SingleSelect
              items={ADD_OVERRIDE_OPTIONS}
              onChange={(type) => setOverrideTypeToCreate(type as OverrideType)}
              value={overrideTypeToCreate}
              name="CALSG_CREATE_OVERRIDE_TYPE"
              size="sm"
              isClearable={false}
            />
          </Box>
          <Button
            variant="outline"
            size="sm"
            onClick={handleAddRow}
            gridArea={AVAILABILITY_COL_2_GRID_TEMPLATE_AREA}
            mr={{ base: 0, lg: '16px' }}
          >
            <Text textStyle={'body-2'}>Add override</Text>
          </Button>
        </AvailabilityRowWrapper>
      </VStack>
    </>
  )
}
