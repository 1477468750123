import { useCallback } from 'react'
import { BiLogInCircle } from 'react-icons/bi'
import { Icon, Text } from '@chakra-ui/react'
import { Button, useToast } from '@opengovsg/design-system-react'

import {
  setFormStateSelector,
  setIdentitySelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { usePublicSgidLogout } from '~features/form/hooks/useSgidLogout'
import { FormState } from '~features/form/types/formState'

export type SgidLogoutButtonProps = {
  identity: string
  redirectToState?: FormState
}

export const SgidLogoutButton = ({
  identity,
  redirectToState,
}: SgidLogoutButtonProps) => {
  const { mutateAsync: fireLogoutMutation, isLoading: isLogoutLoading } =
    usePublicSgidLogout()
  const setIdentity = useFormStore(setIdentitySelector)
  const setFormState = useFormStore(setFormStateSelector)
  const toast = useToast()

  const logout = useCallback(async () => {
    await fireLogoutMutation()
    setIdentity(null)
    setFormState(redirectToState ?? FormState.ViewSummary)
    toast({
      description: 'Successfully logged out of Singpass.',
      colorScheme: 'success',
    })
  }, [fireLogoutMutation, redirectToState, setFormState, setIdentity])

  return (
    <Button
      rightIcon={<Icon as={BiLogInCircle} fontSize="1.25rem" />}
      isDisabled={isLogoutLoading}
      isLoading={isLogoutLoading}
      onClick={logout}
      size="sm"
      variant="link"
      p="0"
      textColor="interaction.sub.default"
      _hover={{
        textColor: 'interaction.sub.hover',
        textDecoration: 'underline',
      }}
    >
      <Text fontSize="0.875rem">{`${identity} - Log out`}</Text>
    </Button>
  )
}
