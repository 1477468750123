import { BiRightArrowAlt } from 'react-icons/bi'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Flex, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import IconDndSvg from '~/assets/img/icon-dnd.svg'
import IconEmailSvg from '~/assets/img/icon-email.svg'
import IconLogicSvg from '~/assets/img/icon-logic.svg'
import IconPrefillSvg from '~/assets/img/icon-prefill.svg'
import IconSingpassSvg from '~/assets/img/icon-singpass.svg'
import IconWebhooksSvg from '~/assets/img/icon-webhooks.svg'
import LoginImageSvg from '~/assets/img/login-image.svg'
import OgpSuiteSvg from '~/assets/img/ogp-suite.svg'

import { Footer } from '~components/Footer'
import { PublicHeader } from '~components/PublicHeader'

import { FeatureGridItem } from './components/FeatureGridItem'
import { LandingSection } from './components/LandingSection'
import { SectionBodyText } from './components/SectionBodyText'
import { SectionTitleText } from './components/SectionTitleText'

const ADMIN_PATH = '/admin/login'

export const LandingPage = (): JSX.Element => {
  return (
    <Flex flexDir="column">
      <PublicHeader />
      <Stack
        bgColor="standard.white"
        direction={{ base: 'column', lg: 'row' }}
        align="center"
        spacing={{ base: '1.5rem', md: '3.125rem', lg: '7.5rem' }}
        px={{ base: '1.5rem', md: '4.5rem', lg: '8rem' }}
      >
        <Flex flexDir="column" pb={{ lg: '3rem' }}>
          <Text
            textStyle={{
              base: 'responsive-display.heavy',
              md: 'responsive-display.heavy-480',
              lg: 'responsive-display.heavy-1280',
            }}
          >
            Appointments for government
          </Text>
          <SectionBodyText>
            {`Collect appointment bookings easily using a legitimate self-service government tool, at zero cost and no onboarding.`}
          </SectionBodyText>
          <Button
            alignSelf={{ base: 'initial', md: 'start' }}
            px="1.5rem"
            mt="2.5rem"
          >
            <RouterLink to={ADMIN_PATH}>
              Start collecting bookings now
            </RouterLink>
          </Button>
        </Flex>
        <Image src={LoginImageSvg} />
      </Stack>
      <LandingSection>
        <SectionTitleText maxW="37.5rem">Our features</SectionTitleText>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacingX="2.5rem"
          spacingY="4rem"
          mt="4rem"
        >
          <FeatureGridItem
            image={IconWebhooksSvg}
            title="Minimum notice period"
            description="Set a minimum number of days before attendees can make a booking"
          />
          <FeatureGridItem
            image={IconSingpassSvg}
            title="Singpass integration"
            description="Authenticate attendees with Singpass for free"
          />
          <FeatureGridItem
            image={IconLogicSvg}
            title="Reminders"
            description="Automatically schedule reminders for attendees so no one forgets a booking"
          />
          <FeatureGridItem
            image={IconEmailSvg}
            title="Calendar invitations"
            description="Send calendar invitations to both you and your attendee"
          />
          <FeatureGridItem
            image={IconDndSvg}
            title="Reschedule bookings"
            description="Enable attendees to reschedule or cancel bookings, notifying you of the change"
          />
          <FeatureGridItem
            image={IconPrefillSvg}
            title="Event lists"
            description="List multiple events and let attendees choose which to book, e.g. if your event has multiple locations"
          />
        </SimpleGrid>
      </LandingSection>
      <Stack
        bg="white"
        direction={{ base: 'column', lg: 'row' }}
        align="center"
        spacing={{ base: '1.5rem', md: '3.125rem', lg: '7.5rem' }}
        px={{ base: '1.5rem', md: '4.5rem', lg: '8rem' }}
        py={{ base: '1rem', md: '2rem', lg: '4rem' }}
      >
        <Flex flexDir="column" flex={1}>
          <Text
            textStyle={{
              base: 'responsive-heading.heavy',
              md: 'responsive-heading.heavy-480',
              lg: 'responsive-heading.heavy-1280',
            }}
          >
            All the government tools you need to manage your workflow
          </Text>
          <SectionBodyText>
            <Text>
              <Text as="span">{`CalSG is part of the `}</Text>
              <Text fontWeight="bold" as="span">
                Open Government Products Suite
              </Text>
              <Text as="span">{`, and as a public officer you can mix and match from our set of productivity and collaboration tools.`}</Text>
            </Text>
          </SectionBodyText>
          <Button variant="link" mt="2.5rem" rightIcon={<BiRightArrowAlt />}>
            <RouterLink to={'https://www.open.gov.sg/products/overview'}>
              Full list of OGP products
            </RouterLink>
          </Button>
        </Flex>
        <Box flex={1} py="2rem">
          <Image src={OgpSuiteSvg} />
        </Box>
      </Stack>
      <Footer />
    </Flex>
  )
}
