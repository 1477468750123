import { Divider, VStack } from '@chakra-ui/react'

import { AdminDashboardEventDto, AdminDashboardEventListDto } from '~shared/dto'

import { DashboardEventListRow } from './DashboardEventListRow'
import { DashboardEventRow } from './DashboardEventRow'

export type DashboardListItem =
  | ({ itemType: 'event' } & AdminDashboardEventDto)
  | ({ itemType: 'eventList' } & AdminDashboardEventListDto)

export type DashboardListProps = {
  items: DashboardListItem[]
}

export const DashboardList = ({ items }: DashboardListProps): JSX.Element => {
  return (
    <VStack
      spacing={0}
      mt={2}
      divider={<Divider />}
      w="full"
      alignItems="stretch"
    >
      {items.map((item) =>
        item.itemType === 'event' ? (
          <DashboardEventRow key={item.id} event={item} />
        ) : (
          <DashboardEventListRow key={item.id} eventList={item} />
        ),
      )}
    </VStack>
  )
}
