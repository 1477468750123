import { Grid } from '@chakra-ui/react'

import { useClientBreakpointValue } from '~hooks/useClientBreakpointValue'

export const AVAILABILITY_COL_1_GRID_TEMPLATE_AREA = 'availability_col_1'
export const AVAILABILITY_COL_2_GRID_TEMPLATE_AREA = 'availability_col_2'
export const AVAILABILITY_COL_3_GRID_TEMPLATE_AREA = 'availability_col_3'
export const AVAILABILITY_COL_4_GRID_TEMPLATE_AREA = 'availability_col_4'

export interface AvailabilityRowWrapperProps {
  children: React.ReactNode
}

export const AvailabilityRowWrapper = ({
  children,
}: AvailabilityRowWrapperProps) => {
  const shouldRenderCompact = useClientBreakpointValue({
    base: true,
    lg: false,
  })
  return (
    <Grid
      templateColumns={
        shouldRenderCompact
          ? '10fr 1fr'
          : // We want to keep this rigid so that all the columns line up in the availability page.
            // We are splitting 44rem - 48px * 2 across the 3 columns.
            '12rem 20rem 3rem 3rem'
      }
      templateAreas={
        shouldRenderCompact
          ? `"${AVAILABILITY_COL_1_GRID_TEMPLATE_AREA} ${AVAILABILITY_COL_4_GRID_TEMPLATE_AREA}" "${AVAILABILITY_COL_2_GRID_TEMPLATE_AREA} ${AVAILABILITY_COL_3_GRID_TEMPLATE_AREA}"`
          : `"${AVAILABILITY_COL_1_GRID_TEMPLATE_AREA} ${AVAILABILITY_COL_2_GRID_TEMPLATE_AREA} ${AVAILABILITY_COL_3_GRID_TEMPLATE_AREA} ${AVAILABILITY_COL_4_GRID_TEMPLATE_AREA}"`
      }
      width="full"
      rowGap={{ base: '4px', lg: 0 }}
    >
      {children}
    </Grid>
  )
}
