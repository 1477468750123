import { Icon, IconProps } from '@chakra-ui/react'

export const CalendarTimeIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M19 4H17V2H15V4H9V2H7V4H5C3.897 4 3 4.897 3 6V20C3 21.103 3.897 22 5 22H19C20.103 22 21 21.103 21 20V6C21 4.897 20.103 4 19 4ZM19.002 20H5V8H19L19.002 20Z"
        fill="#2C2E34"
      />
      <path
        d="M13 9.5H11V14.414L14.293 17.707L15.707 16.293L13 13.586V9.5Z"
        fill="#2C2E34"
      />
    </Icon>
  )
}
