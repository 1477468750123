import { useMemo } from 'react'
import { HStack, Text, Tooltip, useClipboard } from '@chakra-ui/react'

export type ClickToCopyProps = {
  children: string
  label?: string
  enableCopy?: boolean
}

export const ClickToCopy = ({
  children: name,
  label,
  enableCopy = true,
}: ClickToCopyProps) => {
  const { onCopy, hasCopied } = useClipboard(name)
  const copyText = useMemo(() => {
    if (hasCopied) return 'Copied'
    if (label) return `Click to copy ${label}`
    return `Click to copy`
  }, [hasCopied, label])

  const textElement = (
    <Text
      textStyle="body-2"
      cursor="pointer"
      onClick={(e) => {
        if (enableCopy) {
          onCopy()
          e.stopPropagation()
        }
      }}
    >
      {name?.toLowerCase()}
    </Text>
  )

  return (
    <HStack>
      {enableCopy ? (
        <Tooltip label={copyText} closeOnClick={false}>
          {textElement}
        </Tooltip>
      ) : (
        textElement
      )}
    </HStack>
  )
}
