import { ChangeEvent } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { Box, Flex, FormControl, HStack, Text } from '@chakra-ui/react'
import {
  Checkbox,
  FormErrorMessage,
  NumberInput,
  SingleSelect,
} from '@opengovsg/design-system-react'

import { DISPLAY_TIME_UNIT_OPTIONS } from '~shared/utils'

import { GeneralSettingsFormFields } from '../types'
import { validateMinChangeLeadTimeMinutes } from '../utils/form-utils'

export const BookingChangePolicy = () => {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    register,
  } = useFormContext<GeneralSettingsFormFields>()
  // useWatch ensures that this component rerenders when isMinChangeLeadTimeEnabled
  // changes, else disabled and error states go out of sync
  const isMinChangeLeadTimeEnabled = useWatch<GeneralSettingsFormFields>({
    control,
    name: 'isMinChangeLeadTimeEnabled',
  }) as boolean

  return (
    <Flex flexDir={'column'} alignItems={'flex-start'}>
      <HStack>
        <Checkbox
          size="sm"
          {...register('isMinChangeLeadTimeEnabled', {
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              if (!event.target.checked) {
                setValue('minChangeLeadTimeQuantity', null)
                clearErrors('minChangeLeadTimeQuantity')
              }
            },
          })}
        >
          <Text textStyle={'subhead-2'}>
            Require an advance notice period for booking changes
          </Text>
        </Checkbox>
      </HStack>
      <Text
        textStyle={'body-2'}
        as="span"
        ml="36px"
        mt={1.5}
        pb={2}
        color={
          isMinChangeLeadTimeEnabled
            ? undefined
            : 'interaction.support.disabled-content'
        }
      >
        Invitees can only cancel or reschedule bookings at least...
      </Text>
      <FormControl isInvalid={!!errors.minChangeLeadTimeQuantity}>
        <Flex flexWrap={'wrap'} alignItems="center" gap="4px" ml="36px">
          <Controller
            name="minChangeLeadTimeQuantity"
            rules={{
              validate: validateMinChangeLeadTimeMinutes,
            }}
            render={({ field: { onChange, ref, value } }) => (
              <NumberInput
                ref={ref}
                value={(value ?? '') as string}
                onChange={(_valueAsString, valueAsNumber) =>
                  onChange(isNaN(valueAsNumber) ? null : valueAsNumber)
                }
                showSteppers={false}
                width="20%"
                minW={{ base: '100%', md: '80px' }}
                maxW={{ md: '10ch' }}
                display="inline-block"
                fontSize={'14px'}
                isDisabled={!isMinChangeLeadTimeEnabled}
                size="sm"
              />
            )}
          />
          <Controller
            name="minChangeLeadTimeUnit"
            render={({ field: { onChange, ref, value } }) => (
              // Force select box to be smaller, but big enough to accommodate all characters
              <Box
                maxW={{ md: '15ch' }}
                minW={{ base: '100%', md: 'unset' }}
                display="inline-block"
              >
                <SingleSelect
                  ref={ref}
                  name="minChangeLeadTimeUnit"
                  isClearable={false}
                  value={(value ?? '') as string}
                  onChange={onChange}
                  items={DISPLAY_TIME_UNIT_OPTIONS}
                  isDisabled={!isMinChangeLeadTimeEnabled}
                  size="sm"
                />
              </Box>
            )}
          />
          <Text
            textStyle={'body-2'}
            as="span"
            color={
              isMinChangeLeadTimeEnabled
                ? undefined
                : 'interaction.support.disabled-content'
            }
          >
            before the booking start time
          </Text>
        </Flex>
        <FormErrorMessage ml="36px">
          {errors.minChangeLeadTimeQuantity?.message}
        </FormErrorMessage>
      </FormControl>
    </Flex>
  )
}
