import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import type { GetPublicEventRes } from '~shared/dto/event.dto'

import { api } from '~lib/api'
import { publicQueryKeys } from '~constants/queryKeys'

/**
 * Fetches the event from the server using its eventId.
 *
 * @returns the Event and associated FormFields.
 */
export const getPublicEvent = async (
  eventId: string,
): Promise<GetPublicEventRes> => {
  return api.url(`/events/${eventId}`).get().json<GetPublicEventRes>()
}

type UseGetPublicEvent = {
  eventId: string
} & UseQueryOptions<
  GetPublicEventRes,
  unknown,
  GetPublicEventRes,
  ReturnType<typeof publicQueryKeys.event>
>

export const useGetPublicEvent = ({
  eventId,
  ...useQueryOptions
}: UseGetPublicEvent) => {
  return useQuery(
    publicQueryKeys.event({ eventId }),
    () => getPublicEvent(eventId),
    useQueryOptions,
  )
}
