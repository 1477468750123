import { createContext, useContext, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { AdminDashboardEventDto, AdminDashboardEventListDto } from '~shared/dto'

import { DeleteEventListModal } from '~features/event-lists/components/DeleteEventListModal'
import { DeleteEventModal } from '~features/events/components/EventActions/DeleteEventModal'
import { DuplicateEventModal } from '~features/events/components/EventActions/DuplicateEventModal'

type AdminDashboardEventListSummary = Pick<
  AdminDashboardEventListDto,
  'id' | 'title'
>

interface DashboardModalsContextReturn {
  activeEvent?: AdminDashboardEventDto
  activeEventList?: { id: string; title: string }
  onOpenDuplicateEventModal: (event?: AdminDashboardEventDto) => void
  onOpenDeleteEventModal: (event?: AdminDashboardEventDto) => void
  onOpenDeleteEventListModal: (
    eventList?: AdminDashboardEventListSummary,
  ) => void
}

const DashboardModalsContext =
  createContext<DashboardModalsContextReturn | null>(null)

export const DashboardModalsProvider = ({
  children,
  eventListId,
}: {
  children?: React.ReactNode
  eventListId?: string
}) => {
  const [activeEvent, setActiveEvent] = useState<AdminDashboardEventDto>()
  const [activeEventList, setActiveEventList] =
    useState<AdminDashboardEventListSummary>()

  const duplicateEventModalDisclosure = useDisclosure()
  const deleteEventModalDisclosure = useDisclosure()

  const deleteEventListModalDisclosure = useDisclosure()

  const onOpenDuplicateEventModal = (event?: AdminDashboardEventDto) => {
    setActiveEvent(event)
    if (event) {
      duplicateEventModalDisclosure.onOpen()
    }
  }

  const onOpenDeleteEventModal = (event?: AdminDashboardEventDto) => {
    setActiveEvent(event)
    if (event) {
      deleteEventModalDisclosure.onOpen()
    }
  }

  const onOpenDeleteEventListModal = (
    eventList?: AdminDashboardEventListSummary,
  ) => {
    setActiveEventList(eventList)
    if (eventList) {
      deleteEventListModalDisclosure.onOpen()
    }
  }

  return (
    <DashboardModalsContext.Provider
      value={{
        activeEvent,
        onOpenDeleteEventModal,
        onOpenDuplicateEventModal,
        onOpenDeleteEventListModal,
      }}
    >
      <DeleteEventModal
        eventId={activeEvent?.id ?? ''}
        eventTitle={activeEvent?.title ?? ''}
        eventListId={eventListId}
        isOpen={deleteEventModalDisclosure.isOpen}
        onClose={deleteEventModalDisclosure.onClose}
      />
      <DuplicateEventModal
        eventId={activeEvent?.id ?? ''}
        eventTitle={activeEvent?.title ?? ''}
        eventListId={eventListId}
        isOpen={duplicateEventModalDisclosure.isOpen}
        onClose={duplicateEventModalDisclosure.onClose}
      />
      <DeleteEventListModal
        eventListId={activeEventList?.id ?? ''}
        eventListTitle={activeEventList?.title ?? ''}
        isOpen={deleteEventListModalDisclosure.isOpen}
        onClose={deleteEventListModalDisclosure.onClose}
      />
      {children}
    </DashboardModalsContext.Provider>
  )
}

export const useDashboardModalsContext = () => {
  const context = useContext(DashboardModalsContext)
  if (context === null) {
    throw new Error(
      'useDashboardModalsContext must be used within a DashboardModalsProvider',
    )
  }
  return context
}
