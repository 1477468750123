import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { Icon, Image, Text, VStack } from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'

import EventClosedSvg from '~/assets/img/closed-event.svg'

export const TooManyRequests = () => {
  const navigate = useNavigate()

  return (
    <VStack
      w="100%"
      h="100vh"
      maxW="600px"
      mx="auto"
      paddingY={8}
      spacing={8}
      justify="center"
      align="center"
      textStyle="body-2"
      textAlign="center"
    >
      <Image src={EventClosedSvg} alt="too-many-requests" />
      <VStack paddingX={8} spacing={4}>
        <Text textStyle="h5">{`Too many requests`}</Text>

        <Text>
          {`Looks like you've reloaded this page too quickly. Please wait for a moment and try again.`}
        </Text>
        <Link
          onClick={() => navigate(-1)}
          variant="plain"
          display="flex"
          alignItems="center"
        >
          <Icon as={BiArrowBack} mr={2} />
          Back to the previous page
        </Link>
      </VStack>
    </VStack>
  )
}
