import { Text, VStack } from '@chakra-ui/react'

export const CollaboratorHeading = () => {
  return (
    <VStack alignItems="stretch">
      <Text textStyle="h4">Manage collaborators</Text>
      <Text textStyle="body-2">
        Share your event with users who need to edit the event or access
        responses
      </Text>
    </VStack>
  )
}
