import { Controller, useFormState } from 'react-hook-form'
import { FormControl, Text, VStack } from '@chakra-ui/react'
import { Checkbox, FormErrorMessage } from '@opengovsg/design-system-react'

import { SgidSettingsFormFields } from '../types'

export const RequireSgidInput = () => {
  const { errors } = useFormState<SgidSettingsFormFields>()

  return (
    <FormControl isInvalid={!!errors.isSgidEnabled}>
      <Controller
        name="isSgidEnabled"
        render={({ field: { onChange, value } }) => (
          <Checkbox
            size="sm"
            checked={value as boolean}
            defaultChecked={value as boolean}
            onChange={(val) => onChange(val.target.checked)}
            name="isSgidEnabled"
          >
            <VStack spacing={1} alignItems="stretch">
              <Text textStyle="subhead-2">
                Require attendees to login with Singpass to make bookings
              </Text>
              <Text textStyle="body-2">
                {"Attendee's Singpass-verified NRIC will be collected together " +
                  'with their booking. CalSG can store up to Restricted and ' +
                  'Sensitive (Normal) data.'}
              </Text>
            </VStack>
          </Checkbox>
        )}
      />
      <FormErrorMessage>{errors.isSgidEnabled?.message}</FormErrorMessage>
    </FormControl>
  )
}
