import { BiBlock, BiCalendarAlt } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import {
  Box,
  Center,
  Flex,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { GetPublicEventRes } from '~shared/dto'

import CancelledBookingSvg from '~/assets/img/cancelled-booking.svg'

import { useIsClientMobile } from '~hooks/useIsClientMobile'
import { ButtonStack } from '~components/ButtonStack'

import { EventSummary } from '~features/form/components/EventSummary'
import { SgidLogoutButton } from '~features/form/components/SgidButtons'
import {
  identitySelector,
  previousBookingSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { FormState } from '~features/form/types/formState'

export interface CancelConfirmationPageProps {
  event: GetPublicEventRes
}

export const CancelConfirmationPage = ({
  event,
}: CancelConfirmationPageProps): JSX.Element => {
  const previousBooking = useFormStore(previousBookingSelector)
  const isMobile = useIsClientMobile()
  const identity = useFormStore(identitySelector)

  return (
    <VStack w="full" spacing={6} flex={1}>
      <HStack w="full" alignItems="start">
        <VStack alignItems="stretch" spacing={6} flex={1}>
          <HStack>
            <Icon
              as={BiBlock}
              fontSize="1.25rem"
              color="utility.feedback.critical"
            />
            <Text textStyle="subhead-2">Your booking has been cancelled</Text>
          </HStack>
          <Box w="full">
            <Text
              textStyle={{ base: 'h6', md: 'h4' }}
              w="full"
              mb="0.5rem"
              textDecor="line-through"
              overflowWrap="anywhere"
            >
              {event.title}
            </Text>
            <EventSummary
              event={event}
              strikethrough
              booking={previousBooking}
            />
          </Box>
          {isMobile && (
            <Center w="full">
              <Image
                src={CancelledBookingSvg}
                w="full"
                h="full"
                maxW="200px"
                maxH="100%"
                aria-hidden
              />
            </Center>
          )}
        </VStack>
        {!isMobile && (
          <Image
            src={CancelledBookingSvg}
            w="full"
            h="full"
            maxW="316px"
            maxH="100%"
            aria-hidden
          />
        )}
      </HStack>
      <Spacer />
      <ButtonStack w="full" alignItems="stretch">
        <Link to={`/${event.id}`} reloadDocument>
          <Button
            w={{ base: 'full', md: 'auto' }}
            h={{ base: '56px', md: 'auto' }}
            leftIcon={<BiCalendarAlt fontSize="1.5rem" />}
          >
            Make another booking
          </Button>
        </Link>
        {identity !== null && (
          <>
            <Flex
              alignItems="center"
              direction={{ base: 'column', md: 'row' }}
              flex={1}
              pt={{ base: 2, md: 0 }}
            >
              <Spacer />
              <SgidLogoutButton
                redirectToState={FormState.CancellationSubmitted}
                identity={identity}
              />
            </Flex>
          </>
        )}
      </ButtonStack>
    </VStack>
  )
}
