import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'
import { FormControl, Text, Textarea, VStack } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@opengovsg/design-system-react'

import {
  EVENT_DESCRIPTION_MAX_LEN,
  EVENT_LOCATION_MAX_LEN,
  EVENT_TITLE_MAX_LEN,
} from '~shared/constants'
import { GetEventRes, UpdateEventReq } from '~shared/dto'

import { SaveChangesButton } from '~components/SaveChangesButton'

import { useUpdateEvent } from '../hooks/useAdminEvents'
import { AdminEventOutletContext } from '../types'

import { SyncToDirtyFieldStore } from './SyncToDirtyFieldStore'

type EventSetupFormFields = Pick<
  UpdateEventReq,
  'title' | 'description' | 'location'
>

export const EventSetup = () => {
  const toast = useToast()
  const { event } = useOutletContext<AdminEventOutletContext>()

  const formMethods = useForm<EventSetupFormFields>({
    mode: 'onChange',
    defaultValues: {
      title: event.title,
      description: event.description,
      location: event.location,
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = formMethods

  const { mutateAsync, isLoading } = useUpdateEvent(event.id)

  const onSubmit = handleSubmit((data: EventSetupFormFields) =>
    mutateAsync(data, {
      onSuccess: (res: GetEventRes) => {
        toast({
          description: 'Event updated successfully.',
          status: 'success',
        })
        reset({
          title: res.title,
          description: res.description,
          location: res.location,
        })
      },
    }),
  )

  return (
    <FormProvider {...formMethods}>
      <SyncToDirtyFieldStore />
      <VStack spacing={6} alignItems="stretch">
        <VStack alignItems={'start'}>
          <Text textStyle="h4">Event setup</Text>
          <Text textStyle="body-2">
            Customise the details shown to your attendees when they make a
            booking
          </Text>
        </VStack>
        <FormControl isInvalid={!!errors.title}>
          <FormLabel isRequired size="sm">
            {'Event title'}
          </FormLabel>
          <Controller
            name="title"
            rules={{
              required: 'This field is required',
              maxLength: {
                value: EVENT_TITLE_MAX_LEN,
                message: `Title can be at most ${EVENT_TITLE_MAX_LEN} characters`,
              },
            }}
            render={({ field: { onChange, ref, value } }) => (
              <Input
                ref={ref}
                value={value as string}
                onChange={onChange}
                size="sm"
              />
            )}
          />
          <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.description}>
          <FormLabel
            isRequired
            description="Provide any info which your attendees need to know before booking a slot, like the purpose of the event"
            size="sm"
          >
            {'Details'}
          </FormLabel>
          <Controller
            name="description"
            rules={{
              required: 'This field is required',
              maxLength: {
                value: EVENT_DESCRIPTION_MAX_LEN,
                message: `Description can be at most ${EVENT_DESCRIPTION_MAX_LEN} characters`,
              },
            }}
            render={({ field: { onChange, ref, value } }) => (
              <Textarea
                ref={ref}
                value={value as string}
                onChange={onChange}
                minH="200px"
                size="sm"
              />
            )}
          />
          <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.location}>
          <FormLabel
            description="You can enter a physical location or meeting link"
            size="sm"
          >
            {'Location'}
          </FormLabel>
          <Controller
            name="location"
            rules={{
              maxLength: {
                value: EVENT_LOCATION_MAX_LEN,
                message: `Location can be at most ${EVENT_LOCATION_MAX_LEN} characters`,
              },
            }}
            render={({ field: { onChange, ref, value } }) => (
              <Input
                ref={ref}
                value={value as string}
                onChange={onChange}
                size="sm"
              />
            )}
          />
          <FormErrorMessage>{errors.location?.message}</FormErrorMessage>
        </FormControl>
        <SaveChangesButton
          isDirty={isDirty}
          isLoading={isLoading}
          onClick={onSubmit}
        />
      </VStack>
    </FormProvider>
  )
}
