import { ReactElement } from 'react'
import {
  BiBold,
  BiItalic,
  BiListOl,
  BiListUl,
  BiUnderline,
} from 'react-icons/bi'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  HStack,
  Tooltip,
  TypographyProps,
} from '@chakra-ui/react'
import { Button, IconButton, Menu } from '@opengovsg/design-system-react'
import { Editor } from '@tiptap/react'

import { LinkButton } from './LinkButton'

type MenuButtonTypes = FormatIconButton | DropdownMenuButton | Divider

type FormatIconButton = {
  type: 'icon'
  tooltipLabel: string
  onClick: (editor: Editor) => boolean
  Icon: ReactElement
  isActive: (editor: Editor) => boolean
}

type DropdownMenuButton = {
  type: 'dropdown'
  options: {
    label: string
    onClick: (editor: Editor) => boolean
    isActive: (editor: Editor) => boolean
    styles?: {
      fontWeight: TypographyProps['fontWeight']
      fontSize: TypographyProps['fontSize']
    }
  }[]
}

type Divider = {
  type: 'divider'
}

const menuButtons: MenuButtonTypes[] = [
  {
    type: 'icon',
    tooltipLabel: 'Bold (Ctrl + B)',
    onClick: (editor) => editor.chain().focus().toggleBold().run(),
    Icon: <BiBold />,
    isActive: (editor) => editor.isActive('bold'),
  },
  {
    type: 'icon',
    tooltipLabel: 'Italic (Ctrl + I)',
    onClick: (editor) => editor.chain().focus().toggleItalic().run(),
    Icon: <BiItalic />,
    isActive: (editor) => editor.isActive('italic'),
  },
  {
    type: 'icon',
    tooltipLabel: 'Underline',
    Icon: <BiUnderline />,
    onClick: (editor) => editor.commands.toggleUnderline(),
    isActive: (editor) => editor.isActive('underline'),
  },
  {
    type: 'divider',
  },
  {
    type: 'icon',
    tooltipLabel: 'Bullet List',
    Icon: <BiListUl />,
    onClick: (editor: Editor) =>
      editor.chain().focus().toggleBulletList().run(),
    isActive: (editor: Editor) => editor.isActive('bulletList'),
  },
  {
    type: 'icon',
    tooltipLabel: 'Ordered List',
    Icon: <BiListOl />,
    onClick: (editor: Editor) =>
      editor.chain().focus().toggleOrderedList().run(),
    isActive: (editor: Editor) => editor.isActive('orderedList'),
  },
]

const RichTextDivider = () => {
  return (
    <>
      <Box
        sx={{
          width: '1px',
          height: '1rem',
          backgroundColor: 'gray.300',
          mx: 4,
        }}
      />
    </>
  )
}

export const RichTextMenu = ({ editor }: { editor: Editor | null }) => {
  if (!editor) {
    return null
  }

  return (
    <HStack p={1} className="ProseMenuBar" flexWrap="wrap">
      {menuButtons.map((node, index) => {
        if (node.type === 'divider') return <RichTextDivider key={index} />

        if (node.type === 'dropdown') {
          const currActiveStyle =
            node.options.find((option) => option.isActive(editor))?.label ??
            'Normal'

          return (
            <Menu matchWidth closeOnBlur closeOnSelect key={index}>
              <Menu.Button
                colorScheme="secondary"
                textAlign="start"
                w="140px"
                variant="outline"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                textTransform="capitalize"
              >
                {currActiveStyle}
              </Menu.Button>
              <Menu.List>
                {node.options.map((option) => (
                  <Menu.Item
                    key={option.label}
                    textTransform="capitalize"
                    onClick={() => option.onClick(editor)}
                    {...option.styles}
                  >
                    {option.label}
                  </Menu.Item>
                ))}
              </Menu.List>
            </Menu>
          )
        }

        if (node.type === 'icon') {
          const { tooltipLabel, Icon, isActive, onClick } = node

          return (
            <Tooltip label={tooltipLabel} key={tooltipLabel}>
              <IconButton
                variant="clear"
                color="black"
                backgroundColor={isActive(editor) ? 'primary.100' : undefined}
                aria-label={tooltipLabel}
                onClick={() => onClick(editor)}
                borderColor="transparent"
                icon={Icon}
              />
            </Tooltip>
          )
        }
      })}

      <RichTextDivider />

      <LinkButton editor={editor} />

      <RichTextDivider />
    </HStack>
  )
}
