import { useOutletContext } from 'react-router-dom'
import { VStack } from '@chakra-ui/react'

import { useAdminAuth } from '~features/auth'
import { AdminEventOutletContext } from '~features/events/types'

import { AddCollaboratorForm } from './components/AddCollaboratorForm'
import { CurrentCollaborators } from './components/CurrentCollaborators'
import { EventListCollaboratorPrompt } from './components/EventListCollaboratorPrompt'
import { TransferOwnershipForm } from './components/TransferOwnershipForm'

export const Collaborators = () => {
  const { event } = useOutletContext<AdminEventOutletContext>()
  const { adminUser } = useAdminAuth()

  if (event.eventListIds.length > 0) {
    return <EventListCollaboratorPrompt eventListId={event.eventListIds[0]} />
  }

  return (
    <VStack spacing="32px" alignItems="stretch" w="full">
      <VStack spacing={6} alignItems="stretch" w="full">
        <AddCollaboratorForm event={event} />
        <CurrentCollaborators event={event} />
      </VStack>
      {event.admin.email === adminUser?.email && (
        <TransferOwnershipForm event={event} />
      )}
    </VStack>
  )
}
