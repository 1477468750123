import { useCallback } from 'react'
import { BiLogInCircle } from 'react-icons/bi'
import { Navigate } from 'react-router-dom'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import {
  Button,
  Infobox,
  RestrictedFooter,
} from '@opengovsg/design-system-react'

import LoginImageSvg from '~/assets/img/login-image.svg'

import { APP_NAME } from '~constants/config'
import { useIsDesktop } from '~hooks/useIsDesktop'
import { Logo } from '~components/Logo'

import {
  BackgroundBox,
  BaseGridLayout,
  FooterGridArea,
  LoginGridArea,
  NonMobileSidebarGridArea,
} from './components/LoginPageGrid'
import { useAdminAuth } from './AdminAuthContext'
import { useAdminSgidAuthUrl } from './hooks'

export const SgidAdminLoginPage = (): JSX.Element => {
  const isDesktop = useIsDesktop()
  const { adminUser } = useAdminAuth()

  const sgidAuthUrlMutation = useAdminSgidAuthUrl()

  const handleLoginButtonClick = useCallback(() => {
    sgidAuthUrlMutation.mutate(undefined, {
      onSuccess: ({ url }) => window.location.assign(url),
    })
  }, [sgidAuthUrlMutation])

  if (adminUser) {
    return <Navigate to="/admin/dashboard" />
  }

  return (
    <BackgroundBox>
      <BaseGridLayout flex={1}>
        <NonMobileSidebarGridArea>
          <Image src={LoginImageSvg} />
        </NonMobileSidebarGridArea>
        <LoginGridArea>
          <Box minH={{ base: 'auto', lg: '17.25rem' }} w="100%">
            <Flex mb={{ base: '2.5rem', lg: 0 }} flexDir="column">
              <Text
                display={{ base: 'none', lg: 'initial' }}
                textStyle="responsive-heading.heavy-1280"
                mb="2.5rem"
              >
                Easy appointment scheduling for Government
              </Text>
              <Box display={{ base: 'initial', lg: 'none' }}>
                <Box mb={{ base: '0.75rem', lg: '1.5rem' }}>
                  <Logo h="2rem" />
                </Box>
                <Text
                  textStyle={{
                    base: 'responsive-heading.heavy',
                    md: 'responsive-heading.heavy-480',
                    lg: 'responsive-heading.heavy-1280',
                  }}
                >
                  Easy appointment scheduling for Government
                </Text>
              </Box>
            </Flex>
            <Infobox mb="1rem">
              This is an experimental service currently offered only to OGP
              officers only.
            </Infobox>
            <Button
              rightIcon={<BiLogInCircle fontSize="1.5rem" />}
              onClick={handleLoginButtonClick}
              isLoading={sgidAuthUrlMutation.isLoading}
            >
              Log in with Singpass app
            </Button>
          </Box>
        </LoginGridArea>
      </BaseGridLayout>
      <BaseGridLayout
        bg={{ base: 'base.canvas.brandLight', lg: 'transparent' }}
      >
        <FooterGridArea>
          <RestrictedFooter
            appLink={window.location.origin}
            appName={APP_NAME.full}
            containerProps={{
              px: 0,
            }}
            variant={{ lg: 'compact' }}
            colorMode={isDesktop ? 'dark' : 'light'}
          />
        </FooterGridArea>
      </BaseGridLayout>
    </BackgroundBox>
  )
}
