import { CreateScheduleSegmentReq, UpdateScheduleRes } from '~shared/dto'
import { Day } from '~shared/types'

import { OverridesFormValue } from '../ManageOverrides/common/constants'

import { TimeRange } from './ScheduleTimeRangeField/manage-schedule-time-range-utils'

export type ManageScheduleFormState = {
  days: {
    [day in Day]: {
      isOpen: boolean
      timeslots: TimeRange[]
    }
  }
  slotDurationMins: number
  overrides: OverridesFormValue[]
}

export const generateRecurringDaysFormFromSchedule = (
  schedule: UpdateScheduleRes,
): ManageScheduleFormState['days'] => {
  // If nothing is given, default 9-5 everyday but disabled
  const base = Object.values(Day).reduce((acc, day) => {
    return {
      ...acc,
      [day]: {
        isOpen: false,
        timeslots: [
          {
            start: 9 * 60 * 60 * 1000,
            end: 17 * 60 * 60 * 1000,
          },
        ],
      },
    }
  }, {}) as ManageScheduleFormState

  const accum: Partial<ManageScheduleFormState['days']> = {}
  for (const segment of schedule.segments || []) {
    // If first time accessing this day, initialize it
    accum[segment.day] = accum[segment.day] ?? {
      isOpen: true,
      timeslots: [],
    }

    accum[segment.day]?.timeslots.push({
      start: segment.startTime,
      end: segment.endTime,
    })
  }

  return {
    ...base,
    ...accum,
  } as ManageScheduleFormState['days']
}

export const parseFormStateToSegments = (
  data: ManageScheduleFormState['days'],
): CreateScheduleSegmentReq[] => {
  return Object.entries(data).reduce((accum, [day, dayData]) => {
    if (!dayData.isOpen) return accum

    return [
      ...accum,
      ...dayData.timeslots.map(({ start, end }) => ({
        startTime: start,
        endTime: end,
        day: day as Day,
      })),
    ]
  }, [] as CreateScheduleSegmentReq[])
}
