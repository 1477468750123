import { useMutation, useQueryClient } from '@tanstack/react-query'

import { UpdateScheduleReq, UpdateScheduleRes } from '~shared/dto'

import { api } from '~lib/api'
import { adminQueryKeys } from '~constants/queryKeys'

export const useUpdateSchedule = ({
  eventId,
  scheduleId,
}: {
  eventId: string
  scheduleId: string
}) => {
  const queryCache = useQueryClient()

  return useMutation(
    async (req: UpdateScheduleReq) =>
      api
        .url(`/admin/schedules/${scheduleId}`)
        .put(req)
        .json<UpdateScheduleRes>(),
    {
      onSuccess: async () => {
        // Schedules are fetched together with event, so when schedules update,
        // we need to refetch event
        await queryCache.invalidateQueries(adminQueryKeys.events({ eventId }))
      },
    },
  )
}
