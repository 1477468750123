import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Button, ModalCloseButton } from '@opengovsg/design-system-react'

import { ButtonStack } from '~components/ButtonStack'

export interface UnsavedChangesModalProps {
  onDiscardChanges: () => void
  isOpen: boolean
  onClose: () => void
}

export const UnsavedChangesModal = ({
  onDiscardChanges,
  isOpen,
  onClose,
}: UnsavedChangesModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalHeader mr="44px">You have unsaved changes</ModalHeader>
        <ModalBody whiteSpace="pre-wrap">
          Are you sure you want to leave? Your changes will be lost.
        </ModalBody>
        <ModalFooter>
          <ButtonStack isReverseOrderOnMobile>
            <Button variant="clear" colorScheme="secondary" onClick={onClose}>
              No, stay on page
            </Button>

            <Button colorScheme={'critical'} onClick={onDiscardChanges}>
              Yes, discard changes
            </Button>
          </ButtonStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
