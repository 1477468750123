import { Link as ReactRouterLink } from 'react-router-dom'
import { Alert, AlertIcon, Text, VStack } from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'

import { CollaboratorHeading } from './CollaboratorHeading'

export type EventListCollaboratorPromptProps = {
  eventListId: string
}

export const EventListCollaboratorPrompt = ({
  eventListId,
}: EventListCollaboratorPromptProps) => {
  return (
    <VStack spacing="32px" alignItems="stretch" w="full">
      <CollaboratorHeading />
      <Alert status="info">
        <AlertIcon />
        <Text>
          Collaborators for this event are managed in the event list
          <Link
            ml={1}
            as={ReactRouterLink}
            to={`/admin/event-list/${eventListId}`}
            state="editEventList"
          >
            settings
          </Link>
        </Text>
        .
      </Alert>
    </VStack>
  )
}
