import { IconType } from 'react-icons'
import {
  BiCaretDownSquare,
  BiMobile,
  BiRadioCircleMarked,
  BiRename,
  BiUser,
} from 'react-icons/bi'

import { FormFieldType } from '~shared/types'

export type AddFormFieldOption = {
  label: string
  icon: IconType
  type: FormFieldType
}

export type FormFieldDisplayData = {
  [t in FormFieldType]: { label: string; icon: IconType }
}

export const ADD_FORM_FIELD_OPTIONS: readonly AddFormFieldOption[] = [
  {
    label: 'Short answer',
    icon: BiRename,
    type: FormFieldType.ShortText,
  },
  {
    label: 'Radio',
    icon: BiRadioCircleMarked,
    type: FormFieldType.Radio,
  },
  {
    label: 'Mobile number',
    icon: BiMobile,
    type: FormFieldType.Mobile,
  },
  {
    label: 'NRIC',
    icon: BiUser,
    type: FormFieldType.Nric,
  },
  {
    label: 'Dropdown',
    icon: BiCaretDownSquare,
    type: FormFieldType.Dropdown,
  },
]

export const FORM_FIELD_DISPLAY_DATA: FormFieldDisplayData =
  ADD_FORM_FIELD_OPTIONS.reduce<FormFieldDisplayData>((acc, option) => {
    acc[option.type] = option
    return acc
  }, {} as FormFieldDisplayData)
