import { create } from 'zustand'

import { GetPublicBookingRes } from '~shared/dto'
import { Timeslot } from '~shared/types'
import { getStartOfDateInTz } from '~shared/utils'

import { FormState } from '../types/formState'

export type FormStore = {
  formState: FormState
  date: Date | null
  slot: Timeslot | null
  previousBooking: GetPublicBookingRes | null
  // field ID to response
  formResponses: Record<string, string> | null
  createdBooking: GetPublicBookingRes | null
  isSlotGone: boolean
  identity: string | null

  selectDate: (date: Date | null) => void
  selectSlot: (slot: Timeslot | null) => void
  clearSelectedDate: () => void
  setFormSubmitted: (createdBooking: GetPublicBookingRes) => void
  setFormState: (formState: FormState) => void
  setPreviousBooking: (previousBooking: GetPublicBookingRes) => void
  setIsSlotGone: (isSlotGone: boolean) => void
  leaveFormPage: (responses: Record<string, string>) => void
  setIdentity: (identity: string | null) => void
  setFormResponses: (formResponses: Record<string, string>) => void
}

export const useFormStore = create<FormStore>((set) => ({
  formState: FormState.ViewSummary,
  date: null,
  slot: null,
  formResponses: null,
  previousBooking: null,
  createdBooking: null,
  isSlotGone: false,
  identity: null,
  selectDate: (date) => {
    set((currentState) => {
      const isSameDate = currentState.date?.valueOf() === date?.valueOf()
      const formState = date ? FormState.PickTime : FormState.PickDate

      // If its a different date, we change the date and reset the slot too
      return isSameDate ? { formState } : { formState, date, slot: null }
    })
  },
  selectSlot: (slot) => {
    set({
      slot,
    })
  },
  clearSelectedDate: () => {
    set({
      date: null,
      slot: null,
    })
  },
  setFormSubmitted: (createdBooking) =>
    set({ formState: FormState.Submitted, createdBooking }),
  setFormState: (formState) => set({ formState }),
  setPreviousBooking: (previousBooking) => {
    const bookingDate = new Date(previousBooking.startAt)
    const startOfDay = getStartOfDateInTz(bookingDate)
    if (startOfDay === null) {
      set({ previousBooking })
    } else {
      set({
        previousBooking,
        date: startOfDay,
        slot: {
          startAt: previousBooking.startAt,
          endAt: previousBooking.endAt,
        },
      })
    }
  },
  setIsSlotGone: (isSlotGone) => set({ isSlotGone }),
  leaveFormPage: (formResponses) => set({ formResponses, isSlotGone: false }),
  setIdentity: (identity) => set({ identity }),
  setFormResponses: (formResponses) => set({ formResponses }),
}))

export const formStateSelector = (state: FormStore) => state.formState
export const dateSelector = (state: FormStore) => state.date
export const slotSelector = (state: FormStore) => state.slot
export const previousBookingSelector = (state: FormStore) =>
  state.previousBooking
export const formResponsesSelector = (state: FormStore) => state.formResponses
export const setFormResponsesSelector = (state: FormStore) =>
  state.setFormResponses
export const isSlotGoneSelector = (state: FormStore) => state.isSlotGone
export const identitySelector = (state: FormStore) => state.identity
export const selectDateSelector = (state: FormStore) => state.selectDate
export const selectSlotSelector = (state: FormStore) => state.selectSlot
export const clearSelectedDateSelector = (state: FormStore) =>
  state.clearSelectedDate
export const setFormSubmittedSelector = (state: FormStore) =>
  state.setFormSubmitted
export const setFormStateSelector = (state: FormStore) => state.setFormState
export const setPreviousBookingSelector = (state: FormStore) =>
  state.setPreviousBooking
export const createdBookingSelector = (state: FormStore) => state.createdBooking
export const setIsSlotGoneSelector = (state: FormStore) => state.setIsSlotGone
export const leaveFormPageSelector = (state: FormStore) => state.leaveFormPage
export const setIdentitySelector = (state: FormStore) => state.setIdentity
