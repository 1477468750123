import { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import {
  Button,
  ModalCloseButton,
  useToast,
} from '@opengovsg/design-system-react'

import { ButtonStack } from '~components/ButtonStack'

import { useDuplicateEvent } from '~features/events/hooks/useAdminEvents'

import { EventNameInput } from '../CreateEvent/EventNameInput'

export interface DuplicateEventFormFields {
  eventName: string
}

export interface DuplicateEventModalProps {
  eventTitle: string
  eventId: string
  eventListId?: string
  isOpen: boolean
  onClose: () => void
}

export const DuplicateEventModal = ({
  eventTitle,
  eventId,
  eventListId,
  isOpen,
  onClose,
}: DuplicateEventModalProps) => {
  const toast = useToast()
  const { mutateAsync: duplicateEvent, isLoading } = useDuplicateEvent({
    eventListId,
  })
  const defaultDuplicatedEventName = useMemo(
    () => `Copy of ${eventTitle}`,
    [eventTitle],
  )

  const formMethods = useForm<DuplicateEventFormFields>({
    defaultValues: {
      eventName: defaultDuplicatedEventName,
    },
  })

  // We reset the default value every time the event title changes.
  useEffect(() => {
    formMethods.reset({
      eventName: defaultDuplicatedEventName,
    })
  }, [defaultDuplicatedEventName, formMethods])

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods

  const handleModalClose = useCallback(() => {
    onClose()
    reset()
  }, [onClose, reset])

  const handleDuplicateEvent = useCallback(
    async (inputs: DuplicateEventFormFields) => {
      await duplicateEvent(
        {
          eventId,
          req: {
            title: inputs.eventName,
          },
        },
        {
          onSuccess: () => {
            toast({
              description: 'Event duplicated successfully.',
              status: 'success',
            })
            onClose()
          },
          onError: () => {
            toast({
              description: 'Failed to duplicate event.',
              status: 'error',
            })
          },
        },
      )
    },
    [duplicateEvent, eventId, onClose, toast],
  )

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Duplicate event</ModalHeader>
        <ModalBody>
          <Text mb={6}>
            Note that collaborators and responses will not be duplicated.
          </Text>
          <FormProvider {...formMethods}>
            <EventNameInput
              labelText="What's the name of your duplicated event?"
              error={errors.eventName}
            />
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <ButtonStack isReverseOrderOnMobile>
            <Button
              variant="clear"
              colorScheme="secondary"
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              type="button"
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={handleSubmit(handleDuplicateEvent)}
            >
              Duplicate event
            </Button>
          </ButtonStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
