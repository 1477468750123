import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GetTimeslotsQueryReq, GetTimeslotsRes } from '~shared/dto'

import { api } from '~lib/api'
import { publicQueryKeys } from '~constants/queryKeys'

/**
 * Fetches the event from the server using its eventId.
 *
 * @returns the Event and associated FormFields.
 */
export const getSlotsForSchedule = async ({
  scheduleId,
  ...queryParams
}: {
  scheduleId: string
} & GetTimeslotsQueryReq): Promise<GetTimeslotsRes> => {
  return api
    .url(`/schedules/${scheduleId}/slots`)
    .query(queryParams)
    .get()
    .json<GetTimeslotsRes>()
}

type UseGetSlots = {
  scheduleId: string
} & GetTimeslotsQueryReq &
  UseQueryOptions<
    GetTimeslotsRes,
    unknown,
    GetTimeslotsRes,
    ReturnType<typeof publicQueryKeys.slots>
  >

export const useGetSlots = ({
  scheduleId,
  startTime,
  endTime,
  ...useQueryOptions
}: UseGetSlots) => {
  return useQuery(
    publicQueryKeys.slots({ scheduleId, startTime, endTime }),
    () => getSlotsForSchedule({ scheduleId, startTime, endTime }),
    { staleTime: Infinity, ...useQueryOptions },
  )
}
