import { Components } from 'react-markdown'

import { BannerLevel } from '~shared/types'

import { generateMarkdownComponents, MdComponentProps } from '~utils/markdown'

export const infoBannerLinkStyle = {
  color: 'interaction.links.inverse',
  _hover: {
    color: 'interaction.links.inverse-hover',
  },
  textDecorationLine: 'underline',
}

export const errorBannerLinkStyle = {
  color: 'interaction.links.inverse',
  _hover: {
    color: 'interaction.links.inverse-hover',
  },
  textDecorationLine: 'underline',
}

export const generateBannerMarkdownComponents = (
  bannerLevel: BannerLevel,
): Components => {
  const markdownBannerLinkPropsWithinBanner =
    getMarkdownLinkPropsWithinBanner(bannerLevel)
  return generateMarkdownComponents({
    props: markdownBannerLinkPropsWithinBanner,
  })
}

export const getMarkdownLinkPropsWithinBanner = (
  bannerLevel: BannerLevel,
): MdComponentProps => {
  switch (bannerLevel) {
    case 'info':
      return { link: infoBannerLinkStyle }
    case 'error':
      return { link: errorBannerLinkStyle }
    default:
      return { link: {} }
  }
}
