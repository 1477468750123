import { Link as RouterLink } from 'react-router-dom'
import { As, Flex, FlexProps, HStack, Image } from '@chakra-ui/react'
import { Button, IconButton } from '@opengovsg/design-system-react'

import CalSgSvg from '~/assets/img/calsg.svg'
import CalSgStandaloneSvg from '~/assets/img/calsg-standalone.svg'

import { useClientBreakpointValue } from '~hooks/useClientBreakpointValue'
import { useIsDesktop } from '~hooks/useIsDesktop'

type PublicHeaderLinkProps = {
  label: string
  href: string
  showOnMobile?: boolean
  MobileIcon?: As
}

// Can contain link to guide in future
const PUBLIC_HEADER_LINKS: PublicHeaderLinkProps[] = []

const PublicHeaderLink = ({
  showOnMobile,
  MobileIcon,
  href,
  label,
}: PublicHeaderLinkProps) => {
  const isDesktop = useIsDesktop()

  if (!isDesktop && !showOnMobile) {
    return null
  }

  if (!isDesktop && MobileIcon) {
    return (
      <IconButton
        variant="clear"
        as="a"
        href={href}
        aria-label={label}
        icon={<MobileIcon fontSize="1.25rem" color="primary.500" />}
      />
    )
  }

  return (
    <RouterLink to={href}>
      <Button w="fit-content" variant="link" color="primary.500">
        {label}
      </Button>
    </RouterLink>
  )
}

export type PublicHeaderProps = FlexProps

export const PublicHeader = (props: FlexProps): JSX.Element => {
  const logo = useClientBreakpointValue({
    base: CalSgStandaloneSvg,
    md: CalSgSvg,
  })

  return (
    <Flex
      bgColor="standard.white"
      justify="space-between"
      align="center"
      px={{ base: '1.5rem', md: '4.5rem', lg: '8rem' }}
      py={{ base: '0.625rem', md: '4.5rem' }}
      bg="primary.100"
      {...props}
    >
      <RouterLink to={'/'}>
        <Image src={logo} />
      </RouterLink>
      <HStack
        textStyle="subhead-1"
        spacing={{ base: '1rem', md: '2rem', xl: '2.5rem' }}
      >
        {PUBLIC_HEADER_LINKS.map((link, index) => (
          <PublicHeaderLink key={index} {...link} />
        ))}
        <RouterLink to="/admin/login">
          <Button>Log in</Button>
        </RouterLink>
      </HStack>
    </Flex>
  )
}
