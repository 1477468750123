import { useRef, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Box, Collapse } from '@chakra-ui/react'
import { useSize } from '@chakra-ui/react-use-size'
import { Button } from '@opengovsg/design-system-react'

export interface HideableProps {
  maxHeightPx: number
  isExpandedByDefault?: boolean
  children: React.ReactNode
}

/**
 * Hides child behind a "Hide/See More" button
 * if it exceeds a given height.
 */
export const Hideable = ({
  maxHeightPx,
  isExpandedByDefault = false,
  children,
}: HideableProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const dimensions = useSize(containerRef)
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault)
  return (
    <>
      <Collapse in={isExpanded} startingHeight={`${maxHeightPx}px`}>
        <Box ref={containerRef}>{children}</Box>
      </Collapse>
      {(dimensions?.height ?? 0) > maxHeightPx && (
        <Box alignItems="start">
          <Button
            px={0}
            minW={0}
            variant="link"
            textDecor="underline"
            size="xs"
            textStyle="body-2"
            rightIcon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Hide' : 'See More'}
          </Button>
        </Box>
      )}
    </>
  )
}
