import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GetNextAvailabilityRes } from '~shared/dto'

import { api } from '~lib/api'
import { publicQueryKeys } from '~constants/queryKeys'

/**
 * Fetches the event from the server using its eventId.
 *
 * @returns the Event and associated FormFields.
 */
export const getNextAvailability = async (
  scheduleId: string,
): Promise<GetNextAvailabilityRes> => {
  return api
    .url(`/schedules/${scheduleId}/next-available-slot`)
    .get()
    .json<GetNextAvailabilityRes>()
}

type UseGetNextAvailability = {
  scheduleId: string
} & UseQueryOptions<
  GetNextAvailabilityRes,
  unknown,
  GetNextAvailabilityRes,
  ReturnType<typeof publicQueryKeys.availability>
>

export const useGetNextAvailability = ({
  scheduleId,
  ...useQueryOptions
}: UseGetNextAvailability) => {
  return useQuery(
    publicQueryKeys.availability({ scheduleId }),
    () => getNextAvailability(scheduleId),
    { ...useQueryOptions },
  )
}
