import { GetPublicEventRes } from '~shared/dto'

import { FormFields } from '~features/form/components/FormFields'
import { FormPageWrapper } from '~features/form/components/FormPageWrapper'

export interface FormFieldsPageProps {
  event: GetPublicEventRes
  emailFieldId: string
}

export const FormFieldsPage = ({
  event,
  emailFieldId,
}: FormFieldsPageProps): JSX.Element => {
  return (
    <FormPageWrapper>
      <FormFields event={event} emailFieldId={emailFieldId} />
    </FormPageWrapper>
  )
}
