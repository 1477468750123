import { FormProvider, useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'
import { Center, VStack } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { useToast } from '@opengovsg/design-system-react'

import { GetEventRes } from '~shared/dto'

import { SaveChangesButton } from '~components/SaveChangesButton'

import { useUpdateEvent } from '~features/events/hooks/useAdminEvents'
import { AdminEventOutletContext } from '~features/events/types'

import { SyncToDirtyFieldStore } from '../SyncToDirtyFieldStore'

import { DuplicateNricConstraintInput } from './components/DuplicateNricConstraintInput'
import { RequireSgidInput } from './components/RequireSgidInput'
import {
  getSgidSettingsFields,
  sgidSettingsFieldsToReqBody,
} from './utils/form-utils'
import { SgidSettingsFormFields } from './types'

export const SgidSettings = () => {
  const { event } = useOutletContext<AdminEventOutletContext>()
  const toast = useToast()
  const formMethods = useForm<SgidSettingsFormFields>({
    mode: 'onChange',
    defaultValues: getSgidSettingsFields(event),
  })

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods
  const { mutateAsync, isLoading } = useUpdateEvent(event.id)

  const onSubmit = handleSubmit((data: SgidSettingsFormFields) =>
    mutateAsync(sgidSettingsFieldsToReqBody(data), {
      onSuccess: (res: GetEventRes) => {
        toast({
          description: 'Settings updated successfully.',
          status: 'success',
        })
        reset(getSgidSettingsFields(res))
      },
    }),
  )

  return (
    <VStack spacing={6} alignItems="stretch" w="full">
      <Text textStyle="h4">Singpass authentication</Text>
      <FormProvider {...formMethods}>
        <SyncToDirtyFieldStore />
        <VStack spacing={6} alignItems="stretch">
          <RequireSgidInput />
          <DuplicateNricConstraintInput />
        </VStack>
        <Center mt={6}>
          <SaveChangesButton
            isDirty={isDirty}
            isLoading={isLoading}
            onClick={onSubmit}
            w="full"
          />
        </Center>
      </FormProvider>
    </VStack>
  )
}
