import { useCallback, useEffect, useState } from 'react'
import {
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  useToast,
} from '@opengovsg/design-system-react'

import { AdminDashboardEventDto, GetEventListRes } from '~shared/dto'

import { useUpdateEventList } from '~/features/event-lists/hooks/useAdminEventLists'

import { isNestError } from '~lib/api'
import { ButtonStack } from '~components/ButtonStack'

import { ReorderEventListForm } from './ReorderEventListForm'

export interface ReorderEventListModalProps {
  isOpen: boolean
  onClose: () => void
  eventList: GetEventListRes
}

export const ReorderEventListModal = ({
  isOpen,
  onClose,
  eventList,
}: ReorderEventListModalProps) => {
  const { mutateAsync, isLoading } = useUpdateEventList(eventList.id)
  const toast = useToast()
  const [currentEventOrder, setCurrentEventOrder] = useState<
    AdminDashboardEventDto[]
  >(eventList.events)

  const handleSave = useCallback(async () => {
    try {
      await mutateAsync({
        eventOrder: currentEventOrder.map((event) => event.id),
      })
      toast({
        description: 'Event order saved successfully.',
      })
      onClose()
    } catch (err) {
      toast({
        description: isNestError(err)
          ? err.json.message
          : 'Something went wrong! Please try again later.',
        status: 'error',
      })
    }
  }, [currentEventOrder, mutateAsync, onClose, toast])

  // Propagate updates of the eventList's events to the reorder state
  useEffect(() => {
    setCurrentEventOrder(eventList.events)
  }, [eventList.events])

  const isDirty =
    eventList.events.length !== currentEventOrder.length ||
    eventList.events.some((x, idx) => {
      return currentEventOrder[idx].id !== x.id
    })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent my={{ base: 0, md: 8 }}>
        <ModalHeader>{`Reorder events in ${eventList.title}`}</ModalHeader>
        <ModalBody>
          <VStack spacing={6} alignItems="stretch" my={4}>
            <ReorderEventListForm
              events={currentEventOrder}
              setEventOrder={setCurrentEventOrder}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ButtonStack isReverseOrderOnMobile>
            <Button variant="clear" colorScheme="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={handleSave}
              isDisabled={isLoading || !isDirty}
              isLoading={isLoading}
            >
              Save
            </Button>
          </ButtonStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
