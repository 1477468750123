import { UseBreakpointOptions, useBreakpointValue } from '@chakra-ui/react'

/**
 * This is a helper hook to force client-side rendering on the useBreakpointValue hook.
 * Forcing client-side rendering prevents ui flashes due to an incorrect initial
 * size value.
 */
export const useClientBreakpointValue = <T>(
  values: T[] | Partial<Record<string, T>>,
  options?: UseBreakpointOptions,
) => useBreakpointValue(values, { ssr: false, ...options })
