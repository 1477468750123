import { IconType } from 'react-icons'
import { BiCalendarAlt, BiCalendarX } from 'react-icons/bi'

type AddOverrideOption = {
  label: string
  value: OverrideType
  icon: IconType
}

export enum OverrideType {
  AllDay = 'AllDay',
  DateAndTimes = 'DateAndTimes',
}

export type AllDayOverride = {
  type: OverrideType.AllDay
  // Epoch of start of the day
  date: number
}

export type DateAndTimeOverride = {
  type: OverrideType.DateAndTimes
  dateStart: number
  times: {
    // offset from start of day in ms
    start: number
    // offset from end of day in ms
    end: number
  }[]
}

export type OverridesFormValue = AllDayOverride | DateAndTimeOverride

export const OVERRIDES_FORM_VALUES_KEY = 'overrides' as const
export const DATE_AND_TIME_OVERRIDE_TIMES_KEY = 'times' as const
export const DATE_AND_TIME_OVERRIDE_DATE_KEY = 'dateStart' as const

export const ADD_OVERRIDE_OPTIONS: AddOverrideOption[] = [
  {
    label: 'Full day',
    value: OverrideType.AllDay,
    icon: BiCalendarAlt,
  },
  {
    label: 'Date and time',
    value: OverrideType.DateAndTimes,
    icon: BiCalendarX,
  },
]
