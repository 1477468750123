import { Text, TextProps } from '@chakra-ui/react'

export const SectionTitleText = (props: TextProps) => {
  return (
    <Text
      textStyle={{
        base: 'responsive-heading.heavy',
        md: 'responsive-heading.heavy-480',
        lg: 'responsive-heading.heavy-1280',
      }}
      {...props}
    />
  )
}
