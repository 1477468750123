import { BiMap, BiSolidHourglass } from 'react-icons/bi'
import { HStack, Icon, Text, VStack } from '@chakra-ui/react'

import { GetPublicBookingRes, GetPublicEventRes } from '~shared/dto'
import { minsToHumanReadable } from '~shared/utils'

import { formatDateDisplay } from '~utils/date'
import { Linkify } from '~components/Linkify'

export interface EventSummaryProps {
  event: GetPublicEventRes
  booking?: GetPublicBookingRes | null
  strikethrough?: boolean
}

export const EventSummary = ({
  event,
  booking = null,
  strikethrough,
}: EventSummaryProps): JSX.Element => {
  const slotDurationMins = event.schedules[0].slotDurationMins
  const { location } = event

  return (
    <VStack w="full" alignItems="stretch">
      {booking && (
        <Text
          textStyle={{ base: 'subhead-1', md: 'h6' }}
          textDecor={strikethrough ? 'line-through' : undefined}
        >
          {formatDateDisplay({
            date: booking.startAt,
            withTime: true,
          })}
        </Text>
      )}
      <HStack alignItems="center">
        <Icon as={BiSolidHourglass} fontSize="1.25rem" />
        <Text
          textStyle={'body-2'}
          textDecor={strikethrough ? 'line-through' : undefined}
        >
          {minsToHumanReadable({ mins: slotDurationMins })}
        </Text>
      </HStack>
      {location && (
        <HStack alignItems="center">
          <Icon as={BiMap} fontSize="1.25rem" />
          <Text
            textStyle={'body-2'}
            overflowWrap="anywhere"
            textDecor={strikethrough ? 'line-through' : undefined}
          >
            <Linkify>{location}</Linkify>
          </Text>
        </HStack>
      )}
    </VStack>
  )
}
