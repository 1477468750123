import { useMemo, useState } from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { Box, Center, FormControl, Spacer, Textarea } from '@chakra-ui/react'
import { Button, FormLabel, useToast } from '@opengovsg/design-system-react'

import { GetPublicEventRes } from '~shared/dto'
import { EventAuthType } from '~shared/types'

import { useCancelPublicBooking } from '~features/form/hooks/useCancelPublicBooking'
import {
  identitySelector,
  previousBookingSelector,
  setFormStateSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { FormState } from '~features/form/types/formState'

import { SgidAuthButtonGroup } from '../SgidButtons/SgidAuthButtonGroup'

export interface FormFieldsProps {
  event: GetPublicEventRes
}

export const CancellationFields = ({ event }: FormFieldsProps): JSX.Element => {
  const scheduleId = useMemo(() => event.schedules[0].id, [event])
  const setFormState = useFormStore(setFormStateSelector)
  const previousBooking = useFormStore(previousBookingSelector)
  const { mutate, isLoading } = useCancelPublicBooking()
  const [reasonForCancellation, setReasonForCancellation] = useState<string>('')
  const identity = useFormStore(identitySelector)
  const toast = useToast()

  const onSubmit = () => {
    if (!previousBooking) {
      toast({
        status: 'error',
        description:
          'Your booking ID could not be found. Please contact the event organiser for assistance.',
      })
      return
    }
    mutate(
      {
        eventId: event.id,
        scheduleId,
        bookingId: previousBooking.id,
        reasonForCancellation:
          reasonForCancellation.length === 0
            ? undefined
            : reasonForCancellation,
      },
      {
        onSuccess: () => {
          setFormState(FormState.CancellationSubmitted)
        },
      },
    )
  }
  return (
    <>
      {(event.auth.type !== EventAuthType.SgidWithNric || identity) && (
        <>
          <FormControl>
            <Box mb="0.75rem">
              <FormLabel mb={0}>
                {"Share why you're cancelling your booking"}
              </FormLabel>
            </Box>
            <Textarea
              w="100%"
              errorBorderColor="red.300"
              minH="9rem"
              resize="none"
              placeholder="Tell the host why you want to cancel"
              value={reasonForCancellation}
              onChange={(e) => setReasonForCancellation(e.target.value)}
            ></Textarea>
          </FormControl>
          <Spacer />
        </>
      )}
      <Center>
        <SgidAuthButtonGroup
          event={event}
          action="cancel"
          loggedOutButtonText="Cancel with Singpass"
          loggedOutDescriptionText="You need to verify your identity with Singpass before cancelling this booking"
          logoutRedirectToState={FormState.Cancelling}
        >
          <Button
            rightIcon={<BiRightArrowAlt fontSize="1.5rem" />}
            w="full"
            h={{ base: '56px', md: 'auto' }}
            onClick={onSubmit}
            isLoading={isLoading}
            colorScheme="critical"
          >
            Cancel booking
          </Button>
        </SgidAuthButtonGroup>
      </Center>
    </>
  )
}
