import { useId, useMemo } from 'react'
import { BiSortAlt2 } from 'react-icons/bi'
import { Navigate } from 'react-router-dom'
import {
  Divider,
  HStack,
  Spacer,
  Stack,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { GetEventListRes } from '~shared/dto'

import { isNestError } from '~lib/api'
import { ButtonStack } from '~components/ButtonStack'
import { LoadingState } from '~components/LoadingState'

import { CreateEventButton } from '~features/events/CreateEventButton'

import { DashboardBreadcrumb } from '../dashboard/components/DashboardBreadcrumb'
import { DashboardHeader } from '../dashboard/components/DashboardHeader'
import {
  DashboardList,
  DashboardListItem,
} from '../dashboard/components/DashboardList'
import { EmptyEventList } from '../dashboard/components/EmptyEventList'
import { DashboardModalsProvider } from '../dashboard/DashboardModalsProvider'

import { EditEventListButton } from './components/EditEventListButton'
import { ReorderEventListModal } from './components/ReorderEventListModal'
import { useGetEventList } from './hooks/useAdminEventLists'
import { useEventListIdParam } from './hooks/useEventListIdParam'

export const EventListPage = (): JSX.Element => {
  const { eventListId } = useEventListIdParam()
  const {
    data: eventList,
    isLoading,
    isError,
    error,
  } = useGetEventList({
    eventListId,
    retry: (failureCount, error) => {
      // Prevent retries if failureCount >= 3 OR status code is known to be 404
      return failureCount < 3 && (!isNestError(error) || error.status !== 404)
    },
  })

  // If it is a 404 error, we navigate the user back to the dashboard.
  if (isError && isNestError(error) && error.status === 404) {
    return <Navigate to="/admin/dashboard" />
  }

  if (isLoading || !eventList) return <EventListPageLoadingState />

  return <EventListPageContent eventList={eventList} />
}

const EventListPageLoadingState = () => {
  return (
    <VStack
      alignItems="center"
      spacing={0}
      divider={<Divider />}
      px="admin-app-px"
      minH="admin-content-min-height"
    >
      <DashboardHeader title={''}></DashboardHeader>
      <LoadingState flex={1} minH="admin-content-min-height" />
    </VStack>
  )
}

const EventListPageContent = ({
  eventList,
}: {
  eventList: GetEventListRes
}) => {
  const {
    isOpen: isReorderModalOpen,
    onClose: closeReorderModal,
    onOpen: openReorderModal,
  } = useDisclosure()
  const title = `${eventList.title} (${eventList.events.length})`

  const dashboardListItems: DashboardListItem[] = useMemo(() => {
    // For event lists, the result is already sorted in event order
    // We shouldn't change it, just need to map over it
    return eventList.events.map((event) => ({
      ...event,
      itemType: 'event' as const,
    }))
  }, [eventList])

  const rootBreadcrumbKey = useId()
  const listBreadcrumbKey = useId()

  const isEventListEmpty = eventList.events.length === 0

  return (
    <DashboardModalsProvider eventListId={eventList.id}>
      <VStack
        alignItems="center"
        spacing={0}
        divider={<Divider />}
        px="admin-app-px"
        minH="admin-content-min-height"
      >
        <DashboardHeader
          title={title}
          titleChildren={
            <VStack pl={2}>
              <EditEventListButton eventList={eventList} />
            </VStack>
          }
        >
          <ButtonStack py={{ base: 4, md: 0 }}>
            <CreateEventButton eventListId={eventList.id} />
          </ButtonStack>
        </DashboardHeader>
        <VStack w="full" maxW="51rem" alignItems="stretch">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            pr={3}
            pl={{ base: 3, md: 0 }}
            alignItems={{ base: 'stretch', md: 'center' }}
          >
            <DashboardBreadcrumb
              items={[
                {
                  key: `dashboard-breadcrumb-${rootBreadcrumbKey}`,
                  href: '/admin/dashboard',
                  label: 'All items',
                },
                {
                  key: `dashboard-breadcrumb-${listBreadcrumbKey}`,
                  label: eventList.title,
                },
              ]}
              currentItemIndex={1}
            />
            <Spacer />
            {!isEventListEmpty && (
              <Button
                size="sm"
                variant="outline"
                leftIcon={<BiSortAlt2 />}
                onClick={openReorderModal}
              >
                Reorder
              </Button>
            )}
          </Stack>
          <Divider />
          {isEventListEmpty ? (
            <EmptyEventList />
          ) : (
            <DashboardList items={dashboardListItems} />
          )}
        </VStack>
      </VStack>
      <ReorderEventListModal
        eventList={eventList}
        isOpen={isReorderModalOpen}
        onClose={closeReorderModal}
      />
    </DashboardModalsProvider>
  )
}
