import { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Button, ModalCloseButton } from '@opengovsg/design-system-react'

import { CreateFieldReq } from '~shared/dto'
import { FormFieldType } from '~shared/types'

import { ButtonStack } from '~components/ButtonStack'

import { EditFieldModalContent } from './EditFieldModalContent'
import { EditFieldFormReturn } from './types'

export interface EditFieldModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (field: CreateFieldReq) => void
  initialField: CreateFieldReq
}

export const EditFieldModal = ({
  isOpen,
  onClose,
  onSubmit: onSubmitParent,
  initialField,
}: EditFieldModalProps): JSX.Element => {
  const defaultValues = useMemo<EditFieldFormReturn>(() => {
    if (
      initialField.fieldType === FormFieldType.Radio ||
      initialField.fieldType === FormFieldType.Dropdown
    ) {
      return {
        ...initialField,
        fieldOptions: initialField.fieldOptions.join('\n'),
      }
    }
    return initialField
  }, [initialField])
  const formMethods = useForm<EditFieldFormReturn>({
    defaultValues,
    mode: 'onBlur',
  })

  const handleSubmit = useCallback(
    (inputs: EditFieldFormReturn) => {
      const hasFieldOptions =
        inputs.fieldType === FormFieldType.Radio ||
        inputs.fieldType === FormFieldType.Dropdown
      const outputs: CreateFieldReq = hasFieldOptions
        ? {
            ...inputs,
            fieldOptions: inputs.fieldOptions
              .trim()
              .split('\n')
              .map((option) => option.trim()),
          }
        : inputs
      onSubmitParent(outputs)
      onClose()
    },
    [onClose, onSubmitParent],
  )
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <ModalHeader>Edit question</ModalHeader>
            <ModalBody whiteSpace="pre-wrap">
              <EditFieldModalContent field={initialField} />
            </ModalBody>
            <ModalFooter>
              <ButtonStack isReverseOrderOnMobile>
                <Button
                  variant="clear"
                  colorScheme="secondary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </ButtonStack>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  )
}
