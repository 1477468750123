export enum FormState {
  ViewSummary,
  PickDate,
  PickTime,
  FormFields,
  Cancelling,
  Submitted,
  CancellationSubmitted,
  RedirectFromSgidCallback,
}
