import { BiRightArrowAlt } from 'react-icons/bi'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Center, HStack, Text } from '@chakra-ui/react'

export type EventListCardProps = {
  title: string
  eventId: string
}

export const EventListCard = ({ title, eventId }: EventListCardProps) => {
  return (
    <HStack
      borderRadius={4}
      p={4}
      spacing={6}
      borderWidth="1px"
      as={ReactRouterLink}
      to={`/${eventId}`}
      aria-label={'Go to event'}
      transition="background-color 0.2s ease"
      _hover={{
        backgroundColor: 'brand.primary.100',
      }}
    >
      <Center height={'2.5rem'} justifyContent="center" flex={1}>
        <Text
          noOfLines={2}
          textOverflow="ellipsis"
          textStyle="subhead-2"
          w="full"
        >
          {title}
        </Text>
      </Center>
      <BiRightArrowAlt fontSize="1.25rem" />
    </HStack>
  )
}
