import { useMemo } from 'react'

import { ScheduleTimeRangeListProvider } from '~features/schedules/components/ManageScheduleForm/ScheduleTimeRangeField/ScheduleTimeRangeListContext'

import {
  DATE_AND_TIME_OVERRIDE_DATE_KEY,
  DATE_AND_TIME_OVERRIDE_TIMES_KEY,
  OVERRIDES_FORM_VALUES_KEY,
} from '../../common/constants'

import { TimeRangeRows } from './TimeRangeRows'

export interface UnavailableDateAndTimeRowProps {
  onDelete: () => void
  index: number
}

export const UnavailableDateAndTimeRow = ({
  onDelete: onDeleteWholeRow,
  index: rowIndex,
}: UnavailableDateAndTimeRowProps) => {
  const dateFieldName = useMemo(
    () =>
      `${OVERRIDES_FORM_VALUES_KEY}.${rowIndex}.${DATE_AND_TIME_OVERRIDE_DATE_KEY}` as const,
    [rowIndex],
  )
  const timeFieldsName = useMemo(
    () =>
      `${OVERRIDES_FORM_VALUES_KEY}.${rowIndex}.${DATE_AND_TIME_OVERRIDE_TIMES_KEY}` as const,
    [rowIndex],
  )

  return (
    <ScheduleTimeRangeListProvider
      fieldName={timeFieldsName}
      onEmptyTimeRangeList={onDeleteWholeRow}
      slotDurationMins={30}
    >
      <TimeRangeRows dateFieldName={dateFieldName} />
    </ScheduleTimeRangeListProvider>
  )
}
