import { useFormState } from 'react-hook-form'

import { useSyncToDirtyFieldStore } from '../hooks/useDirtyFieldStore'

/**
 * Component which just serves to sync isDirty state to a custom
 * store, for functionality like showing a warning modal when
 * navigating to a new route. This is really a custom
 * hook in disguise, but we choose to make it a component
 * so it can be nested within a FormProvider and not
 * force a rerender of an entire form when the isDirty
 * state changes.
 *
 * Must be used within a FormProvider.
 */
export const SyncToDirtyFieldStore = () => {
  const { isDirty } = useFormState()
  useSyncToDirtyFieldStore(isDirty)
  return null
}
