import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GetPublicEventListRes } from '~shared/dto'

import { api } from '~lib/api'
import { publicQueryKeys } from '~constants/queryKeys'

/**
 * Fetches the event from the server using its eventId.
 *
 * @returns the Event and associated FormFields.
 */
export const getPublicEventList = async (
  eventListId: string,
): Promise<GetPublicEventListRes> => {
  return api
    .url(`/event-lists/${eventListId}`)
    .get()
    .json<GetPublicEventListRes>()
}

type UseGetPublicEventList = {
  eventListId: string
} & UseQueryOptions<
  GetPublicEventListRes,
  unknown,
  GetPublicEventListRes,
  ReturnType<typeof publicQueryKeys.eventList>
>

export const useGetPublicEventList = ({
  eventListId,
  ...useQueryOptions
}: UseGetPublicEventList) => {
  return useQuery(
    publicQueryKeys.eventList({ eventListId }),
    () => getPublicEventList(eventListId),
    useQueryOptions,
  )
}
