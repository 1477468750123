import { useFormContext } from 'react-hook-form'
import { FormControl, VStack } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  Textarea,
} from '@opengovsg/design-system-react'

import { FormFieldType } from '~shared/types'

import { FORM_FIELD_DISPLAY_DATA } from '../fieldDisplayData'

import { EditFieldTitleAndDescription } from './EditFieldTitleAndDescription'
import { FieldTypeBadge } from './FieldTypeBadge'
import { EditFieldWithOptionsReturn } from './types'

export interface EditFieldWithOptionsProps {
  fieldType: FormFieldType
}

export const EditFieldWithOptions = ({
  fieldType,
}: EditFieldWithOptionsProps): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<EditFieldWithOptionsReturn>()
  const fieldDisplayData = FORM_FIELD_DISPLAY_DATA[fieldType]
  return (
    <VStack spacing="1.5rem" alignItems={'start'}>
      <FieldTypeBadge {...fieldDisplayData} />
      <EditFieldTitleAndDescription />
      <FormControl isRequired isInvalid={!!errors.fieldOptions?.message}>
        <FormLabel>Options</FormLabel>
        <Textarea
          {...register('fieldOptions', {
            required: 'At least 1 option is required',
            validate: {
              checkNoDuplicatedOptions: (value) => {
                const options = value.split('\n').map((option) => option.trim())
                const hasDuplicates =
                  new Set<string>(options).size !== options.length
                return !hasDuplicates || 'Please remove duplicate options'
              },
              checkNoEmptyOptions: (value) => {
                const options = value.split('\n').map((option) => option.trim())
                const hasEmpty = options.some((option) => option.length === 0)

                return !hasEmpty || 'Please remove empty options'
              },
            },
          })}
        />
        <FormErrorMessage>{errors.fieldOptions?.message}</FormErrorMessage>
      </FormControl>
    </VStack>
  )
}
