import { useMutation, useQueryClient } from '@tanstack/react-query'

import { GetFormFieldRes, UpdateFormFieldsReq } from '~shared/dto'

import { ADMIN_URL_PREFIX } from '~/services/constants'

import { api } from '~lib/api'
import { adminQueryKeys } from '~constants/queryKeys'

export const updateFormFields = (
  eventId: string,
  formFields: UpdateFormFieldsReq['formFields'],
): Promise<GetFormFieldRes[]> => {
  return api
    .url(`${ADMIN_URL_PREFIX}/events/${eventId}/form-fields`)
    .put({ formFields })
    .json()
}

export const useUpdateFormFields = (eventId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (formFields: UpdateFormFieldsReq['formFields']) =>
      updateFormFields(eventId, formFields),
    onSuccess: async () => {
      await queryClient.invalidateQueries(adminQueryKeys.events({ eventId }))
    },
  })
}
