import { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BiTrash } from 'react-icons/bi'
import { Box, Icon, VStack } from '@chakra-ui/react'
import { DatePicker, IconButton } from '@opengovsg/design-system-react'

import { useClientBreakpointValue } from '~hooks/useClientBreakpointValue'

import {
  AVAILABILITY_COL_1_GRID_TEMPLATE_AREA,
  AVAILABILITY_COL_2_GRID_TEMPLATE_AREA,
  AVAILABILITY_COL_3_GRID_TEMPLATE_AREA,
  AVAILABILITY_COL_4_GRID_TEMPLATE_AREA,
  AvailabilityRowWrapper,
} from '~features/schedules/components/AvailabilityRowWrapper'
import { ManageScheduleFormState } from '~features/schedules/components/ManageScheduleForm/recurring-schedule-form-utils'
import { AddTimeRangeButton } from '~features/schedules/components/ManageScheduleForm/ScheduleTimeRangeField/AddTimeRangeButton'
import { useScheduleTimeRangeList } from '~features/schedules/components/ManageScheduleForm/ScheduleTimeRangeField/ScheduleTimeRangeListContext'
import { ScheduleTimeRangeRow } from '~features/schedules/components/ManageScheduleForm/ScheduleTimeRangeField/ScheduleTimeRangeRow'

import { OVERRIDES_FORM_VALUES_KEY } from '../../common/constants'

export type TimeRangeRowsProps = {
  dateFieldName: string
}

export const TimeRangeRows = ({ dateFieldName }: TimeRangeRowsProps) => {
  const {
    onDelete,
    isDisabled,
    timeRangeFields,
    fieldName: timeFieldName,
    timeRanges,
  } = useScheduleTimeRangeList()
  const shouldRenderCompact = useClientBreakpointValue({
    base: true,
    lg: false,
  })
  const { trigger } = useFormContext<ManageScheduleFormState>()

  const checkDateConflict = useCallback(() => {
    void trigger(OVERRIDES_FORM_VALUES_KEY)
  }, [trigger])

  return (
    <VStack spacing={{ base: 0, lg: '8px' }}>
      {timeRangeFields.fields.map((field, index) => (
        <AvailabilityRowWrapper key={field.id}>
          {index === 0 ? (
            <Controller
              name={dateFieldName}
              render={({ field: { value, onChange } }) => (
                <Box mr={{ base: 0, lg: '8px' }}>
                  <DatePicker
                    gridArea={AVAILABILITY_COL_1_GRID_TEMPLATE_AREA}
                    size="sm"
                    allowManualInput={false}
                    value={new Date(value as number)}
                    onChange={(newDate) => {
                      onChange(newDate?.getTime())
                      checkDateConflict()
                    }}
                  />
                </Box>
              )}
            />
          ) : (
            <Box display="none"></Box>
          )}
          <ScheduleTimeRangeRow
            gridArea={AVAILABILITY_COL_2_GRID_TEMPLATE_AREA}
            parentName={timeFieldName}
            name={`${timeFieldName}.${index}`}
            neighbourRanges={timeRanges ? timeRanges.slice(0, index) : []}
            isDisabled={isDisabled}
          />
          <Box
            gridArea={AVAILABILITY_COL_3_GRID_TEMPLATE_AREA}
            alignSelf="start"
            justifySelf={'end'}
          >
            <IconButton
              variant="clear"
              icon={<Icon as={BiTrash} height="1.25rem" width="1.25rem" />}
              aria-label="delete timeslot"
              colorScheme="red"
              onClick={() => onDelete(index)}
              isDisabled={isDisabled}
              size={{ base: 'xs', lg: 'sm' }}
            />
          </Box>
          {/* If compact view, render add button with index 0. If not compact view, render add button with last index. */}
          {((index === timeRangeFields.fields.length - 1 &&
            !shouldRenderCompact) ||
            (index === 0 && shouldRenderCompact)) && (
            <Box
              alignSelf={'start'}
              justifySelf={'end'}
              gridArea={AVAILABILITY_COL_4_GRID_TEMPLATE_AREA}
            >
              <AddTimeRangeButton />
            </Box>
          )}
        </AvailabilityRowWrapper>
      ))}
    </VStack>
  )
}
