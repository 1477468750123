import { BiCalendar, BiCalendarWeek } from 'react-icons/bi'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Box,
  Circle,
  Flex,
  HStack,
  Stack,
  StackProps,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react'

import { AdminDashboardRes } from '~shared/dto'
import { EventStatus } from '~shared/types'

import { useIsClientMobile } from '~hooks/useIsClientMobile'

import { EventActionsButtonGroup } from '~features/events'

type DashboardEventRowProps = {
  event: AdminDashboardRes['events'][number]
} & StackProps

export const DashboardEventRow = ({
  event,
  ...rest
}: DashboardEventRowProps): JSX.Element => {
  const isMobile = useIsClientMobile()
  return (
    <HStack alignItems="stretch" spacing={0} pr={4} {...rest}>
      <HStack
        spacing={4}
        borderStartRadius={4}
        flex={1}
        alignItems="center"
        p={6}
        transition="background-color 0.2s ease"
        _hover={{
          backgroundColor: 'brand.primary.100',
        }}
        as={ReactRouterLink}
        to={`/admin/event/${event.id}`}
      >
        <Box>
          {event.type === 'oneOffEvent' ? (
            <BiCalendarWeek fontSize="1.25rem" />
          ) : (
            <BiCalendar fontSize="1.25rem" />
          )}
        </Box>
        <Stack
          flex={1}
          spacing={{ base: '0.25rem', md: '3rem' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Wrap
            shouldWrapChildren
            flex={1}
            alignItems={{ base: 'center', md: 'flex-start' }}
            direction={{ base: 'row', md: 'column' }}
          >
            <Text
              textStyle="subhead-1"
              color="secondary.700"
              wordBreak="break-word"
            >
              {event.title}
            </Text>
          </Wrap>
        </Stack>
        {!isMobile && (
          <Flex alignItems="center" minW="100px">
            <Circle
              size="0.5rem"
              mr="0.5rem"
              bg={
                event.eventStatus === EventStatus.Open
                  ? 'utility.feedback.success'
                  : 'interaction.support.disabled-content'
              }
            />
            <Text textStyle="body-2">
              {event.eventStatus === EventStatus.Open ? 'Open' : 'Closed'}
            </Text>
          </Flex>
        )}
      </HStack>

      <VStack alignItems="center">
        <EventActionsButtonGroup
          event={event}
          isEventOwner={event.accessLevel === 'owner'}
        />
      </VStack>
    </HStack>
  )
}
