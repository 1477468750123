export const navLink = {
  container: {
    parent: {
      px: 'admin-app-px',
      h: 'admin-header-height',
      justify: 'space-between',
      align: 'center',
      boxShadow: 'inset 0px -1px 0px #EDEDED',
    },
    mobile: {
      px: 'admin-app-px',
      boxShadow: 'inset 0px -1px 0px #EDEDED',
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      h: 'admin-mobile-nav-header-height',
      maxH: 'admin-mobile-nav-header-height',
    },
  },
  item: {
    textStyle: 'subhead-3',
    textTransform: 'uppercase',
    fontWeight: 600,
    letterSpacing: '0.08em',
    lineHeight: '1.5rem',
    p: 0,
    m: 0,
    minHeight: '28px',
    maxHeight: '28px',
    borderWidth: 0,
    borderBottomWidth: '2px',
  },
}
