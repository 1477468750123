import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'
import { Divider, Text, VStack } from '@chakra-ui/react'
import { useToast } from '@opengovsg/design-system-react'

import { SaveChangesButton } from '~components/SaveChangesButton'

import { AdminEventOutletContext } from '~features/events/types'
import { useUpdateSchedule } from '~features/schedules/hooks/useAdminSchedules'

import { SyncToDirtyFieldStore } from '../SyncToDirtyFieldStore'

import { BookingLimits } from './components/BookingLimits'
import { MaxLeadTimeCheckbox } from './components/MaxLeadTimeCheckbox'
import { MinLeadTimeCheckbox } from './components/MinLeadTimeCheckbox'
import { SlotCapacityInput } from './components/SlotCapacityInput'
import { BookingRulesFields } from './types/field-info'
import {
  bookingRulesFieldsToReqBody,
  getBookingRulesFields,
} from './utils/form-utils'

export const BookingRules = () => {
  const { event } = useOutletContext<AdminEventOutletContext>()
  const isOneOff = useMemo(() => !event.schedules[0].segments, [event])
  const toast = useToast()
  const schedule = useMemo(() => event.schedules[0], [event.schedules])
  const formMethods = useForm<BookingRulesFields>({
    mode: 'onChange',
    defaultValues: getBookingRulesFields(schedule),
  })

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods

  const { mutateAsync: updateSchedule, isLoading: isUpdateScheduleLoading } =
    useUpdateSchedule({ eventId: event.id, scheduleId: event.schedules[0].id })

  const onSubmit = handleSubmit((formOutput: BookingRulesFields) =>
    updateSchedule(bookingRulesFieldsToReqBody(formOutput), {
      onSuccess: () => {
        toast({
          description: 'Availability successfully updated.',
          status: 'success',
        })
        reset(formOutput)
      },
    }),
  )

  return (
    <VStack spacing={10} alignItems="stretch" w="full">
      <VStack w="full" alignItems="start">
        <Text textStyle="h4">Booking rules</Text>
        <Text textStyle="body-2">Set limits on when bookings can be made</Text>
      </VStack>
      <FormProvider {...formMethods}>
        <SyncToDirtyFieldStore />
        <VStack spacing={8} alignItems="stretch">
          <SlotCapacityInput />
          <MinLeadTimeCheckbox />
          <MaxLeadTimeCheckbox />
          {!isOneOff && (
            <>
              <Divider />
              <BookingLimits />
            </>
          )}
          <SaveChangesButton
            isDirty={isDirty}
            isLoading={isUpdateScheduleLoading}
            onClick={onSubmit}
          />
        </VStack>
      </FormProvider>
    </VStack>
  )
}
