import { useCallback, useState } from 'react'
import { BiLink, BiUnlink } from 'react-icons/bi'
import {
  HStack,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import {
  Button,
  IconButton,
  Input,
  ModalCloseButton,
} from '@opengovsg/design-system-react'
import { Editor } from '@tiptap/react'

// TODO: Modal overlay not "behind" progress notes compose pop up
export const LinkButton = ({ editor }: { editor: Editor | null }) => {
  const [url, setUrl] = useState('')
  const { isOpen: isLinkModalOpen, onClose, onOpen } = useDisclosure()

  const onCloseModal = useCallback(() => {
    onClose()
    setUrl('')
  }, [onClose])

  const onSubmitLink = useCallback(() => {
    if (!editor) return false

    if (editor.isActive('link')) {
      editor.chain().focus().unsetLink().run()
    }

    // cancelled
    if (url === null) {
      false
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
    }

    // update link
    if (url.length > 0) {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: `https://${url}` })
        .run()
    }

    onCloseModal()
  }, [editor, onCloseModal, url])

  if (!editor) {
    return <></>
  }

  const isLinkModeActive = editor.isActive('link')

  return (
    <>
      <Modal
        isOpen={isLinkModalOpen}
        onClose={onCloseModal}
        returnFocusOnClose={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Link</ModalHeader>
          <ModalBody pb={6}>
            <HStack>
              <InputGroup>
                <InputLeftAddon>{'https://'}</InputLeftAddon>
                <Input
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      onSubmitLink()
                    }
                  }}
                />
              </InputGroup>
              <Button onClick={onSubmitLink}>OK</Button>
            </HStack>
          </ModalBody>
          <ModalCloseButton />
        </ModalContent>
      </Modal>

      <Tooltip label="Insert Link">
        <IconButton
          variant="clear"
          color="black"
          backgroundColor={isLinkModeActive ? 'primary.100' : undefined}
          aria-label={isLinkModeActive ? 'Set Link' : 'Unset Link'}
          onClick={
            isLinkModeActive
              ? () => editor.chain().focus().unsetLink().run()
              : onOpen
          }
          borderColor="transparent"
          icon={isLinkModeActive ? <BiUnlink /> : <BiLink />}
        />
      </Tooltip>
    </>
  )
}
