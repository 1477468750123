import { OverridesFormValue, OverrideType } from '../common/constants'

import { UnavailableAllDayRow } from './UnavailableAllDayRow'
import { UnavailableDateAndTimeRow } from './UnavailableDateAndTimeRow'

type OverrideRowProps = {
  overridesFormValue: OverridesFormValue
  onDelete: () => void
  index: number
}

export const OverrideRow = ({
  overridesFormValue,
  onDelete,
  index,
}: OverrideRowProps) => {
  switch (overridesFormValue.type) {
    case OverrideType.AllDay:
      return <UnavailableAllDayRow onDelete={onDelete} index={index} />
    default:
      return <UnavailableDateAndTimeRow onDelete={onDelete} index={index} />
  }
}
