import { FallbackProps } from 'react-error-boundary'
import { Image, Text, VStack } from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'

import SceneDisconnectedSvg from '~/assets/img/scene-disconnected.svg'

import { ERROR_REPORTING_FORMSG_URL } from '~constants/config'

type ErrorCardProps = Partial<FallbackProps> & {
  variant?: 'full' | 'inline'
}

export const ErrorCard = ({ variant = 'full' }: ErrorCardProps) => {
  const isFullVariant = variant === 'full'

  return (
    <VStack
      w={isFullVariant ? '100vw' : '100%'}
      h={isFullVariant ? '100vh' : '100%'}
      maxW={isFullVariant ? '45rem' : 'auto'}
      mx="auto"
      padding={isFullVariant ? 8 : 0}
      spacing={isFullVariant ? 8 : 0}
      justify="center"
      align={isFullVariant ? 'center' : 'flex-start'}
      alignItems="center"
    >
      <Image src={SceneDisconnectedSvg} alt="Error" />
      <Text textStyle="h5">Looks like something went wrong</Text>
      <Text textStyle="body-2">
        {`Try refreshing the page or clearing your browser's cache. If the problem persists, `}
        <Link target="_blank" href={ERROR_REPORTING_FORMSG_URL}>
          {`let us know.`}
        </Link>
      </Text>
    </VStack>
  )
}
