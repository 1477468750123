import { UseMediaQueryOptions } from '@chakra-ui/react'
import { useIsMobile } from '@opengovsg/design-system-react'

/**
 * This is a helper hook to force client-side rendering on the useIsMobile hook.
 * Forcing client-side rendering prevents ui flashes due to an incorrect initial
 * size value.
 */
export const useIsClientMobile = (options?: UseMediaQueryOptions) =>
  useIsMobile({ ssr: false, ...options })
