import { useCallback } from 'react'
import { useToast } from '@opengovsg/design-system-react'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { json2csv, Json2CsvOptions } from 'json-2-csv'

import { GetBookingRes, UpdateFieldReq } from '~shared/dto'

import { api, getNetworkErrorMessage } from '~lib/api'

import { useGetEvent } from './useAdminEvents'

const formatBookingsAsCsv = ({
  formFields,
  bookings,
}: {
  formFields: UpdateFieldReq[]
  bookings: GetBookingRes[]
}): Promise<string> => {
  // Will contain all the data to be written to CSV
  const csvData: Record<string, string>[] = []
  // Maps field ID -> field title. The field title may change over
  // time, and we want the latest field title.
  const fieldIdToTitle: Map<string, string> = new Map()
  let showNricFinCol = false

  // use .forEach instead of .map because there's a side effect of
  // updating fieldIdToTitle and showNricFinCol
  bookings.forEach((booking: GetBookingRes) => {
    const row: Record<string, string> = {
      bookingId: booking.id,
      date: dayjs(booking.startAt).format('DD/MM/YYYY'),
      startAt: dayjs(booking.startAt).format('HH:mm'),
      endAt: dayjs(booking.endAt).format('HH:mm'),
      email: booking.email,
      updatedAt: dayjs(booking.updatedAt).format('DD/MM/YYYY HH:mm'),
      nricFin: booking.nricFin ?? '',
    }
    booking.responses.forEach((response) => {
      row[response.id] = response.answer
      fieldIdToTitle.set(response.id, response.question)
    })
    csvData.push(row)
    // We show as long as there is one non-empty nric value
    showNricFinCol = !!booking.nricFin || showNricFinCol
  })

  // We only want to show the NRIC/FIN column if there is some row with NRIC/FIN
  const nricFinCol = showNricFinCol
    ? [{ field: 'nricFin', title: 'Singpass Verified NRIC/FIN' }]
    : []
  /**
   * Add existing form fields to fieldIdToTitle. This has 2 effects:
   * 1. For fields whose titles have been updated, the CSV will show
   * the most updated title, even if no respondent has seen this title before
   * 2. For new fields for which no respondent has submitted a response yet,
   * there will be a blank column in the CSV.
   */
  formFields.forEach((f) => fieldIdToTitle.set(f.id, f.title))
  const keys = [
    { field: 'bookingId', title: 'Booking ID' },
    { field: 'date', title: 'Booking date' },
    { field: 'startAt', title: 'Booking start time' },
    { field: 'endAt', title: 'Booking end time' },
    { field: 'email', title: 'Email' },
    ...nricFinCol,
    ...Array.from(fieldIdToTitle.entries()).map(([field, title]) => ({
      field,
      title,
    })),
    { field: 'updatedAt', title: 'Submitted at' },
  ]

  const options: Json2CsvOptions = {
    delimiter: {
      wrap: '"', // Double Quote (") character
      field: ',', // Comma field delimiter
      eol: '\n', // Newline delimiter
    },
    prependHeader: true,
    sortHeader: false,
    excelBOM: true,
    trimHeaderFields: true,
    trimFieldValues: true,
    preventCsvInjection: true,
    // if a response doesn't contain an answer
    // to a particular field, this prevents that cell from
    // being populated with 'undefined'
    emptyFieldValue: '',
    keys,
  }

  return json2csv(csvData, options)
}

export const useDownloadAdminBookings = ({ eventId }: { eventId: string }) => {
  const { data: event } = useGetEvent({ eventId })
  const toast = useToast()

  return useCallback(
    async ({
      eventId,
      startDate,
      endDate,
    }: {
      eventId: string
      startDate?: number
      endDate?: number
    }) => {
      if (!event) {
        // Event may not be loaded yet
        toast({
          status: 'error',
          description: 'Something went wrong! Please try again later.',
        })
        return
      }

      try {
        const bookings = await api
          .query(startDate ? { startDate } : {})
          .query(endDate ? { endDate } : {})
          .get(`/admin/events/${eventId}/bookings`)
          .json<GetBookingRes[]>()

        if (bookings.length === 0) {
          toast({
            status: 'warning',
            description: 'No bookings found in the specified date range.',
          })
        } else {
          await formatBookingsAsCsv({
            bookings,
            formFields: event.formFields,
          }).then((csv) => {
            const blob = new Blob([csv], {
              type: 'text/csv',
            })
            saveAs(blob, 'bookings.csv')
          })
        }
      } catch (e: unknown) {
        toast({
          status: 'error',
          description: getNetworkErrorMessage(e),
        })
      }
    },
    [event, toast],
  )
}
