import { useMemo } from 'react'
import { Text, VStack } from '@chakra-ui/react'

import { minutesToDisplayTimeUnits } from '~shared/utils'

import { BookingFlowErrorLayout } from './BookingFlowErrorLayout'

export type BookingCancellationNotAllowedProps = {
  cancellationLeadTimeMins: number
}

export const BookingCancellationNotAllowed = ({
  cancellationLeadTimeMins,
}: BookingCancellationNotAllowedProps) => {
  const { quantity: timeQuantity, unit: timeUnit } = useMemo(
    () => minutesToDisplayTimeUnits(cancellationLeadTimeMins),
    [cancellationLeadTimeMins],
  )

  return (
    <BookingFlowErrorLayout>
      <VStack paddingX={8} spacing={1}>
        <Text textStyle="h4">{'Sorry, this booking cannot be cancelled.'}</Text>
        {timeQuantity !== null && (
          <Text>
            {cancellationLeadTimeMins === 0
              ? `Bookings in the past cannot be cancelled.`
              : `Bookings cannot be cancelled ${timeQuantity} ${timeUnit} before they start.`}
          </Text>
        )}
        <Text>
          {'Please contact the agency that gave you this booking link.'}
        </Text>
      </VStack>
    </BookingFlowErrorLayout>
  )
}
