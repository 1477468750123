import { BiSolidHourglass } from 'react-icons/bi'
import { Box, Center, HStack, Icon, Text, VStack } from '@chakra-ui/react'

import { TimeslotRes } from '~shared/dto'
import { Timeslot } from '~shared/types'
import { minsToHumanReadable } from '~shared/utils'

import { useIsClientMobile } from '~hooks/useIsClientMobile'

import { SlotTimeList } from './SlotTimeList'

const ScrollBox = ({
  children,
  isScrollable = false,
}: {
  children: React.ReactNode
  isScrollable?: boolean
}) => {
  if (!isScrollable) {
    return <>{children}</>
  }

  // Position: relative applied to the outer box is to prevent the innter
  // oversized box from expanding beyond the height of the outer box. Removing
  // it causes whitespace to be added to the bottom of the page because the
  // height of the page would be calculated with the hidden part of the
  // inner box
  return (
    <Box
      alignItems="stretch"
      w="full"
      flex={1}
      overflowY="auto"
      position="relative"
    >
      {/* This css trick is to prevent the children from overflowing the parent */}
      <Box h={0} maxH="100%" overflow="visible" pr={4}>
        {children}
      </Box>
    </Box>
  )
}

export interface SlotTimePickerProps {
  slots: TimeslotRes[] | null
  slotDurationMins: number
  onChange: (slot: Timeslot) => void
  currDate: Date | null
  isScrollable?: boolean
  defaultValue?: Timeslot | null
}

export const SlotTimePicker = ({
  slots,
  slotDurationMins,
  onChange,
  currDate,
  isScrollable,
  defaultValue = null,
}: SlotTimePickerProps): JSX.Element => {
  const isMobile = useIsClientMobile()

  return (
    <VStack alignItems="stretch" flex={1} spacing={4}>
      {/* The duration label always renders on desktop (!isMobile),
          but only renders if a date is selected on mobile (currDate) */}
      {slotDurationMins && (!isMobile || currDate) && (
        <HStack w="full" alignItems="center">
          <Icon as={BiSolidHourglass} fontSize="1.25rem" />
          <Text textStyle={'body-2'} alignSelf="start" flexGrow={0}>
            Each slot is{' '}
            {minsToHumanReadable({
              mins: slotDurationMins,
              useAbbreviation: true,
            })}{' '}
            long
          </Text>
        </HStack>
      )}
      {!slots || !currDate ? (
        <Center flex={1}>
          <Text
            maxW="145px"
            textAlign="center"
            textStyle="body-2"
            color="interaction.support.placeholder"
          >
            Select a date to see available slots
          </Text>
        </Center>
      ) : (
        <ScrollBox isScrollable={isScrollable}>
          <SlotTimeList
            key={currDate.valueOf()}
            slots={slots}
            onChange={onChange}
            defaultValue={defaultValue}
          />
        </ScrollBox>
      )}
    </VStack>
  )
}
