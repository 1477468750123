import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  CreateScheduleOverrideReq,
  CreateScheduleOverrideRes,
  UpdateAllScheduleOverridesReq,
  UpdateAllScheduleOverridesRes,
} from '~shared/dto'

import { api } from '~lib/api'
import { adminQueryKeys } from '~constants/queryKeys'

export const useCreateOverride = (scheduleId: string) => {
  const queryCache = useQueryClient()
  return useMutation(
    async (req: CreateScheduleOverrideReq) =>
      api
        .url(`/admin/schedules/${scheduleId}/overrides`)
        .post(req)
        .json<CreateScheduleOverrideRes>(),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(
          // TODO - for now, invalidate events too, because we are fetching schedule from event
          adminQueryKeys.base,
        )
      },
    },
  )
}

export const useDeleteOverride = (scheduleId: string) => {
  const queryCache = useQueryClient()
  return useMutation(
    async ({ overrideId }: { overrideId: string }) =>
      api
        .url(`/admin/schedules/${scheduleId}/overrides/${overrideId}`)
        .delete()
        .res(),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(
          // TODO - for now, invalidate events too, because we are fetching schedule from event
          adminQueryKeys.base,
        )
      },
    },
  )
}

export const useUpdateAllOverrides = (scheduleId: string) => {
  const queryCache = useQueryClient()
  return useMutation(
    async (req: UpdateAllScheduleOverridesReq) =>
      api
        .url(`/admin/schedules/${scheduleId}/overrides`)
        .put(req)
        .json<UpdateAllScheduleOverridesRes>(),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(
          // TODO - for now, invalidate events too, because we are fetching schedule from event
          adminQueryKeys.base,
        )
      },
    },
  )
}
