import { useEffect } from 'react'
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { FormControl, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import {
  BxsHelpCircle,
  Checkbox,
  TouchableTooltip,
} from '@opengovsg/design-system-react'

import { SgidSettingsFormFields } from '../types'

export const DuplicateNricConstraintInput = () => {
  const { errors } = useFormState<SgidSettingsFormFields>()
  const { control, setValue } = useFormContext<SgidSettingsFormFields>()
  const isSgidEnabled = useWatch<SgidSettingsFormFields, 'isSgidEnabled'>({
    control,
    name: 'isSgidEnabled',
  })

  // This effect is used to unselect all options when sgID login is disabled
  useEffect(() => {
    if (!isSgidEnabled) {
      setValue('isUniqueNricConstraintEnabled', false)
    }
  }, [isSgidEnabled, setValue])

  return (
    <FormControl isInvalid={!!errors.isUniqueNricConstraintEnabled}>
      <Controller
        control={control}
        name="isUniqueNricConstraintEnabled"
        render={({ field: { onChange, value } }) => {
          return (
            <Checkbox
              disabled={!isSgidEnabled}
              size="sm"
              isChecked={value}
              onChange={(event) => {
                onChange(event.target.checked)
              }}
            >
              <VStack spacing={1} alignItems="stretch">
                <HStack>
                  <Text textStyle="subhead-2">
                    Prevent duplicate bookings by the same NRIC
                  </Text>
                  <TouchableTooltip
                    label="e.g. If NRIC S1111111D makes a booking for 10/1, 9 - 10am, then they cannot make another booking until after 10/1, 9am."
                    placement={'top'}
                  >
                    {/* We use display block to prevent the icon from moving itself upwards and getting misaligned */}
                    {/* The click handler prevents activating the default behaviour of toggling the checkbox */}
                    <Icon
                      onClick={(evt) => evt.preventDefault()}
                      as={BxsHelpCircle}
                      fontSize="1rem"
                      display="block"
                      aria-hidden
                    />
                  </TouchableTooltip>
                </HStack>

                <Text textStyle="body-2">
                  Attendees cannot use the same NRIC to make multiple concurrent
                  bookings. They can book again after their current booking
                  passes.
                </Text>
              </VStack>
            </Checkbox>
          )
        }}
      />
    </FormControl>
  )
}
