import { useCallback, useEffect } from 'react'
import {
  Outlet,
  unstable_BlockerFunction as BlockerFunction,
  unstable_useBlocker as useBlocker,
  useOutletContext,
} from 'react-router-dom'
import { Flex, Spacer, useDisclosure } from '@chakra-ui/react'

import { LabelledRouteObject } from '~/app/types'

import { useIsClientMobile } from '~hooks/useIsClientMobile'
import { LoadingState } from '~components/LoadingState'
import { ScrollToTop } from '~components/ScrollToTop'

import {
  isDirtySelector,
  setIsDirtySelector,
  useDirtyFieldStore,
} from '~features/events/hooks/useDirtyFieldStore'

import { UnsavedChangesModal } from '../components/UnsavedChangesModal'
import { AdminEventOutletContext } from '../types'

import { AdminEditEventSidebar } from './AdminEditEventSidebar'

export type AdminEditEventTabPanelProps = {
  subNavigation?: LabelledRouteObject[]
  subRoute?: string
}

export const AdminEditEventTabPanel = ({
  subNavigation,
  subRoute,
}: AdminEditEventTabPanelProps) => {
  const { event } = useOutletContext<AdminEventOutletContext>()
  const isMobile = useIsClientMobile()
  const areChangesUnsaved = useDirtyFieldStore(isDirtySelector)
  const setIsDirty = useDirtyFieldStore(setIsDirtySelector)

  // Reference:
  // https://github.com/remix-run/react-router/blob/main/examples/navigation-blocking/src/app.tsx
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      areChangesUnsaved && currentLocation.pathname !== nextLocation.pathname,
    [areChangesUnsaved],
  )
  const blocker = useBlocker(shouldBlock)

  const {
    isOpen: isUnsavedChangesModalOpen,
    onOpen: onUnsavedChangesModalOpen,
    onClose: onUnsavedChangesModalClose,
  } = useDisclosure()
  useEffect(() => {
    if (blocker.state === 'blocked') {
      onUnsavedChangesModalOpen()
    }
  }, [blocker.state, onUnsavedChangesModalOpen])

  if (!event) return <LoadingState flex={1} />

  return (
    <>
      <UnsavedChangesModal
        isOpen={isUnsavedChangesModalOpen}
        onClose={() => {
          blocker.reset?.()
          onUnsavedChangesModalClose()
        }}
        onDiscardChanges={() => {
          blocker.proceed?.()
          // We need to explicitly set isDirty to false so shouldBlock recomputes.
          // Otherwise, because AdminEditEventTabPanel remounts in the same position in the virtual
          // DOM tree, the state maintained by the shouldBlock may be retained unexpectedly.
          // When there are unsaved changes, the state with areChangesUnsaved = true is carried over to the
          // remounted copy, which defaults areChangesUnsaved to true on mount and shows the popup again
          // after the user dismisses the modal.
          setIsDirty(false)
          onUnsavedChangesModalClose()
        }}
      />
      <Flex alignItems="stretch" flex={1}>
        {!isMobile && (
          <Flex minW="15.75rem" pr={4} flex={1}>
            <AdminEditEventSidebar
              event={event}
              subNavigation={subNavigation}
              subRoute={subRoute}
            />
          </Flex>
        )}
        <Flex
          px={{ base: '24px', md: '3rem' }}
          py="2rem"
          w={{ base: 'full', lg: '44rem' }}
        >
          <ScrollToTop>
            <Outlet context={{ event }} />
          </ScrollToTop>
        </Flex>
        <Spacer />
      </Flex>
    </>
  )
}
