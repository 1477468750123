import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(isToday)
dayjs.extend(duration)
dayjs.extend(relativeTime)

/** E.g. 1234567 -> 1,234,567 | 0 -> 0 | 123 -> 123 | 1234.56 -> 1,235 */
export function numberWithCommas(x: number) {
  return Math.round(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const fmtDate = (
  date: Date,
  fmtString = 'DD/MM/YYYY',
  today = false, // Print "Today if Today"
): string => {
  const d = dayjs(date)
  return d.isToday() && today ? 'Today' : dayjs(date).format(fmtString)
}

export const fmtDateTime = (date: Date): string => {
  return dayjs(date).format('DD/MM/YYYY HH:mm')
}

export const fmtBytes = (numBytes: number): string => {
  if (numBytes == 0) return '0 Bytes'
  const k = 1024
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(numBytes) / Math.log(k))
  const num = numBytes / Math.pow(k, i)
  const dp = 2 - Math.floor(Math.log10(num))
  return `${num.toFixed(dp)} ${units[i]}`
}

// Capitalizes every start of word, including punctuation and spaces
export const frontCase = (str: string): string => {
  return str.toLowerCase().replace(/(^\w|\W\w)/g, (m) => m.toUpperCase())
}

export const fmtCurrency = (value: number, round = false): string => {
  const formatter = new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
    ...(round
      ? {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      : {}),
  })
  return formatter.format(value)
}

export const fmtPercentage = (value: number, round = false): string => {
  const percentage = value * 100
  return round ? `${Math.round(percentage)}%` : `${percentage.toFixed(2)}%`
}

export const minsToHumanReadable = ({
  mins,
  useAbbreviation = false,
}: {
  mins: number
  useAbbreviation?: boolean
}): string => {
  // If this ever needs to be more complex, we can use a library
  // like humanize-duration
  const duration = dayjs.duration(mins, 'm')
  const numHours = duration.hours()
  const numMinutes = duration.minutes()

  const hourLabel = useAbbreviation ? 'h' : 'hour'
  const minLabel = useAbbreviation ? 'min' : 'minute'

  const hourString = `${numHours} ${hourLabel}${
    numHours > 1 && !useAbbreviation ? 's' : ''
  }`
  const minuteString = `${numMinutes} ${minLabel}${numMinutes > 1 ? 's' : ''}`

  if (numHours && numMinutes) {
    return `${hourString}, ${minuteString}`
  }
  if (numHours) {
    return hourString
  }
  return minuteString
}

export const dateToHumanReadable = (date: number | Date): string => {
  return dayjs(date).format('D MMMM YYYY, dddd')
}

export const timeToHumanReadable = (time: number | Date): string => {
  return dayjs(time).format('HH:mm')
}

export const timeToHumanReadableAmPm = (time: number | Date): string => {
  return dayjs(time).format('h:mm A')
}

/**
 * Only for intervals within a given day, i.e. don't cross midnight.
 */
export const intervalToHumanReadable = ({
  startAt,
  endAt,
}: {
  startAt: number | Date
  endAt: number | Date
}): string => {
  const datePart = dateToHumanReadable(startAt)
  const startTimePart = timeToHumanReadable(startAt)
  const endTimePart = timeToHumanReadable(endAt)
  return `${startTimePart} - ${endTimePart}, ${datePart}`
}
