import { useMemo } from 'react'
import { BiTimeFive } from 'react-icons/bi'
import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { GetBookingRes } from '~shared/dto'
import { timeToHumanReadable } from '~shared/utils'

import { ClickToCopy } from '~/components/ClickToCopy'

import { useAdminBookingsContext } from '../AdminBookingsContext'

// This inner component provides type-safety,
// while ensuring the parent Drawer retains the nice onClose animation
const BookingDetailsDrawerBody = ({ booking }: { booking: GetBookingRes }) => {
  const timeText = useMemo(() => {
    return `${timeToHumanReadable(booking.startAt)} - ${timeToHumanReadable(
      booking?.endAt,
    )}`
  }, [booking])

  return (
    <VStack divider={<Divider />} alignItems="stretch" spacing={6}>
      <ClickToCopy label="email">{booking.email}</ClickToCopy>
      <HStack alignItems="center">
        <Icon
          as={BiTimeFive}
          fontSize="1.25rem"
          color="interaction.support.disabled-content"
        />
        <Text color="base.content.strong"> {timeText}</Text>
      </HStack>
      {!!booking.nricFin && (
        <VStack spacing={1}>
          <Text
            alignSelf="start"
            color="base.content.medium"
            textStyle="caption-2"
          >
            Singpass Verified NRIC/FIN
          </Text>
          <Text
            alignSelf="start"
            color="base.content.strong"
            textStyle="body-1"
          >
            {booking.nricFin}
          </Text>
        </VStack>
      )}
      <VStack alignItems="stretch" spacing={7}>
        {booking.responses.map(({ question, answer }) => (
          <VStack key={question} spacing={1}>
            <Text
              alignSelf="start"
              color="base.content.medium"
              textStyle="caption-2"
            >
              {question}
            </Text>
            <Text
              alignSelf="start"
              color="base.content.strong"
              textStyle="body-1"
            >
              {answer}
            </Text>
          </VStack>
        ))}
      </VStack>
    </VStack>
  )
}

export const BookingDetailsDrawer = () => {
  const {
    deleteModalDisclosure,
    drawerDisclosure,
    setCurrBooking,
    currBooking,
  } = useAdminBookingsContext()

  return (
    <Drawer
      size="sm"
      isOpen={drawerDisclosure.isOpen}
      placement="right"
      onClose={() => {
        drawerDisclosure.onClose()
        setCurrBooking(null)
      }}
    >
      <DrawerOverlay width="full" h="full" />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader textStyle="h6">Booking Details</DrawerHeader>
        <DrawerBody overflowY="scroll">
          {currBooking && <BookingDetailsDrawerBody booking={currBooking} />}
        </DrawerBody>

        <DrawerFooter pb={8}>
          <Button
            variant="outline"
            width="full"
            onClick={() => deleteModalDisclosure.onOpen()}
          >
            Delete
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
