import { useCallback } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Divider, FormControl, VStack } from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@opengovsg/design-system-react'

import { UpdateEventListOwnerReq } from '~shared/dto'
import { isAsciiLocalpartEmail } from '~shared/utils/validation'

import { getNetworkErrorMessage } from '~lib/api'

import { useAdminAuth } from '~features/auth'
import { useUpdateEventListOwner } from '~features/event-lists/hooks/useAdminEventLists'

import { EventListCollaboratorRow } from './EventListCollaboratorRow'

export type EventListTransferOwnershipFormProps = {
  eventListId: string
}

type EventListTransferOwnershipFormFields = {
  email: string
}

export const EventListTransferOwnershipForm = ({
  eventListId,
}: EventListTransferOwnershipFormProps) => {
  const toast = useToast()
  const formMethods = useForm<EventListTransferOwnershipFormFields>({
    mode: 'onSubmit',
    defaultValues: { email: '' },
  })
  const { mutate, isLoading } = useUpdateEventListOwner(eventListId)

  const {
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = formMethods

  const onSubmit = useCallback(
    ({ email }: EventListTransferOwnershipFormFields) => {
      const updateOwnerReq: UpdateEventListOwnerReq = {
        userEmail: email.toLowerCase(),
      }
      mutate(updateOwnerReq, {
        onSuccess: (_res, { userEmail }) => {
          toast({
            description: `Successfully transferred event list to ${userEmail}`,
            status: 'success',
          })
          reset()
        },
        onError: (e) => {
          setError('email', {
            type: 'server',
            message: getNetworkErrorMessage(e),
          })
        },
      })
    },
    [],
  )

  return (
    <VStack w="full" gap={6} alignItems="stretch">
      <VStack alignItems="stretch">
        <FormLabel
          isRequired
          description="Transfer ownership of this event list to another user. You will retain Editor status."
        >
          Transfer ownership
        </FormLabel>
        <FormProvider {...formMethods}>
          <FormControl isInvalid={!!errors.email?.message}>
            <Controller
              name="email"
              rules={{
                validate: {
                  valid: (email: string) =>
                    isAsciiLocalpartEmail(email) ||
                    'Please enter a valid email address. Special characters are not allowed.',
                },
                required: 'Please enter an email address',
              }}
              render={({ field: { onChange, ref, value } }) => (
                <Input
                  size="sm"
                  ref={ref}
                  value={value as string}
                  onChange={(...props) => {
                    // Manually clear the error that is set when there is
                    // a network error when the field changes.
                    clearErrors('email')
                    onChange(...props)
                  }}
                />
              )}
            />
            {!!errors.email?.message && (
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            )}
          </FormControl>
        </FormProvider>
      </VStack>
      <Button
        size="sm"
        onClick={handleSubmit(onSubmit)}
        isDisabled={isLoading || !!errors.email}
        isLoading={isLoading}
      >
        Transfer
      </Button>
    </VStack>
  )
}
