import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'
import { Center, Divider, VStack } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { useToast } from '@opengovsg/design-system-react'

import { EventStatus } from '~shared/types'

import { SaveChangesButton } from '~components/SaveChangesButton'

import { useUpdateEvent } from '~features/events/hooks/useAdminEvents'
import { AdminEventOutletContext } from '~features/events/types'
import { useUpdateSchedule } from '~features/schedules/hooks/useAdminSchedules'

import { SyncToDirtyFieldStore } from '../SyncToDirtyFieldStore'

import { BookingChangePolicy } from './components/BookingChangePolicy'
import { ClosedEventMessageSetting } from './components/ClosedEventMessageSetting'
import { EventStatusCheckbox } from './components/EventStatusCheckbox'
import { ShowRemainingCapacitySettings } from './components/ShowRemainingCapacitySettings'
import {
  generalSettingsFieldsToEventReqBody,
  generalSettingsFieldsToScheduleReqBody,
  getGeneralSettingsFields,
} from './utils/form-utils'
import { GeneralSettingsFormFields } from './types'

export const GeneralSettings = () => {
  const { event } = useOutletContext<AdminEventOutletContext>()
  const schedule = useMemo(() => event.schedules[0], [event.schedules])
  const toast = useToast()
  const formMethods = useForm<GeneralSettingsFormFields>({
    mode: 'onChange',
    defaultValues: getGeneralSettingsFields({ event, schedule }),
  })

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods
  const { mutate: mutateSchedule, isLoading: isMutateScheduleLoading } =
    useUpdateSchedule({
      eventId: event.id,
      scheduleId: schedule.id,
    })
  const { mutate: mutateEvent, isLoading: isMutateEventLoading } =
    useUpdateEvent(event.id)

  const onSubmit = handleSubmit((data: GeneralSettingsFormFields) =>
    mutateSchedule(generalSettingsFieldsToScheduleReqBody(data), {
      onSuccess: (updatedSchedule) => {
        mutateEvent(generalSettingsFieldsToEventReqBody(data), {
          onSuccess: (updatedEvent) => {
            toast({
              description: 'Settings updated successfully.',
              status: 'success',
            })
            reset(
              getGeneralSettingsFields({
                event: updatedEvent,
                schedule: updatedSchedule,
              }),
            )
          },
          onError: () => {
            // Schedule update succeeded, but event update did not
            toast({
              description:
                'Sorry, your settings were only updated partially. Please try again.',
              status: 'error',
            })
            reset(
              getGeneralSettingsFields({
                event,
                schedule: updatedSchedule,
              }),
            )
          },
        })
      },
    }),
  )

  return (
    <VStack spacing={6} alignItems="stretch" w="full">
      <VStack w="full" alignItems="start">
        <Text textStyle="h4">Settings</Text>
      </VStack>
      <Text
        textStyle={'subhead-1'}
        bg={
          event.eventStatus === EventStatus.Open
            ? 'interaction.success-subtle.default'
            : 'interaction.critical-subtle.default'
        }
        py="1rem"
        px="16px"
      >
        Your event is currently{' '}
        <b>{event.eventStatus === EventStatus.Open ? 'OPEN' : 'CLOSED'}</b> to
        new bookings.
      </Text>
      <FormProvider {...formMethods}>
        <SyncToDirtyFieldStore />
        <EventStatusCheckbox />
        <ClosedEventMessageSetting />
        <Divider />
        <BookingChangePolicy />
        <Divider />
        <ShowRemainingCapacitySettings />
        <Center pt={6}>
          <SaveChangesButton
            isDirty={isDirty}
            isLoading={isMutateScheduleLoading || isMutateEventLoading}
            onClick={onSubmit}
            w="full"
          />
        </Center>
      </FormProvider>
    </VStack>
  )
}
