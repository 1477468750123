import { BiPlus } from 'react-icons/bi'
import { Icon } from '@chakra-ui/react'
import { IconButton, IconButtonProps } from '@opengovsg/design-system-react'

import { useScheduleTimeRangeList } from './ScheduleTimeRangeListContext'

export const AddTimeRangeButton = (
  props: Omit<IconButtonProps, 'aria-label'>,
) => {
  const { onAdd, isDisabled } = useScheduleTimeRangeList()

  return (
    <IconButton
      variant="clear"
      icon={<Icon as={BiPlus} height="1.25rem" width="1.25rem" />}
      aria-label="add timeslot"
      color="grey.500"
      onClick={onAdd}
      isDisabled={isDisabled}
      size={{ base: 'xs', lg: 'sm' }}
      {...props}
    />
  )
}
