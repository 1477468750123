import { Flex, Image, Text, VStack } from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'

import EmptyBusStopSvg from '~/assets/img/empty-bus-stop.svg'

export const EmptyResponses = () => {
  return (
    <Flex
      flexDirection="column"
      gap={6}
      alignItems="center"
      pt="5rem"
      ml="auto"
      mr="auto"
    >
      <VStack justify="space-between">
        <Text
          textStyle="h6"
          color="base.content.strong"
        >{`No one has made a booking with you yet.`}</Text>
        <VStack spacing={0} textStyle="body-2" color="base.content.medium">
          <Text>
            {`Try sending out your event link with `}
            <Link
              isExternal
              href="https://www.postman.gov.sg"
              color="interaction.links.default"
            >
              Postman
            </Link>
            {','}
          </Text>
          <Text>
            {`or shorten your calendar link with `}
            <Link
              isExternal
              href="https://www.go.gov.sg"
              color="interaction.links.default"
            >
              Go.gov.sg
            </Link>
          </Text>
        </VStack>
      </VStack>
      <Image src={EmptyBusStopSvg} w="180px" maxW="100%" aria-hidden />
    </Flex>
  )
}
