import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@opengovsg/design-system-react'
import { useQueryClient } from '@tanstack/react-query'

import { WhoAmIResponseDto } from '~shared/types/auth.dto'

import { BrowserEvent } from '~constants/events'
import { adminQueryKeys } from '~constants/queryKeys'

import { useAdminLogout, useAdminUser } from './hooks'

type AdminAuthContextProps = {
  adminUser?: WhoAmIResponseDto
  isLoadingAdminUser: boolean
  logout: () => Promise<void>
}

const AdminAuthContext = createContext<AdminAuthContextProps | undefined>(
  undefined,
)

/**
 * Provider component that wraps your app and makes auth object available to any
 * child component that calls `useAuth()`.
 */
export const AdminAuthProvider: FC<React.PropsWithChildren> = ({
  children,
}): JSX.Element => {
  const navigate = useNavigate()
  const { adminUser, isLoadingAdminUser } = useAdminUser()
  const { mutateAsync: adminLogout } = useAdminLogout()
  const queryClient = useQueryClient()
  const toast = useToast()

  const logout = useCallback(async (): Promise<void> => {
    await adminLogout()
    navigate('/admin/login')
    queryClient.removeQueries(adminQueryKeys.base)
  }, [adminLogout, navigate, queryClient])

  const handleUnauthorizedEvent = useCallback(async () => {
    toast({
      status: 'error',
      description: 'Please login again.',
    })
    await logout()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initListeners = useCallback(() => {
    window.addEventListener(BrowserEvent.UNAUTHORIZED, handleUnauthorizedEvent)
  }, [handleUnauthorizedEvent])

  const removeListeners = useCallback(() => {
    window.removeEventListener(
      BrowserEvent.UNAUTHORIZED,
      handleUnauthorizedEvent,
    )
  }, [handleUnauthorizedEvent])

  // Initialise Listeners
  useEffect(() => {
    if (!adminUser) return

    initListeners()
    return removeListeners
  }, [adminUser, initListeners, removeListeners])

  return (
    <AdminAuthContext.Provider
      value={{
        adminUser,
        isLoadingAdminUser,
        logout,
      }}
    >
      {children}
    </AdminAuthContext.Provider>
  )
}

/**
 * Hook for components nested in ProvideAuth component to get the current auth object.
 */
export const useAdminAuth = (): AdminAuthContextProps => {
  const context = useContext(AdminAuthContext)
  if (!context) {
    throw new Error(
      `useAdminAuth must be used within a AdminAuthProvider component`,
    )
  }
  return context
}
