import { useCallback } from 'react'
import { Center } from '@chakra-ui/react'
import { Calendar, CalendarProps } from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import { SlotsByDate } from '~utils/slotsByDate'

export interface SlotDatePickerProps extends CalendarProps {
  slotsByDate: SlotsByDate | null
  earliestAvailableDate: Date | undefined
}

export const SlotDatePicker = ({
  slotsByDate,
  earliestAvailableDate,
  ...props
}: SlotDatePickerProps): JSX.Element => {
  const isDateUnavailable = useCallback(
    (date: Date) => {
      if (!slotsByDate) return true
      const dateStart = dayjs(date).startOf('d').valueOf()
      return !slotsByDate.has(dateStart)
    },
    [slotsByDate],
  )

  return (
    <Center flexGrow={0} flexShrink={0}>
      <Calendar
        defaultFocusedDate={earliestAvailableDate}
        isDateUnavailable={isDateUnavailable}
        showOutsideDays={false}
        {...props}
      />
    </Center>
  )
}
