import { useRef } from 'react'
import { BiTime } from 'react-icons/bi'
import { InputGroup, InputRightAddon, useToken } from '@chakra-ui/react'
import { IconButton, Input, InputProps } from '@opengovsg/design-system-react'

const MS_IN_HOUR = 60 * 60 * 1000
const MS_IN_MIN = 60 * 1000

const parseTimeInputValue = (timeInputString: string) => {
  try {
    const [hourStr, minStr] = timeInputString.split(':')
    const hour = parseInt(hourStr)
    const min = parseInt(minStr)
    if (Number.isNaN(hour) || Number.isNaN(min)) {
      return 0
    }
    if (hour > 23 || hour < 0 || min > 59 || min < 0) {
      return 0
    }

    return hour * MS_IN_HOUR + min * MS_IN_MIN
  } catch (err) {
    return 0
  }
}

const getTimeInputValueFromMillis = (milliseconds: number) => {
  const hour = Math.floor(milliseconds / MS_IN_HOUR)
  const min = Math.floor((milliseconds % MS_IN_HOUR) / MS_IN_MIN)

  const hourStr = `${hour < 10 ? '0' : ''}${hour}`
  const minStr = `${min < 10 ? '0' : ''}${min}`

  return `${hourStr}:${minStr}`
}

export type TimePickerProps = {
  onChange: (millis: number) => void
  value: number
  shouldShowClockIcon?: boolean
} & Omit<InputProps, 'onChange'>

export const TimePicker = ({
  onChange,
  value,
  isRequired,
  isDisabled,
  borderColor,
  shouldShowClockIcon = true,
  ...props
}: TimePickerProps) => {
  const ref = useRef<HTMLInputElement>()
  const transitionDuration = useToken('transition', 'duration.normal')
  const transitionProperties = useToken('transition', 'property.common')

  return (
    <InputGroup size="sm">
      <Input
        ref={ref}
        type="time"
        /* The pattern attribute is used on browsers that fallback to a text input */
        pattern="[0-9]{2}:[0-9]{2}"
        onChange={(evt) => onChange?.(parseTimeInputValue(evt.target.value))}
        /* This is to hide the default clock icon on chrome */
        css={{
          '&::-webkit-calendar-picker-indicator': {
            display: 'none',
          },
        }}
        value={getTimeInputValueFromMillis(value)}
        isDisabled={isDisabled}
        {...props}
      />
      {shouldShowClockIcon && (
        <InputRightAddon
          py={0}
          px={0}
          backgroundColor={
            isDisabled
              ? 'interaction.support.disabled'
              : 'interaction.links.inverse-default'
          }
          borderColor={borderColor ?? 'base.divider.strong'}
          transitionDuration={transitionDuration}
          transitionProperty={transitionProperties}
          onClick={() => {
            if (isDisabled) {
              return
            }
            ref.current?.focus()
            // On chrome and mobile browsers, this opens up the popup.
            ref.current?.showPicker?.()
          }}
        >
          <IconButton
            aria-label="Open timepicker"
            icon={<BiTime />}
            variant="clear"
            fontSize="1rem"
            h="full"
            size="xs"
            isDisabled={isDisabled}
            borderTopLeftRadius={0}
            borderBottomLeftRadius={0}
          />
        </InputRightAddon>
      )}
    </InputGroup>
  )
}
