import { BiDockTop, BiTrash } from 'react-icons/bi'
import { Flex, Text } from '@chakra-ui/react'
import { Button, IconButton } from '@opengovsg/design-system-react'

import { useIsClientMobile } from '~hooks/useIsClientMobile'

interface BookingRowProps {
  email: string
  onClickPrimary: () => void
  onClickDelete: () => void
}

export const BookingRow = ({
  email,
  onClickPrimary,
  onClickDelete,
}: BookingRowProps) => {
  const isMobile = useIsClientMobile()
  return (
    <Flex
      flex={1}
      border="1px"
      borderRadius={0}
      borderColor="base.divider.medium"
    >
      <Button
        leftIcon={!isMobile ? <BiDockTop /> : undefined}
        variant="clear"
        h="100%"
        flexGrow={1}
        flexShrink={1}
        justifyContent="flex-start"
        px={{ base: 1, md: 8 }}
        onClick={onClickPrimary}
      >
        <Text
          color="base.content.default"
          textAlign="start"
          ml={!isMobile ? 4 : 2}
          overflowWrap="anywhere"
          wordBreak="break-all"
          textStyle={{ base: 'body-2', md: 'body-1' }}
        >
          {email}
        </Text>
      </Button>
      <IconButton
        h="100%"
        variant="clear"
        aria-label="delete-booking"
        color="interaction.critical.default"
        onClick={onClickDelete}
        icon={<BiTrash />}
      />
    </Flex>
  )
}
