import { Center, CenterProps } from '@chakra-ui/react'
import { Spinner } from '@opengovsg/design-system-react'

export const LoadingState = (props: CenterProps) => {
  return (
    <Center mr="auto" ml="auto" {...props}>
      <Spinner fontSize="2rem" />
    </Center>
  )
}
