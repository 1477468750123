import { memo, useMemo, useState } from 'react'
import { BiDownload } from 'react-icons/bi'
import { useOutletContext } from 'react-router-dom'
import {
  Box,
  Divider,
  Flex,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import { Calendar } from '@opengovsg/design-system-react'

import {
  GetBookingRes,
  UpdateScheduleOverrideRes,
  UpdateScheduleRes,
} from '~shared/dto'
import { getStartOfDateInTz } from '~shared/utils/date'

import { LoadingState } from '~components/LoadingState'

import { useGetEventBookings } from '~features/events/hooks/useAdminBookings'
import { AdminEventOutletContext } from '~features/events/types'

import { DownloadBookingModal } from '../DownloadBookingsForm'

import { BookingDetailsDrawer } from './components/BookingDetailsDrawer'
import { BookingTimeslot } from './components/BookingTimeslot'
import { CancelBookingModal } from './components/CancelBookingModal'
import { AdminBookingsProvider } from './AdminBookingsContext'
import { EmptyResponses } from './EmptyResponses'

const findOverrideCapacityFor = (
  datetime: number,
  scheduleOverrides: UpdateScheduleOverrideRes[],
) => {
  const override = scheduleOverrides.find(
    (override) => override.startAt <= datetime && override.endAt > datetime,
  )
  return override?.capacity
}

const sortBookingsByDates = ({
  bookings,
  schedule,
}: {
  bookings: GetBookingRes[]
  schedule: UpdateScheduleRes
}) => {
  const processedDateTimeSet: Record<number, Set<number>> = {}
  const bookingsByDatetime: Record<number, GetBookingRes[]> = {}
  const slotCapacityByDatetime: Record<number, number> = {}

  const isOneoffEvent = schedule.segments === null
  const defaultCapacity = isOneoffEvent ? 0 : schedule.slotCapacity

  bookings.forEach((booking: GetBookingRes) => {
    const dateEpochTime = getStartOfDateInTz(booking.startAt)?.getTime()
    const startEpochTime = new Date(booking.startAt).getTime()

    if (!dateEpochTime || !startEpochTime) return
    // Add timings to each date
    if (dateEpochTime in processedDateTimeSet)
      processedDateTimeSet[dateEpochTime].add(startEpochTime)
    else processedDateTimeSet[dateEpochTime] = new Set([startEpochTime])
    if (startEpochTime in bookingsByDatetime) {
      bookingsByDatetime[startEpochTime].push(booking)
    } else {
      bookingsByDatetime[startEpochTime] = [booking]
      // Add to capacity Record
      slotCapacityByDatetime[startEpochTime] =
        findOverrideCapacityFor(startEpochTime, schedule.overrides) ??
        defaultCapacity
    }
  })
  const bookingsByDate: Record<number, number[]> = {}
  for (const dateEpoch in processedDateTimeSet) {
    bookingsByDate[dateEpoch] = Array.from(processedDateTimeSet[dateEpoch])
    bookingsByDate[dateEpoch].sort()
  }

  return {
    bookingsByDate,
    bookingsByDatetime,
    slotCapacityByDatetime,
  }
}

const MemoCalendar = memo(Calendar)

export const Responses = () => {
  const { event } = useOutletContext<AdminEventOutletContext>()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedDate, setSelectedDate] = useState<number>()

  // TODO - for MVP, each event has exactly 1 schedule
  const schedule = useMemo(() => event.schedules[0], [event.schedules])

  const { data: bookings, isLoading } = useGetEventBookings({
    eventId: event.id,
  })

  const { bookingsByDate, bookingsByDatetime, slotCapacityByDatetime } =
    useMemo(() => {
      if (!bookings || isLoading || !schedule) {
        return {
          bookingsByDate: {},
          bookingsByDatetime: {},
          slotCapacityByDatetime: {},
        }
      }
      return sortBookingsByDates({ bookings, schedule })
    }, [bookings, isLoading, schedule])

  if (isLoading || !bookings) {
    return <LoadingState />
  }

  if (bookings.length === 0) {
    return <EmptyResponses />
  }

  return (
    <AdminBookingsProvider>
      <DownloadBookingModal
        eventId={event.id}
        isOpen={isOpen}
        onClose={onClose}
      />
      <BookingDetailsDrawer />
      <CancelBookingModal />
      <VStack spacing={6} alignItems="stretch" w="full">
        <Flex justify="space-between" direction={{ base: 'column', md: 'row' }}>
          <Text textStyle="h4">Bookings</Text>
          <Button
            leftIcon={<BiDownload />}
            onClick={onOpen}
            mt={{ base: 4, md: 0 }}
          >
            Download CSV
          </Button>
        </Flex>
        <Box
          maxW={{ base: 'auto', md: '364px' }}
          w="full"
          mx={{ base: 'auto', md: 0 }}
          textAlign="center"
        >
          <MemoCalendar
            size={{ base: 'sm', md: 'md' }}
            onChange={(date) => setSelectedDate(date?.getTime())}
            isDateUnavailable={(date) => {
              const epochTime = getStartOfDateInTz(date)?.getTime()
              if (!epochTime) return false
              return !(epochTime in bookingsByDate)
            }}
            showOutsideDays={false}
          />
        </Box>
        <Divider />
        <VStack spacing={6} alignItems="stretch">
          {selectedDate &&
            bookingsByDate[selectedDate] &&
            bookingsByDate[selectedDate].map((date) => {
              return (
                <BookingTimeslot
                  key={`${date}-timeslot`}
                  startAt={bookingsByDatetime[date][0].startAt}
                  endAt={bookingsByDatetime[date][0].endAt}
                  responses={bookingsByDatetime[date]}
                  capacity={slotCapacityByDatetime[date]}
                />
              )
            })}
        </VStack>
      </VStack>
    </AdminBookingsProvider>
  )
}
