import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ModifyEventPermissionReq, UpdateEventOwnerReq } from '~shared/dto'

import { api } from '~lib/api'
import { adminQueryKeys } from '~constants/queryKeys'

export const useModifyEventPermission = (eventId: string) => {
  const queryCache = useQueryClient()
  return useMutation(
    async (req: ModifyEventPermissionReq) =>
      api.url(`/admin/events/${eventId}/permissions`).put(req).res(),
    {
      onSuccess: () => {
        void queryCache.invalidateQueries(adminQueryKeys.events({ eventId }))
      },
    },
  )
}

export const useUpdateEventOwner = (eventId: string) => {
  const queryCache = useQueryClient()
  return useMutation(
    async (req: UpdateEventOwnerReq) =>
      api.url(`/admin/events/${eventId}/admin`).put(req).res(),
    {
      onSuccess: () => {
        void queryCache.invalidateQueries(adminQueryKeys.events({ eventId }))
      },
    },
  )
}
