import { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TagInput } from '@opengovsg/design-system-react'

import { isAsciiLocalpartEmail } from '~shared/utils/validation'

export interface MultiEmailInputProps {
  name: string
}

export const MultiEmailInput = ({
  name,
}: MultiEmailInputProps): JSX.Element => {
  const { getValues, setValue, control } =
    useFormContext<Record<string, string[]>>()
  const tagValidation = useMemo(() => isAsciiLocalpartEmail, [])

  const handleBlur = useCallback(() => {
    // Get rid of bad tags
    setValue(
      name,
      getValues(name).filter((e) => tagValidation(e)),
    )
  }, [getValues, name, setValue, tagValidation])

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: {
          valid: (emails: string[]) => {
            return (
              emails.filter(Boolean).every((e) => isAsciiLocalpartEmail(e)) ||
              'Please enter valid email(s) (e.g. me@example.com) separated by commas. Special characters are not allowed.'
            )
          },
          duplicate: (emails: string[]) => {
            const truthyEmails = emails.filter(Boolean)
            return (
              new Set(truthyEmails).size === truthyEmails.length ||
              'Please remove duplicate emails'
            )
          },
        },
      }}
      render={({ field }) => (
        <TagInput
          placeholder="Separate emails with a comma"
          tagColorScheme="brand.primary"
          {...field}
          tagValidation={tagValidation}
          onBlur={handleBlur}
        />
      )}
    />
  )
}
