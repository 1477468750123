import { BiCalendar } from 'react-icons/bi'
import {
  Container,
  Icon,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { Tile } from '@opengovsg/design-system-react'

export interface RecurringEventTileProps {
  isSelected: boolean
  onClick?: () => void
}

export const RecurringEventTile = ({
  isSelected,
  onClick,
}: RecurringEventTileProps) => {
  return (
    <Tile
      icon={() => <Icon as={BiCalendar} boxSize={10} />}
      variant="complex"
      flex="1"
      isSelected={isSelected}
      onClick={() => onClick && onClick()}
    >
      <Tile.Title>Recurring Event</Tile.Title>
      <Tile.Subtitle>Repeats at a regular interval</Tile.Subtitle>
      <Tile.Text as={Container} padding={0}>
        <Text>Examples:</Text>
        <UnorderedList listStylePosition={'inside'}>
          <ListItem>Weekly meeting</ListItem>
          <ListItem>Clinic opening hours</ListItem>
        </UnorderedList>
      </Tile.Text>
    </Tile>
  )
}
