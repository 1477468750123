import { useCallback } from 'react'
import {
  BiCalendar,
  BiCalendarWeek,
  BiDownArrowAlt,
  BiUpArrowAlt,
} from 'react-icons/bi'
import { Divider, HStack, Text, VStack } from '@chakra-ui/react'
import { IconButton } from '@opengovsg/design-system-react'

import { AdminDashboardEventDto } from '~shared/dto'

export type ReorderEventListFormProps = {
  events: AdminDashboardEventDto[]
  setEventOrder: (events: AdminDashboardEventDto[]) => void
}

const EventRow = ({
  event,
  onMoveUp,
  onMoveDown,
}: {
  event: AdminDashboardEventDto
  onMoveUp?: () => void
  onMoveDown?: () => void
}) => {
  return (
    <HStack justifyContent="space-between" alignItems="center">
      <HStack p={4} gap={2}>
        {event.type === 'oneOffEvent' ? (
          <BiCalendarWeek fontSize="1.25rem" />
        ) : (
          <BiCalendar fontSize="1.25rem" />
        )}
        <Text wordBreak="break-all" textStyle="body-2">
          {event.title}
        </Text>
      </HStack>
      <HStack>
        <IconButton
          icon={<BiUpArrowAlt />}
          variant="clear"
          aria-label="Move event up"
          size="sm"
          onClick={onMoveUp}
          isDisabled={!onMoveUp}
        />
        <IconButton
          icon={<BiDownArrowAlt />}
          variant="clear"
          aria-label="Move event down"
          size="sm"
          onClick={onMoveDown}
          isDisabled={!onMoveDown}
        />
      </HStack>
    </HStack>
  )
}

export const ReorderEventListForm = ({
  events,
  setEventOrder,
}: ReorderEventListFormProps) => {
  const moveEventUp = useCallback(
    (index: number) => {
      const eventsBefore = events.slice(0, index - 1)
      const eventsAfter = events.slice(index + 1)

      setEventOrder([
        ...eventsBefore,
        events[index],
        events[index - 1],
        ...eventsAfter,
      ])
    },
    [events],
  )

  const moveEventDown = useCallback(
    (index: number) => {
      const eventsBefore = events.slice(0, index)
      const eventsAfter = events.slice(index + 2)

      setEventOrder([
        ...eventsBefore,
        events[index + 1],
        events[index],
        ...eventsAfter,
      ])
    },
    [events],
  )

  return (
    <VStack spacing={0} alignItems="stretch">
      <VStack divider={<Divider />} alignItems="stretch" spacing={0}>
        {events.map((event, idx) => {
          return (
            <EventRow
              key={event.id}
              event={event}
              onMoveUp={idx <= 0 ? undefined : () => moveEventUp(idx)}
              onMoveDown={
                idx >= events.length - 1 ? undefined : () => moveEventDown(idx)
              }
            />
          )
        })}
      </VStack>
    </VStack>
  )
}
