import React, { createContext, FC, useContext, useState } from 'react'
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react'

import { GetBookingRes } from '~shared/dto'

type AdminBookingsContextProps = {
  deleteModalDisclosure: UseDisclosureReturn
  drawerDisclosure: UseDisclosureReturn
  currBooking: GetBookingRes | null
  setCurrBooking: (booking: GetBookingRes | null) => void
}

const AdminBookingsContext = createContext<
  AdminBookingsContextProps | undefined
>(undefined)

/**
 * Provider component that wraps your app and makes auth object available to any
 * child component that calls `useAuth()`.
 */
export const AdminBookingsProvider: FC<React.PropsWithChildren> = ({
  children,
}): JSX.Element => {
  const deleteModalDisclosure = useDisclosure()
  const drawerDisclosure = useDisclosure()
  const [currBooking, setCurrBooking] = useState<GetBookingRes | null>(null)

  return (
    <AdminBookingsContext.Provider
      value={{
        deleteModalDisclosure,
        drawerDisclosure,
        currBooking,
        setCurrBooking,
      }}
    >
      {children}
    </AdminBookingsContext.Provider>
  )
}

/**
 * Hook for components nested in ProvideAuth component to get the current auth object.
 */
export const useAdminBookingsContext = (): AdminBookingsContextProps => {
  const context = useContext(AdminBookingsContext)
  if (!context) {
    throw new Error(
      `useAdminBookingsContext must be used within a AdminBookingsProvider component`,
    )
  }
  return context
}
