import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'

import { timeOfDayToDayJs } from './day'

dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Singapore')
dayjs.extend(updateLocale)
dayjs.extend(customParseFormat)
dayjs.updateLocale('en', {
  weekStart: 1, // set start of week to monday
})

const DATE_STRING = 'YYYY-MM-DD'
const TIME_STRING = 'h:mm a'

export { default as dayjs } from 'dayjs'

export const formatDateDisplay = ({
  date,
  withTime = false,
  withDay = false,
}: {
  date: Date | string | number
  withTime?: boolean
  withDay?: boolean
}): string => {
  const format = `DD MMM YYYY${withTime ? `, ${TIME_STRING}` : ''}${
    withDay ? ', ddd' : ''
  }`
  return dayjs(date).format(format)
}

export const formatTimeDisplay = (date: Date | string): string => {
  return dayjs(date).format(TIME_STRING)
}

export const toDateString = (date: Date | string): string => {
  return dayjs(date).format(DATE_STRING)
}

// Monday of week
export const startOfWeekDateString = (date: Date): string => {
  return dayjs(date).startOf('week').format(DATE_STRING)
}

// Sunday of week
export const endOfWeekDateString = (date: Date): string => {
  return dayjs(date).endOf('week').format(DATE_STRING)
}

export const formatTimeOfDay = (time: number): string => {
  const day = timeOfDayToDayJs(time)
  return day.format(TIME_STRING)
}

export const formatISOStringFromDateTime = (
  date: string,
  time: number,
): string => {
  const timeStr = timeOfDayToDayJs(time)
  return `${date}T${timeStr.format('HH:mm')}:00+08:00`
}

export enum timeUnits {
  YEAR = 'year',
  MONTH = 'month',
}
export interface minAgeRule {
  value: number
  unit: timeUnits
}
/**
 * Check for minimum age
 */
export const isOfMinimumAge = (dob: string, minAge: minAgeRule): boolean => {
  const latestValidBirthday = dayjs(new Date()).subtract(
    minAge.value,
    minAge.unit,
  )
  return !dayjs.tz(dob).isAfter(latestValidBirthday, 'day')
}

/**
 * Check is valid birth date format (YYYY-MM-DD)
 */
export const isValidDob = (dob: string): boolean => {
  const dobObject = dayjs(dob, DATE_STRING, true)
  return dobObject.isValid() && dobObject.isBefore(new Date(), 'day')
}

/**
 * This is only used on numMinutes which are round multiples of 1440 (e.g. maxLeadTime).
 * We use minutes in backend representation, but days in frontend representation.
 */
export const minutesToDays = (numMinutes: number): number => {
  return Math.floor(numMinutes / 1440)
}

export const secondsToMinutes = (numSeconds: number): number => {
  return Math.floor(numSeconds / 60)
}

export const minutesToHours = (numMinutes: number): number => {
  return Math.floor(numMinutes / 60)
}
