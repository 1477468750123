export const GET_EVENT_KEY = 'get-event'

export const adminQueryKeys = {
  base: ['admin'],
  auth: () => [...adminQueryKeys.base, 'who-am-i'] as const,
  dashboard: () => [...adminQueryKeys.base, 'dashboard'] as const,
  events: ({ eventId }: { eventId?: string } = { eventId: undefined }) => {
    if (!eventId) return [...adminQueryKeys.base, 'event'] as const
    return [...adminQueryKeys.base, 'event', eventId] as const
  },
  eventLists: (
    { eventListId }: { eventListId?: string } = { eventListId: undefined },
  ) => {
    if (!eventListId) return [...adminQueryKeys.base, 'eventList'] as const
    return [...adminQueryKeys.base, 'eventList', eventListId] as const
  },
  bookings: ({ eventId }: { eventId?: string } = { eventId: undefined }) => {
    if (!eventId) return [...adminQueryKeys.base, 'booking'] as const
    return [...adminQueryKeys.base, 'booking', eventId] as const
  },
  schedules: (
    { scheduleId }: { scheduleId?: string } = { scheduleId: undefined },
  ) => {
    if (!scheduleId) return [...adminQueryKeys.base, 'booking'] as const
    return [...adminQueryKeys.base, 'schedule', scheduleId] as const
  },
}

export const publicQueryKeys = {
  base: ['public'],
  sgidCallback: ({ authCode }: { authCode: string }) =>
    [...publicQueryKeys.base, 'sgid-callback', authCode] as const,
  event: ({ eventId }: { eventId: string }) =>
    [...publicQueryKeys.base, 'events', eventId] as const,
  eventList: ({ eventListId }: { eventListId: string }) =>
    [...publicQueryKeys.base, 'event-lists', eventListId] as const,
  schedule: ({ scheduleId }: { scheduleId: string }) =>
    [...publicQueryKeys.base, 'schedules', scheduleId] as const,
  slots: ({
    scheduleId,
    startTime,
    endTime,
  }: {
    scheduleId: string
    startTime?: number
    endTime?: number
  }) => {
    if (!startTime && !endTime)
      return [...publicQueryKeys.schedule({ scheduleId }), 'slots'] as const

    return [
      ...publicQueryKeys.schedule({ scheduleId }),
      'slots',
      { startTime: startTime ?? null, endTime: endTime ?? null },
    ]
  },
  availability: ({ scheduleId }: { scheduleId: string }) => [
    ...publicQueryKeys.schedule({ scheduleId }),
    'availability',
  ],
  booking: ({ bookingId }: { bookingId?: string }) =>
    [...publicQueryKeys.base, 'bookings', bookingId] as const,
}
