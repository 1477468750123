import { Controller, FieldError } from 'react-hook-form'
import { FormControl, Stack } from '@chakra-ui/react'
import { FormErrorMessage, FormLabel } from '@opengovsg/design-system-react'

import { OneOffEventTile } from './OneOffEventTile'
import { RecurringEventTile } from './RecurringEventTile'

export interface EventTypeInputProps {
  error: FieldError | undefined
}

export const EventTypeInput = ({ error }: EventTypeInputProps) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel isRequired>{`What type of event is this?`}</FormLabel>
      <Controller
        name="isRecurring"
        render={({ field: { value, onChange } }) => {
          return (
            <Stack
              direction={{ base: 'column', md: 'row' }}
              justify={'center'}
              alignItems={'stretch'}
              w="full"
              spacing={6}
            >
              <RecurringEventTile
                isSelected={!!value}
                onClick={() => onChange(true)}
              />
              <OneOffEventTile
                isSelected={!value}
                onClick={() => onChange(false)}
              />
            </Stack>
          )
        }}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  )
}
