import { extendTheme } from '@chakra-ui/react'
import { theme as baseTheme } from '@opengovsg/design-system-react'

import { components } from './components'
import { foundations } from './foundations'
import { layerStyles } from './layerStyles'

/**
 * Design system themes can be found at
 * https://github.com/opengovsg/design-system/tree/main/token-gen/themes.
 * README for importing themes can be found at
 * https://github.com/opengovsg/design-system/tree/main/token-gen.
 */
export const theme = extendTheme(
  baseTheme,
  {
    styles: {
      global: {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          fontFeatureSettings: "'tnum' on, 'cv05' on",
        },
        '#root, #__next': {
          height: 'inherit',
        },
      },
    },
  },
  {
    ...foundations,
    layerStyles,
    components,
  },
)
