import { useMemo } from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { Button, ModalCloseButton } from '@opengovsg/design-system-react'

import { ButtonStack } from '~components/ButtonStack'

export type RemoveCollaboratorModalProps = {
  onDelete?: () => void
  isLoading?: boolean
  email: string
  isSelf?: boolean
  disclosure: UseDisclosureReturn
}

export const RemoveCollaboratorModal = ({
  onDelete,
  isLoading = false,
  email,
  isSelf,
  disclosure,
}: RemoveCollaboratorModalProps) => {
  const { title, description, buttonText } = useMemo(() => {
    if (isSelf) {
      return {
        title: 'Remove myself as collaborator',
        description: `You are removing yourself as a collaborator and will lose access to this event. This action cannot be undone.`,
        buttonText: 'Yes, remove myself',
      }
    }

    return {
      title: 'Remove collaborator',
      description: `${email} will lose all access to this event.`,
      buttonText: 'Yes, remove them',
    }
  }, [email, isSelf])

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Text>{description}</Text>
        </ModalBody>
        <ModalFooter>
          <ButtonStack isReverseOrderOnMobile>
            <Button onClick={disclosure.onClose} variant="clear">
              Cancel
            </Button>
            <Button
              onClick={onDelete}
              colorScheme="critical"
              isLoading={isLoading}
            >
              {buttonText}
            </Button>
          </ButtonStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
