import { Text } from '@chakra-ui/react'

import { formatDateDisplay } from '~utils/date'

import {
  formStateSelector,
  previousBookingSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { FormState } from '~features/form/types/formState'

export const EventFormPreviousBookingLabel = () => {
  const formState = useFormStore(formStateSelector)
  const previousBooking = useFormStore(previousBookingSelector)

  if (!previousBooking) {
    return null
  }

  switch (formState) {
    case FormState.PickDate:
    case FormState.PickTime:
      return (
        <Text textStyle={{ base: 'subhead-2', md: 'subhead-1' }} w="full">
          {`Previously booked: ${formatDateDisplay({
            date: previousBooking.startAt,
            withTime: true,
          })}`}
        </Text>
      )
  }
  return null
}
