import { useCallback } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { VStack } from '@chakra-ui/react'
import { useToast } from '@opengovsg/design-system-react'

import { UpdateEventListReq } from '~shared/dto'

import { isNestError } from '~lib/api'
import { SaveChangesButton } from '~components/SaveChangesButton'

import { useUpdateEventList } from '~features/event-lists/hooks/useAdminEventLists'

import {
  EventListDescriptionInput,
  EventListFormFields,
  EventListNameInput,
} from '../EventListForm'
import {
  eventListFormFieldsToReqBody,
  getEventListFormFields,
} from '../EventListForm/utils'

export type EditEventListFormProps = {
  eventListId: string
}

export const EditEventListForm = ({ eventListId }: EditEventListFormProps) => {
  const { mutate, isLoading } = useUpdateEventList(eventListId)
  const { handleSubmit, reset } = useFormContext<EventListFormFields>()
  const { isDirty } = useFormState<EventListFormFields>()
  const toast = useToast()

  const onSubmit = useCallback(
    async (fields: EventListFormFields) => {
      const updateEventListReq: UpdateEventListReq =
        eventListFormFieldsToReqBody(fields)
      mutate(updateEventListReq, {
        onSuccess: (eventList) => {
          reset(getEventListFormFields(eventList))
          toast({
            description: 'Event list updated successfully.',
            status: 'success',
          })
        },
      })
    },
    [mutate, reset, toast],
  )

  return (
    <VStack spacing={6} align="stretch">
      <EventListNameInput />
      <EventListDescriptionInput />
      <SaveChangesButton
        isDirty={isDirty}
        isLoading={isLoading}
        onClick={handleSubmit(onSubmit)}
      />
    </VStack>
  )
}
