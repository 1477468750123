import { useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { LoadingState } from '~components/LoadingState'

import { useAdminSgidLogin } from './hooks'

export const SgidAdminCallbackPage = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { mutate: fireLoginMutation } = useAdminSgidLogin()

  const authCode = useMemo(() => searchParams.get('code'), [searchParams])

  // If auth code is present, log the user in
  useEffect(() => {
    if (authCode === null) {
      navigate('/admin/ogp-login')
      return
    }
    fireLoginMutation(
      { code: authCode },
      {
        onSuccess: () => navigate('/admin/dashboard'),
        // Note that in dev environment, you will see an error toast
        // even if login succeeds, because Strict Mode causes this effect
        // to run twice
        onError: () => navigate('/admin/login'),
      },
    )
  }, [authCode, fireLoginMutation, navigate])

  // This is always a transition page
  return <LoadingState flex={1} />
}
