import { BiDownArrowAlt, BiPencil, BiTrash, BiUpArrowAlt } from 'react-icons/bi'
import { Flex, SimpleGrid } from '@chakra-ui/react'
import { IconButton } from '@opengovsg/design-system-react'

import { useIsClientMobile } from '~hooks/useIsClientMobile'

export interface FieldRowActionsProps {
  onClickEdit: () => void
  onClickDelete: () => void
  onMoveFieldUp: () => void
  onMoveFieldDown: () => void
}

export const FieldRowActions = ({
  onClickEdit,
  onClickDelete,
  onMoveFieldUp,
  onMoveFieldDown,
}: FieldRowActionsProps) => {
  const isMobile = useIsClientMobile()
  return (
    // Force buttons and title to align
    <SimpleGrid columns={isMobile ? 2 : 4} mt="-0.625rem" flexShrink={0}>
      <IconButton
        aria-label="Move up"
        variant="clear"
        icon={<BiUpArrowAlt />}
        onClick={onMoveFieldUp}
        alignSelf="flex-start"
      />
      <IconButton
        aria-label="Move down"
        variant="clear"
        icon={<BiDownArrowAlt />}
        onClick={onMoveFieldDown}
        alignSelf="flex-start"
      />
      <IconButton
        aria-label="Edit"
        variant="clear"
        icon={<BiPencil />}
        onClick={onClickEdit}
        alignSelf="flex-start"
      />
      <IconButton
        aria-label="Delete"
        variant="clear"
        colorScheme={'red'}
        icon={<BiTrash />}
        onClick={onClickDelete}
        alignSelf="flex-start"
      />
    </SimpleGrid>
  )
}
