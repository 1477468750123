import { useCallback, useId } from 'react'
import { Grid, useRadioGroup } from '@chakra-ui/react'

import { TimeslotRes } from '~shared/dto'
import { Timeslot } from '~shared/types'

import { SlotTimeOption } from './SlotTimeOption'

export interface SlotTimeListProps {
  slots: TimeslotRes[]
  onChange: (slot: Timeslot) => void
  defaultValue: Timeslot | null
}

const timeslotToValue = (timeslot: Timeslot) => {
  const { startAt, endAt } = timeslot
  return JSON.stringify({ startAt, endAt })
}

const valueToTimeslot = (value: string) => {
  return JSON.parse(value) as Timeslot
}

export const SlotTimeList = ({
  slots,
  onChange,
  defaultValue,
}: SlotTimeListProps) => {
  const handleChange = useCallback(
    (slotString: string) => {
      onChange(valueToTimeslot(slotString))
    },
    [onChange],
  )
  const radioGroupId = useId()
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: `SlotTimeListRadio-${radioGroupId}`,
    onChange: handleChange,
    defaultValue: defaultValue ? JSON.stringify(defaultValue) : undefined,
  })

  return (
    <Grid
      flex={1}
      {...getRootProps()}
      templateColumns="repeat(2, 1fr)"
      alignItems="stretch"
      columnGap={3}
      rowGap={4}
      overflowY="auto"
    >
      {slots.map((slot, idx) => (
        <SlotTimeOption
          key={idx}
          slot={slot}
          {...getRadioProps({
            value: timeslotToValue(slot),
          })}
        />
      ))}
    </Grid>
  )
}
