import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import { FormErrorMessage, Input } from '@opengovsg/design-system-react'

import { UpdateNricFieldReq } from '~shared/dto'
import { isNricFinValid } from '~shared/utils/validation'

import { FieldLabel } from './FieldLabel'

export const NricFieldView = ({
  id,
  title,
  description,
}: UpdateNricFieldReq) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<Record<string, string>>()

  const nricValidationRules = useMemo(
    () => ({
      required: `This field is required`,
      validate: {
        validNric: (val?: string) => {
          if (!val) return true
          return isNricFinValid(val) || 'Please enter a valid NRIC or FIN'
        },
      },
    }),
    [],
  )

  return (
    <FormControl isRequired isInvalid={!!errors[id]?.message}>
      <FieldLabel title={title} description={description} />
      <Input
        w="100%"
        id={id}
        errorBorderColor="red.300"
        defaultValue=""
        placeholder="S1234567D"
        {...register(id, {
          ...nricValidationRules,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
            setValue(id, event.target.value.trim().toUpperCase()),
        })}
      />
      <FormErrorMessage>{errors[id]?.message}</FormErrorMessage>
    </FormControl>
  )
}
