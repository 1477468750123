import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { AdminDashboardRes } from '~shared/dto'

import { api } from '~lib/api'
import { adminQueryKeys } from '~constants/queryKeys'

type UseGetAdminDashboard = {
  userId?: string
} & UseQueryOptions<
  AdminDashboardRes,
  unknown,
  AdminDashboardRes,
  ReturnType<typeof adminQueryKeys.dashboard>
>

export const useGetAdminDashboard = ({
  userId,
  ...useQueryOptions
}: UseGetAdminDashboard) => {
  return useQuery(
    adminQueryKeys.dashboard(),
    () => api.get(`/admin/dashboard`).json<AdminDashboardRes>(),
    {
      enabled: !!userId,
      ...useQueryOptions,
    },
  )
}
