import { memo } from 'react'
import { Box, HStack, Text } from '@chakra-ui/react'

import { TimePicker } from '~/components/TimePicker'

type ScheduleTimeFieldProps = {
  // Temporarily using type which excludes capacity until the capacity feature is more firmed up
  value: { start: number; end: number }
  onChange: (timeslot: { start: number; end: number }) => void
  isError: boolean
  isDisabled?: boolean
  shouldShowClockIcon?: boolean
}

// eslint-disable-next-line react/display-name
export const ScheduleTimeRangeField = memo(
  ({
    value,
    onChange,
    isError,
    isDisabled,
    shouldShowClockIcon = true,
  }: ScheduleTimeFieldProps) => {
    return (
      <HStack w="full">
        <Box
          minW={{ base: '110px', lg: '140px' }}
          maxW={{ base: 'auto', lg: '140px' }}
          flexGrow={1}
          flexShrink={0}
        >
          <TimePicker
            borderColor={isError ? 'red.500' : undefined}
            isRequired={true}
            isDisabled={isDisabled}
            w="full"
            onChange={(millis: number) => {
              onChange({
                ...value,
                start: millis,
              })
            }}
            value={value.start}
            shouldShowClockIcon={shouldShowClockIcon}
          />
        </Box>
        <Text textStyle="subhead-1" color="secondary.700" textAlign="center">
          {`-`}
        </Text>
        <Box
          minW={{ base: '110px', lg: '140px' }}
          maxW={{ base: 'auto', lg: '140px' }}
          flexGrow={1}
          flexShrink={0}
        >
          <TimePicker
            borderColor={isError ? 'red.500' : undefined}
            isRequired={true}
            isDisabled={isDisabled}
            w="full"
            onChange={(millis: number) => {
              onChange({
                ...value,
                end: millis,
              })
            }}
            value={value.end}
            shouldShowClockIcon={shouldShowClockIcon}
          />
        </Box>
      </HStack>
    )
  },
)
