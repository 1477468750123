import { Box, HStack } from '@chakra-ui/react'
import { MenuItem } from '@chakra-ui/react'

import { AvatarMenu } from '~/components/AvatarMenu'
import { Logo } from '~/components/Logo'

import { useAdminAuth } from '~features/auth'

export const AdminPageHeader = () => {
  const { adminUser, logout } = useAdminAuth()

  return (
    <HStack
      px="admin-app-px"
      h="admin-header-height"
      justify="space-between"
      alignItems="center"
      bgColor="standard.white"
      borderBottomWidth="1px"
      borderStyle="solid"
      borderColor="neutral.300"
    >
      <HStack px="2rem" alignItems="center" h="inherit" spacing="36px">
        <Box h={12}>
          <Logo />
        </Box>
      </HStack>
      <HStack spacing="4">
        <AvatarMenu userName={adminUser?.email || ''}>
          <MenuItem onClick={logout}>Log out</MenuItem>
        </AvatarMenu>
      </HStack>
    </HStack>
  )
}
