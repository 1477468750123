import { Flex } from '@chakra-ui/react'

import { GetBookingRes } from '~shared/dto'

import { useAdminBookingsContext } from '../AdminBookingsContext'

import { BookingRow } from './BookingRow'
import { TimeslotSummary } from './TimeslotSummary'

interface BookingTimeslotProps {
  startAt: number
  endAt: number
  responses: GetBookingRes[]
  capacity: number
}

/** Used in admin view, in a list view of bookings that have been made. */
export const BookingTimeslot = ({
  startAt,
  endAt,
  responses,
  capacity,
}: BookingTimeslotProps) => {
  const { drawerDisclosure, setCurrBooking, deleteModalDisclosure } =
    useAdminBookingsContext()

  return (
    <Flex alignItems="stretch" direction={{ base: 'column', md: 'row' }}>
      <TimeslotSummary
        startAt={startAt}
        endAt={endAt}
        numSlotsRemaining={capacity - responses.length}
        p="1rem"
        alignItems="start"
        flexShrink={0}
        bg="base.canvas.brandSubtle"
        borderColor="base.divider.medium"
        borderWidth="1px"
        borderRadius={{ base: '4px 4px 0 0', md: '4px 0 0 4px' }}
      />
      <Flex gap={0} direction="column" flexGrow={1} flexShrink={1}>
        {responses.map((booking) => (
          <BookingRow
            key={booking.id}
            email={booking.email}
            onClickPrimary={() => {
              setCurrBooking(booking)
              drawerDisclosure.onOpen()
            }}
            onClickDelete={() => {
              setCurrBooking(booking)
              deleteModalDisclosure.onOpen()
            }}
          />
        ))}
      </Flex>
    </Flex>
  )
}
