import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { RestrictedFooter } from '@opengovsg/design-system-react'

import LoginImageSvg from '~/assets/img/login-image.svg'

import { isNestError } from '~lib/api'
import { APP_NAME } from '~constants/config'
import { useIsDesktop } from '~hooks/useIsDesktop'
import { Logo } from '~components/Logo'

import { AdminBanner } from '~features/banner'

import { LoginForm, LoginFormInputs } from './components/LoginForm'
import {
  BackgroundBox,
  BaseGridLayout,
  FooterGridArea,
  LoginGridArea,
  NonMobileSidebarGridArea,
} from './components/LoginPageGrid'
import { OtpForm, OtpFormInputs } from './components/OtpForm'
import { useAdminAuth } from './AdminAuthContext'
import { useAdminSendLoginOtp, useAdminVerifyLoginOtp } from './hooks'

export const LoginPage = (): JSX.Element => {
  const { verifyLoginOtp } = useAdminVerifyLoginOtp()
  const { sendLoginOtp } = useAdminSendLoginOtp()
  const navigate = useNavigate()
  const isDesktop = useIsDesktop()
  const { adminUser } = useAdminAuth()

  const [email, setEmail] = useState<string>()
  const [isVerifyDisabled, setIsVerifyDisabled] = useState(false)

  const handleSendOtp = async ({ email }: LoginFormInputs) => {
    const trimmedEmail = email.trim()
    await sendLoginOtp({ email: trimmedEmail })
    return setEmail(trimmedEmail)
  }

  const handleVerifyOtp = async ({ token }: OtpFormInputs) => {
    // Should not happen, since OtpForm component is only shown when there is
    // already an email state set.
    if (!email) {
      throw new Error('Something went wrong')
    }
    return verifyLoginOtp(
      { token, email },
      {
        onSuccess: () => navigate('/admin/dashboard'),
        onError: (error) => {
          if (isNestError(error) && error.status === 410) {
            setIsVerifyDisabled(true)
          }
        },
      },
    )
  }

  const handleResendOtp = async () => {
    // Should not happen, since OtpForm component is only shown when there is
    // already an email state set.
    if (!email) {
      throw new Error('Something went wrong')
    }
    setIsVerifyDisabled(false)
    await sendLoginOtp({ email })
  }

  if (adminUser) {
    return <Navigate to="/admin/dashboard" />
  }

  return (
    <BackgroundBox>
      <AdminBanner />
      <BaseGridLayout flex={1}>
        <NonMobileSidebarGridArea>
          <Image src={LoginImageSvg} />
        </NonMobileSidebarGridArea>
        <LoginGridArea>
          <Box minH={{ base: 'auto', lg: '17.25rem' }} w="100%">
            <Flex mb={{ base: '2.5rem', lg: 0 }} flexDir="column">
              <Text
                display={{ base: 'none', lg: 'initial' }}
                textStyle="responsive-heading.heavy-1280"
                mb="2.5rem"
              >
                Easy appointment scheduling for Government
              </Text>
              <Box display={{ base: 'initial', lg: 'none' }}>
                <Box mb={{ base: '0.75rem', lg: '1.5rem' }}>
                  <Logo h="2rem" />
                </Box>
                <Text
                  textStyle={{
                    base: 'responsive-heading.heavy',
                    md: 'responsive-heading.heavy-480',
                    lg: 'responsive-heading.heavy-1280',
                  }}
                >
                  Easy appointment scheduling for Government
                </Text>
              </Box>
            </Flex>
            {!email ? (
              <LoginForm onSubmit={handleSendOtp} />
            ) : (
              <OtpForm
                email={email}
                onSubmit={async (values) => {
                  await handleVerifyOtp(values)
                }}
                onResendOtp={handleResendOtp}
                isVerifyDisabled={isVerifyDisabled}
              />
            )}
          </Box>
        </LoginGridArea>
      </BaseGridLayout>
      <BaseGridLayout
        bg={{ base: 'base.canvas.brandLight', lg: 'transparent' }}
      >
        <FooterGridArea>
          <RestrictedFooter
            appLink={window.location.origin}
            appName={APP_NAME.full}
            containerProps={{
              px: 0,
            }}
            variant={{ lg: 'compact' }}
            colorMode={isDesktop ? 'dark' : 'light'}
          />
        </FooterGridArea>
      </BaseGridLayout>
    </BackgroundBox>
  )
}
