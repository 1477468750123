import { Stack, StackProps } from '@chakra-ui/react'

export const ButtonStack = ({
  isReverseOrderOnMobile,
  children,
  ...props
}: StackProps & { isReverseOrderOnMobile?: boolean }) => {
  return (
    <Stack
      direction={{
        base: isReverseOrderOnMobile ? 'column-reverse' : 'column',
        md: 'row',
      }}
      w={{ base: 'full', md: 'fit-content' }}
      spacing={2}
      {...props}
    >
      {children}
    </Stack>
  )
}
