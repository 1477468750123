import { FormProvider, useForm } from 'react-hook-form'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react'
import { Button, ModalCloseButton } from '@opengovsg/design-system-react'

import { ButtonStack } from '~components/ButtonStack'

import { EventNameInput } from './EventNameInput'
import { EventTypeInput } from './EventTypeInput'

export interface CreateEventFormFields {
  eventName: string
  isRecurring: boolean
}

export interface CreateEventModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (fields: CreateEventFormFields) => void
}

export const CreateEventModal = ({
  isOpen,
  onClose,
  onSubmit: onModalSubmit,
}: CreateEventModalProps) => {
  const formMethods = useForm<CreateEventFormFields>({
    defaultValues: {
      eventName: '',
      isRecurring: true,
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods

  const handleModalClose = () => {
    onClose()
    reset()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent my={{ base: 0, md: 8 }}>
        <ModalCloseButton />
        <ModalHeader>Create new event</ModalHeader>
        <ModalBody>
          <FormProvider {...formMethods}>
            <VStack spacing={6} alignItems="stretch">
              <EventNameInput error={errors.eventName} />
              <EventTypeInput error={errors.isRecurring} />
            </VStack>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <ButtonStack isReverseOrderOnMobile>
            <Button
              variant="clear"
              colorScheme="secondary"
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button type="submit" onClick={handleSubmit(onModalSubmit)}>
              Create event
            </Button>
          </ButtonStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
