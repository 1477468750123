import { useCallback } from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { useLocation } from 'react-router-dom'
import { Button, ButtonProps } from '@opengovsg/design-system-react'

import { GetPublicEventRes } from '~shared/dto'
import { EventAuthType } from '~shared/types'

import { MAKE_BOOKING_URL_SESSION_KEY } from '~features/form/constants'
import {
  identitySelector,
  previousBookingSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { usePublicSgidAuthUrl } from '~features/form/hooks/useSgidLogin'

export type SgidLoginButtonProps = {
  event: GetPublicEventRes
  action?: string
} & ButtonProps

export const SgidLoginButton = ({
  event,
  action,
  children,
  ...props
}: SgidLoginButtonProps) => {
  const previousBooking = useFormStore(previousBookingSelector)
  const {
    mutateAsync: getLoginUrl,
    isLoading: isLoginLoading,
    isSuccess: isLoginRedirecting,
  } = usePublicSgidAuthUrl()

  const location = useLocation()

  const redirectToSgid = useCallback(async () => {
    const { url } = await getLoginUrl({
      eventId: event.id,
      scheduleId: event.schedules[0].id,
      bookingId: previousBooking?.id ?? undefined,
      action,
    })
    // We also store the event id in session storage
    sessionStorage.setItem(
      MAKE_BOOKING_URL_SESSION_KEY,
      `${location.pathname}${location.search}`,
    )

    window.location.assign(url)
  }, [
    getLoginUrl,
    event.id,
    event.schedules,
    previousBooking?.id,
    action,
    location.pathname,
    location.search,
  ])

  return (
    <Button
      rightIcon={<BiRightArrowAlt fontSize="1.5rem" />}
      w="full"
      h={{ base: '56px', md: 'auto' }}
      {...props}
      isLoading={isLoginLoading || isLoginRedirecting}
      isDisabled={isLoginLoading || isLoginRedirecting}
      onClick={() => void redirectToSgid()}
    >
      {children}
    </Button>
  )
}
