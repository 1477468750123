import { CreateFieldReq, UpdateFieldReq } from '~shared/dto'
import { FormFieldType } from '~shared/types'

import { FORM_FIELD_DISPLAY_DATA } from '../fieldDisplayData'

import { FieldRow, FieldRowProps } from './FieldRow'

export const createFieldRow = (
  field: CreateFieldReq | UpdateFieldReq,
  actions?: FieldRowProps['actions'],
) => {
  switch (field.fieldType) {
    case FormFieldType.ShortText:
    case FormFieldType.Mobile:
    case FormFieldType.Nric:
      return (
        <FieldRow
          fieldDisplayData={FORM_FIELD_DISPLAY_DATA[field.fieldType]}
          title={field.title}
          description={field.description}
          actions={actions}
          key={field.id}
        />
      )
    case FormFieldType.Radio:
    case FormFieldType.Dropdown:
      return (
        <FieldRow
          fieldDisplayData={FORM_FIELD_DISPLAY_DATA[field.fieldType]}
          title={field.title}
          description={field.description}
          options={field.fieldOptions}
          actions={actions}
          key={field.id}
        />
      )
    default:
      return null
  }
}
