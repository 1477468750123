import { ChangeEvent, useMemo } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'
import {
  Box,
  Flex,
  FormControl,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  BxsHelpCircle,
  Checkbox,
  FormErrorMessage,
  NumberInput,
  SingleSelect,
  TouchableTooltip,
  useToast,
} from '@opengovsg/design-system-react'

import { DISPLAY_TIME_UNIT_DAYS, DISPLAY_TIME_UNIT_HOURS } from '~shared/utils'

import { SaveChangesButton } from '~components/SaveChangesButton'

import { AdminEventOutletContext } from '~features/events/types'
import { useUpdateSchedule } from '~features/schedules/hooks/useAdminSchedules'

import { SyncToDirtyFieldStore } from '../SyncToDirtyFieldStore'

import { ReminderSettingsFormFields } from './types'
import {
  getReminderSettingsFields,
  reminderSettingsFieldsToScheduleReqBody,
  validateReminderLeadTimeMinutes,
} from './utils'

export const ReminderSettings = () => {
  const { event } = useOutletContext<AdminEventOutletContext>()
  const schedule = useMemo(() => event.schedules[0], [event.schedules])
  const toast = useToast()
  const formMethods = useForm<ReminderSettingsFormFields>({
    mode: 'onChange',
    defaultValues: getReminderSettingsFields({ schedule }),
  })
  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    control,
    register,
    setValue,
    clearErrors,
  } = formMethods
  // useWatch ensures that this component rerenders when isReminderLeadTimeEnabled
  // changes, else disabled and error states go out of sync
  const isReminderLeadTimeEnabled = useWatch<ReminderSettingsFormFields>({
    control,
    name: 'isReminderLeadTimeEnabled',
  }) as boolean

  const { mutate: mutateSchedule, isLoading: isMutateScheduleLoading } =
    useUpdateSchedule({
      eventId: event.id,
      scheduleId: schedule.id,
    })

  const onSubmit = handleSubmit((data: ReminderSettingsFormFields) =>
    mutateSchedule(reminderSettingsFieldsToScheduleReqBody(data), {
      onSuccess: (updatedSchedule) => {
        toast({
          description: 'Settings updated successfully.',
          status: 'success',
        })
        reset(
          getReminderSettingsFields({
            schedule: updatedSchedule,
          }),
        )
      },
    }),
  )

  return (
    <VStack spacing="1.5rem" alignItems="stretch" w="full">
      <Text textStyle="h4">Manage reminders</Text>
      <FormProvider {...formMethods}>
        <SyncToDirtyFieldStore />
        <VStack w="full" alignItems={'stretch'} spacing="0.5rem">
          <HStack>
            <Checkbox
              size="sm"
              {...register('isReminderLeadTimeEnabled', {
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                  if (!event.target.checked) {
                    setValue('reminderLeadTimeQuantity', null)
                    clearErrors('reminderLeadTimeQuantity')
                  }
                },
              })}
            >
              <VStack spacing={1} alignItems="stretch">
                <HStack>
                  <Text textStyle="subhead-2">
                    Send attendees a reminder email before their scheduled
                    booking
                  </Text>
                  <TouchableTooltip
                    label="e.g. For a booking at 9am on 10/1 with reminders sent 4 hours before the booking, an email will be sent at 5am. No reminder will be sent if the booking is made from 10/1 5am - 9am."
                    placement={'top'}
                  >
                    {/* We use display block to prevent the icon from moving itself upwards and getting misaligned */}
                    {/* The click handler prevents activating the default behaviour of toggling the checkbox */}
                    <Icon
                      onClick={(evt) => evt.preventDefault()}
                      as={BxsHelpCircle}
                      fontSize="1rem"
                      display="block"
                      aria-hidden
                    />
                  </TouchableTooltip>
                </HStack>
                <Text textStyle="body-2">
                  Pending reminders will not be rescheduled. No reminders will
                  be sent if the the booking is made after the scheduled
                  reminder time.
                </Text>
              </VStack>
            </Checkbox>
          </HStack>
          <VStack w="full" alignItems={'stretch'} spacing="0.25rem">
            <Text
              textStyle={'body-2'}
              as="span"
              pl="36px"
              pb={2}
              color={
                isReminderLeadTimeEnabled
                  ? undefined
                  : 'interaction.support.disabled-content'
              }
            >
              Reminder emails will be sent...
            </Text>
            <FormControl isInvalid={!!errors.reminderLeadTimeQuantity}>
              <Flex flexWrap={'wrap'} alignItems="center" gap="4px" ml="36px">
                <Controller
                  name="reminderLeadTimeQuantity"
                  rules={{
                    validate: validateReminderLeadTimeMinutes,
                  }}
                  render={({ field: { onChange, ref, value } }) => (
                    <NumberInput
                      ref={ref}
                      value={(value ?? '') as string}
                      onChange={(_valueAsString, valueAsNumber) =>
                        onChange(isNaN(valueAsNumber) ? null : valueAsNumber)
                      }
                      showSteppers={false}
                      width="20%"
                      minW={{ base: '100%', md: '80px' }}
                      maxW={{ md: '10ch' }}
                      display="inline-block"
                      fontSize={'14px'}
                      isDisabled={!isReminderLeadTimeEnabled}
                      size="sm"
                    />
                  )}
                />
                <Controller
                  name="reminderLeadTimeUnit"
                  render={({ field: { onChange, ref, value } }) => (
                    // Force select box to be smaller, but big enough to accommodate all characters
                    <>
                      <Box
                        maxW={{ md: '15ch' }}
                        minW={{ base: '100%', md: 'unset' }}
                        display="inline-block"
                      >
                        <SingleSelect
                          ref={ref}
                          name="reminderLeadTimeUnit"
                          isClearable={false}
                          value={(value ?? '') as string}
                          onChange={onChange}
                          items={[
                            DISPLAY_TIME_UNIT_HOURS,
                            DISPLAY_TIME_UNIT_DAYS,
                          ]}
                          isDisabled={!isReminderLeadTimeEnabled}
                          size="sm"
                        />
                      </Box>
                      <Text
                        textStyle={'body-2'}
                        as="span"
                        color={
                          isReminderLeadTimeEnabled
                            ? undefined
                            : 'interaction.support.disabled-content'
                        }
                      >
                        before the appointment start time
                      </Text>
                    </>
                  )}
                />
              </Flex>
              <FormErrorMessage ml="36px">
                {errors.reminderLeadTimeQuantity?.message}
              </FormErrorMessage>
            </FormControl>
          </VStack>
        </VStack>
        <SaveChangesButton
          isDirty={isDirty}
          isLoading={isMutateScheduleLoading}
          onClick={onSubmit}
          w="full"
        />
      </FormProvider>
    </VStack>
  )
}
