import { useMemo, useState } from 'react'
import { BiChevronDown, BiChevronUp, BiUser } from 'react-icons/bi'
import {
  Avatar,
  ButtonProps,
  HStack,
  Icon,
  // Design system menu breaks hover state
  // eslint-disable-next-line no-restricted-imports
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemProps,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

interface AvatarMenuButtonProps extends ButtonProps {
  isOpen: boolean
}

export const AvatarMenuButton = ({
  isOpen,
  ...props
}: AvatarMenuButtonProps) => {
  const icon = useMemo(
    () =>
      isOpen ? <BiChevronUp size="24px" /> : <BiChevronDown size="24px" />,
    [isOpen],
  )
  return (
    <MenuButton
      as={Button}
      colorScheme="primary"
      variant="clear"
      rightIcon={icon}
      justifyContent="space-between"
      iconSpacing={4}
      textAlign="left"
      {...props}
    />
  )
}

export const AvatarMenuUsername = (props: MenuItemProps) => {
  return (
    <HStack py={2} px={4}>
      <Icon as={BiUser} w={4} h={4} mr={4} />
      <Text textStyle="body">{props.children}</Text>
    </HStack>
  )
}

export interface AvatarMenuProps {
  userName?: string
  children?: JSX.Element
}

export const AvatarMenu = ({ userName, children }: AvatarMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Menu
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <AvatarMenuButton role="group" isOpen={isOpen} isActive={isOpen}>
        <Avatar size="xs" name={userName} />
      </AvatarMenuButton>
      {/* To ensure menu shows above all other components */}
      <MenuList zIndex="100">
        <AvatarMenuUsername>{userName}</AvatarMenuUsername>
        <MenuDivider />
        {children}
      </MenuList>
    </Menu>
  )
}
