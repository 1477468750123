import { Controller, useFormContext } from 'react-hook-form'
import { Flex, FormControl, Text } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  NumberInput,
} from '@opengovsg/design-system-react'

import { SLOT_CAPACITY_MAXIMUM, SLOT_CAPACITY_MINIMUM } from '~shared/constants'
import { numberWithCommas } from '~shared/utils'

import { BookingRulesFields } from '../types/field-info'

export const SlotCapacityInput = () => {
  const {
    formState: { errors },
  } = useFormContext<BookingRulesFields>()
  return (
    <FormControl isInvalid={!!errors.slotCapacity}>
      <FormLabel isRequired>
        <Text textStyle={'subhead-2'}>
          How many concurrent bookings allowed per time slot?
        </Text>
      </FormLabel>
      <Flex>
        <Controller
          name="slotCapacity"
          rules={{
            required: 'This field is required',
            min: {
              value: SLOT_CAPACITY_MINIMUM,
              message: `Number of attendees must be at least ${SLOT_CAPACITY_MINIMUM}`,
            },
            max: {
              value: SLOT_CAPACITY_MAXIMUM,
              message: `Number of attendees can be at most ${numberWithCommas(
                SLOT_CAPACITY_MAXIMUM,
              )}`,
            },
          }}
          render={({ field: { onChange, ref, value } }) => (
            <NumberInput
              ref={ref}
              value={(value ?? '') as string}
              onChange={(_valueAsString, valueAsNumber) =>
                onChange(isNaN(valueAsNumber) ? null : valueAsNumber)
              }
              showSteppers={false}
              width="20%"
              minW="80px"
              size="sm"
            />
          )}
        />
        <Text textStyle={'body-2'} alignSelf="center" ml="1rem">
          booking(s)
        </Text>
      </Flex>
      <FormErrorMessage>{errors.slotCapacity?.message}</FormErrorMessage>
    </FormControl>
  )
}
