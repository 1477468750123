import { useCallback } from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import {
  Button,
  ModalCloseButton,
  useToast,
} from '@opengovsg/design-system-react'

import { ButtonStack } from '~components/ButtonStack'

import { useDeleteEvent } from '~features/events/hooks/useAdminEvents'

export interface DeleteEventModalProps {
  eventTitle: string
  eventId: string
  eventListId?: string
  isOpen: boolean
  onClose: () => void
}

export const DeleteEventModal = ({
  eventTitle,
  eventId,
  eventListId,
  isOpen,
  onClose,
}: DeleteEventModalProps) => {
  const { mutateAsync: deleteEvent, isLoading } = useDeleteEvent({
    eventListId,
  })
  const toast = useToast()

  const handleDeleteEvent = useCallback(async () => {
    await deleteEvent(eventId, {
      onSuccess: () => {
        toast({
          description: 'Event deleted successfully.',
          status: 'success',
        })
        onClose()
      },
      onError: () => {
        toast({
          description: 'Failed to delete event.',
          status: 'error',
        })
      },
    })
  }, [deleteEvent, eventId, onClose, toast])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Delete event</ModalHeader>
        <ModalBody>
          <Text>
            Your event and all its booking records will be permanently lost,
            including any pending bookings. CalSG will not notify any attendees
            that the event is deleted.
          </Text>
          <Text mt={2}>Are you sure you want to delete this event?</Text>
          <Text fontWeight="medium" mt={4}>
            {eventTitle}
          </Text>
        </ModalBody>
        <ModalFooter>
          <ButtonStack isReverseOrderOnMobile>
            <Button variant="clear" colorScheme="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="button"
              colorScheme="critical"
              onClick={handleDeleteEvent}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Yes, delete event
            </Button>
          </ButtonStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
