import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { LoadingState } from '~components/LoadingState'

import { useAdminAuth } from './AdminAuthContext'

export const AdminProtectedRoute = ({
  children,
}: {
  children: JSX.Element[]
}) => {
  const navigate = useNavigate()
  const { adminUser, isLoadingAdminUser } = useAdminAuth()
  const [authorized, setAuthorized] = useState<boolean>(false)

  useEffect(() => {
    // Not logged in
    if (isLoadingAdminUser) return
    if (!adminUser) {
      navigate('/admin/login')
      return
    }
    setAuthorized(true)
  }, [navigate, adminUser, isLoadingAdminUser])

  return <>{authorized ? children : <LoadingState flex={1} />}</>
}
