import { Divider, HStack, Spacer, Text, VStack } from '@chakra-ui/react'

import {
  identitySelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'

import { SgidLogoutButton } from '../SgidButtons'

import { EventFormBackButton } from './EventFormBackButton'
import { EventFormPreviousBookingLabel } from './EventFormPreviousBookingLabel'
import { EventFormSubtitle } from './EventFormSubtitle'

export type EventFormTitleProps = {
  title: string
}

export const EventFormTitleBar = ({ title }: EventFormTitleProps) => {
  const identity = useFormStore(identitySelector)
  return (
    <>
      <HStack w="full" alignItems="stretch" mb="0.25rem">
        <EventFormBackButton />
        {identity && (
          <>
            <Spacer />
            <SgidLogoutButton identity={identity} />
          </>
        )}
      </HStack>
      <VStack
        pt={6}
        spacing={0}
        w="full"
        position={{ base: 'sticky', md: 'relative' }}
        top={0}
        zIndex="docked"
        bg="utility.ui"
      >
        <Text
          textStyle={{ base: 'h6', md: 'h4' }}
          mb={{ base: 1, md: 2 }}
          w="full"
        >
          {title}
        </Text>
        <EventFormPreviousBookingLabel />
        <EventFormSubtitle />
        <Divider pt={{ base: 4, md: 6 }} />
      </VStack>
    </>
  )
}
