import { Text, VStack } from '@chakra-ui/react'

import { GetPublicEventRes } from '~shared/dto'
import { EventAuthType } from '~shared/types'

import {
  identitySelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { FormState } from '~features/form/types/formState'

import { SgidLoginButton } from './SgidLoginButton'
import { SgidLogoutButton } from './SgidLogoutButton'

export type SgidAuthButtonGroupProps = {
  event: GetPublicEventRes
  loggedOutButtonText: string
  loggedOutDescriptionText: string
  logoutRedirectToState?: FormState
  action?: string
  children?: React.ReactNode
}

/**
 * If the user is logged in, renders the children of this component.
 * Otherwise, it renders a button with loggedOutButtonText
 */
export const SgidAuthButtonGroup = ({
  event,
  loggedOutButtonText,
  loggedOutDescriptionText,
  logoutRedirectToState,
  children,
  action,
  ...props
}: SgidAuthButtonGroupProps) => {
  const identity = useFormStore(identitySelector)
  const needToLogin =
    event.auth.type === EventAuthType.SgidWithNric && !identity

  return (
    <VStack w="full" pt={6} alignItems="center" spacing={4}>
      {needToLogin ? (
        <SgidLoginButton event={event} action={action} {...props}>
          {loggedOutButtonText}
        </SgidLoginButton>
      ) : (
        <>{children}</>
      )}
      {event.auth.type === EventAuthType.SgidWithNric &&
        (identity ? (
          <SgidLogoutButton
            identity={identity}
            redirectToState={logoutRedirectToState}
          />
        ) : (
          <Text textStyle="caption-2" w="full" maxW="300px" textAlign="center">
            {loggedOutDescriptionText}
          </Text>
        ))}
    </VStack>
  )
}
