import ReactLinkify, { Props } from 'react-linkify'
import { Link } from '@opengovsg/design-system-react'

// This function creates the desired Link components, based on the Chakra-UI `Link`
// Customize as needed!
export const Linkify = (props: Props) => {
  return (
    <ReactLinkify
      {...props}
      componentDecorator={(href, text, key) => (
        <Link href={href} key={key} isExternal={true}>
          {text}
        </Link>
      )}
    />
  )
}
