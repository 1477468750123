import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Divider, VStack } from '@chakra-ui/react'
import { useToast } from '@opengovsg/design-system-react'

import { GetEventRes } from '~shared/dto'
import { Day } from '~shared/types'

import { SlotDurationInput } from '~features/events/components/Availability/components/SlotDurationInput'
import { SyncToDirtyFieldStore } from '~features/events/components/SyncToDirtyFieldStore'
import { ManageOverrides } from '~features/schedules'
import { useUpdateAllOverrides } from '~features/schedules/hooks/useAdminScheduleOverrides'
import { useUpdateSchedule } from '~features/schedules/hooks/useAdminSchedules'

import {
  formValuesToOverrides,
  getComparisonValue,
  overridesToFormValues,
} from '../ManageOverrides/common/helpers'

import { ManageScheduleActionBar } from './ManageScheduleActionBar'
import { ManageScheduleHeading } from './ManageScheduleHeading'
import {
  generateRecurringDaysFormFromSchedule,
  ManageScheduleFormState,
  parseFormStateToSegments,
} from './recurring-schedule-form-utils'
import { ScheduleDayField } from './ScheduleDayField'

type ManageRecurringScheduleFormProps = {
  event: GetEventRes
}

export const ManageRecurringScheduleForm = ({
  event,
}: ManageRecurringScheduleFormProps) => {
  const toast = useToast()
  const schedule = useMemo(() => event.schedules[0], [event.schedules])
  const recurringDaysFormState = useMemo(
    () => generateRecurringDaysFormFromSchedule(schedule),
    [schedule],
  )
  const overridesDefaultValue = useMemo(
    () => overridesToFormValues(schedule.overrides),
    [schedule],
  )

  const formMethods = useForm<ManageScheduleFormState>({
    defaultValues: {
      days: recurringDaysFormState,
      slotDurationMins: schedule.slotDurationMins,
      overrides: overridesDefaultValue,
    },
    mode: 'onChange',
  })

  const { mutate: mutateSchedule, isLoading: isMutateScheduleLoading } =
    useUpdateSchedule({
      eventId: event.id,
      scheduleId: schedule.id,
    })
  const { mutate: mutateOverrides, isLoading: isMutateOverridesLoading } =
    useUpdateAllOverrides(schedule.id)

  const onSubmit = formMethods.handleSubmit((scheduleData) => {
    mutateSchedule(
      {
        segments: parseFormStateToSegments(scheduleData.days),
        slotDurationMins: scheduleData.slotDurationMins,
      },
      {
        onSuccess: () => {
          const sortedFormValues = scheduleData.overrides.sort(
            (a, b) => getComparisonValue(a) - getComparisonValue(b),
          )
          // We set the form value to the sorted state before saving, so that
          // when the form is reset after saving, the fields are not dirty
          formMethods.setValue('overrides', sortedFormValues)
          return mutateOverrides(
            formValuesToOverrides({ overrides: sortedFormValues }),
            {
              onSuccess: (res) => {
                toast({
                  description: 'Availability updated successfully.',
                  status: 'success',
                })
                formMethods.reset({
                  ...scheduleData,
                  overrides: overridesToFormValues(res),
                })
              },
              onError: () => {
                formMethods.reset(scheduleData)
                toast({
                  description:
                    'Sorry, your availability was only partially updated. Please try saving again.',
                  status: 'error',
                })
              },
            },
          )
        },
      },
    )
  })

  return (
    <FormProvider {...formMethods}>
      <SyncToDirtyFieldStore />
      <VStack
        spacing={8}
        alignItems="stretch"
        as="form"
        onSubmit={onSubmit}
        noValidate
      >
        <SlotDurationInput />
        <Divider />
        <ManageScheduleHeading />
        <VStack alignItems="stretch" spacing="24px">
          {Object.values(Day).map((day) => (
            <ScheduleDayField
              key={day}
              day={day}
              slotDurationMins={schedule.slotDurationMins}
            />
          ))}
        </VStack>
        <Divider />
        <ManageOverrides />
        <ManageScheduleActionBar
          isFormLoading={isMutateScheduleLoading || isMutateOverridesLoading}
        />
      </VStack>
    </FormProvider>
  )
}
