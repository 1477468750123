import { DateRangeValue } from '@opengovsg/design-system-react'
import dayjs from 'dayjs'
import simplur from 'simplur'

import {
  MAX_LEAD_TIME_MINS_MAXIMUM,
  MAX_LEAD_TIME_MINS_MINIMUM,
  MIN_LEAD_TIME_MINS_MAXIMUM,
  MIN_LEAD_TIME_MINS_MINIMUM,
  START_END_DATE_MAX_INTERVAL_DAYS,
} from '~shared/constants'
import { GetScheduleRes, UpdateScheduleReq } from '~shared/dto'
import {
  displayTimeUnitsToMinutes,
  minutesToDisplayTimeUnits,
} from '~shared/utils'

import { minutesToDays } from '~utils/date'

import { BookingRulesFields } from '../types/field-info'

export const validateMinLeadTimeMinutes = (
  value: number | null,
  formValues: BookingRulesFields,
): true | string => {
  const { minLeadTimeUnit, isMinLeadTimeEnabled } = formValues
  const minLeadTimeMinutes = displayTimeUnitsToMinutes({
    quantity: value,
    unit: minLeadTimeUnit,
  })
  if (minLeadTimeMinutes === null) {
    if (isMinLeadTimeEnabled) {
      return `Please enter a number, or disable this option`
    }
    return true
  }
  if (minLeadTimeMinutes < MIN_LEAD_TIME_MINS_MINIMUM) {
    return simplur`Notice period must be at least ${MIN_LEAD_TIME_MINS_MINIMUM} minute[|s]`
  }
  if (minLeadTimeMinutes > MIN_LEAD_TIME_MINS_MAXIMUM) {
    return simplur`Notice period can be at most ${minutesToDays(
      MIN_LEAD_TIME_MINS_MAXIMUM,
    )} day[|s]`
  }
  return true
}

export const validateMaxLeadTimeMinutes = (
  value: number | null,
  formValues: BookingRulesFields,
): true | string => {
  const {
    minLeadTimeUnit,
    minLeadTimeQuantity,
    isMinLeadTimeEnabled,
    maxLeadTimeUnit,
    isMaxLeadTimeEnabled,
  } = formValues

  const maxChangeLeadTimeMinutes = displayTimeUnitsToMinutes({
    quantity: value,
    unit: maxLeadTimeUnit,
  })
  if (maxChangeLeadTimeMinutes === null) {
    if (isMaxLeadTimeEnabled) {
      return `Please enter a number, or disable this option`
    }
    return true
  }
  if (maxChangeLeadTimeMinutes < MAX_LEAD_TIME_MINS_MINIMUM) {
    return simplur`Released rolling slots must be least ${MAX_LEAD_TIME_MINS_MINIMUM} minute[|s] from now`
  }
  if (maxChangeLeadTimeMinutes > MAX_LEAD_TIME_MINS_MAXIMUM) {
    return simplur`Released rolling slots can be at most ${minutesToDays(
      MAX_LEAD_TIME_MINS_MAXIMUM,
    )} day[|s] from now`
  }

  if (isMinLeadTimeEnabled) {
    const minChangeLeadTimeMinutes = displayTimeUnitsToMinutes({
      quantity: minLeadTimeQuantity,
      unit: minLeadTimeUnit,
    })

    if (
      minChangeLeadTimeMinutes !== null &&
      minChangeLeadTimeMinutes > maxChangeLeadTimeMinutes
    ) {
      return simplur`Released rolling slots must be more than the advance notice period. Otherwise, no slots will be available for booking.`
    }
  }

  return true
}

export const validateStartEndDateInterval = (
  dates: DateRangeValue,
): true | string => {
  const startDate = dates[0]
  const endDate = dates[1]
  if (startDate === null || endDate === null) {
    return true
  }
  const intervalInDays = dayjs(endDate).diff(dayjs(startDate), 'days')
  return (
    intervalInDays <= START_END_DATE_MAX_INTERVAL_DAYS ||
    simplur`Start and end dates can be at most ${START_END_DATE_MAX_INTERVAL_DAYS} [day|days] apart`
  )
}

export const getBookingRulesFields = (
  schedule: GetScheduleRes,
): BookingRulesFields => {
  const {
    minLeadTimeMinutes,
    maxLeadTimeMinutes,
    startDate,
    endDate,
    slotCapacity,
  } = schedule

  const { quantity: minLeadTimeQuantity, unit: minLeadTimeUnit } =
    minutesToDisplayTimeUnits(minLeadTimeMinutes)
  const { quantity: maxLeadTimeQuantity, unit: maxLeadTimeUnit } =
    minutesToDisplayTimeUnits(maxLeadTimeMinutes)

  return {
    slotCapacity,
    isMinLeadTimeEnabled: minLeadTimeMinutes !== null,
    minLeadTimeQuantity,
    minLeadTimeUnit,
    isMaxLeadTimeEnabled: maxLeadTimeMinutes !== null,
    maxLeadTimeQuantity,
    maxLeadTimeUnit,
    isDateRangeEnabled: startDate !== null && endDate !== null,
    dateRange: [
      startDate ? new Date(startDate) : null,
      endDate ? new Date(endDate) : null,
    ] as DateRangeValue,
  }
}

export const bookingRulesFieldsToReqBody = (
  fields: BookingRulesFields,
): UpdateScheduleReq => {
  const minLeadTimeMinutes = displayTimeUnitsToMinutes({
    quantity: fields.minLeadTimeQuantity,
    unit: fields.minLeadTimeUnit,
  })
  const maxLeadTimeMinutes = displayTimeUnitsToMinutes({
    quantity: fields.maxLeadTimeQuantity,
    unit: fields.maxLeadTimeUnit,
  })

  return {
    slotCapacity: fields.slotCapacity,
    minLeadTimeMinutes: fields.isMinLeadTimeEnabled ? minLeadTimeMinutes : null,
    maxLeadTimeMinutes: fields.isMaxLeadTimeEnabled ? maxLeadTimeMinutes : null,
    startDate: fields.isDateRangeEnabled
      ? fields.dateRange[0]?.valueOf()
      : null,
    endDate: fields.isDateRangeEnabled ? fields.dateRange[1]?.valueOf() : null,
  }
}
