import { Link, useLocation } from 'react-router-dom'
import { Box, Flex, Spacer, useToken } from '@chakra-ui/react'
import { Sidebar } from '@opengovsg/design-system-react'

import { GetEventRes } from '~shared/dto'

import { LabelledRouteObject } from '~/app/types'

export type AdminEditEventSidebarProps = {
  event: GetEventRes
  subNavigation?: LabelledRouteObject[]
  subRoute?: string
}

export const AdminEditEventSidebar = ({
  event,
  subNavigation,
  subRoute,
}: AdminEditEventSidebarProps) => {
  const location = useLocation()
  const sidebarTopPosition = useToken('sizes', 'event-header-height')

  if (!subNavigation || !subRoute) {
    return null
  }

  const tabName = location.pathname.split('/').pop()
  return (
    <>
      <Flex
        bgColor="base.canvas.default"
        borderRight="1px"
        borderColor="base.divider.medium"
      >
        <Box
          p={4}
          h="fit-content"
          position="sticky"
          top={sidebarTopPosition}
          zIndex="docked"
          w="15.75rem"
        >
          <Sidebar
            items={subNavigation
              .filter((route) => !!route.path && !!route.label)
              .map(({ path, icon, label }) => ({
                children: label,
                icon,
                isActive: tabName === path,
                props: {
                  as: Link,
                  to: `/admin/event/${event.id}/${subRoute}/${path ?? ''}`,
                },
              }))}
            size="md"
          />
        </Box>
      </Flex>
      <Spacer />
    </>
  )
}
