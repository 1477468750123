import { useCallback, useState } from 'react'
import { BiCopy } from 'react-icons/bi'
import { useClipboard } from '@chakra-ui/react'
import {
  IconButton,
  IconButtonProps,
  TouchableTooltip,
} from '@opengovsg/design-system-react'

export type CopyToClipboardButtonProps = {
  content: string
  'aria-label'?: string
} & Omit<IconButtonProps, 'aria-label'>

export const CopyToClipboardButton = ({
  content,
  ...otherProps
}: CopyToClipboardButtonProps) => {
  const [isCopied, setIsCopied] = useState(false)
  const { onCopy: copyToClipboard } = useClipboard(content)
  const handleCopyLinkButtonClick = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // This stopPropagation call prevents the tooltip onClick handler
      // from firing and hiding the label prematurely
      evt.stopPropagation()
      copyToClipboard()
      setIsCopied(true)
    },
    [copyToClipboard, setIsCopied],
  )
  return (
    <TouchableTooltip
      label={isCopied ? 'Copied!' : 'Copy event link'}
      fontSize="md"
      gutter={2}
    >
      <IconButton
        aria-label="copy-link"
        variant="outline"
        onPointerLeave={() => {
          setIsCopied(false)
        }}
        onClick={handleCopyLinkButtonClick}
        icon={<BiCopy />}
        {...otherProps}
      />
    </TouchableTooltip>
  )
}
