import { useFormContext } from 'react-hook-form'
import { Box, FormControl } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  Input,
} from '@opengovsg/design-system-react'

import { isAsciiLocalpartEmail } from '~shared/utils/validation'

export interface EmailFieldProps {
  id: string
}

export const EmailField = ({ id }: EmailFieldProps): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<Record<string, string>>()
  return (
    <FormControl isRequired isInvalid={!!errors[id]?.message}>
      <Box mb="0.75rem">
        <FormLabel mb={0}>Email</FormLabel>
      </Box>
      <Input
        w="100%"
        id={id}
        errorBorderColor="red.300"
        {...register(id, {
          required: `Email is required`,
          validate: (v) =>
            isAsciiLocalpartEmail(v) ||
            'Please enter a valid email. Special characters are not allowed.',
        })}
      ></Input>
      <FormErrorMessage>{errors[id]?.message}</FormErrorMessage>
    </FormControl>
  )
}
