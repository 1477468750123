import { useFormContext } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  Input,
} from '@opengovsg/design-system-react'

import {
  FORM_FIELD_DESCRIPTION_MAX_LEN,
  FORM_FIELD_TITLE_MAX_LEN,
} from '~shared/constants'

import { EditFieldFormReturn } from './types'

export const EditFieldTitleAndDescription = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<EditFieldFormReturn>()
  return (
    <>
      <FormControl isRequired isInvalid={!!errors.title?.message}>
        <FormLabel>Question</FormLabel>
        <Input
          autoFocus
          {...register('title', {
            required: `Question is required.`,
            maxLength: {
              value: FORM_FIELD_TITLE_MAX_LEN,
              message: `Please use questions that are less than ${FORM_FIELD_TITLE_MAX_LEN} characters long.`,
            },
          })}
        />
        <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.description?.message}>
        <FormLabel>Description</FormLabel>
        <Input
          {...register('description', {
            maxLength: {
              value: FORM_FIELD_DESCRIPTION_MAX_LEN,
              message: `Description must be less than ${FORM_FIELD_DESCRIPTION_MAX_LEN} characters long.`,
            },
          })}
        />
        <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
      </FormControl>
    </>
  )
}
