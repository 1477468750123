import { BiTrash } from 'react-icons/bi'
import { Flex, HStack, Text } from '@chakra-ui/react'
import { IconButton } from '@opengovsg/design-system-react'

export type CollaboratorProps = {
  onDelete?: () => void
  email: string
  accessLevel: string
}

export const Collaborator = ({
  email,
  accessLevel,
  onDelete,
}: CollaboratorProps) => {
  return (
    <HStack justifyContent="space-between" alignItems="center" minH="2.5rem">
      <Text wordBreak="break-all">{email}</Text>
      <Flex
        w="7rem"
        justifyContent="space-between"
        alignItems="center"
        flexShrink={0}
      >
        <Text textTransform="capitalize" textStyle="subhead-1">
          {accessLevel}
        </Text>
        {onDelete && (
          <IconButton
            icon={<BiTrash />}
            variant="clear"
            colorScheme="red"
            aria-label="delete-collaborator"
            onClick={onDelete}
          />
        )}
      </Flex>
    </HStack>
  )
}
