import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { SgidAdminLoginReq } from '~shared/dto'
import {
  SendLoginOtpRequestDto,
  SendLoginOtpResponseDto,
  VerifyOtpRequestDto,
  VerifyOtpResponseDto,
  WhoAmIResponseDto,
} from '~shared/types/auth.dto'

import { api } from '~lib/api'
import { adminQueryKeys } from '~constants/queryKeys'

export const useAdminUser = () => {
  const { data, isLoading } = useQuery<WhoAmIResponseDto | undefined>(
    adminQueryKeys.auth(),
    () => api.get('/auth/whoami').json<WhoAmIResponseDto>(),
  )
  return { adminUser: data, isLoadingAdminUser: isLoading }
}

export const useAdminVerifyLoginOtp = () => {
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(
    (params: VerifyOtpRequestDto) =>
      api.url('/auth/verify').post(params).json<VerifyOtpResponseDto>(),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(adminQueryKeys.auth())
      },
      onError: () => undefined,
    },
  )
  return { verifyLoginOtp: mutateAsync }
}

export const useAdminSendLoginOtp = () => {
  const { mutateAsync } = useMutation(
    (params: SendLoginOtpRequestDto) => {
      params.email = params.email.toLowerCase()
      return api.url('/auth').post(params).json<SendLoginOtpResponseDto>()
    },
    {
      onError: () => undefined,
    },
  )
  return { sendLoginOtp: mutateAsync }
}

export const useAdminLogout = () => {
  const queryCache = useQueryClient()

  return useMutation<void>(() => api.url('/auth/logout').post().res(), {
    onSuccess: () => {
      queryCache.removeQueries(adminQueryKeys.auth())
    },
  })
}

export const useAdminSgidAuthUrl = () => {
  return useMutation(() =>
    api.url('/auth/sgid/auth-url').post().json<{ url: string }>(),
  )
}

export const useAdminSgidLogin = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (params: SgidAdminLoginReq) =>
      /* Use text() instead of res() here because res() does not
         wait for the payload to complete streaming. Makes the login
         more consistent because refetchQueries may otherwise fire
         before the cookies are set correctly */
      api.url('/auth/sgid/callback').post(params).text(),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(adminQueryKeys.auth())
      },
    },
  )
}
