import { useForm } from 'react-hook-form'
import { Flex, FormControl, VStack } from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@opengovsg/design-system-react'

import { GetEventRes } from '~shared/dto'
import { isAsciiLocalpartEmail } from '~shared/utils/validation'

import { useUpdateEventOwner } from '~features/events/hooks/useEventPermission'

export const TransferOwnershipForm = ({ event }: { event: GetEventRes }) => {
  const toast = useToast()
  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm<{ newOwnerEmail: string }>({
    mode: 'onSubmit',
    defaultValues: { newOwnerEmail: '' },
  })

  const { mutate, isLoading } = useUpdateEventOwner(event.id)

  const onSubmit = handleSubmit(({ newOwnerEmail }) => {
    mutate(
      {
        userEmail: newOwnerEmail.toLowerCase(),
      },
      {
        onSuccess: (_res, { userEmail }) => {
          toast({
            description: `Successfully transferred event to ${userEmail}`,
            status: 'success',
          })
          reset()
        },
      },
    )
  })

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={onSubmit}
    >
      <VStack spacing={6} alignItems="stretch">
        <FormControl isInvalid={!!errors.newOwnerEmail}>
          <FormLabel
            isRequired
            description="Transfer ownership of this event to another user. You will retain Editor status."
          >
            Transfer event ownership
          </FormLabel>
          <Flex
            justifyContent="space-between"
            alignItems="end"
            gap={4}
            flexDir={{ base: 'column', md: 'row' }}
          >
            <Input
              {...register('newOwnerEmail', {
                validate: {
                  valid: (email: string) =>
                    isAsciiLocalpartEmail(email) ||
                    'Please enter a valid email address. Special characters are not allowed.',
                },
              })}
            />
          </Flex>
          <FormErrorMessage>{errors.newOwnerEmail?.message}</FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          isDisabled={!!errors.newOwnerEmail}
          isLoading={isLoading}
          w={{ base: 'full', md: 'auto' }}
        >
          Transfer
        </Button>
      </VStack>
    </form>
  )
}
