/**
 * 5 instead of 1 to reduce the risk of time slot calculations
 * crashing the server
 */
export const SLOT_DURATION_MINS_MINIMUM = 5

/**
 * 1 day, as multi-day bookings are currently not supported
 */
export const SLOT_DURATION_MINS_MAXIMUM = 60 * 24

/**
 * 0 instead of 1 to allow the admin to unset minLeadTime by
 * setting it to 0
 */
export const MIN_LEAD_TIME_MINS_MINIMUM = 0

/**
 * Long enough for any use case, but not so long that it can
 * cause integer overflow in Postgres
 */
export const MIN_LEAD_TIME_MINS_MAXIMUM = 60 * 24 * 365

/**
 * Make at least 1 hour of timeslots available.
 */
export const MAX_LEAD_TIME_MINS_MINIMUM = 60

/**
 * Long enough for any use case, and reduces the risk of time
 * slot calculations crashing the server
 */
export const MAX_LEAD_TIME_MINS_MAXIMUM = 60 * 24 * 365

/**
 * 0 instead of 1 to allow admin to close the event by setting
 * slot capacity to 0.
 */
export const SLOT_CAPACITY_MINIMUM = 0

/**
 * Large enough for any use case, but not so long that it can
 * cause integer overflow in Postgres
 */
export const SLOT_CAPACITY_MAXIMUM = 100000

/**
 * Reduces the risk of time slot calculations crashing the server.
 */
export const START_END_DATE_MAX_INTERVAL_DAYS = 365

/**
 * Bounds the size of timesot calculations to 1 month to reduce server load.
 * TODO - replace above constant
 */
export const START_END_DATE_MAX_INTERVAL_DAYS_NEW = 32

/**
 * 0 instead of 1 to allow the admin to prevent changing bookings that have passed,
 * but not prevent changes until the booking starts.
 */
export const MIN_CHANGE_LEAD_TIME_MINS_MINIMUM = 0

/**
 * Long enough for any use case, but not so long that it can
 * cause integer overflow in Postgres
 */
export const MIN_CHANGE_LEAD_TIME_MINS_MAXIMUM = 60 * 24 * 365

/**
 * Minimum of 1 hour (since we run the reminder job once per hour)
 */
export const REMINDER_LEAD_TIME_MINS_MINIMUM = 60

/**
 * We limit reminders to 1 month now.
 */
export const REMINDER_LEAD_TIME_MINS_MAXIMUM = 60 * 24 * 31
