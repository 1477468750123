import { memo, useCallback, useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { Day } from '~shared/types'

import { ScheduleTimeRangeListProvider } from './ScheduleTimeRangeField/ScheduleTimeRangeListContext'
import { ScheduleDayFieldRows } from './ScheduleDayFieldRows'

type ScheduleDayFieldProps = {
  day: Day
  slotDurationMins: number
}
// eslint-disable-next-line react/display-name
export const ScheduleDayField = memo(
  ({ day, slotDurationMins }: ScheduleDayFieldProps) => {
    const { trigger, setValue } = useFormContext()
    const isDayActiveKey = useMemo(() => `days.${day}.isOpen`, [day])
    const timeslotsKey = useMemo(() => `days.${day}.timeslots`, [day])
    const isOpen = useWatch({ name: isDayActiveKey, exact: true }) as boolean

    // To ignore disabled days by revalidating them
    useEffect(() => {
      void trigger(timeslotsKey)
    }, [trigger, timeslotsKey, isOpen])

    const disableDay = useCallback(() => {
      setValue(isDayActiveKey, false, { shouldDirty: true })
    }, [isDayActiveKey, setValue])

    return (
      <ScheduleTimeRangeListProvider
        fieldName={timeslotsKey}
        slotDurationMins={slotDurationMins}
        onEmptyTimeRangeList={disableDay}
        isDisabled={!isOpen}
      >
        <ScheduleDayFieldRows day={day} />
      </ScheduleTimeRangeListProvider>
    )
  },
)
