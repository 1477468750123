import { useEffect, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { FormControl, FormControlProps, VStack } from '@chakra-ui/react'
import { FormErrorMessage } from '@opengovsg/design-system-react'

import { useClientBreakpointValue } from '~hooks/useClientBreakpointValue'

import { TimeRange } from './manage-schedule-time-range-utils'
import { ScheduleTimeRangeField } from './ScheduleTimeRangeField'

type ScheduleTimeRangeRowProps = {
  parentName: string
  revalidate?: () => void
  isDisabled?: boolean
  neighbourRanges: TimeRange[]
  name: string
} & FormControlProps

export const ScheduleTimeRangeRow = ({
  parentName,
  name,
  isDisabled,
  neighbourRanges,
  revalidate,
  ...props
}: ScheduleTimeRangeRowProps) => {
  const rules = useMemo(() => {
    return {
      // Validate when any siblings change
      deps: parentName,
      validate: {
        rangeCheck: ({
          start,
          end,
        }: {
          start: number
          end: number
          capacity: number
        }) => {
          if (isDisabled) {
            return true
          }
          return start < end || 'Start time should be earlier than end time'
        },
        conflictCheck: ({ start, end }: TimeRange) => {
          if (isDisabled) {
            return true
          }

          for (let idx = 0; idx < neighbourRanges.length; idx++) {
            const other = neighbourRanges[idx]
            if (other.start >= other.end) {
              // Do not validate against inconsistent fields
              continue
            }
            if (start < other.end && end > other.start) {
              return "Please select time slots that don't overlap"
            }
          }
          return true
        },
      },
    }
  }, [isDisabled, neighbourRanges, parentName])

  useEffect(() => {
    revalidate?.()
  }, [name, revalidate])

  const shouldShowClockIcon = useClientBreakpointValue({
    base: false,
    md: true,
  })

  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControl isInvalid={!!error} flex={1} {...props}>
          <VStack alignItems="start" w="full" mr="4px">
            <ScheduleTimeRangeField
              isDisabled={isDisabled}
              value={value as { start: number; end: number }}
              onChange={onChange}
              isError={!!error}
              shouldShowClockIcon={shouldShowClockIcon}
            />
            {/* Need to override design system / Chakra UI styles to ensure
            that if there are two consecutive rows with error messages, the
            error message is closer to the row it is relevant to than to the
            row below */}
            <FormErrorMessage mt="4px !important" mb="8px !important">
              {error?.message}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    />
  )
}
