import { useForm } from 'react-hook-form'
import { FormControl, Stack } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@opengovsg/design-system-react'
import { z } from 'zod'

import { getNetworkErrorMessage } from '~lib/api'
import { useIsDesktop } from '~hooks/useIsDesktop'

import { ResendOtpButton } from './ResendOtpButton'

const schema = z.object({
  token: z
    .string()
    .trim()
    .min(1, 'OTP is required.')
    .regex(/^[0-9a-zA-Z\b]+$/, {
      message: 'Only numbers and letters are allowed.',
    })
    .length(8, 'Please enter an 8 character OTP.'),
})

export type OtpFormInputs = {
  token: string
}

interface OtpFormProps {
  email: string
  onSubmit: (inputs: OtpFormInputs) => Promise<void>
  onResendOtp: () => Promise<void>
  isVerifyDisabled: boolean
}

export const OtpForm = ({
  email,
  onSubmit,
  onResendOtp,
  isVerifyDisabled,
}: OtpFormProps): JSX.Element => {
  const { handleSubmit, register, formState, setError, clearErrors, setValue } =
    useForm<OtpFormInputs>({
      resolver: zodResolver(schema),
    })

  const isDesktop = useIsDesktop()

  const onSubmitForm = async (inputs: OtpFormInputs) => {
    return onSubmit(inputs).catch((e) => {
      setError('token', { type: 'server', message: getNetworkErrorMessage(e) })
    })
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitForm)}>
      <FormControl isRequired isInvalid={!!formState.errors.token} mb="2.5rem">
        <FormLabel htmlFor="token">
          {`Enter OTP sent to ${email.toLowerCase()}`}
        </FormLabel>
        <Input
          type="text"
          maxLength={8}
          autoComplete="one-time-code"
          autoFocus
          textTransform={'uppercase'}
          {...register('token')}
        />
        <FormErrorMessage>{formState.errors.token?.message}</FormErrorMessage>
      </FormControl>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={{ base: '1.5rem', lg: '2.5rem' }}
        align="center"
      >
        <Button
          isFullWidth={!isDesktop}
          isLoading={formState.isSubmitting}
          type="submit"
          isDisabled={isVerifyDisabled}
        >
          Sign in
        </Button>
        <ResendOtpButton
          onResendOtp={async () => {
            clearErrors()
            setValue('token', '')
            await onResendOtp()
          }}
        />
      </Stack>
    </form>
  )
}
