import { BOOKING_TIME_TEMPLATE_STRING } from './constants'
import { intervalToHumanReadable } from './utils'

export const formatEmailConfirmationBookingTime = ({
  startAt,
  endAt,
  emailBody,
}: {
  startAt: number | Date
  endAt: number | Date
  emailBody: string
}) => {
  const bookingTime = intervalToHumanReadable({
    startAt: startAt,
    endAt: endAt,
  })

  return emailBody.replace(BOOKING_TIME_TEMPLATE_STRING, bookingTime)
}
