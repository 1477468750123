import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import {
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  ModalCloseButton,
  Textarea,
  useToast,
} from '@opengovsg/design-system-react'

import { CANCEL_NOTE_FROM_ADMIN_MAX_LEN } from '~shared/constants'

import { FALLBACK_NETWORK_ERROR_MESSAGE } from '~lib/api'
import { ButtonStack } from '~components/ButtonStack'

import { useDeleteEventBooking } from '~features/events/hooks/useAdminBookings'

import { useAdminBookingsContext } from '../AdminBookingsContext'

type AdminCancelBookingInput = {
  noteFromOrganiser: string
}

export const CancelBookingModal = () => {
  const {
    currBooking,
    setCurrBooking,
    deleteModalDisclosure,
    drawerDisclosure,
  } = useAdminBookingsContext()
  const { mutate: deleteEventBooking, isLoading } = useDeleteEventBooking()
  const toast = useToast()
  const { register, handleSubmit, formState, reset } =
    useForm<AdminCancelBookingInput>()

  const onDelete = useCallback(
    ({ noteFromOrganiser }: AdminCancelBookingInput) => {
      // How did the modal get opened without a booking?
      if (!currBooking) {
        toast({
          description: FALLBACK_NETWORK_ERROR_MESSAGE,
          status: 'error',
        })
        drawerDisclosure.onClose()
        deleteModalDisclosure.onClose()

        // eslint-disable-next-line no-console
        console.error('CancelBookingModal: No booking selected.')
        return
      }

      deleteEventBooking(
        {
          bookingId: currBooking.id,
          eventId: currBooking.eventId,
          noteFromOrganiser,
        },
        {
          onSuccess: () => {
            setCurrBooking(null)
            drawerDisclosure.onClose()
            deleteModalDisclosure.onClose()
            toast({
              description: 'Booking has been deleted.',
              status: 'success',
            })
            reset({
              noteFromOrganiser: '',
            })
          },
          onError: () => {
            drawerDisclosure.onClose()
            deleteModalDisclosure.onClose()
          },
        },
      )
    },
    [
      currBooking,
      deleteEventBooking,
      deleteModalDisclosure,
      drawerDisclosure,
      reset,
      setCurrBooking,
      toast,
    ],
  )

  return (
    <Modal
      isOpen={deleteModalDisclosure.isOpen}
      onClose={deleteModalDisclosure.onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Cancel booking</ModalHeader>
        <ModalBody>
          <FormControl isInvalid={!!formState.errors.noteFromOrganiser}>
            <FormLabel description="We'll include this note in a cancellation email to the attendee.">
              Cancellation note
            </FormLabel>
            <Textarea
              {...register('noteFromOrganiser', {
                maxLength: {
                  value: CANCEL_NOTE_FROM_ADMIN_MAX_LEN,
                  message: `Cancellation note must be at most ${CANCEL_NOTE_FROM_ADMIN_MAX_LEN} characters`,
                },
              })}
            />
            <FormErrorMessage>
              {formState.errors.noteFromOrganiser?.message}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ButtonStack isReverseOrderOnMobile>
            <Button onClick={deleteModalDisclosure.onClose} variant="clear">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onDelete)}
              colorScheme="critical"
              isLoading={isLoading}
            >
              Delete
            </Button>
          </ButtonStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
