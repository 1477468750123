import { TimeIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react'
import { BxCalendar, ModalCloseButton } from '@opengovsg/design-system-react'

import {
  EMAIL_CONFIRMATION_MESSAGE,
  EMAIL_NOTES_HEADER,
} from '~shared/constants'
import { EventEmailSetting } from '~shared/types'

import { RichTextEditor } from '~features/richtext'

import { LocationIcon } from './LocationIcon'

export type EmailPreviewProps = {
  senderName?: string
  subject?: string
  eventName?: string
  location?: string
  emailBody?: string
  emailSetting?: EventEmailSetting
}

type EmailPreviewModalProps = {
  disclosure: UseDisclosureReturn
  emailProps: EmailPreviewProps
}

export const EmailPreviewModal = ({
  disclosure,
  emailProps: {
    senderName,
    subject,
    eventName,
    location,
    emailBody,
    emailSetting,
  },
}: EmailPreviewModalProps) => {
  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <ModalOverlay />
      <ModalContent my={{ base: 8, md: 16 }}>
        <ModalCloseButton />
        <ModalHeader>
          <VStack spacing={2} align={'start'} fontSize={'1rem'}>
            <HStack
              spacing={2}
              alignItems={'flex-end'}
              w="full"
              paddingRight={10}
            >
              <Box width="6rem">
                <Text textStyle={'body-1'}>From:</Text>
              </Box>
              <Box w="full">
                <Text textStyle={'subhead-1'}>{senderName}</Text>
              </Box>
            </HStack>
            <HStack
              spacing={2}
              alignItems={'flex-end'}
              w="full"
              paddingRight={10}
            >
              <Box width="6rem">
                <Text textStyle={'body-1'}>Subject:</Text>
              </Box>
              <Box w="full">
                <Text textStyle={'subhead-1'}>{subject}</Text>
              </Box>
            </HStack>
          </VStack>
        </ModalHeader>
        <ModalBody>
          <Divider />
          <VStack spacing={8} w="full" marginTop={6}>
            <VStack w="full" spacing={2}>
              <Box w="full" textStyle={'h6'}>
                {EMAIL_CONFIRMATION_MESSAGE}
              </Box>
              <Box w="full" textStyle={'h4'}>
                {eventName || ''}
              </Box>
            </VStack>
            <VStack w="full">
              <Text
                textStyle={'subhead-1'}
                w="full"
              >{`(Booking ID automatically inserted here)`}</Text>
              <HStack w="full">
                <BxCalendar />
                <Text
                  fontSize="0.8rem"
                  as="i"
                >{`(Date automatically inserted here)`}</Text>
              </HStack>
              <HStack w="full">
                <TimeIcon />
                <Text
                  fontSize="0.8rem"
                  as="i"
                >{`(Time automatically inserted here)`}</Text>
              </HStack>
              {location !== '' && (
                <HStack w="full">
                  <LocationIcon />
                  <Text fontSize="0.8rem">{location}</Text>
                </HStack>
              )}
            </VStack>
            <VStack w="full" gap={6} alignItems="start">
              <VStack w="full">
                <Text textStyle={'subhead-1'} w="full">
                  {EMAIL_NOTES_HEADER}
                </Text>
                <Box w="full">
                  <RichTextEditor isReadOnly content={emailBody || ''} />
                </Box>
              </VStack>
              {emailSetting === EventEmailSetting.IncludeFormResponses && (
                <Text
                  fontSize="0.8rem"
                  as="i"
                >{`(Form responses automatically inserted here)`}</Text>
              )}
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}
