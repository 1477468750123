import { useMemo } from 'react'
import { Divider, VStack } from '@chakra-ui/react'

import { AdminDashboardRes } from '~shared/dto'

import { CreateEventListButton } from '~/features/event-lists/components/CreateEventListButton'

import { ButtonStack } from '~components/ButtonStack'
import { LoadingState } from '~components/LoadingState'

import { useAdminAuth } from '~features/auth'
import { CreateEventButton } from '~features/events'

import { DashboardHeader } from './components/DashboardHeader'
import { DashboardList, DashboardListItem } from './components/DashboardList'
import { EmptyDashboard } from './components/EmptyDashboard'
import { useGetAdminDashboard } from './hooks/useAdminDashboard'
import { DashboardModalsProvider } from './DashboardModalsProvider'

export const DashboardPage = (): JSX.Element => {
  const { adminUser } = useAdminAuth()
  const { data, isLoading } = useGetAdminDashboard({
    userId: adminUser?.id,
  })

  if (isLoading || !data || !adminUser)
    return <LoadingState flex={1} minH="admin-content-min-height" />
  if (data.events.length === 0 && data.eventLists.length === 0)
    return <EmptyDashboard />

  return <DashboardPageContent dashboardData={data} />
}

const DashboardPageContent = ({
  dashboardData,
}: {
  dashboardData: AdminDashboardRes
}) => {
  const title = useMemo(
    () =>
      `Your events (${
        dashboardData.events.length + dashboardData.eventLists.length
      })`,
    [dashboardData],
  )

  const dashboardListItems: DashboardListItem[] = useMemo(() => {
    const dashboardArr: DashboardListItem[] = [
      ...dashboardData.events.map((event) => ({
        ...event,
        itemType: 'event' as const,
      })),
      ...dashboardData.eventLists.map((eventList) => ({
        ...eventList,
        itemType: 'eventList' as const,
      })),
    ]

    return dashboardArr.sort((a, b) => b.updatedAt - a.updatedAt)
  }, [dashboardData])

  return (
    <VStack
      alignItems="center"
      spacing={0}
      px="admin-app-px"
      minH="admin-content-min-height"
      divider={<Divider />}
    >
      <DashboardHeader title={title}>
        <ButtonStack py={{ base: 4, md: 0 }}>
          <CreateEventListButton />
          <CreateEventButton />
        </ButtonStack>
      </DashboardHeader>
      <VStack w="full" maxW="51rem" alignItems="stretch">
        <DashboardModalsProvider>
          <DashboardList items={dashboardListItems} />
        </DashboardModalsProvider>
      </VStack>
    </VStack>
  )
}
