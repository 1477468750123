import { Controller, useFormState } from 'react-hook-form'
import { Box, FormControl, Text } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  Input,
} from '@opengovsg/design-system-react'

import { CLOSED_EVENT_MESSAGE_MAX_LEN } from '~shared/constants'

import { GeneralSettingsFormFields } from '../types'

export const ClosedEventMessageSetting = () => {
  const { errors } = useFormState<GeneralSettingsFormFields>()
  return (
    // Padding forces text input to be in line with checkboxes
    <FormControl isInvalid={!!errors.closedEventMessage} pl="36px">
      <FormLabel isRequired size="sm">
        <Text>Set message for closed or full event</Text>
        <Text textStyle={'body-2'}>
          {'Shown to attendees when event is closed or all slots are full.'}
        </Text>
      </FormLabel>
      <Controller
        name="closedEventMessage"
        rules={{
          required: 'This field is required',
          maxLength: {
            value: CLOSED_EVENT_MESSAGE_MAX_LEN,
            message: `Closed event message can be at most ${CLOSED_EVENT_MESSAGE_MAX_LEN} characters`,
          },
        }}
        render={({ field: { onChange, ref, value } }) => (
          <Input
            ref={ref}
            value={value as string}
            onChange={onChange}
            size="sm"
          />
        )}
      />
      <FormErrorMessage>{errors.closedEventMessage?.message}</FormErrorMessage>
    </FormControl>
  )
}
