import { useCallback } from 'react'
import {
  BiDotsVerticalRounded,
  BiDuplicate,
  BiEditAlt,
  BiLink,
  BiLinkExternal,
  BiTrash,
} from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { MenuButton, MenuDivider, useClipboard } from '@chakra-ui/react'
import { IconButton, Menu, useToast } from '@opengovsg/design-system-react'

import { AdminDashboardEventDto } from '~shared/dto'

import { HOSTNAME_WITH_PROTOCOL } from '~constants/config'

import { useDashboardModalsContext } from '~features/dashboard/DashboardModalsProvider'

export type EventActionsButtonGroupProps = {
  event: AdminDashboardEventDto
  isEventOwner: boolean
}

export const EventActionsButtonGroup = ({
  event,
  isEventOwner,
}: EventActionsButtonGroupProps): JSX.Element => {
  const { onOpenDeleteEventModal, onOpenDuplicateEventModal } =
    useDashboardModalsContext()
  const navigate = useNavigate()
  const toast = useToast()
  const publicEventUrl = `${HOSTNAME_WITH_PROTOCOL}/${event.id}`
  const { onCopy: copyLinkToClipboard } = useClipboard(publicEventUrl)

  const handleCopyLink = useCallback(() => {
    copyLinkToClipboard()
    toast({
      status: 'success',
      description: 'Copied event link.',
    })
  }, [copyLinkToClipboard, toast])

  return (
    <Menu placement={'bottom-end'} offset={[0, -4]}>
      <MenuButton
        as={IconButton}
        icon={<BiDotsVerticalRounded />}
        variant="clear"
        flex={1}
        borderStartRadius={0}
      />
      <Menu.List width={'200px'}>
        <Menu.Item
          icon={<BiEditAlt size="1.25rem" />}
          onClick={() => navigate(`/admin/event/${event.id}`)}
        >
          Edit
        </Menu.Item>
        <Menu.Item icon={<BiLink size="1.25rem" />} onClick={handleCopyLink}>
          Copy link
        </Menu.Item>
        <Menu.Item
          as="a"
          icon={<BiLinkExternal size="1.25rem" />}
          href={publicEventUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          View live event
        </Menu.Item>
        <Menu.Item
          icon={<BiDuplicate size="1.25rem" />}
          onClick={() => onOpenDuplicateEventModal(event)}
        >
          Duplicate
        </Menu.Item>
        {isEventOwner && (
          <>
            <MenuDivider mt={2} mb={2} />
            <Menu.Item
              icon={
                <BiTrash size="1.25rem" color="interaction.critical.default" />
              }
              textColor="interaction.critical.default"
              onClick={() => onOpenDeleteEventModal(event)}
            >
              Delete
            </Menu.Item>
          </>
        )}
      </Menu.List>
    </Menu>
  )
}
