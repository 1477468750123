export const EVENT_TITLE_MAX_LEN = 255
export const EVENT_DESCRIPTION_MAX_LEN = 2000

export const EVENT_LOCATION_MAX_LEN = 2000

export const EMAIL_CONFIRMATION_SUBJ_MAX_LEN = 100
export const EMAIL_CONFIRMATION_SENDER_NAME_MAX_LEN = 100
export const EMAIL_CONFIRMATION_BODY_MAX_CHARACTER_LEN = 2000
export const EMAIL_CONFIRMATION_BODY_MAX_HTML_LEN = 4000

export const CLOSED_EVENT_MESSAGE_MAX_LEN = 1000

export const CANCEL_NOTE_FROM_ADMIN_MAX_LEN = 1000
