import {
  BiCalendarAlt,
  BiCalendarExclamation,
  BiCog,
  BiGroup,
  BiHive,
  BiKey,
  BiMailSend,
  BiQuestionMark,
  BiTimeFive,
} from 'react-icons/bi'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { VStack } from '@chakra-ui/react'

import { AdminPageHeader } from '~/layouts/components/AdminPageHeader'

import { Footer } from '~components/Footer'
import { ScrollToTop } from '~components/ScrollToTop'

import {
  AdminProtectedRoute,
  LoginPage,
  SgidAdminLoginPage,
} from '~features/auth'
import { SgidAdminCallbackPage } from '~features/auth/SgidAdminCallbackPage'
import { AdminBanner } from '~features/banner'
import { DashboardPage } from '~features/dashboard'
import { EventListPage } from '~features/event-lists/EventListPage'
import { EditEventPage } from '~features/events'
import { Availability } from '~features/events/components/Availability/Availability'
import { BookingRules } from '~features/events/components/BookingRules'
import { Collaborators } from '~features/events/components/Collaborators'
import { EmailInvites } from '~features/events/components/EmailInvites'
import { EventSetup } from '~features/events/components/EventSetup'
import { GeneralSettings } from '~features/events/components/GeneralSettings'
import { EditFormFields } from '~features/events/components/InviteeQuestions'
import { ReminderSettings } from '~features/events/components/ReminderSettings'
import { Responses } from '~features/events/components/Responses'
import { SgidSettings } from '~features/events/components/SgidSettings'
import { AdminEditEventTabPanel } from '~features/events/layouts/AdminEditEventTabPanel'

import { LabelledRouteObject } from './types'

// Sub-sub routes of /event/:eventId/create
export const adminEventCreateRoutes: LabelledRouteObject[] = [
  {
    index: true,
    element: <Navigate to="setup" replace />,
  },
  {
    path: 'setup',
    label: 'Event setup',
    icon: BiHive,
    element: <EventSetup />,
  },
  {
    path: 'availability',
    label: 'Availability',
    icon: BiCalendarAlt,
    element: <Availability />,
  },
  {
    path: 'booking-rules',
    label: 'Booking rules',
    icon: BiCalendarExclamation,
    element: <BookingRules />,
  },
  {
    path: 'questions',
    label: 'Form questions',
    icon: BiQuestionMark,
    element: <EditFormFields />,
  },
  {
    path: 'email-confirmation',
    label: 'Email confirmation',
    icon: BiMailSend,
    element: <EmailInvites />,
  },
  {
    path: '*',
    element: <Navigate to="setup" replace />,
  },
]

// Sub-sub routes of /event/:eventId/settings
export const adminEventSettingsRoutes: LabelledRouteObject[] = [
  {
    index: true,
    element: <Navigate to="general" replace />,
  },
  {
    path: 'general',
    label: 'General',
    icon: BiCog,
    element: <GeneralSettings />,
  },
  {
    path: 'collaborators',
    label: 'Collaborators',
    icon: BiGroup,
    element: <Collaborators />,
  },
  {
    path: 'singpass',
    label: 'Singpass',
    icon: BiKey,
    element: <SgidSettings />,
  },
  {
    path: 'reminders',
    label: 'Reminders',
    icon: BiTimeFive,
    element: <ReminderSettings />,
  },
  {
    path: '*',
    element: <Navigate to="general" replace />,
  },
]

// Sub routes of /event/:eventId
export const adminEventRoutes: LabelledRouteObject[] = [
  {
    path: 'create',
    label: 'Create',
    element: (
      <AdminEditEventTabPanel
        subNavigation={adminEventCreateRoutes}
        subRoute="create"
      />
    ),
    subNavigation: adminEventCreateRoutes,
    children: adminEventCreateRoutes,
  },
  {
    path: 'settings',
    label: 'Settings',
    element: (
      <AdminEditEventTabPanel
        subNavigation={adminEventSettingsRoutes}
        subRoute="settings"
      />
    ),
    subNavigation: adminEventSettingsRoutes,
    children: adminEventSettingsRoutes,
  },
  {
    path: 'responses',
    label: 'Responses',
    element: <AdminEditEventTabPanel />,
    children: [
      {
        index: true,
        element: <Responses />,
      },
    ],
  },
  {
    index: true,
    element: <Navigate to="create" replace />,
  },
]

export const adminRoutes: RouteObject[] = [
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: 'ogp-login',
    element: <SgidAdminLoginPage />,
  },
  {
    path: 'ogp-login-callback',
    element: <SgidAdminCallbackPage />,
  },
  {
    element: (
      <AdminProtectedRoute>
        <AdminBanner />
        <VStack minWidth="100%" alignItems="stretch" spacing={0} flex={1}>
          <Outlet />
        </VStack>
      </AdminProtectedRoute>
    ),
    children: [
      {
        element: (
          <>
            <AdminPageHeader />
            <Outlet />
            <Footer />
          </>
        ),
        children: [
          {
            element: (
              <ScrollToTop>
                <Outlet />
              </ScrollToTop>
            ),
            children: [
              {
                path: 'dashboard',
                element: <DashboardPage />,
              },
              {
                path: 'event-list/:eventListId',
                element: <EventListPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'event/:eventId',
        element: <EditEventPage />,
        children: adminEventRoutes,
      },
    ],
  },
  {
    index: true,
    element: <Navigate to="dashboard" />,
  },
]
