import { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BiTrash } from 'react-icons/bi'
import { Box, Icon, Text } from '@chakra-ui/react'
import { DatePicker, IconButton } from '@opengovsg/design-system-react'

import { useClientBreakpointValue } from '~hooks/useClientBreakpointValue'

import { ManageScheduleFormState } from '~features/schedules/components/ManageScheduleForm/recurring-schedule-form-utils'

import {
  AVAILABILITY_COL_1_GRID_TEMPLATE_AREA,
  AVAILABILITY_COL_2_GRID_TEMPLATE_AREA,
  AVAILABILITY_COL_3_GRID_TEMPLATE_AREA,
  AVAILABILITY_COL_4_GRID_TEMPLATE_AREA,
  AvailabilityRowWrapper,
} from '../../AvailabilityRowWrapper'
import { OVERRIDES_FORM_VALUES_KEY } from '../common/constants'

export interface UnavailableAllDayRowProps {
  onDelete: () => void
  index: number
}

export const UnavailableAllDayRow = ({
  index,
  onDelete,
}: UnavailableAllDayRowProps) => {
  const { control } = useFormContext<ManageScheduleFormState>()
  const shouldRenderCompact = useClientBreakpointValue({
    base: true,
    lg: false,
  })
  const { trigger } = useFormContext<ManageScheduleFormState>()

  const checkDateConflict = useCallback(() => {
    void trigger(OVERRIDES_FORM_VALUES_KEY)
  }, [trigger])

  return (
    <AvailabilityRowWrapper>
      <Controller
        control={control}
        name={`${OVERRIDES_FORM_VALUES_KEY}.${index}.date`}
        render={({ field: { value, onChange } }) => (
          <Box mr={{ base: 0, lg: '8px' }}>
            <DatePicker
              gridArea={AVAILABILITY_COL_1_GRID_TEMPLATE_AREA}
              size="sm"
              allowManualInput={false}
              value={new Date(value)}
              onChange={(newDate) => {
                onChange(newDate?.getTime())
                checkDateConflict()
              }}
            />
          </Box>
        )}
      />
      <Text
        textStyle={'body-2'}
        gridArea={AVAILABILITY_COL_2_GRID_TEMPLATE_AREA}
        alignSelf="center"
        // Hack to make things line up in mobile view
        minW="248px"
      >
        Unavailable all day
      </Text>
      <Box
        gridArea={
          shouldRenderCompact
            ? AVAILABILITY_COL_4_GRID_TEMPLATE_AREA
            : AVAILABILITY_COL_3_GRID_TEMPLATE_AREA
        }
        alignSelf="start"
        justifySelf={'end'}
      >
        <IconButton
          size={{ base: 'xs', lg: 'sm' }}
          variant="clear"
          icon={<Icon as={BiTrash} height="1.25rem" width="1.25rem" />}
          aria-label="Delete date"
          colorScheme="red"
          onClick={onDelete}
        />
      </Box>
    </AvailabilityRowWrapper>
  )
}
