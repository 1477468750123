import { Controller, useFormState } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  Textarea,
} from '@opengovsg/design-system-react'

import { EVENT_LIST_DESCRIPTION_MAX_LEN } from '~shared/constants'

import { EventListFormFields } from './types'

export interface EventListDescriptionInputProps {
  labelText?: string
}

export const EventListDescriptionInput = ({
  labelText = `Event list description`,
}: EventListDescriptionInputProps) => {
  const { errors } = useFormState<EventListFormFields>()
  return (
    <FormControl isInvalid={!!errors.eventListDescription}>
      <FormLabel size="sm">{labelText}</FormLabel>
      <Controller
        name="eventListDescription"
        rules={{
          maxLength: {
            value: EVENT_LIST_DESCRIPTION_MAX_LEN,
            message: `Event list description can be at most ${EVENT_LIST_DESCRIPTION_MAX_LEN} characters`,
          },
        }}
        render={({ field: { onChange, ref, value } }) => (
          <Textarea
            ref={ref}
            value={value as string}
            onChange={onChange}
            minH="200px"
            size="sm"
            placeholder="Describe the types of events or services within this event list"
          />
        )}
      />
      <FormErrorMessage>
        {errors.eventListDescription?.message}
      </FormErrorMessage>
    </FormControl>
  )
}
