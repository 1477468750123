import { useMemo } from 'react'
import { Text, VStack } from '@chakra-ui/react'

import { minutesToDisplayTimeUnits } from '~shared/utils'

import { BookingFlowErrorLayout } from './BookingFlowErrorLayout'

export type BookingRescheduleNotAllowedProps = {
  rescheduleLeadTimeMins: number
}

export const BookingRescheduleNotAllowed = ({
  rescheduleLeadTimeMins,
}: BookingRescheduleNotAllowedProps) => {
  const { quantity: timeQuantity, unit: timeUnit } = useMemo(
    () => minutesToDisplayTimeUnits(rescheduleLeadTimeMins),
    [rescheduleLeadTimeMins],
  )

  return (
    <BookingFlowErrorLayout>
      <VStack paddingX={8} spacing={1}>
        <Text textStyle="h4">
          {'Sorry, this booking cannot be rescheduled.'}
        </Text>
        {timeQuantity !== null && (
          <Text>
            {rescheduleLeadTimeMins === 0
              ? `Bookings in the past cannot be rescheduled.`
              : `Bookings cannot be rescheduled ${timeQuantity} ${timeUnit} before they start.`}
          </Text>
        )}
        <Text>
          {'Please contact the agency that gave you this booking link.'}
        </Text>
      </VStack>
    </BookingFlowErrorLayout>
  )
}
