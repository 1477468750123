import 'inter-ui/inter.css'
import '@fontsource/ibm-plex-mono'

import { useRef } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { ThemeProvider, useToast } from '@opengovsg/design-system-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { theme } from '~/theme'

import { getNetworkErrorMessage } from '~lib/api'

import { appRoutes } from './app.routes'

const queryClient = new QueryClient()
const router = createBrowserRouter(appRoutes)

export const App = (): JSX.Element => {
  const toast = useToast()
  queryClient.setDefaultOptions({
    queries: {
      onError: (err) => {
        toast({
          description: getNetworkErrorMessage(err),
          status: 'error',
          isClosable: true,
        })
      },
    },
    mutations: {
      onError: (err) => {
        toast({
          description: getNetworkErrorMessage(err),
          status: 'error',
          isClosable: true,
        })
      },
    },
  })

  // This is to force the toast portal to render first, and hence sit above all portals
  // including modals. Otherwise, toasts are shown underneath modals.
  // See https://github.com/chakra-ui/chakra-ui/issues/6276
  const toastContainerRef = useRef<HTMLDivElement | null>(null)

  return (
    <QueryClientProvider client={queryClient}>
      {/** By default, RQ Devtools only bundled if process.env.NODE_ENV === 'development'. */}
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider
        theme={theme}
        toastOptions={{
          defaultOptions: { isClosable: true },
          portalProps: {
            containerRef: toastContainerRef,
          },
        }}
        resetCSS
      >
        <RouterProvider router={router} />
      </ThemeProvider>
      <Box ref={toastContainerRef}></Box>
    </QueryClientProvider>
  )
}
