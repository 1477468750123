import { Link as ReactRouterLink } from 'react-router-dom'
import { BreadcrumbItem, BreadcrumbLink, HStack } from '@chakra-ui/react'
import { Breadcrumb } from '@opengovsg/design-system-react'

type DashboardBreadcrumbLink = {
  href?: string
  label: string
  key: string
}

export type DashboardBreadcrumbLinkProps = {
  items: DashboardBreadcrumbLink[]
  currentItemIndex?: number
}

export const DashboardBreadcrumb = ({
  items,
  currentItemIndex,
}: DashboardBreadcrumbLinkProps) => {
  return (
    <HStack pt={8} pb={4} px={6}>
      <Breadcrumb separator="/" size="sm">
        {items.map((item, idx) => (
          <BreadcrumbItem
            key={item.key}
            isCurrentPage={idx === currentItemIndex}
          >
            <BreadcrumbLink to={item.href} as={ReactRouterLink}>
              {item.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </HStack>
  )
}
