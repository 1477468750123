import { Image, StackProps, VStack } from '@chakra-ui/react'

import EmptySvg from '~/assets/img/empty-cup.svg'

export type BookingFlowErrorLayoutProps = StackProps

export const BookingFlowErrorLayout = ({
  children,
  ...rest
}: BookingFlowErrorLayoutProps) => (
  <VStack
    w="full"
    h="full"
    justifyContent="center"
    alignItems="stretch"
    flex={1}
    {...rest}
  >
    <VStack
      maxW="600px"
      mx="auto"
      paddingY={8}
      spacing={6}
      justify="center"
      align="center"
      textStyle="body-2"
      textAlign="center"
    >
      <Image src={EmptySvg} alt="not-found" />
      {children}
    </VStack>
  </VStack>
)
