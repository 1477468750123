import { useMemo } from 'react'
import { Divider, Text, VStack } from '@chakra-ui/react'

import { GetPublicEventRes } from '~shared/dto'
import { minsToMs } from '~shared/utils'

import {
  BookingCancellationNotAllowed,
  BookingNotFound,
} from '~components/ErrorPages'

import { CancellationFields } from '~features/form/components/CancellationFields'
import { EventSummary } from '~features/form/components/EventSummary'
import {
  previousBookingSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'

export interface CancelPageProps {
  event: GetPublicEventRes
}

export const CancelPage = ({ event }: CancelPageProps): JSX.Element => {
  const previousBooking = useFormStore(previousBookingSelector)
  const changeLeadTimeMins = useMemo(
    () => event.schedules[0].minChangeLeadTimeMinutes,
    [event.schedules],
  )

  if (!previousBooking) {
    return <BookingNotFound />
  }

  if (
    changeLeadTimeMins !== null &&
    previousBooking.startAt - Date.now() < minsToMs(changeLeadTimeMins)
  ) {
    return (
      <VStack spacing={6} w="full" flex={1} alignItems="stretch">
        <Text textStyle={{ base: 'subhead-2', md: 'h6' }}>{event.title}</Text>
        <EventSummary event={event} booking={previousBooking} />
        <Divider />
        <BookingCancellationNotAllowed
          cancellationLeadTimeMins={changeLeadTimeMins}
        />
      </VStack>
    )
  }

  return (
    <VStack spacing={6} w="full" flex={1} alignItems="stretch">
      <Text textStyle={{ base: 'h6', md: 'h4' }} mt={{ base: 0, md: '52px' }}>
        Cancel this booking?
      </Text>
      <VStack alignItems="stretch">
        <Text textStyle={{ base: 'subhead-2', md: 'h6' }}>{event.title}</Text>
        <EventSummary event={event} booking={previousBooking} />
      </VStack>
      <Divider />
      <CancellationFields event={event} />
    </VStack>
  )
}
