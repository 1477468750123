import { Box, Text, useRadio, UseRadioProps } from '@chakra-ui/react'
import simplur from 'simplur'

import { TimeslotRes } from '~shared/dto'
import { timeToHumanReadableAmPm } from '~shared/utils'

interface SlotTimeOptionProps extends UseRadioProps {
  slot: TimeslotRes
}

export const SlotTimeOption = ({
  slot,
  ...props
}: SlotTimeOptionProps): JSX.Element => {
  const { getInputProps, getRadioProps } = useRadio(props)

  const borderRadius = slot.remainingCapacity !== undefined ? '12px' : 'full'
  return (
    <Box
      {...getRadioProps()}
      as="label"
      border="1px solid"
      borderColor="interaction.sub.default"
      maxW="100%"
      borderRadius={borderRadius}
      _checked={{
        borderColor: 'interaction.sub.active',
        bgColor: 'interaction.sub.active',
      }}
    >
      <input {...getInputProps()} aria-hidden />
      <Box
        {...getRadioProps()}
        borderRadius={borderRadius}
        py="0.5rem"
        cursor={'pointer'}
        textAlign="center"
        textColor={'interaction.sub.default'}
        _checked={{
          textColor: 'base.content.inverse',
        }}
      >
        <Text
          textStyle="subhead-2"
          pt={0.5}
          pb={slot.remainingCapacity === undefined ? 0.5 : 0}
        >
          {timeToHumanReadableAmPm(slot.startAt)}
        </Text>
        {slot.remainingCapacity !== undefined && (
          <Text
            {...getRadioProps()}
            textStyle="caption-2"
            pb={0.5}
            textColor="utility.feedback.info"
            _checked={{
              textColor: 'base.content.inverse',
            }}
          >
            {simplur`${slot.remainingCapacity} slot[|s]`}
          </Text>
        )}
      </Box>
    </Box>
  )
}
