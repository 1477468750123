import { BiArrowBack, BiHome } from 'react-icons/bi'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Icon, Image, Text, VStack } from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'

import NotFoundSvg from '~/assets/img/not-found.svg'

export const FourOhFour = () => {
  const navigate = useNavigate()

  return (
    <VStack
      w="100vw"
      h="100vh"
      maxW="600px"
      mx="auto"
      padding={8}
      spacing={8}
      justify="center"
      align="center"
      textStyle="body-2"
      textAlign="center"
    >
      <Image src={NotFoundSvg} alt="not-found" />

      <Text textStyle="h5">
        {"It seems like you've stumbled upon a missing page."}
      </Text>

      <VStack spacing={4}>
        <Link
          onClick={() => navigate(-1)}
          variant="plain"
          display="flex"
          alignItems="center"
        >
          <Icon as={BiArrowBack} mr={2} />
          Back to the previous page
        </Link>

        <Link
          as={RouterLink}
          variant="plain"
          to={'/'}
          display="flex"
          alignItems="center"
        >
          <Icon as={BiHome} mr={2} />
          Back to homepage
        </Link>
      </VStack>
    </VStack>
  )
}
