export const APP_NAME = {
  short: 'CalSG',
  full: 'CalSG',
}

export const IS_DEVELOPMENT_ENV = import.meta.env.VITE_ENV === 'development'

export const IS_PRODUCTION_ENV = import.meta.env.VITE_ENV === 'production'

export const HOSTNAME_WITH_PROTOCOL = IS_DEVELOPMENT_ENV
  ? `http://localhost:3000`
  : `https://${window.location.hostname}`

export const ERROR_REPORTING_FORMSG_URL =
  'https://form.gov.sg/64157c075c42b00011b49d55'

export const TURNSTILE_SITE_KEY = IS_DEVELOPMENT_ENV
  ? '1x00000000000000000000AA' // Turnstile always passes
  : // '2x00000000000000000000AB' // Turnstile always blocks
  // '3x00000000000000000000FF' // Turnstile always forces interactive challenge
  IS_PRODUCTION_ENV
  ? '0x4AAAAAAATQddGzRjKzMF3S'
  : // Staging
    '0x4AAAAAAATCRB4HdB5G8oJd'
