import { useCallback, useMemo } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  FormControl,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import {
  Button,
  FormLabel,
  IconButton,
  Input,
  ModalCloseButton,
} from '@opengovsg/design-system-react'

import { EventStatus } from '~shared/types'

import { CopyButton } from './CopyButton'

export interface ShareEventModalProps {
  isOpen: boolean
  onClose: () => void
  eventId: string
  eventStatus: EventStatus
}

export const ShareEventModal = ({
  isOpen,
  onClose,
  eventId,
  eventStatus,
}: ShareEventModalProps): JSX.Element => {
  const navigate = useNavigate()

  const shareLink = useMemo(
    () => `${window.location.origin}/${eventId}`,
    [eventId],
  )

  const handleRedirectToSettings = useCallback(() => {
    onClose()
    navigate(`/admin/event/${eventId}/settings/general`)
  }, [eventId, navigate, onClose])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader color="secondary.700">Share event</ModalHeader>
        <ModalBody pb="2rem">
          {eventStatus === EventStatus.Closed ? (
            <Text
              bg={'interaction.warning-subtle.default'}
              py="1rem"
              px="16px"
              mb="1rem"
            >
              This event is currently closed to new bookings. Open your event in{' '}
              <Button
                p={0}
                variant="link"
                textDecoration={'underline'}
                onClick={handleRedirectToSettings}
              >
                Settings
              </Button>{' '}
              to allow new bookings.
            </Text>
          ) : null}
          <FormLabel isRequired>Event link</FormLabel>
          <Stack direction="row" align="center">
            <InputGroup>
              <Input isReadOnly value={shareLink} />
              <InputRightElement>
                <CopyButton
                  colorScheme="secondary"
                  stringToCopy={shareLink}
                  aria-label="Copy respondent form link"
                />
              </InputRightElement>
            </InputGroup>
            <IconButton
              as="a"
              icon={<BiLinkExternal fontSize="1.5rem" />}
              href={shareLink}
              target="_blank"
              rel="noopener"
              aria-label="Open link in new tab"
            />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
