import { Controller, FieldError } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  Input,
} from '@opengovsg/design-system-react'

import { EVENT_TITLE_MAX_LEN } from '~shared/constants'

export interface EventNameInputProps {
  labelText?: string
  error?: FieldError
}

export const EventNameInput = ({
  error,
  labelText = `What's the name of your event?`,
}: EventNameInputProps) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel isRequired>{labelText}</FormLabel>
      <Controller
        name="eventName"
        rules={{
          required: 'An event name is required',
          maxLength: {
            value: EVENT_TITLE_MAX_LEN,
            message: `Event name can be at most ${EVENT_TITLE_MAX_LEN} characters`,
          },
        }}
        render={({ field: { onChange, ref, value } }) => (
          <Input
            ref={ref}
            value={value as string}
            onChange={onChange}
            autoFocus
          />
        )}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  )
}
