import { BiTrash } from 'react-icons/bi'
import { Flex, HStack, Text } from '@chakra-ui/react'
import { IconButton } from '@opengovsg/design-system-react'

import { AccessLevel } from '~shared/constants'

export type EventListCollaboratorRowProps = {
  email: string
  accessLevel: AccessLevel
  onDelete?: () => void
}

export const EventListCollaboratorRow = ({
  email,
  accessLevel,
  onDelete,
}: EventListCollaboratorRowProps) => {
  return (
    <HStack justifyContent="space-between" alignItems="center">
      <Text wordBreak="break-all" p={4} textStyle="body-2">
        {email}
      </Text>
      <HStack w="7rem">
        <Text fontWeight={500}>
          {accessLevel === 'owner' ? 'Owner' : 'Editor'}
        </Text>
        {onDelete && (
          <Flex alignItems="center" flexShrink={0}>
            <IconButton
              icon={<BiTrash />}
              variant="clear"
              colorScheme="red"
              aria-label="delete-collaborator"
              size="sm"
              onClick={() => onDelete()}
            />
          </Flex>
        )}
      </HStack>
    </HStack>
  )
}
