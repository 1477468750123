import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GetPublicBookingRes } from '~shared/dto'

import { api } from '~lib/api'
import { publicQueryKeys } from '~constants/queryKeys'

type UseGetPublicBooking = {
  bookingId?: string
} & UseQueryOptions<
  GetPublicBookingRes,
  unknown,
  GetPublicBookingRes,
  ReturnType<typeof publicQueryKeys.booking>
>

export const useGetPublicBooking = ({
  bookingId,
  ...useQueryOptions
}: UseGetPublicBooking) => {
  return useQuery(
    publicQueryKeys.booking({ bookingId }),
    () =>
      api
        .url(`/bookings/${bookingId ?? ''}`)
        .get()
        .json<GetPublicBookingRes>(),
    { enabled: !!bookingId, ...useQueryOptions },
  )
}
