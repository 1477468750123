import { isEmail } from 'class-validator'

// Amazon SES allows unicode domains, encoded as punycode. Punycode encoding
// can be handled automatically by nodemailer.
// Since June 2023, it strictly does not allow unicode in the local part of emails.
// https://docs.aws.amazon.com/ses/latest/APIReference/API_Destination.html

export const isAsciiLocalpartEmail = (value: unknown) => {
  return (
    typeof value === 'string' &&
    isEmail(value, {
      allow_utf8_local_part: false,
    })
  )
}
