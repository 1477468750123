import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'
import { Box, Divider, FormControl, Text, VStack } from '@chakra-ui/react'
import {
  Checkbox,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@opengovsg/design-system-react'

import {
  EMAIL_CONFIRMATION_BODY_MAX_CHARACTER_LEN,
  EMAIL_CONFIRMATION_BODY_MAX_HTML_LEN,
  EMAIL_CONFIRMATION_SENDER_FORMAT_REGEX,
  EMAIL_CONFIRMATION_SENDER_NAME_MAX_LEN,
  EMAIL_CONFIRMATION_SENDER_NAME_VALID_CHARS_REGEX,
  EMAIL_CONFIRMATION_SUBJ_MAX_LEN,
} from '~shared/constants'
import { GetEventRes, UpdateEventReq } from '~shared/dto'
import { EventEmailSetting } from '~shared/types'

import { MultiEmailInput } from '~components/MultiEmailInput'
import { SaveChangesButton } from '~components/SaveChangesButton'

import { RichTextEditor } from '~features/richtext'

import { useUpdateEvent } from '../hooks/useAdminEvents'
import { AdminEventOutletContext } from '../types'

import { EmailPreview } from './EmailPreview/EmailPreview'
import { SyncToDirtyFieldStore } from './SyncToDirtyFieldStore'

type EmailInvitesFormFields = Pick<
  UpdateEventReq,
  | 'emailConfirmationSubject'
  | 'emailConfirmationSenderName'
  | 'emailConfirmationBody'
  | 'emailSetting'
  | 'adminEmailRecipients'
>

export const EmailInvites = () => {
  const { event } = useOutletContext<AdminEventOutletContext>()

  const toast = useToast()

  const formMethods = useForm<EmailInvitesFormFields>({
    mode: 'onChange',
    defaultValues: {
      emailConfirmationSubject: event.emailConfirmationSubject,
      emailConfirmationSenderName: event.emailConfirmationSenderName,
      emailConfirmationBody: event.emailConfirmationBody,
      adminEmailRecipients: event.adminEmailRecipients,
      emailSetting: event.emailSetting,
    },
  })

  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = formMethods

  const { mutate, isLoading } = useUpdateEvent(event.id)

  const onSubmit = handleSubmit((data: EmailInvitesFormFields) =>
    mutate(data, {
      onSuccess: (res: GetEventRes) => {
        toast({
          description: 'Email invite settings updated successfully.',
          status: 'success',
        })
        reset({
          emailConfirmationSubject: res.emailConfirmationSubject,
          emailConfirmationSenderName: res.emailConfirmationSenderName,
          emailConfirmationBody: res.emailConfirmationBody,
          adminEmailRecipients: res.adminEmailRecipients,
        })
      },
    }),
  )

  const {
    emailConfirmationSubject,
    emailConfirmationSenderName,
    emailConfirmationBody,
    emailSetting,
  } = watch()

  return (
    <FormProvider {...formMethods}>
      <SyncToDirtyFieldStore />
      <VStack spacing={8} alignItems="stretch" w="full">
        <Text textStyle="h4" mb={2}>
          Email confirmation
        </Text>
        <VStack alignItems="stretch">
          <Text textStyle={'h6'}>Confirmation email for event attendees</Text>
          <Text textStyle="body-2">
            {`Customise the email confirmation sent to attendees when they make
            bookings`}
          </Text>
        </VStack>
        <VStack alignItems="stretch" spacing={6}>
          <FormControl isInvalid={!!errors.emailConfirmationSubject}>
            <FormLabel isRequired size="sm">
              {'Email subject'}
            </FormLabel>
            <Controller
              name="emailConfirmationSubject"
              rules={{
                required: 'This field is required',
                maxLength: {
                  value: EMAIL_CONFIRMATION_SUBJ_MAX_LEN,
                  message: `Email subject can be at most ${EMAIL_CONFIRMATION_SUBJ_MAX_LEN} characters`,
                },
              }}
              render={({ field: { onChange, ref, value } }) => (
                <Input
                  ref={ref}
                  value={value as string}
                  onChange={onChange}
                  size="sm"
                />
              )}
            />
            <FormErrorMessage>
              {errors.emailConfirmationSubject?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.emailConfirmationSenderName}>
            <FormLabel isRequired size="sm">
              {'Sender name'}
            </FormLabel>
            <Controller
              name="emailConfirmationSenderName"
              rules={{
                required: 'This field is required',
                maxLength: {
                  value: EMAIL_CONFIRMATION_SENDER_NAME_MAX_LEN,
                  message: `Sender name can be at most ${EMAIL_CONFIRMATION_SENDER_NAME_MAX_LEN} characters`,
                },
                validate: {
                  characters: (v: string) =>
                    EMAIL_CONFIRMATION_SENDER_NAME_VALID_CHARS_REGEX.test(v) ||
                    "Only alphabets, numbers and the symbols !#$%&'*+/=?^_`{|}~- are allowed",
                  startingCharacter: (v: string) =>
                    !v.startsWith('.') || 'Sender name cannot start with a dot',
                  doubleWhitespace: (v: string) =>
                    !v.includes('  ') ||
                    'Sender name cannot have consecutive spaces',
                  format: (v: string) =>
                    EMAIL_CONFIRMATION_SENDER_FORMAT_REGEX.test(v.trim()) ||
                    'Sender name is in an invalid format',
                },
              }}
              render={({ field: { onChange, ref, value } }) => (
                <Input
                  ref={ref}
                  value={value as string}
                  onChange={onChange}
                  size="sm"
                />
              )}
            />
            <FormErrorMessage>
              {errors.emailConfirmationSenderName?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.emailConfirmationBody}>
            <FormLabel isRequired size="sm">
              <Text>{`Email body / Note from organiser`}</Text>
              <Text textStyle={'body-2'}>
                {"This will also be shown to attendees on the confirmation page after they've made a booking." +
                  'Note that the booking date and time will be included automatically.'}
              </Text>
            </FormLabel>
            <Controller
              name="emailConfirmationBody"
              rules={{
                required: 'This field is required',
                maxLength: {
                  value: EMAIL_CONFIRMATION_BODY_MAX_HTML_LEN,
                  message: `Message with styling is too long. Try removing some styling or shortening your message.`,
                },
              }}
              render={({ field: { value } }) => (
                <RichTextEditor
                  content={value as string}
                  characterLimit={EMAIL_CONFIRMATION_BODY_MAX_CHARACTER_LEN}
                  setContent={(c) =>
                    setValue('emailConfirmationBody', c, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    })
                  }
                />
              )}
            />
            <FormErrorMessage>
              {errors.emailConfirmationBody?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.emailSetting}>
            <Controller
              name="emailSetting"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  size="sm"
                  defaultChecked={
                    emailSetting === EventEmailSetting.IncludeFormResponses
                  }
                  checked={value === EventEmailSetting.IncludeFormResponses}
                  onChange={(event) => {
                    if (event.target.checked) {
                      onChange(EventEmailSetting.IncludeFormResponses)
                    } else {
                      onChange(EventEmailSetting.NoFormResponses)
                    }
                  }}
                  name="emailSetting"
                >
                  <Text textStyle={'subhead-2'}>
                    {`Include a copy of attendee's form responses`}
                  </Text>
                </Checkbox>
              )}
            />
            <FormErrorMessage>{errors.emailSetting?.message}</FormErrorMessage>
          </FormControl>

          {/* Box just to make the Preview button auto-size rather than stretch */}
          <Box>
            <EmailPreview
              senderName={emailConfirmationSenderName}
              subject={emailConfirmationSubject}
              eventName={event.title}
              location={event.location}
              emailBody={emailConfirmationBody}
              emailSetting={emailSetting}
            />
          </Box>
        </VStack>
        <Divider />
        <VStack alignItems={'start'}>
          <Text textStyle="h6">Confirmation email for event organiser</Text>
          <Text textStyle="body-2">
            {`Email confirmations are sent to organisers each time a slot is booked, rescheduled or cancelled`}
          </Text>
        </VStack>
        <FormControl isInvalid={!!errors.adminEmailRecipients}>
          <FormLabel
            isRequired
            description="You can add more than one email address"
            size="sm"
          >
            {'Where should the confirmation email be sent?'}
          </FormLabel>
          <MultiEmailInput name="adminEmailRecipients" />
          <FormErrorMessage>
            {errors.adminEmailRecipients?.message}
          </FormErrorMessage>
        </FormControl>

        <SaveChangesButton
          isDirty={isDirty}
          isLoading={isLoading}
          onClick={() => void onSubmit()}
        />
      </VStack>
    </FormProvider>
  )
}
