import { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { BiTrash } from 'react-icons/bi'
import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import { Checkbox, IconButton } from '@opengovsg/design-system-react'

import { Day } from '~shared/types'

import { useClientBreakpointValue } from '~hooks/useClientBreakpointValue'

import {
  AVAILABILITY_COL_1_GRID_TEMPLATE_AREA,
  AVAILABILITY_COL_2_GRID_TEMPLATE_AREA,
  AVAILABILITY_COL_3_GRID_TEMPLATE_AREA,
  AVAILABILITY_COL_4_GRID_TEMPLATE_AREA,
  AvailabilityRowWrapper,
} from '../AvailabilityRowWrapper'

import { AddTimeRangeButton } from './ScheduleTimeRangeField/AddTimeRangeButton'
import { useScheduleTimeRangeList } from './ScheduleTimeRangeField/ScheduleTimeRangeListContext'
import { ScheduleTimeRangeRow } from './ScheduleTimeRangeField/ScheduleTimeRangeRow'

type ScheduleDayFieldRowsProps = {
  day: Day
}

const DAY_DISPLAY: Record<Day, string> = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday',
}

export const ScheduleDayFieldRows = ({ day }: ScheduleDayFieldRowsProps) => {
  const { onDelete, isDisabled, timeRangeFields, fieldName, timeRanges } =
    useScheduleTimeRangeList()
  const { register, trigger } = useFormContext()
  const isDayActiveKey = useMemo(() => `days.${day}.isOpen`, [day])
  const timeslotsKey = useMemo(() => `days.${day}.timeslots`, [day])
  const isOpen = useWatch({ name: isDayActiveKey, exact: true }) as boolean

  // To ignore disabled days by revalidating them
  useEffect(() => {
    void trigger(timeslotsKey)
  }, [trigger, timeslotsKey, isOpen])

  const shouldRenderCompact = useClientBreakpointValue({
    base: true,
    lg: false,
  })

  return (
    <VStack spacing={{ base: 0, lg: '8px' }}>
      {timeRangeFields.fields.map((field, index) => (
        <AvailabilityRowWrapper key={field.id}>
          {index === 0 ? (
            <HStack
              alignSelf={'start'}
              alignItems="center"
              pt={'8px'}
              spacing={4}
              w={shouldRenderCompact ? 'full' : 'auto'}
              gridArea={AVAILABILITY_COL_1_GRID_TEMPLATE_AREA}
            >
              <Checkbox {...register(isDayActiveKey)} p={0} mr="8px">
                <Text textStyle="subhead-2" textTransform="capitalize">
                  {DAY_DISPLAY[day]}
                </Text>
              </Checkbox>
            </HStack>
          ) : (
            <Box display="none"></Box>
          )}
          <ScheduleTimeRangeRow
            gridArea={AVAILABILITY_COL_2_GRID_TEMPLATE_AREA}
            parentName={fieldName}
            name={`${fieldName}.${index}`}
            neighbourRanges={timeRanges ? timeRanges.slice(0, index) : []}
            isDisabled={isDisabled}
            revalidate={() => {
              void trigger(fieldName)
            }}
          />
          <Box
            gridArea={AVAILABILITY_COL_3_GRID_TEMPLATE_AREA}
            alignSelf="start"
            justifySelf={'end'}
          >
            <IconButton
              variant="clear"
              icon={<Icon as={BiTrash} height="1.25rem" width="1.25rem" />}
              aria-label="delete timeslot"
              colorScheme="red"
              onClick={() => onDelete(index)}
              isDisabled={isDisabled}
              size={{ base: 'xs', lg: 'sm' }}
            />
          </Box>
          {/* If compact view, render add button with index 0. If not compact view, render add button with last index. */}
          {((index === timeRangeFields.fields.length - 1 &&
            !shouldRenderCompact) ||
            (index === 0 && shouldRenderCompact)) && (
            <Box
              alignSelf={'start'}
              justifySelf={'end'}
              gridArea={AVAILABILITY_COL_4_GRID_TEMPLATE_AREA}
            >
              <AddTimeRangeButton />
            </Box>
          )}
        </AvailabilityRowWrapper>
      ))}
    </VStack>
  )
}
