import { useMemo } from 'react'
import { BiMenu } from 'react-icons/bi'
import { Flex, HStack, Spacer, useDisclosure } from '@chakra-ui/react'
import { Button, IconButton } from '@opengovsg/design-system-react'

import { GetEventRes } from '~shared/dto'

import { HOSTNAME_WITH_PROTOCOL } from '~constants/config'

import { CopyToClipboardButton } from '../CopyToClipboardButton/CopyToClipboardButton'
import { ShareEventModal } from '../DesktopEventPageHeader/ShareEventModal'

import { MobileEventPageNavDrawer } from './MobileEventPageNavDrawer'

export const MobileEventPageHeader = ({
  event,
  shareFormModalDisclosure,
}: {
  event: GetEventRes
  shareFormModalDisclosure: ReturnType<typeof useDisclosure>
}) => {
  const publicUrl = useMemo(
    () => `${HOSTNAME_WITH_PROTOCOL}/${event.id}`,
    [event.id],
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Flex
        py={2}
        px={3}
        gap={12}
        justify="space-between"
        alignItems="center"
        borderBottom="1px"
        borderColor="grey.100"
        overflow="auto"
        backgroundColor="utility.ui"
        position="sticky"
        top={0}
        zIndex="docked"
      >
        <IconButton
          size="sm"
          variant="clear"
          aria-label="open menu"
          onClick={onOpen}
          icon={<BiMenu fontSize="1.25rem" />}
        />

        <Spacer />
        <HStack spacing={2}>
          <CopyToClipboardButton
            variant="clear"
            size="sm"
            content={publicUrl}
          />
          <Button
            aria-label="Share event link"
            onClick={shareFormModalDisclosure.onOpen}
          >
            Share
          </Button>
        </HStack>
      </Flex>
      <ShareEventModal
        eventId={event.id}
        eventStatus={event.eventStatus}
        isOpen={shareFormModalDisclosure.isOpen}
        onClose={shareFormModalDisclosure.onClose}
      />
      <MobileEventPageNavDrawer isOpen={isOpen} onClose={onClose} />
    </>
  )
}
