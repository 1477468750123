import { useFormState } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import { FormErrorMessage } from '@opengovsg/design-system-react'

import { ManageOneOffScheduleFormState } from './one-off-schedule-form-utils'

export const OneOffScheduleFormError = () => {
  const { errors } = useFormState<ManageOneOffScheduleFormState>()

  return (
    <FormControl isInvalid={!!errors.dates?.root?.message}>
      <FormErrorMessage>{errors.dates?.root?.message}</FormErrorMessage>
    </FormControl>
  )
}
