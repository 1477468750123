import { useMutation } from '@tanstack/react-query'

import { CancelBookingByAttendeeReq } from '~shared/dto'

import { api } from '~lib/api'

export const useCancelPublicBooking = () => {
  return useMutation(
    ({
      eventId,
      scheduleId,
      bookingId,
      ...rest
    }: {
      eventId: string
      scheduleId: string
      bookingId: string
    } & CancelBookingByAttendeeReq) =>
      api
        .url(
          `/events/${eventId}/schedules/${scheduleId}/bookings/${bookingId}/cancel`,
        )
        .patch(rest)
        .res(),
  )
}
