import dayjs from 'dayjs'

import {
  UpdateAllScheduleOverridesReq,
  UpdateScheduleOverrideRes,
} from '~shared/dto'

import {
  AllDayOverride,
  DateAndTimeOverride,
  OverridesFormValue,
  OverrideType,
} from './constants'

const isAllDayOverride = (override: UpdateScheduleOverrideRes): boolean => {
  return (
    override.endAt - override.startAt === 1000 * 60 * 60 * 24 &&
    // Ensure startAt epoch is at start of day
    dayjs(override.startAt).startOf('day').valueOf() === override.startAt
  )
}

const isDateAndTimesOverride = (
  override: UpdateScheduleOverrideRes,
): boolean => {
  return (
    dayjs(override.startAt).isSame(override.endAt, 'day') &&
    // We only allow minute-level granularity
    override.startAt % (1000 * 60) === 0 &&
    override.endAt % (1000 * 60) === 0
  )
}

export const getComparisonValue = (
  overridesFormValue: OverridesFormValue,
): number => {
  switch (overridesFormValue.type) {
    case OverrideType.AllDay:
      return overridesFormValue.date
    default:
      return overridesFormValue.dateStart
  }
}

export const formValuesToOverrides = (formValues: {
  overrides: OverridesFormValue[]
}): UpdateAllScheduleOverridesReq => {
  const result: UpdateAllScheduleOverridesReq = []
  formValues.overrides.forEach((val) => {
    switch (val.type) {
      case OverrideType.AllDay:
        result.push({
          capacity: 0,
          startAt: dayjs(val.date).startOf('day').valueOf(),
          endAt: dayjs(val.date).add(1, 'day').valueOf(),
        })
        break
      default: {
        const day = dayjs(val.dateStart)
        val.times.forEach((time) => {
          result.push({
            capacity: 0,
            startAt: day.startOf('day').add(time.start, 'ms').valueOf(),
            endAt: day.startOf('day').add(time.end, 'ms').valueOf(),
          })
        })
        break
      }
    }
  })
  return result
}

export const overridesToFormValues = (
  overrides: UpdateScheduleOverrideRes[],
): OverridesFormValue[] => {
  const result: OverridesFormValue[] = []

  // Group multiple times on the same day
  const dateAndTimeOverrides: Map<number, DateAndTimeOverride['times']> =
    new Map()

  overrides.forEach((override) => {
    // All day override
    if (isAllDayOverride(override)) {
      result.push({
        type: OverrideType.AllDay,
        date: override.startAt,
      })
    } else if (isDateAndTimesOverride(override)) {
      const startOfDay = dayjs(override.startAt).startOf('day').valueOf()
      const times = {
        start: override.startAt - startOfDay,
        end: override.endAt - startOfDay,
      }
      const currTimes = dateAndTimeOverrides.get(startOfDay)
      if (!currTimes) {
        dateAndTimeOverrides.set(startOfDay, [times])
      } else {
        currTimes.push(times)
      }
    } else {
      // Do nothing if data from server doesn't fit in any of the above categories.
      // This could happen if e.g. an override was created in one timezone, but is now
      // being accessed in another timezone. We don't currently need to deal with this case.
    }
  })

  // Sort times within the same day for date+time overrides and add them in to array
  dateAndTimeOverrides.forEach((times, dateStart) => {
    times.sort((a, b) => a.start - b.start)
    result.push({
      type: OverrideType.DateAndTimes,
      dateStart,
      times,
    })
  })

  // Sort all overrides
  result.sort((a, b) => getComparisonValue(a) - getComparisonValue(b))

  return result
}

export const doDatesOverlap = (values: OverridesFormValue[]): boolean => {
  for (const [index, value1] of values.entries()) {
    const remainingValues = values.slice(index + 1)
    for (const value2 of remainingValues) {
      if (value1.type === OverrideType.AllDay) {
        if (doesOverrideOverlapWithAllDayOverride(value2, value1)) {
          return true
        }
      } else if (value1.type === OverrideType.DateAndTimes) {
        if (doesOverrideOverlapWithDateAndTimeOverride(value2, value1)) {
          return true
        }
      }
    }
  }
  return false
}

const doesOverrideOverlapWithAllDayOverride = (
  value1: OverridesFormValue,
  value2: AllDayOverride,
): boolean => {
  if (value1.type === OverrideType.AllDay) {
    return value1.date === value2.date
  } else if (value1.type === OverrideType.DateAndTimes) {
    return value1.dateStart === value2.date
  }
  return false
}

const doesOverrideOverlapWithDateAndTimeOverride = (
  value1: OverridesFormValue,
  value2: DateAndTimeOverride,
): boolean => {
  if (value1.type === OverrideType.AllDay) {
    return doesOverrideOverlapWithAllDayOverride(value2, value1)
  } else if (value1.type === OverrideType.DateAndTimes) {
    return value1.dateStart === value2.dateStart
  }
  return false
}
