import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { BrowserEvent } from '~constants/events'

export const useNavigateOnEvent = ({
  path,
  event,
}: {
  path: string
  event: BrowserEvent
}) => {
  const navigate = useNavigate()
  const onEvent = useCallback(() => navigate(path), [navigate, path])

  const initListeners = useCallback(() => {
    window.addEventListener(event, onEvent)
  }, [event, onEvent])

  const removeListeners = useCallback(() => {
    window.removeEventListener(event, onEvent)
  }, [event, onEvent])

  // Initialise Listeners
  useEffect(() => {
    initListeners()
    return removeListeners
  }, [initListeners, removeListeners])
}
