import { useCallback, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Divider,
  FormControl,
  HStack,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@opengovsg/design-system-react'

import { AccessLevel } from '~shared/constants'
import { isAsciiLocalpartEmail } from '~shared/utils/validation'

import { getNetworkErrorMessage } from '~lib/api'

import { useAdminAuth } from '~features/auth'
import { useModifyEventListPermission } from '~features/event-lists/hooks/useAdminEventLists'

import { EventListCollaboratorRow } from './EventListCollaboratorRow'
import { EventListRemoveSelfModal } from './EventListRemoveCollaboratorModal'

type CollaboratorRecord = {
  email: string
  accessLevel: AccessLevel
}

export type EventListCollaboratorFormProps = {
  eventListId: string
  adminEmail: string
  collaborators: CollaboratorRecord[]
}

export const EventListCollaboratorForm = ({
  eventListId,
  adminEmail,
  collaborators,
}: EventListCollaboratorFormProps) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { adminUser } = useAdminAuth()
  const formMethods = useForm<{ email: string }>({
    mode: 'onSubmit',
    defaultValues: { email: '' },
  })
  const { mutate, isLoading } = useModifyEventListPermission(eventListId)

  const {
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = formMethods

  const onAddCollaborator = handleSubmit(({ email }) =>
    mutate(
      {
        userEmail: email.toLowerCase(),
        accessLevel: 'editor',
      },
      {
        onSuccess: (_res, { userEmail }) => {
          toast({
            description: `Successfully added ${userEmail}`,
            status: 'success',
          })
          reset()
        },
        onError: (e) => {
          setError('email', {
            type: 'server',
            message: getNetworkErrorMessage(e),
          })
        },
      },
    ),
  )

  // This function invokes the network call to remove the collaborator
  const removeCollaborator = useCallback(
    (email: string) =>
      mutate(
        {
          userEmail: email.toLowerCase(),
          accessLevel: null,
        },
        {
          onSuccess: (_res, { userEmail }) => {
            const isSelf = email === adminUser?.email
            toast({
              description: isSelf
                ? `Successfully removed yourself`
                : `Successfully removed ${userEmail}`,
              status: 'success',
            })
            if (isSelf) {
              navigate('/admin/dashboard')
            } else {
              reset()
            }
          },
          onError: (e) => {
            setError('email', {
              type: 'server',
              message: getNetworkErrorMessage(e),
            })
          },
        },
      ),
    [mutate],
  )

  const removeSelfDisclosure = useDisclosure()
  // This function decides if the confirmation modal for removing self should be shown
  const handleRemoveCollaborator = useCallback((email: string) => {
    if (email === adminUser?.email) {
      removeSelfDisclosure.onOpen()
    } else {
      removeCollaborator(email)
    }
  }, [])

  return (
    <>
      <EventListRemoveSelfModal
        disclosure={removeSelfDisclosure}
        onDelete={() => {
          if (!adminUser) {
            toast({
              description:
                'Sorry, something went wrong while removing collaborators. Please try again.',
              status: 'error',
            })
            return
          }

          removeCollaborator(adminUser.email)
        }}
      />
      <VStack w="full" gap={6} alignItems="stretch">
        <VStack>
          <FormLabel
            isRequired
            description="Collaborators added here will have access to all events within this event list, even if they're not added to a particular event"
          >
            Add collaborators
          </FormLabel>

          <FormProvider {...formMethods}>
            <FormControl isInvalid={!!errors.email?.message}>
              <HStack
                justifyContent="space-between"
                alignItems="end"
                spacing={2}
              >
                <Controller
                  name="email"
                  rules={{
                    validate: {
                      existing: (email: string) => {
                        if (
                          email === adminEmail ||
                          collaborators.map((x) => x.email).includes(email)
                        ) {
                          return 'User is already a collaborator.'
                        }

                        return true
                      },
                      valid: (email: string) =>
                        isAsciiLocalpartEmail(email) ||
                        'Please enter a valid email address. Special characters are not allowed.',
                    },
                  }}
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      size="sm"
                      ref={ref}
                      value={value as string}
                      onChange={(...props) => {
                        // Manually clear the error that is set when there is
                        // a network error when the field changes.
                        clearErrors('email')
                        onChange(...props)
                      }}
                    />
                  )}
                />

                <Button
                  size="sm"
                  onClick={onAddCollaborator}
                  isDisabled={isLoading || !!errors.email}
                  isLoading={isLoading}
                >
                  Add
                </Button>
              </HStack>
              {!!errors.email?.message && (
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              )}
            </FormControl>
          </FormProvider>
        </VStack>
        <VStack spacing={0} alignItems="stretch">
          <Divider />
          <VStack divider={<Divider />} alignItems="stretch" spacing={0}>
            {adminEmail && (
              <EventListCollaboratorRow
                key={adminEmail}
                email={adminEmail}
                accessLevel="owner"
              />
            )}
            {collaborators.map(({ email, accessLevel }) => {
              return (
                <EventListCollaboratorRow
                  key={email}
                  email={email}
                  accessLevel={accessLevel}
                  onDelete={() => {
                    handleRemoveCollaborator(email)
                  }}
                />
              )
            })}
          </VStack>
          <Divider />
        </VStack>
      </VStack>
    </>
  )
}
