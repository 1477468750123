import { useCallback, useEffect } from 'react'
import {
  BiCog,
  BiDotsVerticalRounded,
  BiLink,
  BiLinkExternal,
  BiTrash,
} from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react'
import { IconButton, Menu, useToast } from '@opengovsg/design-system-react'

import { GetEventListRes } from '~shared/dto'

import { useAdminAuth } from '~features/auth'
import { useDashboardModalsContext } from '~features/dashboard/DashboardModalsProvider'
import { getUrlForPublicEventList } from '~features/dashboard/utils'

import { EditEventListModal } from '../EditEventListModal'

export type EditEventListButtonProps = {
  eventList: GetEventListRes
} & MenuButtonProps

export const EditEventListButton = ({
  eventList,
  ...props
}: EditEventListButtonProps) => {
  const {
    isOpen: isEditModalOpen,
    onOpen: openEditModal,
    onClose: closeEditModal,
  } = useDisclosure()
  const { onOpenDeleteEventListModal: openDeleteModal } =
    useDashboardModalsContext()
  const adminUser = useAdminAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const publicEventListUrl = getUrlForPublicEventList(eventList.id)
  const { onCopy: copyLinkToClipboard } = useClipboard(publicEventListUrl)
  const toast = useToast()
  const isEventListOwner = eventList.admin.id === adminUser.adminUser?.id

  const handleCopyLink = useCallback(() => {
    copyLinkToClipboard()
    toast({
      status: 'success',
      description: 'Copied event list link.',
    })
  }, [copyLinkToClipboard, toast])

  useEffect(() => {
    if (location.state === 'editEventList') {
      openEditModal()
      // We dont want the settings to show again when the user refreshes
      navigate('.', { state: null, replace: true })
    }
  }, [location])

  return (
    <>
      <EditEventListModal
        eventList={eventList}
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
      />
      <Menu placement={'bottom-start'} offset={[0, -4]}>
        <MenuButton
          as={IconButton}
          icon={<BiDotsVerticalRounded />}
          variant="clear"
          {...props}
        />
        <Menu.List width={'200px'}>
          <Menu.Item icon={<BiCog size="1.25rem" />} onClick={openEditModal}>
            Edit settings
          </Menu.Item>
          <Menu.Item icon={<BiLink size="1.25rem" />} onClick={handleCopyLink}>
            Copy link
          </Menu.Item>
          <Menu.Item
            as="a"
            icon={<BiLinkExternal size="1.25rem" />}
            href={publicEventListUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            View live list
          </Menu.Item>
          {isEventListOwner && (
            <>
              <MenuDivider mt={2} mb={2} />
              <Menu.Item
                icon={
                  <BiTrash
                    size="1.25rem"
                    color="interaction.critical.default"
                  />
                }
                textColor="interaction.critical.default"
                onClick={() => openDeleteModal(eventList)}
              >
                Delete
              </Menu.Item>
            </>
          )}
        </Menu.List>
      </Menu>
    </>
  )
}
