import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  SgidEventCallbackReq,
  SgidEventCallbackRes,
  SgidEventGetRedirectUrlReq,
} from '~shared/dto'

import { api } from '~lib/api'
import { publicQueryKeys } from '~constants/queryKeys'

export const usePublicSgidAuthUrl = () => {
  return useMutation((params: SgidEventGetRedirectUrlReq) =>
    api.url('/sgid/auth-url').post(params).json<{ url: string }>(),
  )
}

type UsePublicSgidVerify = {
  authCode: string
} & UseQueryOptions<
  SgidEventCallbackRes,
  unknown,
  SgidEventCallbackRes,
  ReturnType<typeof publicQueryKeys.sgidCallback>
>

export const usePublicSgidVerify = ({
  authCode,
  ...opts
}: UsePublicSgidVerify) => {
  const params: SgidEventCallbackReq = { code: authCode }
  return useQuery(
    publicQueryKeys.sgidCallback({ authCode }),
    () => api.url('/sgid/callback').post(params).json<SgidEventCallbackRes>(),
    { ...opts, retry: false, staleTime: Infinity },
  )
}
