import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useToast } from '@opengovsg/design-system-react'

import { LoadingState } from '~components/LoadingState'

import { MAKE_BOOKING_URL_SESSION_KEY } from '../constants'
import { setFormStateSelector, useFormStore } from '../hooks/useFormStore'
import { usePublicSgidVerify } from '../hooks/useSgidLogin'
import { FormState } from '../types/formState'

export const SgidEventCallbackPage = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const setFormState = useFormStore(setFormStateSelector)
  const toast = useToast()

  const authCode = useMemo(() => searchParams.get('code'), [searchParams])
  const {
    data: result,
    isLoading,
    isError,
  } = usePublicSgidVerify({
    authCode: authCode ?? '',
    enabled: !!authCode,
  })

  const failureRedirect = useMemo(() => {
    const savedRedirectUrl = sessionStorage.getItem(
      MAKE_BOOKING_URL_SESSION_KEY,
    )
    return savedRedirectUrl ?? '/fourohfour'
  }, [])

  const navigateAndClearUrl = useCallback(
    (redirectUrl: string) => {
      sessionStorage.removeItem(MAKE_BOOKING_URL_SESSION_KEY)
      navigate(redirectUrl, { replace: true })
    },
    [navigate],
  )

  // If auth code is present, log the user in
  useEffect(() => {
    if (authCode === null) {
      toast({
        description:
          'Sorry, something went wrong with your Singpass login. please try again.',
        status: 'error',
      })
      navigateAndClearUrl(failureRedirect)
      return
    }

    if (isLoading) {
      return
    }

    if (isError) {
      // Error toast is shown by the default query client parameters
      navigateAndClearUrl(failureRedirect)
      return
    }

    const bookingIdParam = result.bookingId
      ? `?bookingId=${result.bookingId}`
      : ''
    const actionParam =
      result.bookingId && result.action ? `&action=${result.action}` : ''
    const redirectUrl = `/${result.eventId}${bookingIdParam}${actionParam}`
    setFormState(FormState.RedirectFromSgidCallback)
    navigateAndClearUrl(redirectUrl)
  }, [
    authCode,
    failureRedirect,
    isError,
    isLoading,
    navigateAndClearUrl,
    result,
    setFormState,
    toast,
  ])

  // This always loads until completion
  return <LoadingState height="100vh" />
}
