import { Text, VStack } from '@chakra-ui/react'

import { BookingFlowErrorLayout } from './BookingFlowErrorLayout'

export const BookingNotFound = () => {
  return (
    <BookingFlowErrorLayout minH="100vh">
      <VStack paddingX={8}>
        <Text textStyle="h5">{"We can't find that booking."}</Text>

        <Text>{'It may have been cancelled or rescheduled.'}</Text>
      </VStack>
    </BookingFlowErrorLayout>
  )
}
