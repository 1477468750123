import { Controller, useFormState } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  Input,
} from '@opengovsg/design-system-react'

import { EVENT_LIST_TITLE_MAX_LEN } from '~shared/constants'

import { EventListFormFields } from './types'

export interface EventListNameInputProps {
  labelText?: string
}

export const EventListNameInput = ({
  labelText = `What's the name of your event list?`,
}: EventListNameInputProps) => {
  const { errors } = useFormState<EventListFormFields>()
  return (
    <FormControl isInvalid={!!errors.eventListName}>
      <FormLabel isRequired size="sm">
        {labelText}
      </FormLabel>
      <Controller
        name="eventListName"
        rules={{
          required: 'An event list name is required',
          maxLength: {
            value: EVENT_LIST_TITLE_MAX_LEN,
            message: `Event list name can be at most ${EVENT_LIST_TITLE_MAX_LEN} characters`,
          },
        }}
        render={({ field: { onChange, ref, value } }) => (
          <Input
            ref={ref}
            value={value as string}
            onChange={onChange}
            size="sm"
            autoFocus
          />
        )}
      />
      <FormErrorMessage>{errors.eventListName?.message}</FormErrorMessage>
    </FormControl>
  )
}
