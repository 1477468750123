import { BiPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { useDisclosure } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { CreateEventReq } from '~shared/dto'

import { useCreateEvent } from '~features/events/hooks/useAdminEvents'

import {
  CreateEventFormFields,
  CreateEventModal,
} from './components/CreateEvent/CreateEventModal'

export type CreateEventButtonProps = {
  eventListId?: string
}

export const CreateEventButton = ({
  eventListId,
}: CreateEventButtonProps): JSX.Element => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutateAsync } = useCreateEvent({ eventListId })
  const onSubmit = async (inputs: CreateEventFormFields) => {
    const createEventReq: CreateEventReq = {
      title: inputs.eventName,
      isRecurring: inputs.isRecurring,
    }

    const event = await mutateAsync(createEventReq)
    navigate(`../event/${event.id}`)
  }

  return (
    <>
      <CreateEventModal isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} />
      <Button
        leftIcon={<BiPlus />}
        onClick={onOpen}
        my={4}
        w={{ base: 'full', md: 'unset' }}
      >
        {`Create event`}
      </Button>
    </>
  )
}
