import { memo } from 'react'

import { ScheduleTimeRangeListProvider } from './ScheduleTimeRangeField/ScheduleTimeRangeListContext'
import { ScheduleDateFieldRows } from './ScheduleDateFieldRows'

type ScheduleDateFieldProps = {
  fieldName: string
  slotDurationMins: number
  onDeleteDate: () => void
  onRevalidate: () => void
}

// eslint-disable-next-line react/display-name
export const ScheduleDateField = memo(
  ({
    fieldName,
    slotDurationMins,
    onDeleteDate,
    onRevalidate,
  }: ScheduleDateFieldProps) => {
    return (
      <ScheduleTimeRangeListProvider
        fieldName={`${fieldName}.timeslots`}
        slotDurationMins={slotDurationMins}
        onEmptyTimeRangeList={onDeleteDate}
      >
        <ScheduleDateFieldRows
          onRevalidate={onRevalidate}
          dateFieldName={fieldName}
        />
      </ScheduleTimeRangeListProvider>
    )
  },
)
