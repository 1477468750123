import { useCallback, useMemo } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { BiPlus } from 'react-icons/bi'
import { Divider, HStack, VStack } from '@chakra-ui/react'
import { Button, useToast } from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import { GetEventRes } from '~shared/dto'

import { SlotDurationInput } from '~features/events/components/Availability/components/SlotDurationInput'
import { SyncToDirtyFieldStore } from '~features/events/components/SyncToDirtyFieldStore'
import { useUpdateAllOverrides } from '~features/schedules/hooks/useAdminScheduleOverrides'
import { useUpdateSchedule } from '~features/schedules/hooks/useAdminSchedules'

import { ManageScheduleActionBar } from './ManageScheduleActionBar'
import { ManageScheduleHeading } from './ManageScheduleHeading'
import {
  generateOneOffDatesFormFromSchedule,
  ManageOneOffScheduleFormState,
  parseFormStateToOneOffSchedule,
} from './one-off-schedule-form-utils'
import { OneOffScheduleFormError } from './OneOffScheduleFormError'
import { ScheduleDateField } from './ScheduleDateField'

type ManageOneOffScheduleFormProps = {
  event: GetEventRes
}

export const ManageOneOffScheduleForm = ({
  event,
}: ManageOneOffScheduleFormProps) => {
  const toast = useToast()
  const schedule = useMemo(() => event.schedules[0], [event.schedules])
  const datesFormState = useMemo(
    () => generateOneOffDatesFormFromSchedule(schedule.overrides),
    [schedule.overrides],
  )

  const formMethods = useForm<ManageOneOffScheduleFormState>({
    defaultValues: {
      dates: datesFormState,
      slotDurationMins: schedule.slotDurationMins,
    },
    mode: 'onChange',
  })

  const { handleSubmit, reset, control, getValues, trigger } = formMethods

  const rootFormKey = 'dates'
  const dayFields = useFieldArray({
    control,
    shouldUnregister: false,
    name: rootFormKey,
    rules: {
      validate: {
        checkConflict: (dateRows) => {
          const seenDates = new Set<number>()
          for (const x of dateRows) {
            if (seenDates.has(x.date)) {
              return 'Please remove duplicate dates. You can add multiple time slots for the same date.'
            }
            seenDates.add(x.date)
          }
          return true
        },
      },
    },
  })

  const { mutateAsync: mutateOverrides, isLoading: isOverridesUpdateLoading } =
    useUpdateAllOverrides(schedule.id)
  const { mutateAsync: mutateSchedule, isLoading: isScheduleUpdateLoading } =
    useUpdateSchedule({
      eventId: event.id,
      scheduleId: schedule.id,
    })

  const onSubmit = handleSubmit(async (formData) => {
    const overridesState = parseFormStateToOneOffSchedule(
      formData,
      schedule.slotCapacity,
    )

    try {
      // Overrides and slot duration are pretty uncoupled. If one
      // gets updated and the other doesn't, it's not a big deal,
      // user should just refresh
      const overridesResult = await mutateOverrides(overridesState)
      const scheduleResult = await mutateSchedule({
        slotDurationMins: formData.slotDurationMins,
      })
      toast({
        description: 'Schedule updated successfully.',
        status: 'success',
      })
      reset({
        dates: generateOneOffDatesFormFromSchedule(overridesResult),
        slotDurationMins: scheduleResult.slotDurationMins,
      })
    } catch {
      toast({
        description:
          'An error occurred while updating your schedule. Please refresh and try again.',
        status: 'error',
      })
    }
  })

  const onAdd = useCallback(() => {
    const allFormDates = getValues(rootFormKey).map((dateObj) => dateObj.date)
    // We guard against the case where there are 0 date rows by defaulting to today
    // Otherwise, new rows will always be latest day + 1 day
    const nextDate =
      allFormDates.length > 0
        ? Math.max(...allFormDates) + 24 * 60 * 60 * 1000
        : dayjs().startOf('day').unix() * 1000

    dayFields?.append({
      date: nextDate,
      timeslots: [
        {
          start: 9 * 60 * 60 * 1000,
          end: 17 * 60 * 60 * 1000,
        },
      ],
    })
  }, [dayFields, getValues])

  const onDelete = useCallback(
    (index: number) => {
      dayFields?.remove(index)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dayFields.fields.length],
  )

  const checkDateConflict = useCallback(() => {
    void trigger(rootFormKey)
  }, [trigger])

  return (
    <FormProvider {...formMethods}>
      <SyncToDirtyFieldStore />
      <VStack spacing={8} alignItems="stretch" as="form" onSubmit={onSubmit}>
        <SlotDurationInput />
        <Divider />
        <ManageScheduleHeading />
        <VStack alignItems="stretch" spacing={{ base: 6, lg: 4 }}>
          {dayFields.fields.map((field, index) => (
            <ScheduleDateField
              key={field.id}
              onDeleteDate={() => onDelete(index)}
              fieldName={`${rootFormKey}.${index}`}
              slotDurationMins={schedule.slotDurationMins}
              onRevalidate={checkDateConflict}
            />
          ))}
          <OneOffScheduleFormError />
          <HStack alignItems={'start'}>
            <Button
              variant={dayFields.fields.length === 0 ? 'outline' : 'clear'}
              leftIcon={<BiPlus />}
              onClick={onAdd}
            >
              {dayFields.fields.length === 0 ? 'Add timeslot' : 'Add date'}
            </Button>
          </HStack>
        </VStack>
        <ManageScheduleActionBar
          isFormLoading={isOverridesUpdateLoading || isScheduleUpdateLoading}
        />
      </VStack>
    </FormProvider>
  )
}
