import dayjs from 'dayjs'

import { Day } from '~shared/types/base'

export function dayJsToTimeOfDay(day: dayjs.Dayjs) {
  return day.diff(dayjs().startOf('day'), 'milliseconds')
}

export function timeOfDayToDayJs(time: number): dayjs.Dayjs {
  return dayjs().startOf('day').add(time, 'milliseconds')
}

export function isDayOfWeek(day: string): day is Day {
  return day in Day
}
