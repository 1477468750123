import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import {
  FormErrorMessage,
  PhoneNumberInput,
} from '@opengovsg/design-system-react'

import { UpdateMobileFieldReq } from '~shared/dto'
import { isPhoneNumber } from '~shared/utils/validation'

import { FieldLabel } from './FieldLabel'

export const MobileFieldView = ({
  id,
  title,
  description,
}: UpdateMobileFieldReq) => {
  const { control } = useFormContext<Record<string, string>>()

  const validationRules = useMemo(
    () => ({
      required: `This field is required`,
      validate: {
        validatePhoneNumber: (phoneNumber?: string) => {
          if (!phoneNumber) return true
          return (
            isPhoneNumber(phoneNumber) || 'Please enter a valid phone number'
          )
        },
      },
    }),
    [],
  )

  return (
    <Controller
      control={control}
      rules={validationRules}
      name={id}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => (
        <FormControl isRequired isInvalid={!!error}>
          <FieldLabel title={title} description={description} />
          <PhoneNumberInput
            w="100%"
            errorBorderColor="red.300"
            autoComplete="tel"
            allowInternational={true}
            value={value}
            onChange={onChange}
            {...field}
          />
          <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  )
}
