import { NavLink, NavLinkProps } from 'react-router-dom'
import { Button } from '@opengovsg/design-system-react'

type AdminHeaderNavLinkProps = NavLinkProps & {
  children: React.ReactNode
  showBadge?: boolean
}

const activeAdminHeaderNavLinkStyle = {
  background: 'transparent',
  color: 'interaction.main.default',
  p: 0,
  m: 0,
}

export const AdminHeaderNavLink = ({
  children,
  showBadge,
  ...props
}: AdminHeaderNavLinkProps) => {
  return (
    <NavLink {...props}>
      {({ isActive }) => (
        // Font style is custom and does not conform to any predefined values
        <Button
          variant="clear"
          layerStyle="navLink.item"
          textColor={
            isActive
              ? 'interaction.main.default'
              : 'interaction.support.unselected'
          }
          colorScheme={isActive ? 'primary' : 'neutral'}
          borderColor={isActive ? 'primary' : '#0000'}
          _hover={activeAdminHeaderNavLinkStyle}
          _focus={activeAdminHeaderNavLinkStyle}
          _active={activeAdminHeaderNavLinkStyle}
          whiteSpace="nowrap"
          borderRadius={0}
        >
          {children}
        </Button>
      )}
    </NavLink>
  )
}
