import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, RadioGroup, VStack } from '@chakra-ui/react'
import { FormErrorMessage, Radio } from '@opengovsg/design-system-react'

import { UpdateRadioFieldReq } from '~shared/dto'

import { FieldLabel } from './FieldLabel'

export const RadioFieldView = ({
  id,
  title,
  description,
  fieldOptions,
}: UpdateRadioFieldReq) => {
  const {
    formState: { errors },
    control,
    getValues,
    resetField,
  } = useFormContext<Record<string, string>>()

  // If fetching latest state of event results in current option
  // being made invalid, reset this field
  useEffect(() => {
    const currentValue = getValues(id)
    if (!fieldOptions.includes(currentValue)) {
      resetField(id)
    }
  }, [getValues, fieldOptions, id, resetField])

  return (
    <FormControl isRequired isInvalid={!!errors[id]?.message}>
      <FieldLabel title={title} description={description} />
      <Controller
        name={id}
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field: { onChange, value } }) => (
          <RadioGroup onChange={onChange} value={value}>
            <VStack>
              {fieldOptions.map((option) => {
                return (
                  <Radio key={`radio-option-${option}`} value={option}>
                    {option}
                  </Radio>
                )
              })}
            </VStack>
          </RadioGroup>
        )}
      />
      <FormErrorMessage>{errors[id]?.message}</FormErrorMessage>
    </FormControl>
  )
}
