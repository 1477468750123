export type DisplayTimeUnits = 'days' | 'hours' | 'minutes'

export const DISPLAY_TIME_UNIT_DAYS = {
  value: 'days' as const,
  label: 'days',
}

export const DISPLAY_TIME_UNIT_HOURS = {
  value: 'hours' as const,
  label: 'hours',
}

export const DISPLAY_TIME_UNIT_MINUTES = {
  value: 'minutes' as const,
  label: 'minutes',
}

export const DISPLAY_TIME_UNIT_OPTIONS: {
  value: DisplayTimeUnits
  label: string
}[] = [
  DISPLAY_TIME_UNIT_DAYS,
  DISPLAY_TIME_UNIT_HOURS,
  DISPLAY_TIME_UNIT_MINUTES,
]

export const minutesToDisplayTimeUnits = (
  numMinutes: number | null,
): {
  quantity: number | null
  unit: DisplayTimeUnits
} => {
  if (numMinutes === null) {
    return {
      quantity: null,
      unit: 'days',
    }
  } else if (numMinutes % 1440 === 0) {
    return {
      quantity: numMinutes / 1440,
      unit: 'days',
    }
  } else if (numMinutes % 60 === 0) {
    return {
      quantity: numMinutes / 60,
      unit: 'hours',
    }
  } else {
    return {
      quantity: numMinutes,
      unit: 'minutes',
    }
  }
}

export const displayTimeUnitsToMinutes = ({
  quantity,
  unit,
}: {
  quantity: number | null
  unit: DisplayTimeUnits
}): number | null => {
  if (quantity === null) {
    return null
  }
  switch (unit) {
    case 'days':
      return quantity * 1440
    case 'hours':
      return quantity * 60
    case 'minutes':
      return quantity
  }
}

export const minsToMs = (mins: number) => mins * 60 * 1000
export const hoursToMs = (hours: number) => hours * 60 * 60 * 1000
export const hoursToMins = (hours: number) => hours * 60

export const doTimeslotsInArrayOverlap = (
  slots: { start: number; end: number }[],
): boolean => {
  for (const [index, slot1] of slots.entries()) {
    const remainingSlots = slots.slice(index + 1)
    if (
      remainingSlots.some((slot2) =>
        doTimeslotsOverlap(slot1.start, slot1.end, slot2.start, slot2.end),
      )
    ) {
      return true
    }
  }
  return false
}

export const doTimeslotsOverlap = (
  start1: number,
  end1: number,
  start2: number,
  end2: number,
): boolean => {
  //           <-- 1 --->
  // <-- 2 -->
  if (end2 <= start1) return false

  // <-- 1 -->
  //            <-- 2 -->
  if (start2 >= end1) return false

  //      <-- 1 --->
  // <-- 2 ----->
  // or
  //      <-- 1 -->
  // <-- 2 ---------->
  // or
  // <-- 1 ---------->
  //     <-- 2 -->
  // or
  // <-- 1 ---------->
  //    <-- 2 ---------->
  return true
}
