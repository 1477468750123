import { useFormContext } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import { FormErrorMessage, Input } from '@opengovsg/design-system-react'

import { UpdateShortTextFieldReq } from '~shared/dto'

import { FieldLabel } from './FieldLabel'

export const TextFieldView = ({
  id,
  title,
  description,
}: UpdateShortTextFieldReq) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<Record<string, string>>()
  return (
    <FormControl isRequired isInvalid={!!errors[id]?.message}>
      <FieldLabel title={title} description={description} />
      <Input
        w="100%"
        id={id}
        errorBorderColor="red.300"
        {...register(id, { required: `This field is required` })}
      ></Input>
      <FormErrorMessage>{errors[id]?.message}</FormErrorMessage>
    </FormControl>
  )
}
