import { Navigate, Outlet } from 'react-router-dom'
import { Flex, useDisclosure } from '@chakra-ui/react'

import { isNestError } from '~lib/api'
import { useIsClientMobile } from '~hooks/useIsClientMobile'
import { LoadingState } from '~components/LoadingState'

import { DesktopEventPageHeader } from './components/DesktopEventPageHeader'
import { MobileEventPageHeader } from './components/MobileEventPageHeader'
import { useGetEvent } from './hooks/useAdminEvents'
import { useEventIdParam } from './hooks/useEventIdParam'

export const EditEventPage = () => {
  const { eventId } = useEventIdParam()
  const {
    data: event,
    isLoading,
    isError,
    error,
  } = useGetEvent({
    eventId,
    retry: (failureCount, error) => {
      // Prevent retries if failureCount >= 3 OR status code is known to be 404
      return failureCount < 3 && (!isNestError(error) || error.status !== 404)
    },
  })
  const isMobile = useIsClientMobile()

  const shareFormModalDisclosure = useDisclosure()

  // If it is a 404 error, we navigate the user back to the dashboard.
  if (isError && isNestError(error) && error.status === 404) {
    return <Navigate to="/admin/dashboard" />
  }

  if (!event || isLoading) return <LoadingState flex={1} />

  return (
    <Flex flexDir="column" alignItems="stretch" minH="100%" flex={1}>
      {isMobile ? (
        <MobileEventPageHeader
          event={event}
          shareFormModalDisclosure={shareFormModalDisclosure}
        />
      ) : (
        <DesktopEventPageHeader
          event={event}
          shareFormModalDisclosure={shareFormModalDisclosure}
        />
      )}
      <Outlet context={{ event }} />
    </Flex>
  )
}
