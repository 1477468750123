import { useMemo } from 'react'
import { BiTimeFive } from 'react-icons/bi'
import { Box, HStack, Icon, StackProps, Text, VStack } from '@chakra-ui/react'

import { timeToHumanReadable } from '~shared/utils'

type TimeslotSummaryProps = {
  startAt: number
  endAt: number
  numSlotsRemaining: number
} & StackProps

export const TimeslotSummary = ({
  startAt,
  endAt,
  numSlotsRemaining,
  ...rest
}: TimeslotSummaryProps) => {
  const timeText = useMemo(() => {
    return `${timeToHumanReadable(startAt)} - ${timeToHumanReadable(endAt)}`
  }, [endAt, startAt])

  const slotsText = useMemo(() => {
    if (numSlotsRemaining <= 0) return 'All slots booked'
    return `${numSlotsRemaining} slot${
      numSlotsRemaining === 1 ? '' : 's'
    } available`
  }, [numSlotsRemaining])

  return (
    <VStack {...rest}>
      <HStack alignItems="center">
        <Icon as={BiTimeFive} fontSize={{ base: '1rem', md: '1.25rem' }} />
        <Text> {timeText}</Text>
      </HStack>
      <Box
        bg={
          numSlotsRemaining > 0
            ? 'interaction.success-subtle.default'
            : 'interaction.main-subtle.default'
        }
        px="8px"
        py="4px"
        borderRadius="4px"
      >
        <Text
          fontSize="0.75rem"
          color={
            numSlotsRemaining > 0
              ? 'utility.feedback.success'
              : 'utility.feedback.info'
          }
        >
          {slotsText}
        </Text>
      </Box>
    </VStack>
  )
}
