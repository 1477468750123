import { GetEventRes, UpdateEventReq } from '~shared/dto'
import { EventAuthType, EventUniqueNricConstraint } from '~shared/types'

import { SgidSettingsFormFields } from '../types'

export const getSgidSettingsFields = (
  event: GetEventRes,
): SgidSettingsFormFields => {
  const { authenticationType, uniqueNricConstraint } = event
  const isSgidEnabled = authenticationType === EventAuthType.SgidWithNric
  const isUniqueNricConstraintEnabled =
    isSgidEnabled && uniqueNricConstraint !== null
  return {
    isSgidEnabled,
    isUniqueNricConstraintEnabled,
  }
}

export const sgidSettingsFieldsToReqBody = (
  fields: SgidSettingsFormFields,
): UpdateEventReq => {
  const uniqueNricConstraint =
    fields.isSgidEnabled && fields.isUniqueNricConstraintEnabled
      ? EventUniqueNricConstraint.UniqueActive
      : null

  return {
    uniqueNricConstraint,
    authenticationType: fields.isSgidEnabled
      ? EventAuthType.SgidWithNric
      : EventAuthType.NoAuth,
  }
}
