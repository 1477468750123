export enum EventAuthType {
  NoAuth = 'noAuth',
  SgidWithNric = 'sgidWithNric',
}

export enum EventUniqueNricConstraint {
  UniqueActive = 'uniqueActive',
}

export enum EventStatus {
  Open = 'open',
  Closed = 'closed',
}

export enum EventEmailSetting {
  // Attendee does not see form responses
  NoFormResponses = 'noFormResponses',
  // Attendee sees form responses
  IncludeFormResponses = 'includeFormResponses',
}
