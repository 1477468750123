import { GetEventListRes } from '~shared/dto'

import { EventListFormFields } from './types'

export const eventListFormFieldsToReqBody = (fields: EventListFormFields) => {
  return {
    title: fields.eventListName,
    description: fields.eventListDescription,
  }
}

export const getEventListFormFields = (eventList: GetEventListRes) => {
  return {
    eventListName: eventList.title,
    eventListDescription: eventList.description,
  }
}
