import { FormProvider, useForm } from 'react-hook-form'
import {
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react'
import { ModalCloseButton } from '@opengovsg/design-system-react'

import { GetEventListRes } from '~shared/dto'

import { useAdminAuth } from '~features/auth'

import { EventListFormFields } from '../EventListForm'
import { EventListCollaboratorForm } from '../EventListForm/EventListCollaboratorForm'
import { EventListTransferOwnershipForm } from '../EventListForm/EventListTransferOwnershipForm'
import { getEventListFormFields } from '../EventListForm/utils'

import { EditEventListForm } from './EditEventListForm'

export interface EditEventListModalProps {
  isOpen: boolean
  onClose: () => void
  eventList: GetEventListRes
}

export const EditEventListModal = ({
  isOpen,
  onClose,
  eventList,
}: EditEventListModalProps) => {
  const { adminUser } = useAdminAuth()
  const editEventListFormMethods = useForm<EventListFormFields>({
    defaultValues: getEventListFormFields(eventList),
  })

  const { reset } = editEventListFormMethods

  const handleModalClose = () => {
    onClose()
    reset()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent my={{ base: 0, md: 8 }}>
        <ModalCloseButton />
        <ModalHeader>Edit event list settings</ModalHeader>
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <FormProvider {...editEventListFormMethods}>
              <EditEventListForm eventListId={eventList.id} />
            </FormProvider>
            <Divider />
            <EventListCollaboratorForm
              eventListId={eventList.id}
              adminEmail={eventList.admin.email}
              collaborators={eventList.permissions.map(
                (eventListPermission) => ({
                  email: eventListPermission.userEmail,
                  accessLevel: eventListPermission.accessLevel,
                }),
              )}
            />
            {adminUser?.id === eventList.admin.id && (
              <>
                <EventListTransferOwnershipForm eventListId={eventList.id} />
              </>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}
