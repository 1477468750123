import { ErrorBoundary } from 'react-error-boundary'
import { Outlet } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import { RestrictedGovtMasthead } from '@opengovsg/design-system-react'

import { ErrorCard } from '~components/ErrorCard'

export const AppLayout = () => {
  return (
    <ErrorBoundary fallback={<ErrorCard />}>
      <Flex flexDir={'column'} minH="100%" bgColor="base.canvas.alt">
        <RestrictedGovtMasthead />
        <Outlet />
      </Flex>
    </ErrorBoundary>
  )
}
