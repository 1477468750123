import { Outlet } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

import { PublicBanner } from '~features/banner'

export const PublicLayout = () => {
  return (
    <Box>
      <PublicBanner />
      <Outlet />
    </Box>
  )
}
