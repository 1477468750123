import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { Day } from '../types'

dayjs.extend(timezone)
dayjs.extend(utc)

export const dateToDay = (d: Date | dayjs.Dayjs): Day => {
  const dayIndex = dayjs.isDayjs(d) ? d.day() : d.getDay()
  switch (dayIndex) {
    case 0:
      return Day.Sun
    case 1:
      return Day.Mon
    case 2:
      return Day.Tue
    case 3:
      return Day.Wed
    case 4:
      return Day.Thu
    case 5:
      return Day.Fri
    default:
      return Day.Sat
  }
}

export const nextDay = (d: Day): Day => {
  switch (d) {
    case Day.Sun:
      return Day.Mon
    case Day.Mon:
      return Day.Tue
    case Day.Tue:
      return Day.Wed
    case Day.Wed:
      return Day.Thu
    case Day.Thu:
      return Day.Fri
    case Day.Fri:
      return Day.Sat
    case Day.Sat:
      return Day.Sun
  }
}

export const epochToDate = (epoch: number | null | undefined): Date | null => {
  return typeof epoch === 'number' ? new Date(epoch) : null
}

export const TIMEZONE = 'Asia/Singapore'

export const getStartOfDateInTz = (
  date: Date | null | number | undefined,
  tz = TIMEZONE,
): Date | null => {
  if (date === null || date === undefined) return null

  return dayjs(date).tz(tz, true).startOf('d').toDate()
}

export const getEndOfDateInTz = (
  date: Date | null | number | undefined,
  tz = TIMEZONE,
): Date | null => {
  if (date === null || date === undefined) return null

  return dayjs(date).tz(tz, true).endOf('d').toDate()
}
