import { BiRightArrowAlt } from 'react-icons/bi'
import { Divider, Spacer, Text, VStack } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { GetPublicEventRes } from '~shared/dto'

import { useIsClientMobile } from '~hooks/useIsClientMobile'
import { Linkify } from '~components/Linkify'

import { EventSummary } from '~features/form/components/EventSummary'
import { SgidAuthButtonGroup } from '~features/form/components/SgidButtons/SgidAuthButtonGroup'
import {
  setFormStateSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { FormState } from '~features/form/types/formState'

export interface EventSummaryPageProps {
  event: GetPublicEventRes
}

export const EventSummaryPage = ({ event }: EventSummaryPageProps) => {
  const setFormState = useFormStore(setFormStateSelector)
  const isMobile = useIsClientMobile()
  const { title, description } = event

  return (
    <VStack spacing={6} w="full" flex={1} alignItems="stretch">
      <Text textStyle={{ base: 'h6', md: 'h4' }} mt={{ base: 0, md: '52px' }}>
        {title}
      </Text>
      {/* This divider only shows on the desktop view */}
      {!isMobile && <Divider />}
      <EventSummary event={event} />
      <Text
        textStyle={'body-2'}
        whiteSpace={'pre-wrap'}
        overflowWrap="anywhere"
      >
        <Linkify>{description}</Linkify>
      </Text>
      {isMobile && <Spacer />}
      <SgidAuthButtonGroup
        event={event}
        loggedOutButtonText="Book with Singpass"
        loggedOutDescriptionText="You need to verify your identity with Singpass before making this booking"
      >
        <Button
          rightIcon={<BiRightArrowAlt fontSize="1.5rem" />}
          w="full"
          h={{ base: '56px', md: 'auto' }}
          onClick={() => setFormState(FormState.PickDate)}
        >
          Make a booking
        </Button>
      </SgidAuthButtonGroup>
    </VStack>
  )
}
