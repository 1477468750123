import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button, ModalCloseButton } from '@opengovsg/design-system-react'

import { CreateEventListReq } from '~shared/dto'

import { ButtonStack } from '~components/ButtonStack'

import { useCreateEventList } from '~features/event-lists/hooks/useAdminEventLists'

import {
  EventListDescriptionInput,
  EventListFormFields,
  EventListNameInput,
} from '../EventListForm'
import { eventListFormFieldsToReqBody } from '../EventListForm/utils'

export interface CreateEventListModalProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateEventListModal = ({
  isOpen,
  onClose,
}: CreateEventListModalProps) => {
  const { mutate, isLoading } = useCreateEventList()
  const navigate = useNavigate()
  const formMethods = useForm<EventListFormFields>({
    defaultValues: {
      eventListName: '',
      eventListDescription: '',
    },
  })

  const { handleSubmit, reset } = formMethods

  const handleModalClose = () => {
    onClose()
    reset()
  }

  const onSubmit = useCallback(
    (fields: EventListFormFields) => {
      const createEventListReq: CreateEventListReq =
        eventListFormFieldsToReqBody(fields)

      mutate(createEventListReq, {
        onSuccess: (eventList) => {
          navigate(`../event-list/${eventList.id}`)
        },
      })
    },
    [mutate, navigate],
  )

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent my={{ base: 0, md: 8 }}>
        <ModalCloseButton />
        <ModalHeader>Create an event list</ModalHeader>
        <ModalBody>
          <Text textStyle="body-2" mb="1rem">
            Event lists allow you to group multiple events into a single page,
            e.g. if your event has multiple locations, services or sub-events.
          </Text>
          <FormProvider {...formMethods}>
            <VStack spacing={6} alignItems="stretch" mt={2} w="full">
              <EventListNameInput />
              <EventListDescriptionInput />
            </VStack>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <ButtonStack isReverseOrderOnMobile>
            <Button
              variant="clear"
              colorScheme="secondary"
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Create event list
            </Button>
          </ButtonStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
