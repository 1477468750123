import { useState } from 'react'
import { BiLeftArrowAlt } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button, BxX, IconButton } from '@opengovsg/design-system-react'

import { adminEventRoutes } from '~/app/admin.routes'

export type MobileEventPageNavDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

export const MobileEventPageNavDrawer = ({
  isOpen,
  onClose,
}: MobileEventPageNavDrawerProps) => {
  const [expandedCategories, setExpandedCategories] = useState<
    number | number[]
  >()
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="xs">
      <DrawerOverlay />
      <DrawerContent backgroundColor="utility.ui">
        <DrawerHeader px={2}>
          <Flex w="full">
            <Link to="/admin/dashboard">
              <Button
                leftIcon={<BiLeftArrowAlt fontSize="1.25rem" />}
                aria-label="back to all events"
                variant="clear"
                onClick={onClose}
              >
                <Text textStyle="body-1">All Events</Text>
              </Button>
            </Link>
            <Spacer />
            <IconButton
              icon={<BxX />}
              aria-label="close drawer"
              variant="clear"
              onClick={onClose}
            />
          </Flex>
        </DrawerHeader>

        <DrawerBody px={2}>
          <Accordion
            allowMultiple
            defaultIndex={expandedCategories}
            onChange={setExpandedCategories}
          >
            {adminEventRoutes.map((route) => {
              const { path: parentPath, label: parentLabel } = route
              if (!parentPath || !parentLabel) {
                return null
              }

              if (!route.subNavigation) {
                return (
                  <Link to={parentPath} onClick={onClose} key={parentPath}>
                    <Box ml={4} py={2} mt={2}>
                      {parentLabel}
                    </Box>
                  </Link>
                )
              }

              return (
                <AccordionItem key={parentPath} border="none">
                  <h2>
                    <AccordionButton mt={2}>
                      <Box as="span" flex="1" textAlign="left">
                        {parentLabel}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel mt={2} pt={0} pb={0}>
                    <VStack alignItems="start" w="full" spacing={2}>
                      {/* We pad the elements instead of adding margin to vstack
                      for a bigger hitbox */}
                      {route.subNavigation.map((subRoute) =>
                        subRoute.label && subRoute.path ? (
                          <Link
                            key={subRoute.path}
                            to={`${parentPath}/${subRoute.path}`}
                            onClick={onClose}
                            style={{ width: '100%' }}
                          >
                            <Box w="full" pl={4} py={2}>
                              {subRoute.label}
                            </Box>
                          </Link>
                        ) : null,
                      )}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              )
            })}
          </Accordion>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
