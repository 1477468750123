import { memo } from 'react'
import { Box } from '@chakra-ui/react'
import { FormLabel } from '@opengovsg/design-system-react'

export interface FieldLabelProps {
  title: string
  description: string
}

export const FieldLabel = memo(({ title, description }: FieldLabelProps) => {
  return (
    <Box mb="0.75rem">
      <FormLabel mb={0}>{title}</FormLabel>
      <FormLabel.Description color="text.description" whiteSpace={'pre-wrap'}>
        {description}
      </FormLabel.Description>
    </Box>
  )
})

FieldLabel.displayName = 'FieldLabel'
