import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import {
  CreateEventListReq,
  GetEventListRes,
  ModifyEventListPermissionReq,
  UpdateEventListOwnerReq,
  UpdateEventListReq,
} from '~shared/dto'

import { api } from '~lib/api'
import { adminQueryKeys } from '~constants/queryKeys'

type UseGetEventList = {
  eventListId: string
} & UseQueryOptions<
  GetEventListRes,
  unknown,
  GetEventListRes,
  ReturnType<typeof adminQueryKeys.eventLists>
>

export const useGetEventList = ({
  eventListId,
  ...useQueryOptions
}: UseGetEventList) => {
  return useQuery(
    adminQueryKeys.eventLists({ eventListId }),
    () => api.get(`/admin/event-lists/${eventListId}`).json<GetEventListRes>(),
    useQueryOptions,
  )
}

export const useCreateEventList = () => {
  const queryCache = useQueryClient()
  return useMutation(
    async (req: CreateEventListReq) =>
      api.url(`/admin/event-lists`).post(req).json<GetEventListRes>(),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(adminQueryKeys.eventLists())
        await queryCache.invalidateQueries(adminQueryKeys.dashboard())
      },
      onError: () => {
        // Do nothing here, error cannot be shown in a toast because this is called
        // from a modal, which overlays toasts.
      },
    },
  )
}

export const useUpdateEventList = (eventListId: string) => {
  const queryCache = useQueryClient()
  return useMutation(
    async (req: UpdateEventListReq) =>
      api
        .url(`/admin/event-lists/${eventListId}`)
        .put(req)
        .json<GetEventListRes>(),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(adminQueryKeys.eventLists())
        await queryCache.invalidateQueries(adminQueryKeys.dashboard())
      },
      onError: () => {
        // Do nothing here, error cannot be shown in a toast because this is called
        // from a modal, which overlays toasts.
      },
    },
  )
}

export const useDeleteEventList = () => {
  const queryCache = useQueryClient()
  return useMutation(
    async (eventListId: string) =>
      api.url(`/admin/event-lists/${eventListId}`).delete().res(),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(adminQueryKeys.dashboard())
        // We don't invalidate adminQueryKeys.eventLists here because it
        // will trigger a refetch of the deleted event list, which returns
        // a 404 since it is now deleted. The 404 causes an error toast
        // that we dont want.
      },
    },
  )
}

export const useModifyEventListPermission = (eventListId: string) => {
  const queryCache = useQueryClient()
  return useMutation(
    async (req: ModifyEventListPermissionReq) =>
      api.url(`/admin/event-lists/${eventListId}/permissions`).put(req).res(),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(
          adminQueryKeys.eventLists({ eventListId }),
        )
        // We don't invalidate adminQueryKeys.eventLists here because it
        // will trigger a refetch of the deleted event list, which returns
        // a 404 since it is now deleted. The 404 causes an error toast
        // that we dont want.
      },
      onError: () => {
        // We pass a no-op here to prevent the default toast from showing
      },
    },
  )
}

export const useUpdateEventListOwner = (eventListId: string) => {
  const queryCache = useQueryClient()
  return useMutation(
    async (req: UpdateEventListOwnerReq) =>
      api.url(`/admin/event-lists/${eventListId}/admin`).put(req).res(),
    {
      onSuccess: () => {
        void queryCache.invalidateQueries(
          adminQueryKeys.eventLists({ eventListId }),
        )
      },
      onError: () => {
        // We pass a no-op here to prevent the default toast from showing
      },
    },
  )
}
