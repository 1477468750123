import { useState } from 'react'
import { VStack } from '@chakra-ui/react'
import { Content, EditorContent, useEditor } from '@tiptap/react'

import { CharacterCountView } from './CharacterCountView'
import { DEFAULT_MAX_CHAR_COUNT } from './constants'
import { RichTextMenu } from './RichTextMenu'
import { styles } from './styles'
import { getCharacterCountFromEditor, getRteExtensions } from './utils'

export const RichTextEditor = ({
  content,
  setContent,
  isReadOnly = false,
  onChange,
  characterLimit = DEFAULT_MAX_CHAR_COUNT,
}: {
  content: Content
  setContent?: (content: string) => void
  isReadOnly?: boolean
  onChange?: VoidFunction
  characterLimit?: number
}) => {
  const editor = useEditor({
    extensions: getRteExtensions({ characterLimit }),
    onUpdate: ({ editor }) => {
      const numChars = getCharacterCountFromEditor(editor)

      setContent?.(numChars > 0 ? editor.getHTML() : '')
      onChange?.()
    },
    content,
    onFocus: () => {
      setIsFocused(true)
    },
    onBlur: () => {
      setIsFocused(false)
    },
  })

  if (isReadOnly) {
    editor?.setEditable(false)
  }

  const [isFocused, setIsFocused] = useState(false)

  return (
    <VStack
      width="full"
      wordBreak="break-word"
      alignItems="left"
      sx={styles({
        isFocused,
        isReadOnly,
      })}
      spacing={0}
    >
      {!isReadOnly && <RichTextMenu editor={editor} />}
      <EditorContent editor={editor} />
      {!!editor && !isReadOnly && (
        <CharacterCountView
          editor={editor}
          characterLimit={characterLimit}
          pt={3}
        />
      )}
    </VStack>
  )
}
