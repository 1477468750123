import { BiCalendarWeek } from 'react-icons/bi'
import {
  Container,
  Icon,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { Tile } from '@opengovsg/design-system-react'

export interface OneOffEventTileProps {
  isSelected: boolean
  onClick?: () => void
}

export const OneOffEventTile = ({
  isSelected,
  onClick,
}: OneOffEventTileProps) => {
  return (
    <Tile
      icon={() => <Icon as={BiCalendarWeek} boxSize={10} />}
      variant="complex"
      flex="1"
      isSelected={isSelected}
      onClick={() => onClick && onClick()}
    >
      <Tile.Title>One-off event</Tile.Title>
      <Tile.Subtitle>Standalone and does not repeat</Tile.Subtitle>
      <Tile.Text as={Container} padding={0}>
        <Text textAlign={'left'}>Examples:</Text>
        <UnorderedList listStylePosition={'inside'}>
          <ListItem>3-day conference</ListItem>
          <ListItem>Pop-up event</ListItem>
        </UnorderedList>
      </Tile.Text>
    </Tile>
  )
}
