import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { GetBannerRes } from '~shared/types/health.dto'

import { api } from '~lib/api'

import { bannerQueryKeys } from '../query-keys'

type UseAdminBanner = UseQueryOptions<
  GetBannerRes,
  unknown,
  GetBannerRes,
  ReturnType<typeof bannerQueryKeys.adminBannerKey>
>

export const useAdminBanner = (useQueryOptions: UseAdminBanner = {}) => {
  return useQuery(
    bannerQueryKeys.adminBannerKey(),
    async () => api.get(`/metadata/admin-banner`).json<GetBannerRes>(),
    {
      refetchOnWindowFocus: true,
      refetchInterval: 1000 * 60 * 5, // 5 minutes,
      ...useQueryOptions,
    },
  )
}
