import { Box, BoxProps, Text } from '@chakra-ui/react'

interface SectionBodyTextProps extends BoxProps {
  children: string | JSX.Element | JSX.Element[]
}

export const SectionBodyText = ({
  children,
  ...props
}: SectionBodyTextProps) => {
  return (
    <Box mt="1rem" {...props}>
      <Text
        sx={{
          text: {
            textStyle: 'body-1',
            color: 'secondary.500',
          },
        }}
      >
        {children}
      </Text>
    </Box>
  )
}
