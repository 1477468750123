import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  ButtonGroup,
  Flex,
  HStack,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Button, Link as StyledLink } from '@opengovsg/design-system-react'

import { GetEventRes } from '~shared/dto'

import { adminEventRoutes } from '~/app/admin.routes'

import { HOSTNAME_WITH_PROTOCOL } from '~constants/config'
import { AdminHeaderNavLink } from '~components/AdminHeaderNavLink'

import { CopyToClipboardButton } from '../CopyToClipboardButton'

import { ShareEventModal } from './ShareEventModal'

export const DesktopEventPageHeader = ({
  event,
  shareFormModalDisclosure,
}: {
  event: GetEventRes
  shareFormModalDisclosure: ReturnType<typeof useDisclosure>
}) => {
  const publicUrl = useMemo(
    () => `${HOSTNAME_WITH_PROTOCOL}/${event.id}`,
    [event.id],
  )

  const breadcrumbUrl = useMemo(() => {
    if (event.eventListIds?.length) {
      // This works for now while events can only belong to
      // 1 event list
      return `/admin/event-list/${event.eventListIds[0]}`
    }
    return `/admin/dashboard`
  }, [event.eventListIds])

  return (
    <>
      <Flex
        py={2}
        px={8}
        gap={12}
        justify="space-between"
        alignItems="center"
        borderBottom="1px"
        borderColor="grey.100"
        backgroundColor="utility.ui"
        position="sticky"
        top={0}
        zIndex="docked"
        h="event-header-height"
        w="full"
      >
        <Flex alignItems="center" gap={2} flex={1}>
          <StyledLink flexShrink={0} as={Link} to={breadcrumbUrl}>
            <Text>
              {event.eventListIds?.length ? `Event list` : `All events`}
            </Text>
          </StyledLink>
          <Text>{`/`}</Text>
          <Text noOfLines={1} wordBreak="break-word">{`${event.title}`}</Text>
        </Flex>
        <HStack
          spacing={8}
          flex={1}
          justifyContent="center"
          flexShrink={0}
          flexGrow={0}
        >
          {adminEventRoutes.map(
            ({ path, label }) =>
              !!path &&
              !!label && (
                <AdminHeaderNavLink
                  to={path}
                  key={path}
                  data-testid={`admin-header-${path}`}
                >
                  {label}
                </AdminHeaderNavLink>
              ),
          )}
        </HStack>

        <ButtonGroup flex={1}>
          <Spacer />
          <CopyToClipboardButton content={publicUrl} />

          <Button onClick={shareFormModalDisclosure.onOpen}>Share</Button>
        </ButtonGroup>
      </Flex>
      <ShareEventModal
        eventId={event.id}
        eventStatus={event.eventStatus}
        isOpen={shareFormModalDisclosure.isOpen}
        onClose={shareFormModalDisclosure.onClose}
      />
    </>
  )
}
