import { BiShow } from 'react-icons/bi'
import { Text, useDisclosure } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { BOOKING_TIME_TEMPLATE_STRING } from '~shared/constants'

import { EmailPreviewModal, EmailPreviewProps } from './EmailPreviewModal'

export const EmailPreview = (emailProps: EmailPreviewProps) => {
  const previewModalDisclosure = useDisclosure()

  const formattedBody = emailProps.emailBody
    ? emailProps.emailBody.replace(
        BOOKING_TIME_TEMPLATE_STRING,
        '<i>(Booking time automatically inserted here)</i>',
      )
    : ''

  const newProps = { ...emailProps, emailBody: formattedBody }

  return (
    <>
      <Button
        variant="outline"
        leftIcon={<BiShow fontSize="1.25rem" />}
        aria-label={'Preview Email'}
        onClick={previewModalDisclosure.onOpen}
        size="sm"
      >
        <Text textStyle={'subhead-2'}>Preview</Text>
      </Button>
      <EmailPreviewModal
        emailProps={newProps}
        disclosure={previewModalDisclosure}
      />
    </>
  )
}
