import { Text, VStack } from '@chakra-ui/react'

import { BookingFlowErrorLayout } from './BookingFlowErrorLayout'

export const EventListEmpty = () => {
  return (
    <BookingFlowErrorLayout>
      <VStack paddingX={8}>
        <Text textStyle="h5">{'This event list is empty.'}</Text>

        {/* Max width to make the text easier to read */}
        <Text maxW="28rem">
          If you think this is a mistake, please contact the agency that gave
          you this booking link.
        </Text>
      </VStack>
    </BookingFlowErrorLayout>
  )
}
