import { ChangeEvent } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { Flex, FormControl, HStack, Icon, Text } from '@chakra-ui/react'
import {
  BxsHelpCircle,
  Checkbox,
  DateRangePicker,
  FormErrorMessage,
  TouchableTooltip,
} from '@opengovsg/design-system-react'

import { BookingRulesFields } from '../types/field-info'
import { validateStartEndDateInterval } from '../utils/form-utils'

export const BookingLimits = () => {
  const {
    control,
    formState: { errors },
    clearErrors,
    register,
  } = useFormContext<BookingRulesFields>()
  // useWatch ensures that this component rerenders when isDateRangeEnabled
  // changes, else disabled and error states go out of sync
  const isDateRangeEnabled = useWatch<BookingRulesFields>({
    control,
    name: 'isDateRangeEnabled',
  }) as boolean
  return (
    <Flex flexDir={'column'} alignItems={'flex-start'}>
      <Text textStyle="h6" mb="24px">
        Booking limits
      </Text>
      {/* We wrap the Checkbox in a HStack to prevent it from stretching to full width */}
      <HStack>
        <Checkbox
          size="sm"
          mb={1.5}
          {...register('isDateRangeEnabled', {
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              if (!event.target.checked) {
                clearErrors('dateRange')
              }
            },
          })}
        >
          <HStack alignItems="center">
            <Text textStyle={'subhead-2'} as="span">
              Only allow bookings within a date range
            </Text>
            <TouchableTooltip
              label="e.g. if this is 1 - 5 Jan, then slots will only be available on 1 - 5 Jan"
              placement={'top'}
            >
              {/* We use display block to prevent the icon from moving itself upwards and getting misaligned */}
              {/* The click handler prevents activating the default behaviour of toggling the checkbox */}
              <Icon
                onClick={(evt) => evt.preventDefault()}
                as={BxsHelpCircle}
                fontSize="1rem"
                display="block"
                aria-hidden
              />
            </TouchableTooltip>
          </HStack>
          <Text textStyle={'body-2'} as="span">
            Use this if you have a fixed start and end date
          </Text>
        </Checkbox>
      </HStack>
      <FormControl isInvalid={!!errors.dateRange} pl="36px">
        <Controller
          control={control}
          name="dateRange"
          rules={{
            validate: {
              startAndEndRequired: (dates) => {
                if (!isDateRangeEnabled) return true
                return (
                  (!!dates[0] && !!dates[1]) ||
                  'Please specify start and end dates'
                )
              },
              maxInterval: validateStartEndDateInterval,
            },
          }}
          render={({ field }) => (
            <DateRangePicker
              {...field}
              isDisabled={!isDateRangeEnabled}
              size="sm"
            />
          )}
        />
        <FormErrorMessage>{errors.dateRange?.message}</FormErrorMessage>
      </FormControl>
    </Flex>
  )
}
