import { Controller, useFormState } from 'react-hook-form'
import { FormControl, VStack } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { Checkbox, FormErrorMessage } from '@opengovsg/design-system-react'

import { GeneralSettingsFormFields } from '../types'

export const EventStatusCheckbox = () => {
  const { errors } = useFormState<GeneralSettingsFormFields>()
  return (
    <FormControl isInvalid={!!errors.isEventOpen}>
      <Controller
        name="isEventOpen"
        render={({ field: { onChange, value } }) => (
          <Checkbox
            size="sm"
            checked={value as boolean}
            defaultChecked={value as boolean}
            onChange={(val) => onChange(val.target.checked)}
            name="isEventOpen"
          >
            <VStack spacing={0} alignItems="stretch">
              <Text textStyle="subhead-2">Open event for new bookings</Text>
              <Text textStyle="body-2">
                When your event is closed, attendees can still reschedule/cancel
                bookings. You can adjust the advance notice period below.
              </Text>
            </VStack>
          </Checkbox>
        )}
      />
      <FormErrorMessage>{errors.isEventOpen?.message}</FormErrorMessage>
    </FormControl>
  )
}
