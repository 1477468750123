import { useMemo } from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { Divider, Spacer, Text, VStack } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { GetPublicBookingRes, GetPublicEventRes } from '~shared/dto'
import { minsToMs } from '~shared/utils'

import { useIsClientMobile } from '~hooks/useIsClientMobile'
import { BookingRescheduleNotAllowed } from '~components/ErrorPages'
import { Hideable } from '~components/Hideable'
import { Linkify } from '~components/Linkify'

import { EventSummary } from '~features/form/components/EventSummary'
import { SgidAuthButtonGroup } from '~features/form/components/SgidButtons/SgidAuthButtonGroup'
import {
  setFormStateSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { FormState } from '~features/form/types/formState'

export interface RescheduleSummaryPageProps {
  event: GetPublicEventRes
  previousBooking: GetPublicBookingRes
}

export const RescheduleSummaryPage = ({
  event,
  previousBooking,
}: RescheduleSummaryPageProps) => {
  const setFormState = useFormStore(setFormStateSelector)
  const { title, description } = event
  const isMobile = useIsClientMobile()

  const changeLeadTimeMins = useMemo(
    () => event.schedules[0].minChangeLeadTimeMinutes,
    [event.schedules],
  )

  const isRescheduleAllowed =
    changeLeadTimeMins === null ||
    previousBooking.startAt - Date.now() >= minsToMs(changeLeadTimeMins)

  if (!isRescheduleAllowed) {
    return (
      <VStack spacing={6} w="full" flex={1} alignItems="stretch">
        <Text textStyle={{ base: 'subhead-2', md: 'h6' }}>{title}</Text>
        <VStack alignItems="stretch">
          <Text textStyle="body-2">Current booking:</Text>
          <EventSummary event={event} booking={previousBooking} />
        </VStack>
        <Divider />
        <BookingRescheduleNotAllowed
          rescheduleLeadTimeMins={changeLeadTimeMins}
        />
      </VStack>
    )
  }

  return (
    <VStack spacing={6} w="full" flex={1} alignItems="stretch">
      <VStack spacing={1} mt={{ base: 0, md: '52px' }} alignItems="stretch">
        <Text textStyle={{ base: 'h6', md: 'h4' }}>Change this booking?</Text>
        <Text textStyle="body-2">
          {`We'll keep your booking until you confirm your new timeslot`}
        </Text>
      </VStack>
      <Divider />
      <VStack alignItems="stretch">
        <Text textStyle={{ base: 'subhead-2', md: 'h6' }}>{title}</Text>
        <Hideable maxHeightPx={80}>
          <Text
            textStyle={'body-2'}
            whiteSpace={'pre-wrap'}
            overflowWrap="anywhere"
          >
            <Linkify>{description}</Linkify>
          </Text>
        </Hideable>
      </VStack>
      <VStack alignItems="stretch">
        <Text textStyle="body-2">Current booking:</Text>
        <EventSummary event={event} booking={previousBooking} />
      </VStack>

      {isMobile && <Spacer />}
      <SgidAuthButtonGroup
        event={event}
        loggedOutButtonText="Change with Singpass"
        loggedOutDescriptionText="You need to verify your identity with Singpass before changing this booking"
      >
        <Button
          rightIcon={<BiRightArrowAlt fontSize="1.5rem" />}
          w="full"
          h={{ base: '56px', md: 'auto' }}
          onClick={() => setFormState(FormState.PickDate)}
        >
          Change booking
        </Button>
        <Button
          w="full"
          h={{ base: '56px', md: 'auto' }}
          colorScheme="critical"
          variant="outline"
          as="a"
          href={`?bookingId=${previousBooking.id}&action=cancel`}
        >
          Cancel booking
        </Button>
      </SgidAuthButtonGroup>
    </VStack>
  )
}
