import { useForm } from 'react-hook-form'
import { Flex, FormControl, Text, VStack } from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@opengovsg/design-system-react'

import { GetEventRes } from '~shared/dto'
import { isAsciiLocalpartEmail } from '~shared/utils/validation'

import { getNetworkErrorMessage } from '~lib/api'

import { useModifyEventPermission } from '~features/events/hooks/useEventPermission'

import { CollaboratorHeading } from './CollaboratorHeading'

export const AddCollaboratorForm = ({ event }: { event: GetEventRes }) => {
  const toast = useToast()
  const {
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    register,
  } = useForm<{ email: string }>({
    mode: 'onSubmit',
    defaultValues: { email: '' },
  })

  const { mutate, isLoading } = useModifyEventPermission(event.id)

  const existingCollaborators = [
    event.admin.email,
    ...event.permissions.map(({ userEmail }) => userEmail),
  ]

  const onSubmit = handleSubmit(({ email }) => {
    mutate(
      {
        userEmail: email.toLowerCase(),
        accessLevel: 'editor',
      },
      {
        onSuccess: (_res, { userEmail }) => {
          toast({
            description: `Successfully added ${userEmail}`,
            status: 'success',
          })
          reset()
        },
        onError: (e) => {
          setError('email', {
            type: 'server',
            message: getNetworkErrorMessage(e),
          })
        },
      },
    )
  })

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={onSubmit}
    >
      <VStack spacing={6} alignItems="stretch">
        <CollaboratorHeading />

        <FormControl isInvalid={!!errors.email}>
          <FormLabel isRequired>{'Add collaborator'}</FormLabel>
          <Flex
            justifyContent="space-between"
            alignItems="end"
            gap={4}
            flexDir={{ base: 'column', md: 'row' }}
          >
            <Input
              {...register('email', {
                validate: {
                  existing: (email: string) =>
                    !existingCollaborators.includes(email) ||
                    'User is already a collaborator.',
                  valid: (email: string) =>
                    isAsciiLocalpartEmail(email) ||
                    'Please enter a valid email address. Special characters are not allowed.',
                },
              })}
            />
          </Flex>

          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <Button
          type="submit"
          isDisabled={!!errors.email}
          isLoading={isLoading}
          w={{ base: 'full', md: 'auto' }}
        >
          Add
        </Button>
      </VStack>
    </form>
  )
}
