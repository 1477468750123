import dayjs from 'dayjs'

import { UpdateAllScheduleOverridesReq, UpdateScheduleRes } from '~shared/dto'

import { TimeRange } from './ScheduleTimeRangeField/manage-schedule-time-range-utils'

export type OverrideSegment = TimeRange & { overrideId?: string }

export type ManageOneOffScheduleFormState = {
  slotDurationMins: number
  dates: {
    date: number
    timeslots: OverrideSegment[]
  }[]
}

export const generateOneOffDatesFormFromSchedule = (
  overrides: UpdateScheduleRes['overrides'],
): ManageOneOffScheduleFormState['dates'] => {
  const accum: Pick<ManageOneOffScheduleFormState, 'dates'> = {
    dates: [],
  }

  overrides.forEach((segment) => {
    const startOfDay = dayjs(segment.startAt).startOf('day').unix() * 1000
    const newTimeSegment: OverrideSegment = {
      start: segment.startAt - startOfDay,
      end: segment.endAt - startOfDay,
      overrideId: segment.id,
    }

    const indexOfDate = accum.dates.findIndex((d) => d.date === startOfDay)
    if (indexOfDate >= 0) {
      accum.dates[indexOfDate].timeslots.push(newTimeSegment)
    } else {
      accum.dates.push({
        date: startOfDay,
        timeslots: [newTimeSegment],
      })
    }
  })
  // We make sure the dates appear chronologically
  accum.dates.sort((a, b) => a.date - b.date)

  // We make sure that the timeslots appear chronologically
  accum.dates.forEach(({ timeslots }) =>
    timeslots.sort((a, b) => a.start - b.start),
  )
  return accum.dates
}

export const parseFormStateToOneOffSchedule = (
  newState: ManageOneOffScheduleFormState,
  capacity: number,
): UpdateAllScheduleOverridesReq => {
  return newState.dates.flatMap((dateData) =>
    dateData.timeslots.map((timeslot) => {
      const startTimestamp = dateData.date + timeslot.start
      const endTimestamp = dateData.date + timeslot.end

      return {
        startAt: startTimestamp,
        endAt: endTimestamp,
        capacity,
      }
    }),
  )
}
