import { Icon, IconProps } from '@chakra-ui/react'

export const LocationIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M7.99941 9.33203C9.47007 9.33203 10.6661 8.13603 10.6661 6.66536C10.6661 5.1947 9.47007 3.9987 7.99941 3.9987C6.52874 3.9987 5.33274 5.1947 5.33274 6.66536C5.33274 8.13603 6.52874 9.33203 7.99941 9.33203ZM7.99941 5.33203C8.73474 5.33203 9.33274 5.93003 9.33274 6.66536C9.33274 7.4007 8.73474 7.9987 7.99941 7.9987C7.26407 7.9987 6.66607 7.4007 6.66607 6.66536C6.66607 5.93003 7.26407 5.33203 7.99941 5.33203Z"
        fill="currentColor"
      />
      <path
        d="M7.61274 14.5414C7.72874 14.624 7.86407 14.6654 7.99941 14.6654C8.13474 14.6654 8.27007 14.624 8.38607 14.5414C8.58874 14.398 13.3521 10.9587 13.3327 6.66536C13.3327 3.7247 10.9401 1.33203 7.99941 1.33203C5.05874 1.33203 2.66607 3.7247 2.66607 6.66203C2.64674 10.9587 7.41007 14.398 7.61274 14.5414ZM7.99941 2.66536C10.2054 2.66536 11.9994 4.45936 11.9994 6.6687C12.0134 9.62737 9.07407 12.284 7.99941 13.1554C6.92541 12.2834 3.98541 9.62603 3.99941 6.66536C3.99941 4.45936 5.79341 2.66536 7.99941 2.66536Z"
        fill="currentColor"
      />
    </Icon>
  )
}
