import { Center, StackProps, VStack } from '@chakra-ui/react'

import { GetPublicEventRes } from '~shared/dto'

import { EventFormTitleBar } from '~features/form/components/EventFormTitle'

export type EventFormLayoutProps = {
  event: GetPublicEventRes
  showTitleBar?: boolean
} & StackProps

export const EventFormLayout = ({
  event,
  showTitleBar,
  children,
  ...props
}: EventFormLayoutProps) => {
  return (
    <Center py={{ base: 0, md: '3rem' }} w="full" h="full">
      <VStack
        mt={{ base: 0, md: 9 }}
        py={8}
        px={{ base: 6, md: 10 }}
        spacing={0}
        bgColor="white"
        minH={{ base: 'calc(100vh - 24px)', md: '680px' }}
        w="full"
        maxW="47.5rem"
        mx="auto"
        {...props}
      >
        {showTitleBar && <EventFormTitleBar title={event.title} />}
        <VStack pt={showTitleBar ? 6 : 0} w="full" flex={1}>
          {children}
        </VStack>
      </VStack>
    </Center>
  )
}
