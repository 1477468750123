export const BOOKING_TIME_TEMPLATE_STRING = '{{booking-time}}'

export const BOOKING_TIME_MATCHER = (str: string) =>
  str.match(/\{\{booking-time\}\}/g) || []
export const DEFAULT_EMAIL_BODY = `<p>Thank you for your booking.</p>`

// Follows RFC5322 dot-atom-text allowed characters
// See https://www.rfc-editor.org/rfc/rfc5322
const RFC5322_ATOM = "-a-zA-Z0-9!#$%&'*+/=?^_`{|}~"
const RFC5322_DOT_ATOM = `${RFC5322_ATOM}.`

export const EMAIL_CONFIRMATION_SENDER_NAME_VALID_CHARS_REGEX = new RegExp(
  `^[${RFC5322_DOT_ATOM} ]+$`,
)

// This single rule encapsulates the above checks
export const EMAIL_CONFIRMATION_SENDER_FORMAT_REGEX = new RegExp(
  `^[${RFC5322_ATOM}]+( [${RFC5322_DOT_ATOM}]+)*$`,
)

// Headline message for the different types of emails

export const EMAIL_CONFIRMATION_MESSAGE = "We've received your booking for"
export const EMAIL_RESCHEDULE_MESSAGE = "We've updated your booking for"
export const EMAIL_CANCELLATION_MESSAGE = "We've cancelled your booking for"
export const EMAIL_NOTES_HEADER = 'Note from organiser:'

export const EMAIL_REMINDER_HEADER = 'You have an upcoming booking for'
