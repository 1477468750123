import { useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Text, VStack } from '@chakra-ui/react'

import { AdminEventOutletContext } from '~features/events/types'
import {
  ManageOneOffScheduleForm,
  ManageRecurringScheduleForm,
} from '~features/schedules'

export const Availability = () => {
  const { event } = useOutletContext<AdminEventOutletContext>()

  // For MVP, each event has exactly 1 schedule
  const schedule = useMemo(() => event.schedules[0], [event.schedules])
  const isOneOff = !schedule.segments

  return (
    <VStack spacing={10} alignItems="stretch" w="full">
      <VStack w="full" alignItems="start">
        <Text textStyle="h4">Availability</Text>
        <Text textStyle="body-2">Customise when users can make bookings</Text>
      </VStack>
      {isOneOff ? (
        <ManageOneOffScheduleForm event={event} />
      ) : (
        <ManageRecurringScheduleForm event={event} />
      )}
    </VStack>
  )
}
