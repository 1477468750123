import dayjs from 'dayjs'

import { TimeslotRes } from '~shared/dto'

export type SlotsByDate = Map<number, TimeslotRes[]>

export const getSlotsByDate = (slots: TimeslotRes[]): SlotsByDate => {
  const result: SlotsByDate = new Map()
  slots.forEach(({ startAt, endAt, remainingCapacity }) => {
    const dayStart = dayjs(startAt).startOf('d').valueOf()
    const timeslotObject = { startAt, endAt, remainingCapacity }

    if (result.has(dayStart)) {
      result.get(dayStart)?.push(timeslotObject)
    } else {
      result.set(dayStart, [timeslotObject])
    }
  })
  return result
}
