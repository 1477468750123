import simplur from 'simplur'

import {
  REMINDER_LEAD_TIME_MINS_MAXIMUM,
  REMINDER_LEAD_TIME_MINS_MINIMUM,
} from '~shared/constants'
import { GetScheduleRes, UpdateScheduleReq } from '~shared/dto'
import {
  displayTimeUnitsToMinutes,
  minutesToDisplayTimeUnits,
} from '~shared/utils'

import { minutesToDays, minutesToHours } from '~utils/date'

import { ReminderSettingsFormFields } from './types'

export const validateReminderLeadTimeMinutes = (
  value: number | null,
  formValues: ReminderSettingsFormFields,
): true | string => {
  const { reminderLeadTimeUnit, isReminderLeadTimeEnabled } = formValues
  const reminderLeadTimeMinutes = displayTimeUnitsToMinutes({
    quantity: value,
    unit: reminderLeadTimeUnit,
  })
  if (reminderLeadTimeMinutes === null) {
    if (isReminderLeadTimeEnabled) {
      return `Please enter a number, or disable reminders`
    }
    return true
  }

  if (reminderLeadTimeMinutes < REMINDER_LEAD_TIME_MINS_MINIMUM) {
    return simplur`Reminder lead time must be least ${minutesToHours(
      REMINDER_LEAD_TIME_MINS_MINIMUM,
    )} hour[|s]`
  }

  if (reminderLeadTimeMinutes > REMINDER_LEAD_TIME_MINS_MAXIMUM) {
    return simplur`Reminder lead time can be at most ${minutesToDays(
      REMINDER_LEAD_TIME_MINS_MAXIMUM,
    )} day[|s]`
  }
  return true
}

export const getReminderSettingsFields = ({
  schedule,
}: {
  schedule: GetScheduleRes
}): ReminderSettingsFormFields => {
  const { reminderLeadTimeMinutes } = schedule
  const { quantity: reminderLeadTimeQuantity, unit: reminderLeadTimeUnit } =
    minutesToDisplayTimeUnits(reminderLeadTimeMinutes)
  return {
    isReminderLeadTimeEnabled: reminderLeadTimeMinutes !== null,
    reminderLeadTimeQuantity,
    reminderLeadTimeUnit,
  }
}

export const reminderSettingsFieldsToScheduleReqBody = (
  fields: ReminderSettingsFormFields,
): UpdateScheduleReq => {
  const reminderLeadTimeMinutes = displayTimeUnitsToMinutes({
    quantity: fields.reminderLeadTimeQuantity,
    unit: fields.reminderLeadTimeUnit,
  })

  return {
    reminderLeadTimeMinutes: fields.isReminderLeadTimeEnabled
      ? reminderLeadTimeMinutes
      : null,
  }
}
