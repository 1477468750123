import { Controller, useFormState } from 'react-hook-form'
import { FormControl, VStack } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { Checkbox, FormErrorMessage } from '@opengovsg/design-system-react'

import { GeneralSettingsFormFields } from '../types'

export const ShowRemainingCapacitySettings = () => {
  const { errors } = useFormState<GeneralSettingsFormFields>()
  return (
    <FormControl isInvalid={!!errors.showRemainingCapacity}>
      <Controller
        name="showRemainingCapacity"
        render={({ field: { onChange, value } }) => (
          <Checkbox
            size="sm"
            checked={value as boolean}
            defaultChecked={value as boolean}
            onChange={(val) => onChange(val.target.checked)}
            name="showRemainingCapacity"
          >
            <VStack spacing={0} alignItems="stretch">
              <Text textStyle="subhead-2">
                Show remaining capacity for each timeslot
              </Text>
              <Text textStyle="body-2">
                Attendees will see the number of slots left when making a
                booking
              </Text>
            </VStack>
          </Checkbox>
        )}
      />
      <FormErrorMessage>
        {errors.showRemainingCapacity?.message}
      </FormErrorMessage>
    </FormControl>
  )
}
