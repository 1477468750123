import { Controller, FormProvider, useForm } from 'react-hook-form'
import { BiDownload } from 'react-icons/bi'
import {
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  ModalCloseButton,
} from '@opengovsg/design-system-react'
import {
  DateRangePicker,
  DateRangeValue,
  FormLabel,
} from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import { ButtonStack } from '~components/ButtonStack'

import { useDownloadAdminBookings } from '~features/events/hooks/useDownloadAdminBookings'

export interface DownloadBookingFormReturn {
  dateRange: DateRangeValue
}

export interface DownloadBookingModalProps {
  eventId: string
  isOpen: boolean
  onClose: () => void
}

export const DownloadBookingModal = ({
  eventId,
  isOpen,
  onClose,
}: DownloadBookingModalProps) => {
  const formMethods = useForm<DownloadBookingFormReturn>({
    defaultValues: {
      dateRange: [],
    },
  })
  const {
    reset,
    control,
    formState: { errors },
  } = formMethods

  const downloadAdminBookings = useDownloadAdminBookings({ eventId })

  const onSubmitForm = async ({ dateRange }: DownloadBookingFormReturn) => {
    await downloadAdminBookings({
      eventId,
      startDate: dateRange[0] ? dayjs(dateRange[0]).valueOf() : undefined,
      endDate: dateRange[1] ? dayjs(dateRange[1]).valueOf() : undefined,
    })

    reset()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <FormProvider {...formMethods}>
          <form noValidate onSubmit={formMethods.handleSubmit(onSubmitForm)}>
            <ModalHeader mr="44px">Download bookings</ModalHeader>
            <ModalBody whiteSpace="pre-wrap">
              <FormControl isInvalid={!!errors.dateRange} isRequired={false}>
                <FormLabel>{`Download a specific range`}</FormLabel>
                <Controller
                  control={control}
                  name="dateRange"
                  render={({ field }) => <DateRangePicker {...field} />}
                />
                <FormErrorMessage>{errors.dateRange?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <ButtonStack isReverseOrderOnMobile>
                <Button
                  variant="clear"
                  colorScheme="secondary"
                  onClick={onClose}
                >
                  Cancel
                </Button>

                <Button leftIcon={<BiDownload />} type="submit">
                  Download CSV
                </Button>
              </ButtonStack>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  )
}
